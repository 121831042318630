var CryptoJS = require('crypto-js');

const cipherUtils = {
    encrypt: function (e, r) {
        var t = e || "000102030405060708090a0b0c0d0e0f";
        return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(r), CryptoJS.enc.Hex.parse(t), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.ZeroPadding
        }).ciphertext.toString(CryptoJS.enc.Base64)
    },
    decrypt: function (e, r) {
        var t = e || "000102030405060708090a0b0c0d0e0f";
        return CryptoJS.AES.decrypt(CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Base64.parse(r)
        }), CryptoJS.enc.Hex.parse(t), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.ZeroPadding
        }).toString(CryptoJS.enc.Utf8)
    },
    encodeUrl: function (plainText) {
        return window.btoa(encodeURIComponent(plainText));
    },
    decodeUrl: function (cipherText) {
        return decodeURIComponent(window.atob(cipherText));
    }
};

export default cipherUtils;