import moment from "moment";
import React, { Component } from "react";
import MouseTooltip from "react-sticky-mouse-tooltip";
import AppUtil from "../../../utils/AppUtil";

export default class MouseTooltipCpn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMouseTooltipVisible: false,
            startTime: null
        };
    }

    toggleMouseTooltip(date) {
        const startTime = moment(date).format("HH:mm A");
        this.setState({
            startTime
        });
    }

    onChangeVisible(value) {
        this.setState({
            isMouseTooltipVisible: value
        });
    }
    getVisibile() {
        return this.state.isMouseTooltipVisible;
    }
    onClickMouse() {
        this.setState({
            isMouseTooltipVisible: !this.state.isMouseTooltipVisible
        });
    }

    render() {
        const { startTime, isMouseTooltipVisible } = this.state;
        return (
            <MouseTooltip visible={isMouseTooltipVisible} offsetX={15} offsetY={10}>
                {startTime && <span>{startTime}</span>}
            </MouseTooltip>
        );
    }
}
