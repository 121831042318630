import React, { PureComponent } from "react";
import Container from "@idtek/component/lib/container/Container";
import moment from "moment";
import Tooltip from "@idtek/component/lib/tooltip/Tooltip";

export default class StatisticOfDay extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.refStatistic && !this.refStatistic.contains(event.target)) {
            this.setState({ show: false });
        }
    }

    render() {
        const bookings = this.props.bookings;
        let totalPrice = 0;
        let price = 0;
        let totalBooking = 0;
        let completedBooking = 0;
        let waitingBooking = 0;
        let processingBooking = 0;
        let cancelBooking = 0;
        let noShowBooking = 0;
        // eslint-disable-next-line array-callback-return
        bookings.map((booking) => {
            totalBooking += 1;
            switch (booking.status) {
                case "Waiting":
                    totalPrice += booking.totalAmount;
                    waitingBooking += 1;
                    break;
                case "Processing":
                    totalPrice += booking.totalAmount;
                    processingBooking += 1;
                    break;
                case "Completed":
                    price += booking.totalAmount;
                    totalPrice += booking.totalAmount;
                    completedBooking += 1;
                    break;
                case "Cancel":
                    cancelBooking += 1;
                    break;
                case "NoShow":
                    noShowBooking += 1;
                    break;
                default:
                    break;
            }
        });
        return (
            <Container className="statistic-of-day">
                <Tooltip title="Statistics of the day!" placement="topRight">
                    <div className="button-statistic" onClick={() => this.setState({ show: !this.state.show })}>
                        <i className="fa fa-bar-chart" />
                    </div>
                    <div className="popup-statistic" hidden={!this.state.show} ref={(refS) => (this.refStatistic = refS)}>
                        <div className="statistic-info">
                            <div className="title-statistic">Daily performance summary: {moment(this.props.date).format("DD/MM/YYYY")}</div>
                            <div className="content-statistic">
                                <div className="row-info">
                                    <span className="text-info">The actual receipts</span>
                                    <span className="value-info">{price.toFixed(2)} $</span>
                                </div>
                                <div className="row-info">
                                    <span className="text-info">The projected turnover</span>
                                    <span className="value-info">{totalPrice.toFixed(2)} $</span>
                                </div>
                                <div className="row-info">
                                    <span className="text-info">Total bookings</span>
                                    <span className="value-info">{totalBooking}</span>
                                </div>
                                <div className="row-info-child">
                                    <span className="text-info">Waiting bookings</span>
                                    <span className="value-info">{waitingBooking}</span>
                                </div>
                                <div className="row-info-child">
                                    <span className="text-info">Processing bookings</span>
                                    <span className="value-info">{processingBooking}</span>
                                </div>
                                <div className="row-info-child">
                                    <span className="text-info">Completed bookings</span>
                                    <span className="value-info">{completedBooking}</span>
                                </div>
                                <div className="row-info-child">
                                    <span className="text-info">Canceled bookings</span>
                                    <span className="value-info">{cancelBooking}</span>
                                </div>
                                <div className="row-info-child">
                                    <span className="text-info">NoShow bookings</span>
                                    <span className="value-info">{noShowBooking}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tooltip>
            </Container>
        );
    }
}
