import Alert from "@idtek/component/lib/alert/Alert";
import Combobox from "@idtek/component/lib/combobox/Combobox";
import Container from "@idtek/component/lib/container/Container";
import Icon from "@idtek/component/lib/icon/Icon";
import Loading from "@idtek/component/lib/loading/Loading";
import Modal from "@idtek/component/lib/modal/Modal";
import Popover from "@idtek/component/lib/popover/Popover";
import Tooltip from "@idtek/component/lib/tooltip/Tooltip";
import { Timeline } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { loadComboboxDataBookingForm, notResetStore, updateBrandInAuthData } from "../../application/actions/action";
import MainDrawer from "../../application/components/MainDrawer";
import Overlay from "../../common/component/Overlay";
import GlobalStore from "../../GlobalStore";
import AppStore from "../../store";
import AppUtil from "../../utils/AppUtil";
import ReactDatePickerCustom from "../booking/component/ReactDatePickerCustom";
import UnUse_FormBookingCreate from "../booking/form/UnUse_FormBookingCreate";
import UnUse_FormBookingUpdate from "../booking/form/UnUse_FormBookingUpdate";
import ComponentDrawerBooking from "./component/ComponentDrawerBooking";
import SelectCustomComponent from "./component/SelectCustomComponent";
import StatisticOfDay from "./component/StatisticOfDay";
import ThreeDotButton from "./component/ThreeDotButton";
import FormPaymentInDashboard from "./form/FormPaymentInDashboard";
import IconCancel from "./icon/IconCancel";
import IconClock from "./icon/IconClock";
import IconMoney from "./icon/IconMoney";
import IconPlus from "./icon/IconPlus";
import "./style/dashboard-v2.scss";
import "./style/dashboard.scss";

class DashboardIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scheduleBooking: [],
            rendererTable: false,
            technician: 12,
            branchId: _.get(props, "authData.user.branchId")
        };
        this.branchId = _.get(props, "authData.user.branchId");
        this.intervalTotalNewBooking = setInterval(this.loadTotalNewBooking, 30000);
    }

    saveUi = "confirm toolbar";
    closeText = "Cancel";
    saveIconCls = "fa fa-floppy-o";
    saveApi = undefined;

    time = 14;
    startTime = 8;
    filterDate = moment().format("YYYY-MM-DD");

    minutesOfDay(m) {
        return m.minutes() + m.hours() * 60;
    }

    componentDidMount() {
        // load default combobox data
        this.loadTotalNewBooking();
        this.loadData();
        loadComboboxDataBookingForm(this.props.dispatch);
    }

    componentWillUnmount() {
        clearInterval(this.intervalTotalNewBooking);
    }

    loadTotalNewBooking = async () => {
        const branch = this.state.branchId;
        const params = {
            branch: branch ? branch : 1
        };
        const res = await AppUtil.getApi("/booking/get-new", params);

        if (_.get(res, "data.success")) {
            const totalNewBooking = _.get(res, "data.totalCount");
            this.ThreeDotButton && this.ThreeDotButton.setCount(totalNewBooking);
        }
    };

    scrollToHours() {
        const hours = parseInt(moment().format("HH"));
        let y = 0;
        if (hours < this.startTime) {
            y = 0;
        } else if (hours > this.startTime + this.time - 1) {
            y = this.startTime - 1;
        } else {
            y = hours - this.startTime;
        }
        let divLeft = document.getElementById("left-content-schedules-booking");
        let divRight = document.getElementById("right-content-schedules-booking");
        if (divRight) divRight.scrollTop = y * 480;
        if (divRight && divLeft) divLeft.scrollTop = divRight.scrollTop;
    }

    async selectPaymentMethod(bookingId, paymentMethodId) {
        const data = { bookingId: bookingId, paymentMethodId: paymentMethodId };
        const response = await AppUtil.postApi("/booking/select-payment-method", data);
        if (response.data.success) {
            return response.data.template;
        }
        return "Template not exist!";
    }

    async handlerSelectPayment(bookingId, paymentMethodId) {
        //close
        this.ModalMethod && this.ModalMethod.onClose();
        const template = await this.selectPaymentMethod(bookingId, paymentMethodId);
        AppUtil.printBookingByTemplate(template);
    }

    async getTemplateBill(id) {
        const response = await AppUtil.getApi("/booking/get-bill/" + id);
        if (response.data.success) {
            return response.data.template;
        }
        return "Template not exist!";
    }

    async doActionBooking(actionName, booking) {
        //Check action bill
        const submitValue = { isScheduleBooking: true };
        if (actionName === "bill") {
            const { paymentMethod } = this.props.authData;
            if (paymentMethod.length > 0) {
                this.handlerChoosePaymentMethod(booking);
            } else {
                const template = await this.getTemplateBill(booking.id);
                AppUtil.printBookingByTemplate(template);
            }
        } else {
            this.refOverlay && this.refOverlay.open(<span>Please waiting...</span>);
            const url = `/booking/${actionName}?id=` + booking.id;
            const res = await AppUtil.postApi(url, submitValue);
            if (_.get(res, "data.success")) {
                this.loadData(null, false);
            } else {
                this.refOverlay && this.refOverlay.close();
                this.checkMessage(_.get(res, "data.message"), actionName, booking.id);
            }
        }
    }

    onProceedPayment(booking) {
        const { authData } = this.props;
        const { paymentMethod } = this.props.authData;
        const branch = this.state.branchId;
        this.ModalMethod.onOpen(
            <FormPaymentInDashboard
                id={booking.id}
                onClose={() => {
                    this.ModalMethod.onClose();
                }}
                onReloadData={() => {
                    this.loadData(null, false);
                }}
                thueVAT={_.get(authData, "gst")}
                // booking={booking}
                user={_.get(authData, "user.fullName")}
                paymentMethod={paymentMethod}
                branch={branch}
            />,
            <span>Take Payment</span>,
            800
        );
    }

    async checkMessage(message, actionName, bookingId) {
        if (message) {
            let confirm = await Alert.Swal_confirm("WARNING", message, 3);
            if (_.get(confirm, "value") === true) {
                const obj = {};
                obj.unCheckWarning = true;
                obj.isScheduleBooking = true;
                obj.id = bookingId;
                this.refOverlay && this.refOverlay.open(<span>Please waiting...</span>);
                const res = await AppUtil.postApi(`/booking/${actionName}`, obj);
                if (_.get(res, "data.success")) {
                    this.loadData(null, false);
                }
            }
        }
    }

    handlerChoosePaymentMethod(booking) {
        this.ModalMethod.onOpen(this.renderPaymentMethod(booking), <span>Choose payment method</span>, 450);
    }

    renderPaymentMethod(booking) {
        const { paymentMethod } = this.state;
        return (
            <Container layout="fit">
                <div className="list-payment-method">
                    {paymentMethod.map((method, index) => {
                        return (
                            <div
                                key={index}
                                className={"method"}
                                onDoubleClick={() => this.handlerSelectPayment(booking.id, method.id)}
                                onClick={() => this.handlerSelectPayment(booking.id, method.id)}>
                                <span className={"title"}>{method.name}</span>
                                {this.renderInfoFee(method)}
                                <span className={"select-method"} onClick={() => this.handlerSelectPayment(booking.id, method.id)}>
                                    <i className={"fa fa-check"} />
                                </span>
                            </div>
                        );
                    })}
                </div>
            </Container>
        );
    }

    renderInfoFee(method) {
        if (method.fee > 0) {
            return <span className={"fee"}>Fee: {method.fee}</span>;
        }
        if (method.fee < 0) {
            return <span className={"fee"}>DISCOUNT: {method.fee}</span>;
        }
        if (method.percentFee > 0) {
            return <span className={"fee"}>Fee: {method.percentFee}%</span>;
        }
        if (method.percentFee < 0) {
            return <span className={"fee"}>DISCOUNT: {method.percentFee}%</span>;
        }
        return "";
    }

    showConfirm = async (titleConfirm, messageConfirm, callback) => {
        let confirm = await Alert.Swal_confirm(titleConfirm, messageConfirm, 3);
        if (_.get(confirm, "value") === true) {
            callback && callback();
        }
    };

    async loadData(date, scrollToCurrentHour = true) {
        const me = this;
        const branch = this.state.branchId;
        // this.container && this.container.mask();
        this.refOverlay && this.refOverlay.open(<span>Please waiting...</span>);
        const params = {
            date: date ? date : this.filterDate ? this.filterDate : moment().format("YYYY-MM-DD"),
            branch: branch ? branch : 1
        };
        if (params.branch) {
            const response = await AppUtil.getApi("/booking/schedule-booking", params);
            if (_.get(response, "data.success")) {
                this.refOverlay && this.refOverlay.close();
                const { data } = response;
                const results = _.get(data, "results", []);
                const paymentMethod = _.get(data, "paymentMethod", []);
                const numTechnicianWorking = _.get(data, "technician", 10);
                this.startTime = _.get(data, "startTime") ? parseInt(data.startTime) : 9;
                this.time = _.get(data, "timeWork") ? parseInt(data.timeWork) : 10;
                me.setState({
                    scheduleBooking: results,
                    rendererTable: true,
                    technician: numTechnicianWorking,
                    paymentMethod: paymentMethod
                });
                if (scrollToCurrentHour) {
                    this.scrollToHours();
                }
            } else {
                this.refOverlay && this.refOverlay.close();
            }
        }
    }

    round(date, duration, method) {
        return moment(Math[method](+date / +duration) * +duration);
    }

    filterBookingRow(time) {
        var me = this;
        return _.filter(this.state.scheduleBooking, function (booking) {
            const timeRound = me.round(moment(booking.startTime), moment.duration(15, "minutes"), "floor").format("HH:mm");
            return timeRound === time && booking.status !== "Cancel" && booking.status.toUpperCase() !== "NEW";
        });
    }

    renderRowTimeSchedule() {
        const me = this;
        const contents = [];
        let y = 0;
        for (let i = 0; i < this.time; i++) {
            for (let j = 0; j < 4; j++) {
                let time = "";
                let time24h = "";
                let mins = j * 15 === 0 ? "00" : j * 15;
                if (i + this.startTime < 10) {
                    time = "0" + (i + this.startTime) + ":" + mins + " AM";
                    time24h = "0" + (i + this.startTime) + ":" + mins;
                } else {
                    time24h = i + this.startTime + ":" + mins;
                    if (i + this.startTime === 12) {
                        time = i + this.startTime + ":" + mins + " PM";
                    } else if (i + this.startTime > 12) {
                        time = i + this.startTime - 12 + ":" + mins + " PM";
                    } else {
                        time = i + this.startTime + ":" + mins + " AM";
                    }
                }
                y++;
                const bookings = this.filterBookingRow(time24h);
                const bookingsWithNoteClass = bookings.filter((booking) => booking.description !== null).length > 0 ? "li-bookings-with-note" : "";
                const showCreate = this.minutesOfDay(moment(time24h, "HH:mm")) <= this.minutesOfDay(moment());
                if (j === 0) {
                    contents.push(
                        <li key={y} className={"li-bookings background-hours " + bookingsWithNoteClass}>
                            <span className="total-booking">{bookings.length}</span>
                            <span className="time-booking">{time} </span>
                            {me.filterDate === moment().format("YYYY-MM-DD") && bookings.length < 12 ? (
                                <div className="booking-in-time" style={{ width: 100 + "%" }}>
                                    <div className="action-in-time">
                                        <button
                                            onClick={() => {
                                                const dateTime = me.filterDate + " " + time24h;
                                                const brandId = me.comboboxBranch ? me.comboboxBranch.getValue() : 1;
                                                me.onClickCreateBooking(dateTime, brandId);
                                            }}
                                            disabled={showCreate}
                                            className={showCreate ? "btn-kiritm-create-booking btn-disabled" : "btn-kiritm-create-booking"}
                                            title="Create booking">
                                            <IconPlus />
                                        </button>
                                    </div>
                                </div>
                            ) : null}
                            {me.filterDate > moment().format("YYYY-MM-DD") && bookings.length < 12 ? (
                                <div className="booking-in-time" style={{ width: 100 + "%" }}>
                                    <div className="action-in-time">
                                        <button
                                            onClick={() => {
                                                const dateTime = me.filterDate + " " + time24h;
                                                const brandId = me.comboboxBranch ? me.comboboxBranch.getValue() : 1;
                                                me.onClickCreateBooking(dateTime, brandId);
                                            }}
                                            className="btn-kiritm-create-booking"
                                            title="Create booking">
                                            <IconPlus />
                                        </button>
                                    </div>
                                </div>
                            ) : null}
                        </li>
                    );
                } else if (j === 2) {
                    contents.push(
                        <li key={y} className={"li-bookings background-line " + bookingsWithNoteClass}>
                            <span className="total-booking">{bookings.length}</span>
                            <span className="time-booking">{time} </span>
                            {me.filterDate === moment().format("YYYY-MM-DD") && bookings.length < 12 ? (
                                <div className="booking-in-time" style={{ width: 100 + "%" }}>
                                    <div className="action-in-time">
                                        <button
                                            onClick={() => {
                                                const dateTime = me.filterDate + " " + time24h;
                                                const brandId = me.comboboxBranch ? me.comboboxBranch.getValue() : 1;
                                                me.onClickCreateBooking(dateTime, brandId);
                                            }}
                                            disabled={showCreate}
                                            className={showCreate ? "btn-kiritm-create-booking btn-disabled" : "btn-kiritm-create-booking"}
                                            title="Create booking">
                                            <IconPlus />
                                        </button>
                                    </div>
                                </div>
                            ) : null}
                            {me.filterDate > moment().format("YYYY-MM-DD") && bookings.length < 12 ? (
                                <div className="booking-in-time" style={{ width: 100 + "%" }}>
                                    <div className="action-in-time">
                                        <button
                                            onClick={() => {
                                                const dateTime = me.filterDate + " " + time24h;
                                                const brandId = me.comboboxBranch ? me.comboboxBranch.getValue() : 1;
                                                me.onClickCreateBooking(dateTime, brandId);
                                            }}
                                            className="btn-kiritm-create-booking"
                                            title="Create booking">
                                            <IconPlus />
                                        </button>
                                    </div>
                                </div>
                            ) : null}
                        </li>
                    );
                } else {
                    contents.push(
                        <li key={y} className={"li-bookings " + bookingsWithNoteClass}>
                            <span className="total-booking">{bookings.length}</span>
                            <span className="time-booking">{time} </span>
                            {me.filterDate === moment().format("YYYY-MM-DD") && bookings.length < 12 ? (
                                <div className="booking-in-time" style={{ width: 100 + "%" }}>
                                    <div className="action-in-time">
                                        <button
                                            onClick={() => {
                                                const dateTime = me.filterDate + " " + time24h;
                                                const brandId = me.comboboxBranch ? me.comboboxBranch.getValue() : 1;
                                                me.onClickCreateBooking(dateTime, brandId);
                                            }}
                                            disabled={showCreate}
                                            className={showCreate ? "btn-kiritm-create-booking btn-disabled" : "btn-kiritm-create-booking"}
                                            title="Create booking">
                                            <IconPlus />
                                        </button>
                                    </div>
                                </div>
                            ) : null}
                            {me.filterDate > moment().format("YYYY-MM-DD") && bookings.length < 12 ? (
                                <div className="booking-in-time" style={{ width: 100 + "%" }}>
                                    <div className="action-in-time">
                                        <button
                                            onClick={() => {
                                                const dateTime = me.filterDate + " " + time24h;
                                                const brandId = me.comboboxBranch ? me.comboboxBranch.getValue() : 1;
                                                me.onClickCreateBooking(dateTime, brandId);
                                            }}
                                            className="btn-kiritm-create-booking"
                                            title="Create booking">
                                            <IconPlus />
                                        </button>
                                    </div>
                                </div>
                            ) : null}
                        </li>
                    );
                }
            }
        }
        return <ul className="bookings">{contents}</ul>;
    }

    renderHeaderSchedule() {
        const contents = [];
        const width = (1 / this.state.technician) * 100;
        const widthRightScheduleHeader = this.state.technician * 224;
        for (let i = 0; i < this.state.technician; i++) {
            contents.push(
                <li key={i} className="time-schedule" style={{ width: width + "%" }}>
                    <span className="text-time">{i + 1}</span>
                </li>
            );
        }
        return (
            <ul className="header-time-schedule" style={{ width: widthRightScheduleHeader + "px" }}>
                {contents}
            </ul>
        );
    }

    renderTableSchedule() {
        const me = this;
        const contents = [];
        const widthRightSchedule = me.state.technician * 224;
        let x = 0;
        for (let i = 0; i < this.time; i++) {
            for (let j = 0; j < 4; j++) {
                let time = "";
                let mins = j * 15 === 0 ? "00" : j * 15;
                if (i + this.startTime < 10) {
                    time = "0" + (i + this.startTime) + ":" + mins;
                } else {
                    time = i + this.startTime + ":" + mins;
                }
                x++;
                const bookings = this.filterBookingRow(time);
                const bookingsWithNoteClass = bookings.filter((booking) => booking.description !== null).length > 0 ? "li-schedules-booking-with-note" : "";
                if (j === 0) {
                    contents.push(
                        <li key={x} className={"li-schedules-booking background-hours " + bookingsWithNoteClass}>
                            {this.renderColSchedule()}
                            {this.renderBookingSchedule(bookings, time)}
                        </li>
                    );
                } else if (j === 2) {
                    contents.push(
                        <li key={x} className={"li-schedules-booking background-line " + bookingsWithNoteClass}>
                            {this.renderColSchedule()}
                            {this.renderBookingSchedule(bookings, time)}
                        </li>
                    );
                } else {
                    contents.push(
                        <li key={x} className={"li-schedules-booking " + bookingsWithNoteClass}>
                            {this.renderColSchedule()}
                            {this.renderBookingSchedule(bookings, time)}
                        </li>
                    );
                }
            }
        }
        return (
            <ul className="schedules-booking" style={{ width: widthRightSchedule + "px" }}>
                {contents}
            </ul>
        );
    }
    onClickCreateBooking(dateTime, branchId) {
        this.Modal.onOpen(
            <UnUse_FormBookingCreate
                ref={(c) => (this.refCreateBooking = c)}
                onClose={() => {
                    this.Modal.onClose();
                }}
                onReloadData={() => {
                    this.loadData(null, false);
                }}
                startTime={dateTime}
            />,
            <span>
                <span style={{ position: "relative", marginRight: 7, display: "flex", alignItems: "center" }}>
                    <Icon type="form" style={{ fontSize: 16, display: "flex", color: "var(--main-color-blue-ocean)" }} />
                    <span style={{ fontSize: 15, display: "flex", marginLeft: 7 }}>Create booking</span>
                </span>
            </span>,
            "1000px"
        );
    }

    onClone(bookingData) {
        bookingData.status = "Waiting";
        AppStore.dispatch(notResetStore(true));
        this.Modal && this.Modal.onClose();
        this.ModalClone.onOpen(
            <UnUse_FormBookingCreate
                initData={bookingData}
                cloneView={true}
                ref={(c) => (this.refCloneBooking = c)}
                onClose={() => {
                    AppStore.dispatch(notResetStore(false));
                    this.ModalClone.onClose();
                }}
                onReloadData={() => {
                    this.loadData(null, false);
                }}
                startTime={_.get(bookingData, "startTime")}
            />,
            <span>
                <span style={{ position: "relative", marginRight: 7, display: "flex", alignItems: "center" }}>
                    <Icon type="form" style={{ fontSize: 16, display: "flex", color: "var(--main-color-blue-ocean)" }} />
                    <span style={{ fontSize: 15, display: "flex", marginLeft: 7 }}>{`Clone booking from ${bookingData.code}`}</span>
                </span>
            </span>,
            "1000px"
        );
    }

    onClickUpdateBooking(bookingData, id) {
        const booking = _.cloneDeep(bookingData);
        let title = "Update booking";
        if (booking) {
            title = `Update booking ${booking.code} - Status: ${booking.status}`;
            if (booking.status === "Completed") {
                title = "Detail booking";
            }
        }
        if (booking || id) {
            this.Modal.onOpen(
                <UnUse_FormBookingUpdate
                    ref={(c) => (this.refUpdateBooking = c)}
                    onClose={() => {
                        this.Modal.onClose();
                    }}
                    onReloadData={() => {
                        this.loadData(null, false);
                    }}
                    onClone={() => this.onClone(booking)}
                    id={booking ? booking.id : id}
                    record={booking}
                    buttons={[
                        {
                            text: "Close",
                            className: "btn-danger",
                            icon: <Icon type="close-square" style={{ fontSize: 16 }} />,
                            onPress: this.onClose
                        }
                    ]}
                />,
                <span>
                    <span style={{ position: "relative", marginRight: 7, display: "flex", alignItems: "center" }}>
                        <Icon type="form" style={{ fontSize: 16, display: "flex", color: "var(--main-color-blue-ocean)" }} />
                        <span style={{ fontSize: 15, display: "flex", marginLeft: 7 }}>{title}</span>
                    </span>
                </span>,
                "1000px"
            );
        }
    }

    onClickCloneBooking(bookingData) {
        this.createBookingDialog.getWrappedInstance().showDialogWithCloneData(bookingData);
    }

    renderBookingSchedule(bookings, time) {
        const me = this;
        const width = (1 / this.state.technician) * 100;
        return (
            <div className="list-booking-in-time" style={{ display: "flex" }}>
                {bookings.map((booking, index) => {
                    let canDoStart = true;
                    for (let i = 0; i < booking.bookingItems.length; i++) {
                        if (booking.bookingItems[i].technician_id === null) {
                            canDoStart = false;
                        }
                    }
                    let technicians = "";
                    booking.bookingItems.forEach((item, indext) => {
                        if (technicians.indexOf(item.technician_name) === -1) {
                            technicians = technicians + item.technician_name + (indext !== booking.bookingItems.length - 1 ? ", " : "");
                        }
                    });
                    return (
                        <div className="booking-in-time-wrap" style={{ width: `${width}%` }}>
                            <div className={"booking-in-time pointer " + booking.status.toLocaleLowerCase()} style={{ width: `100%` }} key={index}>
                                <div className="left-booking-in-time" onDoubleClick={() => this.onClickUpdateBooking(booking)} onClick={() => this.onClickUpdateBooking(booking)}>
                                    <span className="title-value">
                                        INFORMATION -
                                        <span className="highlight-value" style={{ paddingLeft: 3 }}>
                                            {" "}
                                            {booking.code}
                                        </span>
                                    </span>
                                    <span className="title-value">
                                        <span className="title-in-db">Status: </span>
                                        <span className={"schedule-status " + booking.status}>{booking.status}</span>
                                    </span>
                                    <span className="title-value">
                                        <span className="title-in-db">Name: </span>
                                        {_.size(booking.customerNotes) > 0 ? (
                                            <Popover
                                                placement="topLeft"
                                                trigger="hover"
                                                overlayClassName="customer-note-info"
                                                title={"Customer notes"}
                                                getPopupContainer={(trigger) => trigger.parentNode.closest(".popup-container") || document.body}
                                                content={
                                                    <Timeline mode={"left"}>
                                                        {_.map(booking.customerNotes, (item) => {
                                                            return <Timeline.Item>{_.get(item, "note")}</Timeline.Item>;
                                                        })}
                                                    </Timeline>
                                                }>
                                                <span className="highlight-value">{booking.customerName}</span>
                                            </Popover>
                                        ) : (
                                            <span className="highlight-value">{booking.customerName}</span>
                                        )}
                                    </span>
                                    <span className="title-value">
                                        <span className="title-in-db">Phone: </span>
                                        <span>{AppUtil.formatPhone(booking.phone)}</span>
                                    </span>
                                    <Tooltip
                                        title={booking.bookingItems.map((item, indexx) => {
                                            return item.code + ", ";
                                        })}>
                                        <span className="title-value">
                                            <span className="title-in-db">Services: </span>
                                            <span style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}>
                                                {booking.bookingItems.map((item, indext) => {
                                                    return item.code + (indext !== booking.bookingItems.length - 1 ? ", " : "");
                                                })}
                                            </span>
                                        </span>
                                    </Tooltip>
                                    <Tooltip title={technicians}>
                                        <span className="title-value">
                                            <span className="title-in-db">Techn: </span>
                                            <span className="highlight-technician">{technicians}</span>
                                        </span>
                                    </Tooltip>
                                    {booking.description !== null ? (
                                        <Tooltip title={booking.description}>
                                            <span className="title-value">
                                                <span className="title-in-db">Note: </span>
                                                <span className="highlight-description">{booking.description}</span>
                                            </span>
                                        </Tooltip>
                                    ) : null}
                                </div>
                                <div className="col-action">
                                    {new Date(me.filterDate) >= new Date(moment().format("YYYY-MM-DD")) ? (
                                        <div className="list-action-booking">
                                            {canDoStart ? (
                                                <Tooltip title="Start">
                                                    <button
                                                        onClick={async () => {
                                                            this.showConfirm("START", `Are you sure you want to start booking ${booking.code}?`, this.doActionBooking.bind(this, "start", booking));
                                                        }}
                                                        hidden={!(booking.status === "Waiting" || booking.status === "Checked in")}
                                                        className="btn-kiritm-action">
                                                        <IconClock />
                                                    </button>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Choose technician">
                                                    <button
                                                        onClick={() => this.onClickUpdateBooking(booking)}
                                                        hidden={!(booking.status === "Waiting" || booking.status === "Checked in")}
                                                        className="btn-kiritm-action">
                                                        <IconClock />
                                                    </button>
                                                </Tooltip>
                                            )}
                                            {_.size(_.get(this.props.authData, "paymentMethod")) > 0 ? (
                                                <Tooltip title="Take Payment">
                                                    <button onClick={() => this.onProceedPayment(booking)} hidden={!(booking.status === "Completed")} className="btn-kiritm-action">
                                                        {/* <Icon type="dollar" style={{ fontSize: 18 }} /> */}
                                                        <IconMoney fill={"#fff"} />
                                                    </button>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Print Bill">
                                                    <button
                                                        onClick={this.doActionBooking.bind(this, "bill", booking)}
                                                        hidden={!(booking.status === "Processing" || booking.status === "Completed")}
                                                        className="btn-kiritm-action">
                                                        <Icon type="printer" style={{ fontSize: 16 }} />
                                                    </button>
                                                </Tooltip>
                                            )}
                                            <Tooltip title="Complete">
                                                <button
                                                    onClick={() =>
                                                        this.showConfirm("COMPLETE", `Are you sure you want to complete ${booking.code}?`, this.doActionBooking.bind(this, "complete", booking))
                                                    }
                                                    hidden={booking.status !== "Processing"}
                                                    className="btn-kiritm-action btn-kiritm-complete">
                                                    <i className="fa fa-check" />
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Cancel">
                                                <button
                                                    onClick={() =>
                                                        this.showConfirm("CANCEL", `Are you sure you want to cancel booking ${booking.code}?`, this.doActionBooking.bind(this, "cancel", booking))
                                                    }
                                                    hidden={!booking.status === "Cancel" || !(booking.status === "Waiting")}
                                                    className="btn-kiritm-action btn-kiritm-cancel">
                                                    <IconCancel />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    renderColSchedule() {
        const contents = [];
        const width = (1 / this.state.technician) * 100;
        for (let i = 0; i < this.state.technician; i++) {
            contents.push(<span key={i} className="time-schedule-col" style={{ width: width + "%" }}></span>);
        }
        return <div className="time-schedule-row">{contents}</div>;
    }

    onChangeFilterDate(date) {
        this.filterDate = moment(date).format("YYYY-MM-DD");
        this.loadData(date);
    }

    onChangeDate(key) {
        let date = moment().format("YYYY-MM-DD");
        switch (key) {
            case "prev":
                date = moment(this.filterDate).add(-1, "days").format("YYYY-MM-DD");
                break;
            case "next":
                date = moment(this.filterDate).add(1, "days").format("YYYY-MM-DD");
                break;
            case "today":
                date = moment().format("YYYY-MM-DD");
                break;
            default:
                date = moment().format("YYYY-MM-DD");
                break;
        }
        this.filterDate = date;
        this.loadData(date);
    }

    onShowDrawer = () => {
        GlobalStore.MainDrawer &&
            GlobalStore.MainDrawer.open(
                <Suspense fallback={<Loading />}>
                    <ComponentDrawerBooking ref={(c) => (this.drawer = c)} />
                </Suspense>,
                null,
                "33%"
            );
    };
    onSelectBranch(val) {
        AppStore.dispatch(updateBrandInAuthData(this.props.authData, val));
        this.loadData(this.filterDate);
        this.setState({
            branchId: val
        });
    }

    onScroll = (me) => {
        let divLeft = document.getElementById("left-content-schedules-booking");
        let divTopRight = document.getElementById("right-content-header-schedules-booking");
        let divRight = document.getElementById("right-content-schedules-booking");
        if (divRight && divLeft) divLeft.scrollTop = divRight.scrollTop;
        if (divRight && divTopRight) divTopRight.scrollLeft = divRight.scrollLeft;
    };

    renderModal() {
        return (
            <Modal
                ref={(component) => {
                    this.ModalMethod = component;
                }}
                onClose={this.onCloseDefault}
                maskClosable={false}
                className="popup-crud popup-container popup-method"
            />
        );
    }
    renderModalClone() {
        return (
            <Modal
                ref={(component) => {
                    this.ModalClone = component;
                }}
                onClose={this.onCloseDefault}
                maskClosable={false}
                className="popup-crud popup-container popup-in-dashboard"
            />
        );
    }

    renderModalFormBooking() {
        return (
            <Modal
                ref={(component) => {
                    this.Modal = component;
                }}
                onClose={this.onCloseDefault}
                maskClosable={false}
                className="popup-crud popup-container popup-in-dashboard"
            />
        );
    }

    renderOverlay() {
        return (
            <Overlay
                ref={(component) => (this.refOverlay = component)}
                style={{
                    padding: 15,
                    borderRadius: 3,
                    zIndex: 9
                }}
            />
        );
    }

    renderDrawer() {
        return (
            <MainDrawer
                ref={(component) => (GlobalStore.MainDrawer = component)}
                onClose={() => {
                    if (this.drawer.checkChangeData()) {
                        setTimeout(() => {
                            this.loadTotalNewBooking();
                            this.loadData(null, false);
                        }, 300);
                    }
                }}
            />
        );
    }
    render() {
        const me = this;
        return (
            <Container ref={(c) => (this.container = c)} style={{ height: "100%", width: "100%", position: "absolute", top: 56, background: "#EAEFF3" }}>
                <div className="dashboard-v2" style={{ width: "100%", padding: "16px 21px 16px 21px" }}>
                    <div>
                        <div className="header-schedules overflow-visible filter-date" style={{ display: "flex" }}>
                            <div className="filter-left">
                                <div style={{ flex: 1, width: 350 }}>
                                    <SelectCustomComponent
                                        branch={this.props.authData.user.branchId}
                                        date={this.filterDate}
                                        onChange={(data) => {
                                            me.onClickUpdateBooking(null, data);
                                        }}
                                    />
                                </div>
                                <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                                    <div style={{ padding: "0px 10px 0px 20px", fontSize: 14, color: "#272829" }}>Date</div>
                                    <div className="today">
                                        <button onClick={() => this.onChangeDate("today")} className="btn-date-filer today-date">
                                            TODAY
                                        </button>
                                    </div>
                                    <div className="date-cpn-filter">
                                        <Icon type="left" className="prev" onClick={() => this.onChangeDate("prev")} />
                                        <ReactDatePickerCustom
                                            ref={(ref) => (this.dateFilter = ref)}
                                            value={this.filterDate}
                                            onChange={this.onChangeFilterDate.bind(this)}
                                            dateFormat={"MMM/dd/yyyy"}
                                        />
                                        <Icon type="right" className="next" onClick={() => this.onChangeDate("next")} />
                                    </div>
                                </div>
                            </div>

                            <div
                                className="filter-right"
                                style={{
                                    justifyContent: "flex-end",
                                    flex: 1,
                                    display: "flex"
                                }}>
                                <div
                                    className="filter-branch"
                                    style={{
                                        // display: this.props.authData.user.branches.length < 2 ? 'none' : '',
                                        width: 250
                                    }}>
                                    <Combobox
                                        ref={(ref) => (this.comboboxBranch = ref)}
                                        value={this.state.branchId}
                                        placeholder="Select branch..."
                                        isClearable={false}
                                        options={this.props.authData.user.branches}
                                        valueKey="id"
                                        labelKey="name"
                                        onChange={(val) => this.onSelectBranch(val)}
                                    />
                                </div>
                                <StatisticOfDay bookings={this.state.scheduleBooking} date={this.filterDate} />
                                <ThreeDotButton ref={(c) => (this.ThreeDotButton = c)} onClick={this.onShowDrawer} />
                            </div>
                        </div>
                        <div className="wrap-dashboard-v2">
                            <div
                                className={"content-header-wrap"}
                                style={{
                                    width: "100%",
                                    overflow: "hidden"
                                }}>
                                <div ref={"content-schedules-header"} className="content-schedules" style={{ display: "flex" }}>
                                    <div className="left-content-schedules-booking" style={{ width: 80 }}>
                                        <div className="title">Time/No</div>
                                    </div>
                                    <div id={"right-content-header-schedules-booking"} className="right-content-header-schedules-booking" style={{ flex: 1, marginLeft: 3, overflow: "hidden" }}>
                                        {this.renderHeaderSchedule()}
                                    </div>
                                </div>
                            </div>
                            <div ref={"content-schedules"} className="content-schedules" style={{ display: "flex", overflow: "hidden" }}>
                                {this.renderOverlay()}

                                <div id={"left-content-schedules-booking"} className="left-content-schedules-booking" style={{ overflow: "hidden" }}>
                                    {this.renderRowTimeSchedule()}
                                    <div className="scroll-mark"></div>
                                </div>
                                <div
                                    id={"right-content-schedules-booking"}
                                    className="right-content-schedules-booking"
                                    style={{ flex: 1, overflow: "auto", marginLeft: 6 }}
                                    onScroll={(val) => this.onScroll(me)}>
                                    {this.state.rendererTable ? this.renderTableSchedule() : null}
                                </div>
                            </div>
                            {this.renderDrawer()}
                        </div>
                        {this.renderModalFormBooking()}
                        {this.renderModal()}
                        {this.renderModalClone()}
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return { ...(state.root || {}) };
};

export default connect(mapStateToProps)(DashboardIndex);
