import { lazy } from "react";
const BookingIndex = lazy(() => import("./modules/booking/BookingIndex"));
const CustomerListView = lazy(() => import("./modules/customer/CustomerListView"));
const BranchListView = lazy(() => import("./modules/branch/view/BranchListView"));
const TechnicianIndex = lazy(() => import("./modules/technician/TechnicianIndex"));
const ReportIndex = lazy(() => import("./modules/report/ReportIndex"));
const BookingReviewListView = lazy(() => import("./modules/booking_review/BookingReviewListView"));

const ServiceListView = lazy(() => import("./modules/service/view/ServiceListView"));

const SystemIndex = lazy(() => import("./modules/system/SystemIndex"));
const ConfigIndex = lazy(() => import("./modules/config/ConfigIndex"));

const Routes = [
    { id: "/booking", component: BookingIndex },
    { id: "/customer", component: CustomerListView },
    { id: "/booking-review", component: BookingReviewListView },
    { id: "/branch", component: BranchListView },
    { id: "/technician", component: TechnicianIndex },
    { id: "/service", component: ServiceListView },
    { id: "/report", component: ReportIndex },

    { id: "/system", component: SystemIndex },
    { id: "/config", component: ConfigIndex }
    // { id: '*', component: NotFound }
];

export default Routes;
