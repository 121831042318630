import Alert from "@idtek/component/lib/alert/Alert";
import Icon from "@idtek/component/lib/icon/Icon";
import ComboField from "@idtek/form/lib/combox/ComboField";
import TextField from "@idtek/form/lib/text/TextField";
import BaseStore from "@idtek/grid/lib/stores/BaseStore";
import _ from "lodash";
import React, {Fragment} from "react";
import BaseListView from "../../../common/baseView/BaseListView";
import AppUtil from "../../../utils/AppUtil";
import {allRights} from "../../../utils/rightUtils";
import {SERVICE_GROUP_TYPE_CONSTANT, SERVICE_STATUS_CONSTANT, SERVICE_TYPE_CONSTANT} from "../constant/ServiceConstant";
import Row from "@idtek/component/lib/container/Row";
import Cell from "@idtek/component/lib/container/Cell";

export default class ServiceListView extends BaseListView {
    constructor(props) {
        super(props);
        this.state = {};
        ///right
        this.rightAccess = allRights.SERVICE_RIGHT__ACCESS;
        this.updateRight = allRights.SERVICE_RIGHT__UPDATE;
        this.keyID = "service_id";
        this.moduleName = "Service";
        this.defaultRenderGrid = false;
        this.apiIndex = "/service/get-all-service-tree";
        this.apiDetail = "/service/show";
        this.apiUpdate = "/service/update";
        this.apiCreate = "/service/create";
        this.apiDelete = "/service/delete";
        this.apiDetailGroup = "/serviceGroup/show";
        this.apiUpdateGroup = "/serviceGroup/update";
        this.apiCreateGroup = "/serviceGroup/create";
        this.apiDeleteGroup = "/serviceGroup/delete";
        this.store = new BaseStore({
            columns: this.columnDefs,
            url: AppUtil.GLOBAL_API_PATH + this.apiIndex,
            pageSize: this.pageSize
        });
        this.levelTree = {};
        this.defaultNumberRows = false;
        this.floatingFilter = false;
        this.defaultColDef = {
            sortable: false,
            filter: false,
            headerAlign: "left",
            textAlign: "left"
        };
        this.module = "";
        this.columnDefs = [
            {
                headerName: "Service Name",
                field: "name",
                minWidth: 300
            },
            {
                headerName: "Status",
                field: "status",
                width: 120
            },
            {
                headerName: "Price (CAD)",
                field: "price",
                width: 110
            },
            {
                headerName: " Duration (minutes)",
                field: "time",
                width: 110
            },
            {
                headerName: "Order",
                field: "order",
                width: 110
            }
        ];

        this.cellRenderer = {
            status: {
                renderer: (data, value) => {
                    const find = _.find(SERVICE_STATUS_CONSTANT, (item) => item.value === value);
                    if (find) return <div className="global-flex-align-center">{find.label}</div>;
                    return null;
                }
            },
            name: {
                renderer: (data, value) => {
                    if (data.type === SERVICE_TYPE_CONSTANT) return value + " (" + data.code + ")";
                    return value;
                }
            }
        };

        this.model = {
            fields: {
                service_id: {
                    hide: true
                },
                code: {
                    label: "Code",
                    required: true
                },
                name: {
                    label: "Name",
                    required: true
                },
                time: {
                    label: " Duration (minutes)",
                    required: true
                },
                price: {
                    label: "Price (CAD)",
                    required: true
                },
                order: {
                    label: "Order",
                    required: true
                },
                serviceGroup: {
                    label: "Group",
                    required: true
                },
                status: {
                    label: "Status",
                    required: true
                }
            }
        };
    }

    onGlobalFilter = async (key) => {
        this.store && this.store.loading.open();
        const res = await AppUtil.getApi(this.apiIndex, {key: key});
        if (res) {
            let data = _.get(res, "data.results");
            data = _.filter(data, (item) => {
                if (_.toLower(item.name).includes(_.toLower(key))) {
                    return true;
                } else {
                    return !_.isEmpty(item.children);
                }
            });
            this.store && this.store.setData(data);
        }
        this.store && this.store.loading.close();
    };

    onShowGroup = async (id) => {
        this.refBasseForm && this.refBasseForm.onMask();
        const res = await AppUtil.getApi(`${this.apiDetailGroup}/${id}`, {});
        if (_.get(res, "data.success")) {
            const values = await this.beforeSetValues(_.get(res.data, "results"));
            this.refBasseForm && this.refBasseForm.pageSetValueForm(values);
            this.afterSetValues(values);
        }
        this.refBasseForm && this.refBasseForm.onUnMask();
    };

    onShow = async (id) => {
        this.refBasseForm && this.refBasseForm.onMask();
        const fetchs = [AppUtil.getApi(`${this.apiDetail}/${id}`, {}), AppUtil.getApi(`/serviceGroup/`, {})];
        await AppUtil.Axios.all(fetchs)
            .then(async ([responseDetail, responseGroup]) => {
                if (_.get(responseDetail, "data.success") && _.get(responseGroup, "data.success")) {
                    const serviceGroupOptions = _.get(responseGroup, "data.results");
                    const values = await this.beforeSetValues(_.get(responseDetail.data, "results"));
                    this.serviceGroup && this.serviceGroup.setData(serviceGroupOptions, values.serviceGroup);
                    this.refBasseForm && this.refBasseForm.pageSetValueForm(values);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.refBasseForm && this.refBasseForm.onUnMask();
            });
    };

    onLoadInitForm = async (row) => {
        this.refBasseForm && this.refBasseForm.onMask();
        const res = await AppUtil.getApi(`/serviceGroup/`, {});
        if (_.get(res, "data.success")) {
            const serviceGroupOptions = await this.beforeSetValues(_.get(res.data, "results"));
            this.serviceGroup && this.serviceGroup.setData(serviceGroupOptions, row.service_group_id);
        }
        this.refBasseForm && this.refBasseForm.onUnMask();
    };

    onCreate = (row) => {
        this.formDefaults["readOnly"] = false;
        const BaseForm = this.BaseForm;
        if (row.type !== SERVICE_GROUP_TYPE_CONSTANT) {
            this.Modal.onOpen(
                <BaseForm
                    ref={(refBasseForm) => (this.refBasseForm = refBasseForm)}
                    initialValues={this.initialValues ? this.initialValues : {}}
                    model={{
                        fields: {
                            name: {
                                label: "Name",
                                required: true
                            }
                        }
                    }}
                    onDidMount={this.onLoadInitForm.bind(this)}
                    formDefaults={this.formDefaults}
                    buttons={this.buttons}
                    renderBodyForm={this.renderBodyFormGroup(row, false)}
                />,
                <span>{this.titleCreate + "service Group"}</span>,
                this.popUpWidth
            );
        } else {
            this.Modal.onOpen(
                <BaseForm
                    ref={(refBasseForm) => (this.refBasseForm = refBasseForm)}
                    initialValues={{serviceGroup: row.serviceGroup, status: 1}}
                    model={this.model}
                    onDidMount={this.onLoadInitForm.bind(this, row)}
                    formDefaults={this.formDefaults}
                    buttons={this.buttons}
                    renderBodyForm={this.renderBodyForm()}
                />,
                <span>{this.titleCreate + "service"}</span>,
                this.popUpWidth
            );
        }
        this.refContainer && this.refContainer.unmask();
    };

    onEditRow = async (row) => {
        this.formDefaults["readOnly"] = false;
        const BaseForm = this.BaseForm;
        if (row.type !== SERVICE_TYPE_CONSTANT) {
            this.Modal.onOpen(
                <BaseForm
                    ref={(refBasseForm) => (this.refBasseForm = refBasseForm)}
                    initialValues={this.initialValues ? this.initialValues : {}}
                    model={{
                        fields: {
                            name: {
                                label: "Name",
                                required: true
                            }
                        }
                    }}
                    onDidMount={this.onShowGroup.bind(this, row["service_group_id"])}
                    formDefaults={this.formDefaults}
                    buttons={this.buttons}
                    renderBodyForm={this.renderBodyFormGroup(row, false)}
                />,
                <span>{this.titleUpdate + "service Group"}</span>,
                this.popUpWidth
            );
            this.refContainer && this.refContainer.unmask();
        } else {
            this.Modal.onOpen(
                <BaseForm
                    ref={(refBasseForm) => (this.refBasseForm = refBasseForm)}
                    initialValues={this.initialValues ? this.initialValues : {}}
                    model={this.model}
                    onDidMount={this.onShow.bind(this, row[this.keyID])}
                    formDefaults={this.formDefaults}
                    buttons={this.buttons}
                    renderBodyForm={this.renderBodyForm(row, false)}
                />,
                <span>{this.titleUpdate + this.moduleName}</span>,
                this.popUpWidth
            );
            this.refContainer && this.refContainer.unmask();
        }
    };

    onSave = async (form, values) => {
        let url = "";
        if (values[this.keyID] && values["serviceGroup"]) {
            url = this.apiUpdate + "/" + values[this.keyID];
        } else if (!values[this.keyID] && values["serviceGroup"]) {
            url = this.apiCreate;
        } else if (values["id"] && !values["serviceGroup"]) {
            url = this.apiUpdateGroup + "/" + values["id"];
        } else {
            url = this.apiCreateGroup;
        }
        form && form.mask();
        const res = await AppUtil.postApi(url, values);
        form && form.unmask();
        if (res) {
            if (res.data && res.data.success === false && !_.get(res, "data.success")) {
                form && form.setErrors(res.data.errors);
            } else {
                AppUtil.ToastSuccess();
                this.Modal.onClose();
                this.reLoad();
            }
        }
    };

    onDeleteRow = async (row) => {
        let apiDelete = this.apiDelete;
        let id = row[this.keyID];
        if (row.type === SERVICE_GROUP_TYPE_CONSTANT) {
            apiDelete = this.apiDeleteGroup;
            id = row.service_group_id;
        }
        let confirm = await Alert.Swal_confirm("Confirm", "Are you sure you want to delete this record?", 3);
        if (_.get(confirm, "value") === true) {
            const res = await AppUtil.deleteApi(`${apiDelete}/${id}`);
            if (res && _.get(res.data, "success")) {
                AppUtil.ToastDeleteSuccess();
                this.reLoad();
            } else {
                AppUtil.ToastDeleteError(_.get(res.data, "messages") || _.get(res.data, "message") || "Data error, please try again!");
            }
        }
    };

    renderActions = (row) => {
        const buttons = [];
        if (_.get(row, "type") === SERVICE_GROUP_TYPE_CONSTANT) {
            buttons.push({
                onPress: this.onCreate,
                icon: {
                    type: "plus",
                    style: {background: "#007bff"},
                    color: "#ffffff"
                },
                tooltip: "Add",
                style: {
                    background: "#007bff"
                }
            });
        }
        if (this.checkUpdateRight() && this.hasUpdate) {
            buttons.push(
                {
                    type: "edit",
                    onPress: this.onEditRow,
                    icon: {
                        type: "edit",
                        style: {background: "#2dce89"},
                        color: "#ffffff"
                    },
                    tooltip: "Update"
                },
                {
                    type: "delete",
                    onPress: this.onDeleteRow,
                    icon: {
                        type: "delete",
                        style: {background: "var(--main-color-red)"},
                        color: "#ffffff"
                    },
                    tooltip: "Delete"
                }
            );
        }
        return buttons;
    };

    renderActionBar() {
        const buttons = [];
        if (this.checkCreateRight()) {
            buttons.push({
                className: "create-btn",
                tooltip: "Add service group",
                icon: <Icon type="plus"/>,
                onPress: this.onCreate
            });
        }
        buttons.push(
            {
                type: "refresh",
                onPress: this.reLoad,
                tooltip: "Refresh"
            },
            {
                type: "fullScreen",
                onPress: () => this.FullBrowser.toogleFullScreen(),
                tooltip: "Full screen"
            }
        );

        return buttons;
    }

    renderBodyForm() {
        return (
            <Fragment>
                <Row>
                    <Cell>
                        <TextField name="code"/>
                    </Cell>
                    <Cell>
                        <TextField name="name"/>
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <TextField name="time"/>
                    </Cell>
                    <Cell>
                        <TextField name="price" format="number"/>
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <TextField name="order" format="number"/>
                    </Cell>
                    <Cell>
                        <ComboField ref={(c) => (this.serviceGroup = c)} name="serviceGroup" options={[]}
                                    labelKey="name" valueKey="id"/>
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <ComboField name="status" options={SERVICE_STATUS_CONSTANT}/>
                    </Cell>
                    <Cell/>
                </Row>
            </Fragment>
        );
    }

    renderBodyFormGroup() {
        return (
            <Fragment>
                <Row>
                    <Cell>
                        <TextField name="name"/>
                    </Cell>
                </Row>
            </Fragment>
        );
    }
}
