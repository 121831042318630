import React from 'react';
import ConfigGlobal from '@idtek/component/lib/config/ConfigGlobal';
import Loading from './pages/Loading';
import { Empty } from 'antd';

ConfigGlobal.positionNotification = 'bottomRight';
ConfigGlobal.loading = <Loading />;
ConfigGlobal.loadingForm = <Loading text="Loading..." />;
ConfigGlobal.loadingGrid = <Loading text="Loading..." />;
ConfigGlobal.gridSkeleton = true;
ConfigGlobal.gridSkeletonDeplay = 100;
ConfigGlobal.emptyComponent = <Empty />;
ConfigGlobal.store = {
    separator: '[]'
};