import Alert from "@idtek/component/lib/alert/Alert";
import Container from "@idtek/component/lib/container/Container";
import Modal from "@idtek/component/lib/modal/Modal";
import Popover from "@idtek/component/lib/popover/Popover";
import Axios from "axios";
import _ from "lodash";
import React, { Suspense } from "react";
import { connect } from "react-redux";
import GlobalStore from "../../GlobalStore";
import PosActionsDetail from "../../modules/dashboard/component/PosActionsDetail";
import FormRecevieMoney from "../../modules/dashboard/form/FormRecevieMoney";
import FormTakeMoney from "../../modules/dashboard/form/FormTakeMoney";
import IconMoney from "../../modules/dashboard/icon/IconMoney";
import Loading from "../../pages/Loading";
import AppStore from "../../store";
import AppUtil from "../../utils/AppUtil";
import { logout, showSideBarMenu } from "../actions/action";
import { HEADER_HEIGHT, LOG_OUT_SUCCESSFUL } from "../constants/constant";
import LOGO_IDBEAUTY from "../images/logo_idbeauty_1.svg";
import ButtonHeader from "./ButtonHeader";
import MainDrawer from "./MainDrawer";
import rightUtils, {allRights} from "../../utils/rightUtils";
class HeaderPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: localStorage.getItem("collapsedSideMenu") === "true" ? true : false,
            visible: false
        };
        this.rightAccessReport = allRights.REPORT_RIGHT__ACCESS;
    }

    componentDidMount() {
        const { dispatch } = this.props;
        if (
            window.location.pathname === "/dashboard" ||
            window.location.pathname.startsWith("/booking/create-mobile") ||
            window.location.pathname.startsWith("/booking/update-mobile") ||
            window.location.pathname === "/"
        ) {
            dispatch(showSideBarMenu(false));
        } else {
            dispatch(showSideBarMenu(true));
        }
    }

    handleClick = (e) => {
        const { dispatch } = this.props;
        if (e.key === "logout") {
            dispatch(logout());
        }
    };

    handleVisibleChange = (visible) => {
        this.setState({ visible });
    };
    handleChangePassword = () => {
        this.showDrawerChangePass();
        this.setState({
            visible: !this.state.visible
        });
    };

    onSaveChangePass = () => {
        this.Form.mask();
        const data = this.Form.getValues();
        AppUtil.Axios.post(AppUtil.GLOBAL_API_PATH + `/employee/change-pass`, data)
            .then((res) => {
                if (res.data.success) {
                    AppUtil.ToastUpdateSuccess();
                    if (window._qaq) {
                        window._qaq.push(["resetUserId"]);
                        window._qaq.push(["trackPageView"]);
                    }
                    setTimeout(() => {
                        AppStore.dispatch({
                            type: LOG_OUT_SUCCESSFUL
                        });
                        window.location.hash = "#/login";
                    }, 500);
                } else {
                    AppUtil.ToastServerError(res.data.message);
                }
            })
            .catch((res) => {
                AppUtil.ToastServerError();
            })
            .finally(() => {
                this.Form.unmask();
            });
    };

    onLogout = async () => {
        let confirm = await Alert.Swal_confirm("Notification", "Are you sure want to Logout?", 3);
        if (_.get(confirm, "value") === true) {
            await AppUtil.getApi("/logout").then((response) => {
                const { data } = response;
                if (data.success) {
                    if (window._qaq) {
                        window._qaq.push(["resetUserId"]);
                        window._qaq.push(["trackPageView"]);
                    }
                    setTimeout(() => {
                        AppStore.dispatch({
                            type: LOG_OUT_SUCCESSFUL
                        });
                        // window.location.hash = '/';
                    }, 300);
                }
            });
        }
    };

    onChangeRoute = (route) => {
        const { dispatch } = this.props;
        this.props.history.push(route);
        if (route) {
            if (route === "/dashboard") {
                dispatch(showSideBarMenu(false));
            } else {
                dispatch(showSideBarMenu(true));
            }
        }
    };

    onRecevie = () => {
        const { authData } = this.props;
        this.Modal.onOpen(
            <FormRecevieMoney
                ref={(c) => (this.refFormRecevieMoney = c)}
                onClose={() => {
                    this.Modal.onClose();
                }}
                user={_.get(authData, "user.fullName")}
                branch={_.get(authData, "user.branchId")}
            />,
            <span>CASH INFLOW</span>,
            "600px"
        );
    };

    onTake = () => {
        const { authData } = this.props;
        this.Modal.onOpen(
            <FormTakeMoney
                ref={(c) => (this.refFormTakeMoney = c)}
                onClose={() => {
                    this.Modal.onClose();
                }}
                user={_.get(authData, "user.fullName")}
                branch={_.get(authData, "user.branchId")}
            />,
            <span>CASH OUTFLOW</span>,
            "600px"
        );
    };

    onPosActions = () => {
        const { authData } = this.props;
        GlobalStore.DrawerPosActions &&
            GlobalStore.DrawerPosActions.open(
                <Suspense fallback={<Loading />}>
                    <PosActionsDetail branchId={_.get(authData, "user.branchId")} />
                </Suspense>,
                null,
                "450px"
            );
    };

    renderModal() {
        return (
            <Modal
                draggable
                ref={(component) => {
                    this.Modal = component;
                }}
                onClose={this.onCloseDefault}
                maskClosable={false}
                className="popup-crud popup-container popup-method"
            />
        );
    }

    renderDrawer() {
        return (
            <MainDrawer
                className="drawer-pos-actions"
                ref={(component) => (GlobalStore.DrawerPosActions = component)}
                onClose={() => {
                    console.log(123);
                }}
            />
        );
    }

    render() {
        const { authData } = this.props;
        const { username } = authData.user;
        const { showSideMenuDesktop } = this.props;
        const { rights } = rightUtils

        return (
            <Container ref={(component) => (this.Container = component)}>
                <header
                    style={{
                        height: HEADER_HEIGHT,
                        paddingLeft: 20
                    }}
                    className="header-page">
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            alignItems: "center",
                            justifyContent: "flex-start"
                        }}>
                        <img className="logo" src={LOGO_IDBEAUTY} style={{ cursor: "pointer" }} alt="" onClick={() => (window.location.href = "/dashboard")}></img>
                        <div className={showSideMenuDesktop ? "menu-header" : "menu-header active"} onClick={() => this.onChangeRoute("/dashboard")}>
                            APPOINTMENT
                        </div>
                        <div className={showSideMenuDesktop ? "menu-header active" : "menu-header"} onClick={() => this.onChangeRoute("/booking/index")}>
                            MANAGEMENT
                        </div>
                        <div className="end-menu">
                            {_.size(authData.posType) > 0 ? (
                                <div style={{ display: "flex" }}>
                                    <div className="button-header-v2 receive-money" onClick={this.onRecevie}>
                                        CASH INFLOW
                                    </div>
                                    <div className="button-header-v2 take-money" onClick={this.onTake}>
                                        CASH OUTFLOW
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {
                                AppUtil.checkPermisstion(this.rightAccessReport, rights) &&  <div className="button-header-money" onClick={this.onPosActions}>
                                    {" "}
                                    <IconMoney fill={"#fff"} />
                                </div>
                            }
                            {
                                <div className={`button-header `} onClick={() => window.open("http://readme.idtek.com.vn/O4BjPmV8R2-w1FuQk9UfJQ")} title={"User Guides"} style={{ width: 20 }}>
                                    <i className={"fa fa-info-circle"} style={{ fontSize: 18 }} />
                                </div>
                            }

                            <Popover
                                placement="bottom"
                                trigger="click"
                                overlayClassName="header-submenu"
                                onVisibleChange={this.handleVisibleChange}
                                visible={this.state.visible}
                                content={
                                    <div>
                                        <div
                                            className="header-submenu-item"
                                            onClick={() => this.onLogout()}
                                            style={{
                                                padding: 10,
                                                width: "100%"
                                            }}>
                                            <i className="fa fa-sign-in" style={{ paddingRight: "5px" }}></i>
                                            <span>Logout</span>
                                        </div>
                                    </div>
                                }>
                                <ButtonHeader style={{ marginRight: 16 }} iconCls="fa fa-user-circle-o" text={username} className="avatar-user" />
                            </Popover>
                        </div>
                    </div>
                </header>
                {this.renderDrawer()}
                {this.renderModal()}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...(state.root || {})
    };
};

export default connect(mapStateToProps)(HeaderPage);
