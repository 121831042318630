import React, { Component } from 'react'

export default class IconPlus extends Component {
    render() {
        return (
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.82998 0.344995L6.99998 0.333328C7.30204 0.333341 7.59388 0.442733 7.82153 0.641273C8.04918 0.839814 8.19723 1.11407 8.23831 1.41333L8.24998 1.58333V5.74833L12.4166 5.74999C12.7187 5.75001 13.0105 5.8594 13.2382 6.05794C13.4658 6.25648 13.6139 6.53074 13.655 6.82999L13.6666 6.99999C13.6666 7.30206 13.5572 7.5939 13.3587 7.82155C13.1602 8.04919 12.8859 8.19725 12.5866 8.23833L12.4166 8.24999L8.24998 8.24833V12.4167C8.24997 12.7187 8.14057 13.0106 7.94203 13.2382C7.74349 13.4659 7.46924 13.6139 7.16998 13.655L6.99998 13.6667C6.69792 13.6666 6.40608 13.5573 6.17843 13.3587C5.95078 13.1602 5.80273 12.8859 5.76165 12.5867L5.74998 12.4167V8.24833L1.58331 8.24999C1.28125 8.24998 0.98941 8.14059 0.761762 7.94205C0.534114 7.74351 0.38606 7.46925 0.34498 7.17L0.333313 6.99999C0.333326 6.69793 0.442717 6.40609 0.641258 6.17844C0.839799 5.9508 1.11406 5.80274 1.41331 5.76166L1.58331 5.74999L5.74998 5.74833V1.58333C5.74999 1.28127 5.85938 0.989425 6.05792 0.761777C6.25646 0.534129 6.53072 0.386075 6.82998 0.344995Z" />
            </svg>

        )
    }
}
