import Alert from "@idtek/component/lib/alert/Alert";
import Icon from "@idtek/component/lib/icon/Icon";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
    loadAllServiceStore,
    loadComboboxDataBookingForm,
    loadComboboxPriotityTechnician,
    resetServiceStore,
    selectManyService,
    updateBooking,
    updateCustomer,
    updateExtraService
} from "../../../application/actions/action";
import AppStore from "../../../store";
import AppUtil from "../../../utils/AppUtil";
import { allRights } from "../../../utils/rightUtils";
import ApiConstants from "../../booking/constant/ApiConstants";
import "../../booking/style/style-update-booking.scss";
import BaseBookingCreate from "../../booking/view/BaseBookingCreate";
import FormProceedPayment from "../../dashboard/form/FormProceedPayment";
class FormBookingUpdateV2 extends BaseBookingCreate {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            initialValues: {},
            loading: true
        };
        this.apiCreate = "/booking/save";
        this.inModal = true;
        this.type = props.type ? props.type : "update";
        this.maxDate = null;
        this.deleteRight = allRights.BOOKING_RIGHT__DELETE_BOOKING;
    }

    componentDidMount() {
        this.loadFormDataUpdate();
    }
    async loadFormDataUpdate() {
        // this.form && this.form.mask();
        const { storeList, authData } = this.props;
        const { id } = this.state;
        const branchIdInit = _.get(authData, "user.branchId");
        let initialValues = {
            branch: branchIdInit,
            startTime: this.startTime
        };
        const fetchs = [await AppUtil.getApi(ApiConstants.API_LOAD_COMBOBOX_DATA_BOOKING, {}), AppUtil.getApi(`/booking/show/` + id, {}), AppUtil.getApi(`/service/get-service-tree`, {})];
        if (_.isEmpty(_.get(this.props.storeList, "priorityTechnicians"))) {
            fetchs.push();
        }
        await AppUtil.Axios.all(fetchs)
            .then(async ([res, resDetail, resSer]) => {
                if (_.get(res, "data.success") && _.get(resDetail, "data.success") && resSer) {
                    let dateInit = _.get(resDetail.data.results, "startTime") ? moment(resDetail.data.results.startTime).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
                    const resCbxPrio = await AppUtil.getApi(`/technician/combobox-priority`, { branch: initialValues.branch, date: dateInit });
                    const resCus = await AppUtil.getApi(`/customer/combobox`, {
                        start: 0,
                        limit: 100,
                        query: _.get(resDetail, "data.results.phone")
                    });
                    initialValues = _.get(resDetail, "data.results");
                    this.minDate = moment();
                    initialValues.createdAt = moment(initialValues.createdAt).format(AppUtil.GLOBAL_DATETIME_FORMAT);
                    AppStore.dispatch(loadAllServiceStore(resSer.data.children));
                    if (resCbxPrio) {
                        AppStore.dispatch(loadComboboxPriotityTechnician(resCbxPrio.data));
                    }
                    AppStore.dispatch(loadComboboxDataBookingForm(_.get(res, "data.results")));
                    AppStore.dispatch(updateCustomer(_.get(resCus, "data")));
                    AppStore.dispatch(selectManyService(storeList, initialValues.bookingItems));
                    AppStore.dispatch(updateExtraService(initialValues.extraServices));
                    AppStore.dispatch(updateBooking(initialValues));
                    this.dateInit = dateInit;
                } else {
                    this.props.onClose && this.props.onClose();
                    AppUtil.ToastApiError();
                }
                // this.form && this.form.setValues(initialValues);
                // setTimeout(() => {
                //     this.form && this.form.unmask();
                // }, 50);

                this.setState({
                    initialValues,
                    loading: false
                });
            })
            .catch((error) => {
                AppUtil.ToastApiError();
            });
    }
    componentWillUnmount() {
        if (!this.props.notResetStoreServices) {
            AppStore.dispatch(resetServiceStore());
        }
    }

    onProceedPayment() {
        const { authData, booking } = this.props;
        const { paymentMethod } = this.props.authData;
        this.Modal.onOpen(
            <FormProceedPayment
                onClose={() => {
                    this.Modal.onClose();
                    this.loadFormDataUpdate();
                    this.props.onReloadData && this.props.onReloadData();
                }}
                thueVAT={_.get(authData, "gst")}
                booking={booking}
                user={_.get(authData, "user.fullName")}
                paymentMethod={paymentMethod}
                branch={_.get(authData, "user.branchId")}
            />,
            <span>Take Payment</span>,
            800
        );
    }

    renderButtons() {
        const { initialValues } = this.state;
        const buttons = [];
        let rightAdmin = this.checkDeleteRight();
        if (this.props.isDetail) {
            rightAdmin = false;
        }
        if (moment(initialValues.startTime).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")) {
            if (initialValues.status !== "Completed" || rightAdmin) {
                buttons.push({
                    text: "Save",
                    type: "submit",
                    className: "btn-success",
                    icon: <Icon type="save" style={{ fontSize: 16 }} />,
                    onPress: (form, values) => this.onSave(form, values, false)
                });
            }
            if (["Waiting", "Checked in"].indexOf(initialValues.status) >= 0) {
                buttons.push({
                    text: "Start",
                    type: "submit",
                    className: "btn-primary",
                    icon: <Icon type="clock-circle" style={{ fontSize: 15 }} />,
                    onPress: (form, values) => {
                        this.onSave(form, values, false, true, "/booking/start", "start");
                    }
                });
            }
            if (["Completed"].indexOf(initialValues.status) >= 0) {
                if (initialValues.paymentMethod) {
                    buttons.push({
                        text: "Bill",
                        className: "btn-primary",
                        icon: <Icon type="printer" style={{ fontSize: 18 }} />,
                        onPress: (form, values) => {
                            this.doActionBooking("bill");
                        }
                    });
                }
                buttons.push({
                    text: "Take Payment",
                    className: "btn-primary",
                    icon: <Icon type="dollar" style={{ fontSize: 18 }} />,
                    onPress: (form, values) => {
                        this.onProceedPayment();
                    }
                });
            }
            if (["Processing"].indexOf(initialValues.status) >= 0) {
                buttons.push({
                    text: "COMPLETE & PAYMENT",
                    type: "submit",
                    className: "btn-success",
                    icon: <Icon type="check-square" style={{ fontSize: 16 }} />,
                    onPress: (form, values) => {
                        const url = `/booking/complete`;
                        this.showConfirm("COMPLETE", "Are you sure you want to complete?", this.onSave.bind(this, form, values, false, false, url, "complete"));
                    }
                });
            }
            if (["Cancel", "Completed"].indexOf(initialValues.status) < 0) {
                buttons.push({
                    text: "CANCEL",
                    type: "submit",
                    className: "btn-danger",
                    icon: <Icon type="close-circle" style={{ fontSize: 15 }} />,
                    onPress: (form, values) => {
                        const url = `/booking/cancel`;
                        this.showConfirm("CANCEL", "Are you sure you want to cancel?", this.onSave.bind(this, form, values, false, false, url, "cancel"));
                    }
                });
            }
            if (["NoShow", "Completed"].indexOf(initialValues.status) < 0) {
                buttons.push({
                    text: "NO SHOW",
                    type: "submit",
                    className: "btn-danger",
                    icon: <Icon type="pause-circle" style={{ fontSize: 15 }} />,
                    onPress: (form, values) => {
                        const url = `/booking/no-show`;
                        this.showConfirm("CANCEL", "Are you sure you want to no show?", this.onSave.bind(this, form, values, false, false, url, "no-show"));
                    }
                });
            }
        } else {
            if (initialValues.status === "Completed") {
                buttons.push({
                    text: "Bill",
                    className: "btn-primary",
                    icon: <Icon type="printer" style={{ fontSize: 16 }} />,
                    onPress: (form, values) => this.doActionBooking("bill")
                });
            } else {
                buttons.push({
                    text: "Save",
                    type: "submit",
                    className: "btn-success",
                    icon: <Icon type="save" style={{ fontSize: 16 }} />,
                    onPress: (form, values) => this.onSave(form, values, false, false, "/booking/save-history", "save-history")
                });
            }
        }
        buttons.push(
            {
                text: "DUPLICATE",
                className: "btn-clone",
                icon: <Icon type="copy" style={{ fontSize: 16 }} />,
                onPress: (form, values) => this.onClone(values)
            },
            {
                text: "Close",
                className: "btn-danger",
                icon: <Icon type="close-square" style={{ fontSize: 15 }} />,
                onPress: () => {
                    this.props.onClose && this.props.onClose();
                }
            }
        );
        return buttons;
    }

    onClone() {
        this.props.onClone && this.props.onClone(this.props.booking);
    }

    sendEmailReview(id) {
        const params = { id: id };
        const response = AppUtil.getApi("/booking/send-mail-review", params);
    }

    async getTemplateBill(id) {
        const response = await AppUtil.getApi("/booking/get-bill/" + id);
        if (response.data.success) {
            return response.data.template;
        }
        return "Template not exist!";
    }

    async doActionBooking(actionName) {
        const me = this;
        const { id } = me.state;
        //Check action bill
        if (actionName === "bill") {
            AppUtil.loadAndPrintBooking(id);
        }
    }

    afterSave(form, res, action) {
        const data = form && form.getValues();
        if (action === "complete") {
            this.sendEmailReview(_.get(data, "id"));
            this.props.onProceedPayment && this.props.onProceedPayment(_.get(data, "id"));
        }
    }

    continute() {}

    continuteAfterCheckMessage() {
        this.props.onReloadData && this.props.onReloadData();
        AppUtil.ToastSaveSuccess();
    }

    async checkMessage(message, newValues, actionName, continute) {
        const { multiSetting } = this.props;
        if (message && message === "Please select full technician for services") {
            AppUtil.ToastError(message);
        } else if (message) {
            if (!multiSetting && actionName === "start") {
                const mess = message.split(".");
                const newMess = mess && mess[0] + ". Are you want to start at the time of booking in schedule?";
                const url = `/booking/${actionName}?id=` + newValues.id;
                let confirmStart = await Alert.Swal_confirm("Notification", newMess, 3);
                if (_.get(confirmStart, "value") === true) {
                    const res = await AppUtil.postApi(url, { startByPass: true });
                    if (_.get(res, "data.success")) {
                        this.props.onClose && this.props.onClose();
                        this.props.onReloadData && this.props.onReloadData();
                        return;
                    }
                }
                return;
            }
            if (!multiSetting && actionName !== "start") {
                const newMess = message.split(".");
                Alert.Swal_info("WARNING", newMess && newMess[0], 3);
                return;
            }
            let confirm = await Alert.Swal_confirm("WARNING", message, 3);
            if (_.get(confirm, "value") === true) {
                newValues.unCheckWarning = true;
                newValues.id = this.state.id;
                const res = await AppUtil.postApi(`/booking/${actionName}`, newValues);
                if (_.get(res, "data.success")) {
                    // AppUtil.ToastSaveSuccess();
                    if (continute) {
                        this.continuteAfterCheckMessage();
                    } else {
                        this.props.onClose && this.props.onClose();
                        this.props.onReloadData && this.props.onReloadData();
                        if (!this.inModal) {
                            this.onBack();
                        }
                    }
                }
            }
        }
    }

    beforeSave(form, values, actionName) {
        const data = {};
        const { booking } = this.props;
        const { services, extra } = this.GridBooking && this.GridBooking.getData();
        const stringFormat = "YYYY-MM-DD HH:mm";
        const bookingItems = _.map(services, (item, index) => {
            return {
                service_id: item.service_id ? item.service_id : null,
                technician: item.technician ? item.technician : null,
                startTime: item.startTime && moment(values.startTime).format(stringFormat) === moment(booking.startTime).format(stringFormat) ? item.startTime : null,
                endTime: item.endTime && moment(values.startTime).format(stringFormat) === moment(booking.startTime).format(stringFormat) ? item.endTime : null
            };
        });
        const extraServices = _.map(extra, (item) => {
            return {
                ...item,
                startTime: values.startTime ? moment(values.startTime).format("YYYY-MM-DD") : null,
                price: item.price ? parseFloat(item.price) : 0
            };
        });
        data.email = values.email ? values.email : null;
        data.bookingItems = bookingItems;
        data.branch = values.branch;
        data.customerName = values.customerName;
        data.description = values.description;
        data.extraServices = extraServices;
        data.paymentMethod = values.paymentMethod ? values.paymentMethod : null;
        data.technician = values.technician ? values.technician : null;
        if (values.status && ["start", "complete", "cancel", "no-show"].indexOf(actionName) < 0 && !this.props.cloneView) {
            data.status = values.status;
        }

        if (typeof values.phone === "string" || values.phone instanceof String) {
            data.phone = values.phone;
        } else {
            data.phone = _.get(values.phone, "phone");
        }
        data.startTime = moment(values.startTime);
        data.sex = values.sex ? values.sex : "";
        data.block = values.block ? 1 : 0;
        data.color = values.color ? values.color : "";
        data.typeBooking = values.typeBooking ? values.typeBooking : "TYPE_BOOKING_OFFLINE";
        return data;
    }

    async onCancel() {
        let confirm = await Alert.Swal_confirm("CANCEL", "Are you sure you want to cancel booking?", 3);
        if (_.get(confirm, "value") === true) {
        }
    }

    render() {
        return (
            <div className={this.className}>
                <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    <div style={{ flex: 1, minHeight: 400, display: "flex", alignItems: "center" }}>{this.renderForm()}</div>
                    {this.renderModal()}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        servicesStore: state.root.storeList.servicesStore,
        priorityTechnicians: state.root.storeList.priorityTechnicians,
        ...state.root
    };
};

export default withRouter(connect(mapStateToProps, {}, null, { forwardRef: true })(FormBookingUpdateV2));
