import React, { Component } from 'react'

export default class IconCancel extends Component {
    render() {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00077 10.1288L11.9463 14.1684C12.3421 14.5768 12.5996 14.581 13.0027 14.1684L13.7943 13.3577C14.1821 12.9608 14.2084 12.7002 13.7943 12.2762L9.61716 8L13.7947 3.72381C14.1859 3.32191 14.1935 3.05067 13.7947 2.64191L13.0031 1.83162C12.5924 1.41105 12.3387 1.43048 11.9467 1.83162L8.00077 5.87124L4.05525 1.832C3.66325 1.43086 3.40954 1.41143 2.99887 1.832L2.20725 2.64229C1.80801 3.05105 1.81525 3.32229 2.20725 3.72419L6.38439 8L2.20725 12.2762C1.79315 12.7002 1.81525 12.9608 2.20725 13.3577L2.99849 14.1684C3.39811 14.581 3.65563 14.5768 4.05487 14.1684L8.00077 10.1288Z" />
            </svg>

        )
    }
}
