import Icon from "@idtek/component/lib/icon/Icon";
import ComboField from "@idtek/form/lib/combox/ComboField";
import IDField from "@idtek/form/lib/IDField";
import Form from "@idtek/form/lib/IDForm";
import TextField from "@idtek/form/lib/text/TextField";
import TextAreaField from "@idtek/form/lib/textarea/TextAreaField";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { notResetStore, resetServiceStore, updateBooking } from "../../../application/actions/action";
import Loading from "../../../pages/Loading";
import AppStore from "../../../store";
import GridBookingService from "../component/GridBookingService";
import ReactDatePickerCustom from "../component/ReactDatePickerCustom";
import SearchPhoneComponent from "../component/SearchPhoneComponent";
import "../style/style-update-booking.scss";
import BaseBookingCreate from "../view/BaseBookingCreate";

class UnUse_FormBookingCreate extends BaseBookingCreate {
    constructor(props) {
        super(props);
        const { initialValues } = this;
        initialValues.quantity = 1;
        initialValues.startTime = props.startTime;
        this.state = {
            initialValues: initialValues,
            loading: true
        };
        this.contentHeight = "100%";
        this.inModal = true;
        this.apiCreate = "/booking/save";
        this.minDate = props.startTime;
    }

    componentDidMount() {
        if (!this.props.notResetStoreServices) {
            this.loadFormData();
        } else {
            const { initData } = this.props;
            if (_.get(initData, "startTime")) {
                moment(initData.startTime);
                initData.quantity = 1;
                initData.id = null;
            }
            if (this.props.cloneView) {
                AppStore.dispatch(updateBooking(initData));
            }
            this.setState({ loading: false, initialValues: this.props.initData });
        }
    }

    componentWillUnmount() {
        if (!this.props.notResetStoreServices) {
            AppStore.dispatch(resetServiceStore());
        }
    }

    afterSave() {
        AppStore.dispatch(notResetStore(false));
    }

    renderForm() {
        const { authData, storeList, optionsCustomer } = this.props;
        const { initialValues } = this.state;
        if (this.state.loading) return <Loading />;
        return (
            <Form
                ref={(component) => (this.form = component)}
                initialValues={initialValues}
                model={this.model}
                className="base-form-modal create-booking"
                style={{ padding: 15, display: "flex", flexDirection: "column" }}
                buttons={[
                    {
                        text: "Save",
                        type: "submit",
                        className: "btn-success",
                        icon: <Icon type="save" style={{ fontSize: 16 }} />,
                        onPress: (form, values) => this.onSave(form, values)
                    },
                    {
                        text: "Continue",
                        type: "submit",
                        className: "btn-success",
                        icon: <Icon type="play-square" style={{ fontSize: 16 }} />,
                        onPress: (form, values) => this.onSave(form, values, true)
                    },
                    {
                        text: "Start",
                        type: "submit",
                        className: "btn-primary",
                        icon: <Icon type="clock-circle" style={{ fontSize: 16 }} />,
                        onPress: (form, values) => this.onSave(form, values, false, true, "", "start")
                    },
                    {
                        text: "Close",
                        className: "btn-danger",
                        icon: <Icon type="close-square" style={{ fontSize: 16 }} />,
                        onPress: () => this.props.onClose && this.props.onClose()
                    }
                ]}
                defaults={{
                    alignLabel: "left",
                    widthLabel: 110,
                    isClearable: true,
                    inCondition: true,
                    labelKey: "name",
                    valueKey: "id"
                }}>
                <div className="row">
                    <div className="col-md-6 phone-input">
                        <IDField name="phone" onChange={this.onChangePhone} options={optionsCustomer}>
                            <SearchPhoneComponent />
                        </IDField>
                    </div>
                    <div className="col-md-6">
                        <IDField
                            name="startTime"
                            status={!this.props.cloneView ? _.get(initialValues, "status") : ""}
                            showTimeSelect={true}
                            minDate={this.minDate}
                            maxDate={this.maxDate}
                            onChange={this.onChangeDate}>
                            <ReactDatePickerCustom />
                        </IDField>
                    </div>
                    <div className="col-md-6">
                        <TextField name="customerName" upperCase={true} />
                    </div>
                    <div className="col-md-6">
                        <TextField name="email" type="email" />
                    </div>
                    <div className="col-md-6">
                        <ComboField name="branch" options={_.get(authData, "user.branches")} />
                    </div>
                    <div className="col-md-6">
                        <TextField type="number" name="quantity" />
                    </div>
                    <div className="col-md-6">
                        <ComboField name="technician" options={this.props.priorityTechnicians} onChange={(val) => this.onSelectMainTechnician(val)} />
                    </div>
                    <div className="col-md-12">
                        <TextAreaField name="description" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <GridBookingService
                            ref={(c) => (this.GridBooking = c)}
                            onSelectTechnician={this.onSelectTechnicianOnGrid.bind(this)}
                            selectServiceHandler={this.selectServiceHandler}
                            {...this.props}
                        />
                    </div>
                </div>
            </Form>
        );
    }

    render() {
        return (
            <div className={this.className}>
                <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    <div style={{ flex: 1, minHeight: 400, display: "flex", alignItems: "center" }}>{this.renderForm()}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.root.authData,
        storeList: state.root.storeList,
        selectedServiceStoreReordering: state.root.selectedServiceStoreReordering,
        extraServices: state.root.extraServices,
        servicesStore: state.root.storeList.servicesStore,
        priorityTechnicians: state.root.storeList.priorityTechnicians,
        subTotal: state.root.subTotal,
        totalTime: state.root.totalTime,
        totalGST: state.root.totalGST,
        notResetStoreServices: state.root.notResetStoreServices,
        optionsCustomer: state.root.optionsCustomer,
        technician: state.root.technician,
        ...state.root
    };
};
export default withRouter(connect(mapStateToProps, {}, null, { forwardRef: true })(UnUse_FormBookingCreate));
