import React, { Component } from "react";
import { connect } from "react-redux";
import AppRoute from "../../AppRoute";
import LoginPage from "../../LoginPage";
import "../css/custom.scss";
import "../css/fontExt.css";
import "../css/grid.scss";
import "../css/index.scss";
import "../css/globalButton.scss";
import "antd/dist/antd.css";
import HeaderPage from "./Header";
import SideMenu from "./SideMenu";
import _ from "lodash";
import DashboardIndex from "../../modules/dashboard/UnUse_DashboardIndex";
import DashboardIndexMobile from "../../modules/dashboard/UnUse_DashboardIndexMobile";
import DashboardDesktop from "../../modules/dashboard_premium/DashboardDesktop";
import DashboardMobileV2 from "../../modules/dashboard_premium/DashboardMobileV2";

class LayoutPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isMobile: false
        };
    }

    componentWillMount() {
        const isMobile = this.checkIsMobile();
        if (isMobile) {
            this.setState({
                isMobile: true
            });
        }
    }

    checkIsMobile() {
        return (
            navigator.userAgent.match(/Android/i) !== null ||
            navigator.userAgent.match(/webOS/i) !== null ||
            navigator.userAgent.match(/iPhone/i) !== null ||
            navigator.userAgent.match(/iPad/i) !== null ||
            navigator.userAgent.match(/iPod/i) !== null ||
            navigator.userAgent.match(/BlackBerry/i) !== null ||
            navigator.userAgent.match(/Windows Phone/i) !== null
        );
    }

    renderLayoutPtw() {
        const { allMenuItems, selectKeys, headerMenu, showSideMenuDesktop } = this.props;
        const { isMobile } = this.state;
        let classWrapHeader = "";
        if (isMobile) {
            classWrapHeader = "header-mobile";
        }
        const menu = SideMenu.findRoute(selectKeys[0], allMenuItems) || {};
        if (!showSideMenuDesktop && window.location.pathname === "/dashboard") {
            return (
                <div className="layout-page" style={{ flexDirection: "column", height: "100%" }}>
                    <div className={`header-page-v2 ${classWrapHeader}`}>
                        <HeaderPage history={this.props.history} />
                    </div>
                    <div
                        style={{
                            display: "none",
                            position: "absolute",
                            width: "100%",
                            top: "56px",
                            overflow: "hidden"
                        }}>
                        <SideMenu isMobile={isMobile} history={this.props.history} />
                        <div style={{ flex: 1, overflow: "hidden" }}>
                            <AppRoute headerMenu={headerMenu} menuId={menu && menu.id} allMenuItems={allMenuItems} />
                        </div>
                    </div>
                    {isMobile ? <DashboardMobileV2 history={this.props.history} /> : <DashboardDesktop />}
                </div>
            );
        }
        return (
            <div className="layout-page" style={{ flexDirection: "column", height: "100%" }}>
                <div className={`header-page-v2 ${classWrapHeader}`}>
                    <HeaderPage history={this.props.history} />
                </div>
                <div
                    style={{
                        display: "flex",
                        position: "absolute",
                        width: "100%",
                        top: "56px",
                        overflow: "hidden"
                    }}>
                    <SideMenu isMobile={isMobile} history={this.props.history} />
                    <div style={{ flex: 1, overflow: "hidden" }}>
                        <AppRoute headerMenu={headerMenu} menuId={menu && menu.id} allMenuItems={allMenuItems} />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { isAuthenticated, showSideMenuDesktop } = this.props;
        if (isAuthenticated) {
            return this.renderLayoutPtw();
        }
        return <LoginPage showSideMenuDesktop={showSideMenuDesktop} />;
    }
}

const mapStateToProps = (state) => {
    return { ...(state.root || {}) };
};

export default connect(mapStateToProps)(LayoutPage);
