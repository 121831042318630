import Tooltip from '@idtek/component/lib/tooltip/Tooltip'
import { Badge } from 'antd'
import React, { Component } from 'react'
import IconThreeDot from '../icon/IconThreeDot'

export default class ThreeDotButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    setCount(count) {
        this.setState({
            count
        })
    }
    render() {
        const { count } = this.state
        return (
            <Tooltip title={`${count} Bookings in queue`} placement="topRight">
                <Badge count={count} showZero>
                    <div className="three-dot-dashboard" onClick={() => this.props.onClick && this.props.onClick()}>
                        <IconThreeDot />
                    </div>
                </Badge>
            </Tooltip>
        )
    }
}
