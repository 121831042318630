import { Alert } from "@idtek/component";
import Icon from "@idtek/component/lib/icon/Icon";
import Tooltip from "@idtek/component/lib/tooltip/Tooltip";
import Axios from "axios";
import { default as DataUtil, default as _ } from "lodash";
import moment from "moment";
import React from "react";
import voucher_codes from "voucher-code-generator";
import { changeSideMenu } from "../application/actions/action";
import { loadMenu } from "../application/actions/menuAction";
import SideMenu from "../application/components/SideMenu";
import "../application/css/styleArrow.scss";
import AppStore from "../store";

/* global numeral */

export default class AppUtil {
    static GLOBAL_DATE_FORMAT = "MMM/DD/YYYY";
    static GLOBAL_DATETIME_FORMAT = "hh:mm A MMM/DD/YYYY ";
    static toNumber(value) {
        return numeral(value).value();
    }

    static capitalizeFirstLetter(string) {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
    }

    static formatNumberValue(value, format) {
        return numeral(value).format(format);
    }

    static GLOBAL_DOMAIN_PATH = "";

    static getCurrentUrl = (props) => {
        return DataUtil.get(props, "match.url", "undefined");
    };

    static getQueryParams(name, url) {
        if (!url) url = window.location.href;
        name = name.replace("/[]/g", "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    static encodeURLQuery(obj) {
        let str =
            "?" +
            Object.keys(obj)
                .reduce(function (a, k) {
                    let val = obj[k];
                    if (typeof val == "object") {
                        val = JSON.stringify(val);
                    }
                    a.push(k + "=" + encodeURIComponent(val));
                    return a;
                }, [])
                .join("&");
        return str;
    }

    static mergeConfig = (mergeTarget, customize) => {};

    static doBulkActionWorkflow = async (commands) => {
        const url = AppUtil.GLOBAL_API_PATH + "/workflow/bulk-action-workflow";
        const param = {
            commands
        };
        return await AppUtil.Axios.post(url, param);
    };

    static doActionWorkflow = async (id, workflowDomain, workflowCode, cmdId) => {
        const url = AppUtil.GLOBAL_API_PATH + "/workflow/action-wkf";
        const param = {
            id,
            workflowDomain,
            workflowCode,
            cmdId
        };
        return await AppUtil.Axios.post(url, param);
    };

    static GLOBAL_API_PATH = this.GLOBAL_DOMAIN_PATH + "/api/app.php/api";
    static REGIONAL_PHONE_NUMBER = ["226", "289", "343", "416", "519", "613", "647", "705", "807", "905"];

    static DataUtil = DataUtil;

    static Axios = Axios;

    static getApi = async (api, params) => {
        const url = AppUtil.GLOBAL_API_PATH + api;
        let response;
        try {
            response = await AppUtil.Axios.get(url, { params });
        } catch {}
        return response;
    };

    static postApi = async (api, params) => {
        const url = AppUtil.GLOBAL_API_PATH + api;
        let response;
        try {
            response = await AppUtil.Axios.post(url, params);
        } catch {}
        return response;
    };

    static deleteApi = async (api, params) => {
        const url = AppUtil.GLOBAL_API_PATH + api;
        let response;
        try {
            response = await AppUtil.Axios.delete(url, params);
        } catch {}
        return response;
    };

    static checkPermisstion = (actionKey, rights) => {
        let right = false;
        if (actionKey) {
            right = rights[actionKey];
        } else {
            right = true;
        }
        return right;
    };

    static LogErrors(message = "Log 123") {
        console.log(message);
    }

    static ToastSuccess(message = "Success!") {
        Alert.Toast_info("Notification", message, 1);
    }

    static ToastUpdateSuccess(message = "Update success!") {
        Alert.Toast_info("Notification", message, 1);
    }

    static ToastSaveSuccess(message = "Save success!") {
        Alert.Toast_info("Notification", message, 1);
    }

    static ToastCreateSuccess(message = "Create success!") {
        Alert.Toast_info("Notification", message, 1);
    }

    static ToastServerError(message = "Server error, please try again!") {
        Alert.Toast_info("Notification", message, 0);
    }

    static ToastHostError(message = "Server error, please try again!") {
        Alert.Toast_info("Notification", message, 0);
    }

    static ToastApiError(message = "Server error, please try again!") {
        Alert.Toast_info("Notification", message, 0);
    }

    static ToastDeleteSuccess(message = "Delete success!") {
        Alert.Toast_info("Notification", message, 1);
    }

    static ToastDeleteError(message = "Data error, please try again!") {
        Alert.Toast_info("Notification", message, 0);
    }

    static ToastError(message) {
        Alert.Toast_info("Notification", message, 0);
    }
    static ToastWarning(message) {
        Alert.Toast_info("Warning", message, 3);
    }
    static async DeleteConfirm(message, confirmHandler) {
        let confirm = await Alert.Swal_confirm("Notification", message, 0);
        if (confirm.value) {
            confirmHandler && confirmHandler();
        }
    }

    static isNullOrUndefined = (value) => {
        return _.isNull(value) || _.isUndefined(value);
    };

    static downloadFile = async (url, params, method = "get", fileName = "Export.xlsx", callback) => {
        if (method === "get") {
            const api = url + AppUtil.encodeURLQuery(params);
            callback && callback();
            return window.open(api, "_blank");
        }
        const response = await Axios.post(AppUtil.GLOBAL_API_PATH + url, params, { responseType: "blob" });
        if (response.status === 200) {
            let url = window.URL.createObjectURL(response.data);
            let link = document.createElement("a");
            link.href = url;
            link.download = fileName;
            link.click();
            callback && callback();
        } else {
            callback && callback();
        }
    };

    static capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    static loadAndPrintBooking = async (bookingId, url = "/booking/get-bill/") => {
        var dualScreenLeft = window.screenLeft;
        var dualScreenTop = window.screenTop;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight;

        var left = width / 2 - 800 / 2 + dualScreenLeft;
        var top = height / 2 - 500 / 2 + dualScreenTop;
        const mywindow = window.open("url", "_blank", "height=500,width=800 , top=" + top + ", left=" + left);
        console.log(mywindow);
        if (mywindow == null || typeof mywindow == "undefined") {
            alert("Please disable your pop-up blocker and try again.");
        } else {
            mywindow.focus();
        }
        mywindow.document.write("<html><head><title>Print</title>");
        mywindow.document.write(
            "<style>@media print {  html, body { width: 80mm;height: auto;margin: 0;}  @page {border: initial;border-radius: initial;width: initial;min-height: initial;box-shadow: initial;background: initial;page-break-after: always;}}</style>"
        );

        mywindow.document.write("</head><body>");
        const response = await AppUtil.getApi(url + bookingId);
        if (_.get(response, "data.success")) {
            mywindow.document.write("</head><body>");
            mywindow.document.write(response.data.template);
            mywindow.document.write("</body></html>");
            mywindow.document.close();
            mywindow.focus(); // necessary for IE >= 10*/
            mywindow.print();
            mywindow.close();
        } else {
            AppUtil.ToastApiError();
            mywindow.close();
        }
    };

    static printBookingByTemplate = (template) => {
        var dualScreenLeft = window.screenLeft;
        var dualScreenTop = window.screenTop;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight;

        var left = width / 2 - 800 / 2 + dualScreenLeft;
        var top = height / 2 - 500 / 2 + dualScreenTop;
        //
        const mywindow = window.open("", "_blank", "height=500,width=800 , top=" + top + ", left=" + left);
        mywindow.document.write("<html><head><title>Print</title>");
        mywindow.document.write(
            "<style>@media print {  html, body { width: 80mm;height: auto;margin: 0 auto;}  @page {border: initial;border-radius: initial;width: initial;min-height: initial;box-shadow: initial;background: initial;page-break-after: always;}}</style>"
        );
        mywindow.document.write("</head><body>");
        mywindow.document.write(template);
        mywindow.document.write("</body></html>");
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.print();
        mywindow.close();
    };

    static renderRowStatus = (key, value) => {
        const ptwStatus = AppStore.getState().root.ptwStatus;
        const optionStatus = _.find(ptwStatus, (x) => _.toUpper(x.code) === _.toUpper(key));
        const itemStatus = _.find(_.get(optionStatus, "catalos", []), (x) => _.toUpper(x.code) === _.toUpper(value));
        let text = _.get(itemStatus, "name");
        let color = _.get(itemStatus, "note");
        if (!value || value === "NONE") return null;
        return (
            <div className="status-ptw">
                <div className={"box-new"} style={{ backgroundColor: color ? color : "transparent" }} />
                <span>{text}</span>
            </div>
        );
    };

    static getRowStatusName = (key, value) => {
        const ptwStatus = AppStore.getState().root.ptwStatus;
        const optionStatus = _.find(ptwStatus, (x) => _.toUpper(x.code) === _.toUpper(key));
        const itemStatus = _.find(_.get(optionStatus, "catalos", []), (x) => _.toUpper(x.code) === _.toUpper(value));
        let text = _.get(itemStatus, "name");
        if (!value || value === "NONE") return null;
        return text;
    };

    static renderHeaderStatus = (key, value) => {
        if (!value || value === "DONE") return null;
        const ptwStatus = AppStore.getState().root.ptwStatus;
        const optionStatus = _.find(ptwStatus, (x) => _.toUpper(x.code) === _.toUpper(key));
        const itemStatus = _.find(_.get(optionStatus, "catalos", []), (x) => _.toUpper(x.code) === _.toUpper(value));
        let text = _.get(itemStatus, "name");
        let color = _.get(itemStatus, "note");
        return (
            <div
                className="status-ptw-header"
                style={{
                    color: `${color ? color : "#337ab7"}`,
                    border: `1px solid ${color ? color : "#337ab7"}`
                }}>
                <span>{text && text.toUpperCase()}</span>
            </div>
        );
    };

    static renderRiskStatus = (code) => {
        const ptwStatus = AppStore.getState().root.ptwStatus;
        const optionStatus = _.find(ptwStatus, (x) => _.toUpper(x.code) === _.toUpper("RISK_RANGE"));
        const itemStatus = _.find(_.get(optionStatus, "catalos", []), (x) => _.toUpper(x.code) === _.toUpper(code));
        let color = _.get(itemStatus, "note");
        let text = _.get(itemStatus, "label");
        return (
            <div className="global-flex-align-center">
                <div className="status-ptw">
                    <div className={"box-new"} style={{ backgroundColor: color }} />
                    <span>{text}</span>
                </div>
            </div>
        );
    };

    static renderRiskDetailCol = (value, code) => {
        const ptwStatus = AppStore.getState().root.ptwStatus;
        const optionStatus = _.find(ptwStatus, (x) => _.toUpper(x.code) === _.toUpper("RISK_RANGE"));
        const itemStatus = _.find(_.get(optionStatus, "catalos", []), (x) => _.toUpper(x.code) === _.toUpper(code));
        let color = _.get(itemStatus, "note");
        return (
            <div
                style={{
                    background: `${color ? color : "#337ab7"}`,
                    color: `#fff`,
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center"
                }}>
                <span>{value}</span>
            </div>
        );
    };

    static renderActionRowStatus = (key, value, code, onClick) => {
        const ptwStatus = AppStore.getState().root.ptwStatus;
        const optionStatus = _.find(ptwStatus, (x) => _.toUpper(x.code) === _.toUpper(key));
        const itemStatus = _.find(_.get(optionStatus, "catalos", []), (x) => _.toUpper(x.code) === _.toUpper(value));
        // let text = _.get(itemStatus, 'name');
        let color = _.get(itemStatus, "note");
        if (!value || value === "NONE") return null;
        return (
            <div className="status-ptw" onClick={() => onClick && onClick()}>
                <div className={"box-new"} style={{ backgroundColor: color }} />
                <span className={"hover-tag-a"}>{code}</span>
            </div>
        );
    };
    static renderStatus = (type, value, color) => {
        let element;
        switch (type.toUpperCase()) {
            case "NEW":
                element = (
                    <div className="status-ptw">
                        <div className={"box-new"} style={{ backgroundColor: color }} />
                        <span>{value}</span>
                    </div>
                );
                break;
            case "CANCEL":
                element = (
                    <div className="status-ptw ">
                        <div className={"box-cancel"} style={{ backgroundColor: color }} />
                        <span>{value}</span>
                    </div>
                );
                break;
            case "DENIED":
                element = (
                    <div className="status-ptw">
                        <div className={"box-denied"} style={{ backgroundColor: color }} />
                        <span>{value}</span>
                    </div>
                );
                break;
            case "COMPLETE":
                element = (
                    <div className="status-ptw">
                        <div className={"box-complete"} style={{ backgroundColor: color }} />
                        <span>{value}</span>
                    </div>
                );
                break;
            case "PROGRESS":
                element = (
                    <div className="status-ptw">
                        <div className={"box-progress"} style={{ backgroundColor: color }} />
                        <span>{value}</span>
                    </div>
                );
                break;
            case "PREPARE":
                element = (
                    <div className="status-ptw">
                        <div className={"box-prepare"} style={{ backgroundColor: color }} />
                        <span>{value}</span>
                    </div>
                );
                break;
            default:
                break;
        }
        return element;
    };

    static renderStatusHeader = (type, value, color) => {
        let element;
        switch (type.toUpperCase()) {
            case "NEW":
                element = (
                    <div
                        className="status-ptw-header"
                        style={{
                            color: `${color ? color : "#337ab7"}`,
                            border: `1px solid ${color ? color : "#337ab7"}`
                        }}>
                        <span>{value && value.toUpperCase()}</span>
                    </div>
                );
                break;
            case "CANCEL":
                element = (
                    <div
                        className="status-ptw-header"
                        style={{
                            color: `${color ? color : "#aaadb7"}`,
                            border: `1px solid ${color ? color : "#aaadb7"}`
                        }}>
                        <span>{value && value.toUpperCase()}</span>
                    </div>
                );
                break;
            case "DENIED":
                element = (
                    <div
                        className="status-ptw-header"
                        style={{
                            color: `${color ? color : "#f44336"}`,
                            border: `1px solid ${color ? color : "#f44336"}`
                        }}>
                        <span>{value && value.toUpperCase()}</span>
                    </div>
                );
                break;
            case "COMPLETE":
                element = (
                    <div
                        className="status-ptw-header"
                        style={{
                            color: `${color ? color : "#5cb85c"}`,
                            border: `1px solid ${color ? color : "#5cb85c"}`
                        }}>
                        <span>{value && value.toUpperCase()}</span>
                    </div>
                );
                break;
            case "PROGRESS":
                element = (
                    <div
                        className="status-ptw-header"
                        style={{
                            color: `${color ? color : "#f0ad4e"}`,
                            border: `1px solid ${color ? color : "#f0ad4e"}`
                        }}>
                        <span>{value && value.toUpperCase()}</span>
                    </div>
                );
                break;
            case "PREPARE":
                element = (
                    <div
                        className="status-ptw-header"
                        style={{
                            color: `${color ? color : "#b7ae58"}`,
                            border: `1px solid ${color ? color : "#b7ae58"}`
                        }}>
                        <span>{value && value.toUpperCase()}</span>
                    </div>
                );
                break;
            case "REVOKE":
                element = (
                    <div
                        className="status-ptw-header"
                        style={{
                            color: `${color ? color : "#7c7c7c"}`,
                            border: `1px solid ${color ? color : "#7c7c7c"}`
                        }}>
                        <span>{value && value.toUpperCase()}</span>
                    </div>
                );
                break;
            case "REISSUE":
                element = (
                    <div
                        className="status-ptw-header"
                        style={{
                            color: `${color ? color : "#973FCE"}`,
                            border: `1px solid ${color ? color : "#973FCE"}`
                        }}>
                        <span>{value && value.toUpperCase()}</span>
                    </div>
                );
                break;
            case "RUNTEST":
                element = (
                    <div
                        className="status-ptw-header"
                        style={{
                            color: `${color ? color : "#CE843D"}`,
                            border: `1px solid ${color ? color : "#CE843D"}`
                        }}>
                        <span>{value && value.toUpperCase()}</span>
                    </div>
                );
                break;
            default:
                break;
        }
        return element;
    };

    static renderType = (type) => {
        let element;
        switch (type.toUpperCase()) {
            case "CREATE":
                element = <span>Tạo mới</span>;
                break;
            case "CONFIRM":
                element = <span>Ban hành</span>;
                break;
            case "REVOKE":
                element = <span>Thu hồi</span>;
                break;
            case "ACCEPTED":
                element = <span>Tái ban hành</span>;
                break;
            case "RUNTEST":
                element = <span>Chạy thử</span>;
                break;
            case "CLOSE":
                element = <span>Đóng giấy phép</span>;
                break;
            default:
                break;
        }
        return element;
    };

    static getMenuName() {
        const allMenuItems = _.get(AppStore.getState(), "root.allMenuItems");
        const selectKey = AppStore.getState().root.selectKeys ? AppStore.getState().root.selectKeys[0] : "";
        const route = SideMenu.findRoute(selectKey, allMenuItems);
        return _.get(route, "name");
    }

    static getColorTypeLevel(type, options) {
        let color = "";
        _.each(options, (opt) => {
            if (opt.code === type) {
                color = opt.note;
            }
        });
        return color;
    }

    static renderTypeLevelSiteMap = (type, value, options) => {
        let element;
        _.each(options, (opt) => {
            if (opt.code === type) {
                element = (
                    <div className="status-ptw">
                        <div className={"box-custom"} style={{ backgroundColor: _.get(opt, "note") }} />
                        <span>{value}</span>
                    </div>
                );
            }
        });
        return element;
    };

    // cong viec nong lanh loai 1 va 2
    static renderTypeLevel = (type, value, color) => {
        let element;
        switch (type.toUpperCase()) {
            case "HOT1":
                element = (
                    <div className="status-ptw">
                        <div className={"box-custom"} style={{ backgroundColor: color || "#FF7C80" }} />
                        <span>{value}</span>
                    </div>
                );
                break;
            case "HOT2":
                element = (
                    <div className="status-ptw ">
                        <div className={"box-custom"} style={{ backgroundColor: color || "yellow" }} />
                        <span>{value}</span>
                    </div>
                );
                break;
            case "COOL1":
                element = (
                    <div className="status-ptw">
                        <div className={"box-custom"} style={{ backgroundColor: color || "#66CCFF" }} />
                        <span>{value}</span>
                    </div>
                );
                break;
            case "COOL2":
                element = (
                    <div className="status-ptw">
                        <div className={"box-custom"} style={{ backgroundColor: color || "#D3D3D3" }} />
                        <span>{value}</span>
                    </div>
                );
                break;
            default:
                break;
        }
        return element;
    };

    static renderTrainingStatus = (type) => {
        let element;
        switch (type) {
            case "true":
                element = (
                    <div className="status-ptw">
                        <div className={"box-custom"} style={{ backgroundColor: "rgb(76, 175, 80)" }} />
                        <span>Đã đào tạo</span>
                    </div>
                );
                break;
            case "false":
                element = (
                    <div className="status-ptw ">
                        <div className={"box-custom"} style={{ backgroundColor: "rgb(170, 173, 183)" }} />
                        <span>Chưa đào tạo</span>
                    </div>
                );
                break;
            case "isHr":
                element = (
                    <div className="status-ptw">
                        <div className={"box-custom"} style={{ backgroundColor: "rgb(255, 152, 0)" }} />
                        <span>Không cần đào tạo</span>
                    </div>
                );
                break;
            default:
                break;
        }
        return element;
    };

    static renderStatusV1 = (type, value) => {
        let element;
        switch (type.toUpperCase()) {
            case "NEW":
                element = <span className="status-ptw status-new">{value}</span>;
                break;
            case "CANCEL":
                element = <span className="status-ptw status-cancel">{value}</span>;
                break;
            case "DENIED":
                element = <span className="status-ptw status-denied">{value}</span>;
                break;
            case "COMPLETE":
                element = <span className="status-ptw status-complete">{value}</span>;
                break;
            case "PROGRESS":
                element = <span className="status-ptw status-progress">{value}</span>;
                break;
            default:
                break;
        }
        return element;
    };

    static subTitle(str, length) {
        let title = "";
        if (str.length > length) {
            title = str.substr(0, length);
            const lastSpace = title.lastIndexOf(" ");
            title = title.substr(0, lastSpace) + "...";
        } else {
            title = str;
        }
        return title;
    }

    static updateNotifyBageMenu = () => {
        Axios.get(AppUtil.GLOBAL_DOMAIN_PATH + "/api/identity/load-menu").then((res) => {
            if (res) {
                try {
                    const data = res.data.menu ? res.data.menu : [];
                    const selectKey = AppStore.getState().root.selectKeys ? AppStore.getState().root.selectKeys[0] : "";
                    const inDomainItems = SideMenu.getSideMenus(selectKey, data);
                    let tree = SideMenu.buildMenuTree(inDomainItems);
                    tree = SideMenu.customSort(tree);
                    AppStore.dispatch(loadMenu(data));
                    AppStore.dispatch(changeSideMenu(tree));
                } catch {}
            }
        });
    };

    static setNotifyBageMenu = (data) => {
        try {
            const selectKey = AppStore.getState().toJS().root.selectKeys ? AppStore.getState().root.selectKeys[0] : "";
            const inDomainItems = SideMenu.getSideMenus(selectKey, data);
            let tree = SideMenu.buildMenuTree(inDomainItems);
            tree = SideMenu.customSort(tree);
            AppStore.dispatch(loadMenu(data));
            AppStore.dispatch(changeSideMenu(tree));
        } catch {}
    };

    static writeLog = (menuId, action) => {
        const apiRegisterUrl = `${AppUtil.GLOBAL_DOMAIN_PATH}/api/actionLog/writeLog`;
        let record = {
            menuId: menuId,
            action: action
        };
        Axios.post(apiRegisterUrl, record)
            .then((res) => {
                if (res.status === 200) {
                }
            })
            .catch((error) => {
                if (error.res && error.res.data) {
                    console.log(error.res.data);
                }
            });
    };

    // grid to excel
    static exportExcelClient = (grid, params) => {
        grid.exportToExcel(params);
    };

    static encodeObject = (data) => {
        //if dev
        //return JSON.stringify(data)
        return btoa(JSON.stringify(data));
    };

    static decodeObject = (stringData) => {
        //if dev
        // return JSON.stringify(data)
        return atob(stringData);
    };

    static customHeaderCellExcel = (params) => {
        if (_.isString(params.column.colDef.headerName)) {
            return params.column.colDef.headerName;
        }
        return "Header Name";
    };

    static isNumber = (value) => {
        let processValue;
        try {
            processValue = value && value.toString().replace(",", "");
            processValue = processValue && processValue.replace("%", "");
        } catch (e) {
            console.error(value);
        }
        return !isNaN(Number(processValue));
    };

    static buildTreeDocument = (data, ptwReqActivityId) => {
        const newDocuments = [];
        data = _.orderBy(data, (item) => {
            return _.get(item, "type.displayIndex");
        });
        if (data) {
            const group = data.reduce((r, a) => {
                r[a.typeId] = [...(r[a.typeId] || []), a];
                return r;
            }, {});
            const keyGroup = _.keys(group);
            _.map(keyGroup, (key, index) => {
                let flag = true;
                _.map(group[key], (item) => {
                    const dataCheckFalse = _.find(newDocuments, (itemDocument) => {
                        return itemDocument.typeId === item.typeId && itemDocument.checked === false;
                    });
                    if (item.checked) {
                        if (flag) {
                            if (dataCheckFalse) {
                                newDocuments[index] = {
                                    id: item.id,
                                    name: _.get(item, "type.name"),
                                    checked: _.get(item, "checked"),
                                    index: index,
                                    isLeaf: false,
                                    isEdit: true,
                                    typeId: item.typeId,
                                    expanded: true,
                                    actionRow: true,
                                    ptwReqActivityId: item.ptwReqActivityId,
                                    children: [
                                        {
                                            id: item.id,
                                            name: item.name,
                                            attachFileId: item.attachFileId,
                                            ptwReqActivityId: item.ptwReqActivityId,
                                            ptwReqActivity: item.ptwReqActivity,
                                            isEdit: item.ptwReqActivityId === ptwReqActivityId,
                                            isLeaf: true,
                                            actionRow: true,
                                            typeId: item.typeId
                                        }
                                    ]
                                };
                            } else {
                                newDocuments.push({
                                    id: item.id,
                                    name: _.get(item, "type.name"),
                                    checked: _.get(item, "checked"),
                                    index: index,
                                    isLeaf: false,
                                    isEdit: true,
                                    typeId: item.typeId,
                                    expanded: true,
                                    actionRow: true,
                                    ptwReqActivityId: item.ptwReqActivityId,
                                    children: [
                                        {
                                            id: item.id,
                                            name: item.name,
                                            isEdit: item.ptwReqActivityId === ptwReqActivityId,
                                            attachFileId: item.attachFileId,
                                            ptwReqActivityId: item.ptwReqActivityId,
                                            ptwReqActivity: item.ptwReqActivity,
                                            isLeaf: true,
                                            actionRow: true,
                                            typeId: item.typeId
                                        }
                                    ]
                                });
                            }
                            flag = false;
                        } else {
                            newDocuments[index]["children"].push({
                                id: item.id,
                                name: item.name,
                                attachFileId: item.attachFileId,
                                ptwReqActivityId: item.ptwReqActivityId,
                                ptwReqActivity: item.ptwReqActivity,
                                isEdit: item.ptwReqActivityId === ptwReqActivityId,
                                isLeaf: true,
                                actionRow: true,
                                typeId: item.typeId
                            });
                        }
                    } else if (!item.checked) {
                        if (dataCheckFalse) {
                            newDocuments[index] = {
                                id: item.id,
                                name: _.get(item, "type.name"),
                                checked: _.get(item, "checked"),
                                index: index,
                                isLeaf: false,
                                typeId: item.typeId,
                                expanded: true,
                                actionRow: true,
                                ptwReqActivityId: item.ptwReqActivityId,
                                children: []
                            };
                        } else {
                            newDocuments.push({
                                id: item.id,
                                name: item.name,
                                checked: item.checked,
                                index: index,
                                isLeaf: false,
                                typeId: item.typeId,
                                expanded: true,
                                actionRow: true,
                                ptwReqActivityId: item.ptwReqActivityId,
                                children: []
                            });
                        }
                    }
                });
            });
        }
        //set lại index
        newDocuments.forEach((itemParent) => {
            let index = 0;
            itemParent.children.forEach((item) => {
                item.index = index;
                index += 1;
                return null;
            });
        });
        return newDocuments;
    };

    static toNumeric = (value) => {
        let processValue;
        try {
            processValue = value && value.toString().replace(",", "");
            processValue = processValue && processValue.replace("%", "");
        } catch (e) {
            console.error(value);
        }
        return Number(processValue);
    };

    static customDataCellExcel = (params) => {
        const { value, column, node } = params;
        const field = _.get(column, "colDef.field");
        const rawValue = field && node.data[field];
        let returnVal = node.data.hasOwnProperty(field) ? rawValue : value;
        if (returnVal === null || returnVal === "") {
            return "";
        }
        if (AppUtil.isNumber(returnVal) && !_.isArray(returnVal)) {
            return AppUtil.toNumeric(returnVal);
        }
        if (_.isEmpty(returnVal) && returnVal !== 0 && returnVal !== "0") {
            returnVal = "";
            return returnVal;
        }
        if (_.isArray(returnVal)) {
            let tempValue = "";
            _.map(returnVal, (item) => {
                if (item.name) {
                    tempValue = tempValue.concat(`${item.name}`);
                } else {
                    tempValue.concat(item);
                }
            });
            returnVal = tempValue;
            return returnVal;
        }

        return returnVal;
    };

    static titleExcel = (content = []) => {
        return [
            [
                {
                    styleId: "company",
                    data: {
                        type: "String",
                        value: "TỔNG CÔNG TY KHÍ VIỆT NAM - CTCP"
                    },
                    mergeAcross: 3
                }
            ],
            ...content,
            [],
            []
        ];
    };

    static titleExcelv2 = (content = []) => {
        return [[], ...content, [], []];
    };

    static titleTime = (content = []) => {
        return [...content];
    };

    static cellClassRules = () => {
        return {
            center: function (params) {
                return ["stt", "department.code", "planUnit.code", "financeApproval", "active"].indexOf(params.colDef.field) > -1;
            },

            categoryRow: function (params) {
                if (!_.isEmpty(params.data.children)) {
                    return true;
                }
                return false;
            },

            left: function (params) {
                return ["code", "estNumber"].indexOf(params.colDef.field) > -1;
            },
            cell: function () {
                return true;
            },
            odd: function (params) {
                const { rowIndex } = params;
                return rowIndex % 2 === 0;
            },
            treeLevel1: function (params) {
                const { data, colDef } = params;
                const level = data.level ? data.level.toString().split(".").length : -1;
                return level === 1 && colDef.cellClass.includes("group-tree-col");
            },
            treeLevel1Value: function (params) {
                const { data } = params;
                const level = data.level ? data.level.toString().split(".").length : -1;
                return level === 1;
            },
            treeLevel2: function (params) {
                const { data, colDef } = params;
                const level = data.level ? data.level.toString().split(".").length : -1;
                return level === 2 && colDef.cellClass.includes("group-tree-col");
            },
            treeLevel3: function (params) {
                const { data, colDef } = params;
                const level = data.level ? data.level.toString().split(".").length : -1;
                return level === 3 && colDef.cellClass.includes("group-tree-col");
            },
            treeLevel4: function (params) {
                const { data, colDef } = params;
                const level = data.level ? data.level.toString().split(".").length : -1;
                return level === 4 && colDef.cellClass.includes("group-tree-col");
            },
            treeLevel5: function (params) {
                const { data, colDef } = params;
                const level = data.level ? data.level.toString().split(".").length : -1;
                return level === 5 && colDef.cellClass.includes("group-tree-col");
            },
            compare: function (params) {
                const { data } = params;
                return !!data.compare;
            },
            textArea: function (params) {
                const { value } = params;
                return _.isArray(value) && value.length > 0;
            },
            number: function (params) {
                const { value, column, node } = params;
                const field = _.get(column, "colDef.field");
                const rawValue = field ? node.data[field] : value;
                return rawValue ? AppUtil.isNumber(rawValue) : false;
            }
        };
    };

    static cellClassRulesCustom = () => {
        return {
            center: function (params) {
                return ["stt", "department.code", "planUnit.code", "financeApproval", "active"].indexOf(params.colDef.field) > -1;
            },

            categoryRow: function (params) {
                if (!_.isEmpty(params.data.children)) {
                    return true;
                }
                return false;
            },

            left: function (params) {
                return ["code", "estNumber"].indexOf(params.colDef.field) > -1;
            },
            cell: function () {
                return true;
            },
            odd: function (params) {
                const { rowIndex } = params;
                return rowIndex % 2 === 0;
            },
            treeLevel1: function (params) {
                const { data, colDef } = params;
                const level = data.levelTree ? data.levelTree.toString().split(".").length : -1;
                return level === 1 && colDef.cellClass.includes("group-tree-col");
            },
            treeLevel1Value: function (params) {
                const { data } = params;
                const level = data.levelTree ? data.levelTree.toString().split(".").length : -1;
                return level === 1;
            },
            treeLevel2: function (params) {
                const { data, colDef } = params;
                const level = data.levelTree ? data.levelTree.toString().split(".").length : -1;
                return level === 2 && colDef.cellClass.includes("group-tree-col");
            },
            treeLevel2Value: function (params) {
                const { data } = params;
                const level = data.levelTree ? data.levelTree.toString().split(".").length : -1;
                return level === 2;
            },
            treeLevel3: function (params) {
                const { data, colDef } = params;
                const level = data.levelTree ? data.levelTree.toString().split(".").length : -1;
                return level === 3 && colDef.cellClass.includes("group-tree-col");
            },
            treeLevel4: function (params) {
                const { data, colDef } = params;
                const level = data.levelTree ? data.levelTree.toString().split(".").length : -1;
                return level === 4 && colDef.cellClass.includes("group-tree-col");
            },
            treeLevel5: function (params) {
                const { data, colDef } = params;
                const level = data.levelTree ? data.levelTree.toString().split(".").length : -1;
                return level === 5 && colDef.cellClass.includes("group-tree-col");
            },
            compare: function (params) {
                const { data } = params;
                return !!data.compare;
            },
            textArea: function (params) {
                const { value } = params;
                return _.isArray(value) && value.length > 0;
            },
            number: function (params) {
                const { value, column, node } = params;
                const field = _.get(column, "colDef.field");
                const rawValue = field ? node.data[field] : value;
                return rawValue ? AppUtil.isNumber(rawValue) : false;
            }
        };
    };
    static excelStylesv2 = () => {
        const fontName = "Times New Roman";
        const size = 10;
        const sizeHeader = 12;
        return [
            {
                id: "header",
                alignment: {
                    horizontal: "Center",
                    vertical: "Center",
                    wrapText: true
                },
                interior: {
                    color: "#dbebfa",
                    pattern: "Solid"
                },
                font: {
                    bold: true,
                    color: "#0072bc",
                    size: sizeHeader,
                    fontName
                },
                borders: {
                    borderBottom: {
                        weight: 1,
                        color: "#adadad"
                    },
                    borderLeft: {
                        weight: 1,
                        color: "#adadad"
                    },
                    borderRight: {
                        weight: 1,
                        color: "#adadad"
                    },
                    borderTop: {
                        weight: 1,
                        color: "#adadad"
                    }
                }
            },

            {
                id: "center",
                alignment: {
                    horizontal: "Center",
                    vertical: "Center",
                    shrinkToFit: true,
                    indent: 1
                }
            },
            // {
            //     id: "odd",
            //     font: {
            //         bold: false,
            //         color: '#15294d'
            //     },
            //     interior: {
            //         color: "#e5f7ff",
            //         pattern: "Solid"
            //     }
            // },
            {
                id: "left",
                alignment: {
                    horizontal: "Left",
                    shrinkToFit: true,
                    indent: 1
                }
            },

            {
                id: "right",
                alignment: {
                    horizontal: "Right",
                    shrinkToFit: false,
                    indent: 1
                }
            },
            {
                id: "cell",
                alignment: {
                    vertical: "Center",
                    shrinkToFit: false,
                    indent: 1
                },
                // dataType: "number",
                borders: {
                    borderBottom: {
                        weight: 0,
                        color: "#adadad"
                    },
                    borderLeft: {
                        weight: 0,
                        color: "#adadad"
                    },
                    borderRight: {
                        weight: 0,
                        color: "#adadad"
                    },
                    borderTop: {
                        weight: 0,
                        color: "#adadad"
                    }
                },
                font: {
                    size,
                    fontName
                }
            },

            {
                id: "titleExcelv2",
                alignment: {
                    vertical: "Left",
                    horizontal: "Left",
                    shrinkToFit: true,
                    indent: 1,
                    wrapText: true
                },
                font: {
                    bold: true,
                    size: 16,
                    color: "#172b4d",
                    fontName
                }
            },
            {
                id: "titleTime",
                alignment: {
                    vertical: "Left",
                    horizontal: "Left",
                    shrinkToFit: true,
                    indent: 1,
                    wrapText: true
                },
                font: {
                    bold: true,
                    size: 14,
                    color: "#172b4d"
                }
            },
            {
                id: "company",
                alignment: {
                    horizontal: "Left",
                    vertical: "Center",
                    wrapText: true
                },
                interior: {
                    color: "#fff"
                    // pattern: "Solid"
                },
                font: {
                    bold: true,
                    color: "#333",
                    size: 16,
                    fontName
                }
            },
            {
                id: "treeLevel1",
                font: {
                    size: 11
                }
            },
            {
                id: "treeLevel1Value",
                font: {
                    bold: true,
                    size: 11
                },
                alignment: {
                    wrapText: true
                },
                interior: {
                    color: "#fff2cc",
                    pattern: "Solid"
                }
            },
            {
                id: "treeLevel2",
                alignment: {
                    indent: 3
                },
                font: {
                    size: 11
                }
            },
            {
                id: "treeLevel2Value",
                font: {
                    // bold: true
                },
                alignment: {
                    wrapText: true
                },
                interior: {
                    color: "#eeeeee", //#BDD7EE
                    pattern: "Solid"
                }
            },
            {
                id: "treeLevel3",
                alignment: {
                    indent: 10
                }
            },
            {
                id: "treeLevel4",
                alignment: {
                    indent: 15
                },
                font: {
                    italic: true
                }
            },
            {
                id: "treeLevel5",
                alignment: {
                    indent: 20
                }
            },
            {
                id: "textArea",
                alignment: {
                    wrapText: true
                }
            },
            {
                id: "number",
                numberFormat: {
                    format: "#,##0.00"
                },
                font: {
                    size: 11
                }
            }
        ];
    };

    static excelStyles = () => {
        return [
            {
                id: "header",
                alignment: {
                    horizontal: "Center",
                    vertical: "Center",
                    wrapText: true
                },
                interior: {
                    color: "#f5f7f7",
                    pattern: "Solid"
                },
                font: {
                    bold: true,
                    color: "#0072bc"
                },
                borders: {
                    borderBottom: {
                        weight: 1,
                        color: "#adadad"
                    },
                    borderLeft: {
                        weight: 1,
                        color: "#adadad"
                    },
                    borderRight: {
                        weight: 1,
                        color: "#adadad"
                    },
                    borderTop: {
                        weight: 1,
                        color: "#adadad"
                    }
                }
            },

            {
                id: "center",
                alignment: {
                    horizontal: "Center",
                    vertical: "Center",
                    shrinkToFit: true,
                    indent: 1
                }
            },
            {
                id: "odd",
                font: {
                    bold: false,
                    color: "#15294d"
                },
                interior: {
                    color: "#e5f7ff",
                    pattern: "Solid"
                }
            },
            {
                id: "left",
                alignment: {
                    horizontal: "Left",
                    shrinkToFit: true,
                    indent: 1
                }
            },

            {
                id: "right",
                alignment: {
                    horizontal: "Right",
                    shrinkToFit: false,
                    indent: 1
                }
            },
            {
                id: "cell",
                alignment: {
                    vertical: "Center",
                    shrinkToFit: false,
                    indent: 1
                },
                // dataType: "number",
                borders: {
                    borderBottom: {
                        weight: 0,
                        color: "#adadad"
                    },
                    borderLeft: {
                        weight: 0,
                        color: "#adadad"
                    },
                    borderRight: {
                        weight: 0,
                        color: "#adadad"
                    },
                    borderTop: {
                        weight: 0,
                        color: "#adadad"
                    }
                }
            },

            {
                id: "titleExcel",
                alignment: {
                    vertical: "Center",
                    horizontal: "Center",
                    shrinkToFit: true,
                    indent: 1,
                    wrapText: true
                },
                font: {
                    bold: true,
                    size: 26,
                    color: "#172b4d"
                }
            },
            {
                id: "company",
                alignment: {
                    horizontal: "Left",
                    vertical: "Center",
                    wrapText: true
                },
                interior: {
                    color: "#fff"
                    // pattern: "Solid"
                },
                font: {
                    bold: true,
                    color: "#333",
                    size: 16
                }
            },
            {
                id: "treeLevel1",
                font: {
                    size: 16
                }
            },
            {
                id: "treeLevel1Value",
                font: {
                    bold: true
                },
                alignment: {
                    wrapText: true
                },
                interior: {
                    color: "#fff2cc",
                    pattern: "Solid"
                }
            },
            {
                id: "treeLevel2",
                alignment: {
                    indent: 5
                },
                font: {
                    bold: true
                }
            },
            {
                id: "treeLevel3",
                alignment: {
                    indent: 10
                }
            },
            {
                id: "treeLevel4",
                alignment: {
                    indent: 15
                },
                font: {
                    italic: true
                }
            },
            {
                id: "treeLevel5",
                alignment: {
                    indent: 20
                }
            },
            {
                id: "textArea",
                alignment: {
                    wrapText: true
                }
            },
            {
                id: "number",
                numberFormat: {
                    format: "#,##0.00"
                }
            }
        ];
    };
    static exportGridToExcelv2 = (grid, fileName, sheetName, title, startdate, todate, callback) => {
        // const values = this.FormFilter.getValues();
        // const financeYear = _.get(values, 'financeYear');
        const gridColumns = _.get(grid, "gridColumnApi.columnController.allDisplayedColumns");
        const gridLength = gridColumns ? gridColumns.length - 1 : 10;
        const contentTitle = [
            [
                {
                    styleId: "titleExcelv2",
                    data: {
                        type: "String",
                        value: title.toUpperCase()
                    },
                    mergeAcross: gridLength
                }
            ],
            [
                {
                    styleId: "titleTime",
                    data: {
                        type: "String",
                        value: `(Daily technician report: from "${moment(startdate).format(AppUtil.GLOBAL_DATE_FORMAT)}" to "${moment(todate).format(AppUtil.GLOBAL_DATE_FORMAT)}")`
                    },
                    mergeAcross: gridLength
                }
            ]
        ];

        const options = {
            fileName: fileName || `Daily_technician_report_${moment().format(AppUtil.GLOBAL_DATETIME_FORMAT)}`,
            sheetName: sheetName || "Data",
            headerRowHeight: 50,
            columnGroups: true,
            // columnKeys=[]
            customHeader: AppUtil.titleExcelv2(contentTitle)
        };
        // options.processHeaderCallback = this.customHeaderCellExcel;
        // options.columnKeys = columnKeys;
        //options.processCellCallback = this.customDataCellExcel;
        AppUtil.exportExcelClient(grid, options);
        callback && callback();
    };

    static DeleteTaskTypeBaseValue = async (params) => {
        const url = AppUtil.GLOBAL_API_PATH + "/task/delete-taskType-BaseValue";
        return await AppUtil.Axios.post(url, params);
    };

    static RenderSwapButton = (data, value, rowNode, mainView) => {
        return (
            <div
                className="ag-cell-wrap-edit"
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap"
                }}>
                <div
                    className="ag-cell-name"
                    style={{
                        fontWeight: !_.isEmpty(data.children) ? "500" : "normal"
                    }}>
                    <div style={{ display: "flex" }}>
                        {!_.isEmpty(data.children) && (
                            <Icon
                                type="folder-open"
                                style={{
                                    alignItems: "center",
                                    marginRight: "6px",
                                    fontSize: "16px",
                                    color: "rgb(255, 193, 4)"
                                }}
                            />
                        )}
                        <div
                            style={{
                                flex: 1,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap"
                            }}>
                            {value}
                        </div>
                    </div>
                </div>

                <div className="ag-cell-icons">
                    {data.parentId
                        ? [
                              !_.get(rowNode, "firstChild") && (
                                  <Tooltip title="Di chuyển lên">
                                      <Icon type="arrow-up" onClick={() => mainView.swapRow(data, "up")} style={{ marginRight: 10 }} />
                                  </Tooltip>
                              )
                          ]
                        : []}
                    {data.parentId
                        ? [
                              !_.get(rowNode, "lastChild") && (
                                  <Tooltip title="Di chuyển xuống">
                                      <Icon type="arrow-down" onClick={() => mainView.swapRow(data, "down")} style={{ marginRight: 10 }} />
                                  </Tooltip>
                              )
                          ]
                        : []}
                </div>
            </div>
        );
    };

    static RenderSwapButtonCustom = (data, value, rowNode, mainView) => {
        return (
            <div
                className="ag-cell-wrap-edit"
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap"
                }}>
                <div
                    className="ag-cell-name"
                    style={{
                        fontWeight: !_.isEmpty(data.children) ? "500" : "normal"
                    }}>
                    <div style={{ display: "flex" }}>
                        {!_.isEmpty(data.children) && (
                            <Icon
                                type="folder-open"
                                style={{
                                    alignItems: "center",
                                    marginRight: "6px",
                                    fontSize: "16px",
                                    color: "rgb(255, 193, 4)"
                                }}
                            />
                        )}
                        <div
                            style={{
                                flex: 1,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap"
                            }}>
                            {value}
                        </div>
                    </div>
                </div>

                <div className="ag-cell-icons">
                    {[
                        !_.get(rowNode, "firstChild") && (
                            <Tooltip title="Di chuyển lên">
                                <Icon type="arrow-up" onClick={() => mainView.swapRow(data, "up")} style={{ marginRight: 10 }} />
                            </Tooltip>
                        )
                    ]}
                    {[
                        !_.get(rowNode, "lastChild") && (
                            <Tooltip title="Di chuyển xuống">
                                <Icon type="arrow-down" onClick={() => mainView.swapRow(data, "down")} style={{ marginRight: 10 }} />
                            </Tooltip>
                        )
                    ]}
                </div>
            </div>
        );
    };

    static cloneFile = async (domainUrl, idMaster, folder) => {
        const apiBaseUrl = `${domainUrl}/api/file/clone-file`;
        if (idMaster) {
            // /////////////////////////
            const f = new FormData();
            if (idMaster) {
                f.append("AttachFileID", idMaster);
            }
            f.append("Folder", folder);
            // /////////////////////////
            const res = await AppUtil.Axios.post(apiBaseUrl, f, { headers: { "Content-Type": "multipart/form-data" } });
            return res.data;
        } else {
            return null;
        }
    };

    static saveFile = async (domainUrl, idMaster, files, folder) => {
        const apiBaseUrl = `${domainUrl}/api/file/save-file`;
        if (files) {
            // /////////////////////////
            const f = new FormData();
            const IdList = [];
            const length = !!files ? files.length : 0;
            for (let i = 0; i < length; i++) {
                if (files[i].id) {
                    IdList.push(files[i].id);
                } else {
                    f.append("Files", files[i]);
                }
            }
            if (idMaster) {
                f.append("AttachFileID", idMaster);
            }
            f.append("AttachFileDetailID", IdList);
            f.append("Folder", folder);
            // /////////////////////////
            const res = await AppUtil.Axios.post(apiBaseUrl, f, { headers: { "Content-Type": "multipart/form-data" } });
            return res.data || null;
        } else {
            return null;
        }
    };

    static loadFile = async (domainUrl, id) => {
        if (id) {
            var apiBaseUrl = domainUrl + "/api/file/get-file/" + id;
            const res = await AppUtil.Axios.get(apiBaseUrl);
            return res;
        } else {
            return null;
        }
    };

    static renderDateTime = (data, value, rowNode, grid) => {
        if (data && value && !_.isEmpty(value)) {
            return moment(value).format("DD/MM/YYYY HH:mm");
        } else return <div />;
    };
    static renderDate = (data, value, rowNode, grid) => {
        if (data && value && !_.isEmpty(value)) {
            return moment(value).format("DD/MM/YYYY");
        } else return <div />;
    };

    static renderUserCreatedBy = (data, value) => {
        if (data && value) {
            const userCreatedBy = AppStore.getState().root.userCreatedBy;
            const user = _.find(userCreatedBy, (x) => {
                return value === x.id || value === x.userName;
            });
            if (user) {
                return _.get(user, "userName");
            }
        } else return <div />;
    };

    static autoGenGiftCode = () => {
        const arrayCode = voucher_codes.generate({
            length: 8,
            count: 1,
            charset: voucher_codes.charset("alphanumeric")
        });
        return arrayCode[0].toUpperCase();
    };

    static renderFile = (data, value, mainView) => {
        if (value && !_.isEmpty(value)) {
            return (
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <button
                        onClick={() => {
                            mainView && mainView.openFormAttachFile(value);
                        }}
                        className="action-btn btn-info"
                        title="Tải file">
                        <i className="fa fa-paperclip"></i>
                    </button>
                </div>
            );
        }
        return "";
    };

    static formatPhone(phone) {
        var value = phone ? phone.replace(/\D/g, "") : "";
        var result = "";
        if (parseInt(value) != NaN) {
            for (var i = 0; i < value.length; i++) {
                result = result + value[i];
                if (i !== value.length - 1) {
                    if (i == 2 || i == 5 || (i >= 9 && (i - 9) % 4 == 0)) {
                        result = result + " ";
                    }
                }
            }
        }
        return result;
    }

    static formatPhoneByBlock(phoneNumberString, block) {
        var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
        let blockRegex = "";
        _.each(block, (x) => {
            blockRegex += `(\\d{${x}})`;
        });
        if (blockRegex) {
            const regex = new RegExp(`^${blockRegex}$`);
            var match = cleaned.match(regex);
            if (match) {
                let value = "";
                _.each(block, (x, index) => {
                    value += ` ${match[index + 1]}`;
                });
                return value;
            }
        }

        return null;
    }

    static boDauTiengViet(str) {
        if (!str) {
            return "";
        }
        str = str.toString();
        str = str.toLowerCase();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        return str;
    }
}

export const downLoadTemplate = (url) => {
    try {
        const link = document.createElement("a");
        link.href = url;
        link.download = `No_File_Template.xlsx`;
        link.click();
    } catch (error) {
        console.log(error);
    }
};

export const exportExcelClient = (grid, params) => {
    grid.exportToExcel(params);
};

export const getYearFromCurrentWeek = (week, month) => {
    if (week === 1 && month === 12) {
        return moment().year() + 1;
    } else {
        return moment().year();
    }
};

export const excelStyles = () => {
    const borderColor = "#000000";
    const size = 12;
    const fontName = "Times New Roman";
    const indent = 1;

    const borders = {
        borderBottom: {
            weight: 0,
            color: borderColor
        },
        borderLeft: {
            weight: 0,
            color: borderColor
        },
        borderRight: {
            weight: 0,
            color: borderColor
        },
        borderTop: {
            weight: 0,
            color: borderColor
        }
    };
    return [
        {
            id: "header",
            alignment: {
                horizontal: "Center",
                vertical: "Center",
                indent,
                wrapText: true
            },
            interior: {
                color: "#abb8c9",
                pattern: "Solid"
            },
            font: {
                color: "#000000",
                size,
                bold: true,
                fontName
            },
            borders
        },

        {
            id: "center",
            alignment: {
                horizontal: "Center",
                vertical: "Center",
                indent
            },
            font: {
                size,
                fontName
            }
        },

        {
            id: "categoryRow",
            font: {
                bold: true,
                color: "#172b4d",
                size,
                fontName
            },
            interior: {
                color: "#e5f7ff",
                pattern: "Solid"
            },

            alignment: {
                vertical: "Center",
                indent
            },
            borders
        },

        {
            id: "left",
            alignment: {
                horizontal: "Left",
                indent
            },
            font: {
                size,
                fontName
            },

            borders
        },

        {
            id: "right",
            alignment: {
                horizontal: "Right",
                indent
            },
            font: { size, fontName },
            borders
        },
        {
            id: "number",
            alignment: {
                horizontal: "Right",
                indent
            },
            dataType: "number",
            font: { size, fontName },
            borders
        },

        {
            id: "cell",

            alignment: {
                vertical: "Center",
                indent,
                wrapText: true
            },
            font: {
                size,
                fontName
            },
            borders
        },

        {
            id: "titleExcel",

            alignment: {
                vertical: "Center",
                horizontal: "Center",
                indent,
                wrapText: true
            },

            font: {
                bold: true,
                size: 26,
                color: "#172b4d",
                fontName
            }
        },

        {
            id: "label",
            font: {
                bold: true,
                color: "#172b4d",
                size,
                fontName
            },
            alignment: {
                vertical: "Center",
                horizontal: "Right",
                indent
            }
        },

        {
            id: "value",

            alignment: {
                vertical: "Center",
                horizontal: "Left"
            },
            font: { size, fontName }
        },

        {
            id: "company",

            alignment: {
                vertical: "Center",
                horizontal: "Left"
            },
            font: {
                color: "#222222",
                size: 16,
                italic: false,
                fontName
            }
        }
    ];
};

export const titleExcel = (content = []) => {
    return [
        [
            {
                styleId: "company",
                data: {
                    type: "String",
                    value: "TỔNG CÔNG TY KHÍ VIỆT NAM - CTCP"
                },
                mergeAcross: 3
            }
        ],

        [
            {
                styleId: "company",
                data: {
                    type: "String",
                    value: "CÔNG TY VẬN CHUYỂN KHÍ ĐÔNG NAM BỘ"
                },
                mergeAcross: 3
            }
        ],
        ...content,
        [],
        []
    ];
};

export const cellClassRules = () => {
    return {
        center: function (params) {
            return ["stt", "code", "name"].indexOf(params.colDef.field) > -1;
        },

        categoryRow: function (params) {
            if (!DataUtil.isEmpty(params.data.children)) {
                return true;
            }
            return false;
        },

        left: function (params) {
            return ["estNumber"].indexOf(params.colDef.field) > -1;
        },

        right: function (params) {
            return ["processPercentage", "percentageUTHMonth", "percentageLuyKeUTHMonth", "percentageUTHYear", "amount", "%TH/KH"].indexOf(params.colDef.field) > -1;
        },

        cell: function () {
            return true;
        },

        greenCell: function (params) {
            if (params.value === "Hoàn thành") return true;
            return false;
        },

        redCell: function (params) {
            if (params.value === "Chưa thực hiện") return true;
            return false;
        }
    };
};

export const customDataCellExcel = (params) => {
    const { value } = params;
    // const colId = DataUtil.get(params, 'column.colId');
    // if (colId === 'financeApproval'
    //     || colId === 'taskApproval'
    //     || colId === 'taskReportApproval'
    // ) {
    //     return WORKFLOW_STATUS[value];
    // } else if (colId === 'active') {
    //     return STATUS[value];
    // } else if (colId === 'type') {
    //     return DataUtil.get(FIN_TYPES[value], 'text');
    // } else if (colId === 'scope') {
    //     return DataUtil.get(FIN_SCOPES[value], 'text');
    // }
    return value;
};

export const customHeaderCellExcel = (params) => {
    if (DataUtil.isString(params.column.colDef.headerName)) {
        return params.column.colDef.headerName;
    }
    return "Header Name";
};
