import { Icon } from "@idtek/component/lib";
import Container from "@idtek/component/lib/container/Container";
import Modal from "@idtek/component/lib/modal/Modal";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { loadConfigDashboardV2, notResetStore } from "../../application/actions/action";
import GlobalStore from "../../GlobalStore";
import Loading from "../../pages/Loading";
import AppStore from "../../store";
import AppUtil from "../../utils/AppUtil";
import TreeUtil from "../../utils/TreeUtil";
import FormBookingNew from "../booking/form/FormBookingNew";
import FormBookingCreateV2 from "../booking_premium/form/FormBookingCreateV2";
import FormBookingUpdateV2 from "../booking_premium/form/FormBookingUpdateV2";
import ComponentDrawerBooking from "../dashboard/component/ComponentDrawerBooking";
import FormPaymentInDashboard from "../dashboard/form/FormPaymentInDashboard";
import FilterHeaderMobile from "./component/FilterHeaderMobile";
import DashboardPremium from "./DashboardPremium";
import SchedulerMobile from "./SchedulerMobile";
import "./style/dashboard-mobile-v2.scss";

class DashboardMobileV2 extends DashboardPremium {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false
        };
    }
    componentWillMount() {
        const isMobile = this.checkIsMobile();
        if (isMobile) {
            this.setState({
                isMobile: true
            });
        }
    }
    checkIsMobile() {
        return (
            navigator.userAgent.match(/Android/i) !== null ||
            navigator.userAgent.match(/webOS/i) !== null ||
            navigator.userAgent.match(/iPhone/i) !== null ||
            navigator.userAgent.match(/iPad/i) !== null ||
            navigator.userAgent.match(/iPod/i) !== null ||
            navigator.userAgent.match(/BlackBerry/i) !== null ||
            navigator.userAgent.match(/Windows Phone/i) !== null
        );
    }

    onShowDrawer = () => {
        GlobalStore.MainDrawer &&
            GlobalStore.MainDrawer.open(
                <React.Suspense fallback={<Loading />}>
                    <ComponentDrawerBooking ref={(c) => (this.drawer = c)} />
                </React.Suspense>,
                null,
                "33%"
            );
    };

    onCreateBooking = (technicianId, startTime) => {
        this.Modal.onOpen(
            <FormBookingCreateV2
                ref={(c) => (this.refCreateBooking = c)}
                onClose={() => {
                    this.Modal.onClose();
                }}
                onReloadData={this.onReloadData}
                startTime={moment(startTime)}
                technicianId={technicianId}
                multiSetting={this.props.multiSetting}
            />,
            <span>
                <span style={{ position: "relative", marginRight: 7, display: "flex", alignItems: "center" }}>
                    <Icon type="form" style={{ fontSize: 16, display: "flex", color: "var(--main-color-blue-ocean)" }} />
                    <span style={{ fontSize: 15, display: "flex", marginLeft: 7 }}>Create booking</span>
                </span>
            </span>,
            "1000px"
        );
    };

    onUpdateBooking = (bookingId, code, status, isDetail = false) => {
        let title = "Update booking";
        if (isDetail) {
            title = "Detail booking";
        }
        this.Modal.onOpen(
            <FormBookingUpdateV2
                ref={(c) => (this.refUpdateBooking = c)}
                onClose={() => {
                    this.Modal.onClose();
                }}
                onReloadData={this.onReloadData}
                onClone={(booking) => this.onClone(booking)}
                onProceedPayment={this.onProceedPayment}
                id={bookingId}
                buttons={[
                    {
                        text: "Close",
                        className: "btn-danger",
                        icon: <Icon type="close-square" style={{ fontSize: 16 }} />,
                        onPress: this.onClose
                    }
                ]}
                multiSetting={this.props.multiSetting}
                isMobile={true}
            />,
            <span>
                <span style={{ position: "relative", marginRight: 7, display: "flex", alignItems: "center" }}>
                    <Icon type="form" style={{ fontSize: 16, display: "flex", color: "var(--main-color-blue-ocean)" }} />
                    {code && status ? (
                        <span style={{ fontSize: 15, display: "flex", marginLeft: 7 }}>{title + " " + code + " - Status: " + status}</span>
                    ) : (
                        <span style={{ fontSize: 15, display: "flex", marginLeft: 7 }}>{title}</span>
                    )}
                </span>
            </span>,
            "100%"
        );
    };

    onUpdateBookingNew = (bookingId, code, status) => {
        let title = "Update booking";
        if (code) {
            title = `Update booking ${code} - Status: NEW`;
        }
        this.Modal.onOpen(
            <FormBookingNew
                ref={(c) => (this.refNewBooking = c)}
                onClose={() => {
                    this.Modal.onClose();
                }}
                onReloadData={this.onReloadData}
                id={bookingId}
                multiSetting={this.props.multiSetting}
                isMobile={true}
            />,
            <span>
                <span style={{ position: "relative", marginRight: 7, display: "flex", alignItems: "center" }}>
                    <Icon type="form" style={{ fontSize: 16, display: "flex", color: "var(--main-color-blue-ocean)" }} />
                    <span style={{ fontSize: 15, display: "flex", marginLeft: 7 }}>{title}</span>
                </span>
            </span>,
            "100%"
        );
    };

    onClone(bookingData) {
        bookingData.status = "Waiting";
        AppStore.dispatch(notResetStore(true));
        this.Modal && this.Modal.onClose();
        this.ModalClone.onOpen(
            <FormBookingCreateV2
                initData={bookingData}
                cloneView={true}
                ref={(c) => (this.refCloneBooking = c)}
                onClose={() => {
                    this.ModalClone.onClose();
                    AppStore.dispatch(notResetStore(false));
                }}
                onReloadData={this.onReloadData}
                startTime={_.get(bookingData, "startTime")}
                multiSetting={this.props.multiSetting}
                isMobile={true}
            />,
            <span>
                <span style={{ position: "relative", marginRight: 7, display: "flex", alignItems: "center" }}>
                    <Icon type="form" style={{ fontSize: 16, display: "flex", color: "var(--main-color-blue-ocean)" }} />
                    <span style={{ fontSize: 15, display: "flex", marginLeft: 7 }}>{`Clone booking from ${bookingData.code}`}</span>
                </span>
            </span>,
            "100%"
        );
    }

    onProceedPayment = (bookingId) => {
        const { authData } = this.props;
        const obj = this.FilterHeader.getDataFilter();
        const { paymentMethod } = this.props.authData;
        const branch = obj.branchId;
        this.ModalMethod.onOpen(
            <FormPaymentInDashboard
                id={bookingId}
                onClose={() => {
                    this.ModalMethod.onClose();
                }}
                onReloadData={this.onReloadData}
                thueVAT={_.get(authData, "gst")}
                // booking={booking}
                user={_.get(authData, "user.fullName")}
                paymentMethod={paymentMethod}
                branch={branch}
                isMobile={true}
            />,
            <span>Take Payment</span>,
            "100%"
        );
    };

    render() {
        const {
            bookingSchedule,
            technicianSchedule,
            startHour,
            endHour,
            waitingList,
            dateSelected,
            flatAllData,
            reloadWaiting,
            technicianSkills,
            readOnly,
            scrollToCurrentHour,
            multiSetting,
            extraEndHour,
            minuteOverlap,
            printBillSlip
        } = this.props;
        return (
            <Container ref={(c) => (this.container = c)} style={{ height: "100%", width: "100%", position: "absolute", top: 56, background: "#EAEFF3" }}>
                <div className="dashboard-premium dashboard-v2 dashboard-mobile-v2" style={{ width: "100%" }}>
                    <FilterHeaderMobile
                        onReloadData={this.onReloadData}
                        ref={(c) => (this.FilterHeader = c)}
                        onShowDrawer={this.onShowDrawer}
                        onClickUpdateBooking={this.onUpdateBooking}
                        onChangeDate={(date, scrollToCurrentHour) => this.loadResource(date, scrollToCurrentHour)}
                        {...this.props}
                    />
                    <SchedulerMobile
                        ref={(c) => (this.Scheduler = c)}
                        technicianSchedule={technicianSchedule}
                        bookingSchedule={bookingSchedule}
                        startHour={startHour}
                        endHour={endHour}
                        waitingList={waitingList}
                        dateSelected={dateSelected}
                        flatAllData={flatAllData}
                        reloadWaiting={reloadWaiting}
                        technicianSkills={technicianSkills}
                        readOnly={readOnly}
                        scrollToCurrentHour={scrollToCurrentHour}
                        multiSetting={multiSetting}
                        extraEndHour={extraEndHour}
                        minuteOverlap={minuteOverlap}
                        printBillSlip={printBillSlip}
                        onProceedPayment={this.onProceedPayment}
                        onCreateBooking={this.onCreateBooking}
                        onUpdateBooking={this.onUpdateBooking}
                        onUpdateBookingNew={this.onUpdateBookingNew}
                        onReloadData={this.onReloadData}
                    />
                </div>
                {this.renderModal()}
                {this.renderModalClone()}
                {this.renderModalMethod()}
            </Container>
        );
    }
}
const mapStateToProps = (state) => {
    return { ...(state.root || {}) };
};

export default connect(mapStateToProps)(DashboardMobileV2);
