import {Alert, Modal} from "@idtek/component/lib";
import Avatar from "@idtek/component/lib/avatar/Avatar";
import Icon from "@idtek/component/lib/icon/Icon";
import {addClass, closest, extend, isNullOrUndefined, remove, removeClass} from "@syncfusion/ej2-base";
import {ContextMenuComponent} from "@syncfusion/ej2-react-navigations";
import {
    Day,
    DragAndDrop,
    Inject,
    Resize,
    ResourceDirective,
    ResourcesDirective,
    Schedule,
    ScheduleComponent,
    ViewDirective,
    ViewsDirective
} from "@syncfusion/ej2-react-schedule";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import {loadTaskSchedule} from "../../application/actions/action";
import Overlay from "../../common/component/Overlay";
import {SampleBase} from "../../common/component/sample-base";
import AppStore from "../../store";
import AppUtil from "../../utils/AppUtil";
import rightUtils, {allRights} from "../../utils/rightUtils";
import {
    STATUS_CANCEL,
    STATUS_COMPLETE,
    STATUS_NEW,
    STATUS_NOSHOW,
    STATUS_PROCESSING,
    STATUS_WAITING
} from "../dashboard/constant/BookingConstant";
import FormDetailCustomer from "./component/FormDetailCustomer";
import MouseTooltipCpn from "./component/MouseTooltipCpn";
import TreeListWaiting from "./component/TreeListWaiting";
import "./style/dashboard-premium.scss";
import "./style/index.scss";
import BillComponent from "./component/BillComponent";

Schedule.prototype.animateLayout = (date, string) => {
    return null;
};
export default class Scheduler extends SampleBase {
    constructor(props) {
        super(props);
        const {
            technicianSchedule,
            bookingSchedule,
            startHour,
            endHour,
            waitingList,
            dateSelected,
            extraEndHour
        } = props;
        this.state = {
            dataSource: bookingSchedule
        };
        this.resourceData = technicianSchedule;
        this.dataSource = bookingSchedule;
        this.waitingList = waitingList;
        this.menuItems = [
            {
                text: "New booking",
                iconCss: "e-icons new",
                id: "add"
            },
            {
                text: "Edit booking",
                iconCss: "e-icons edit",
                id: "save"
            },
            {
                text: "Detail booking",
                iconCss: "e-icons detail",
                id: "detail"
            },
            {
                text: "Update booking",
                iconCss: "e-icons update",
                id: "update"
            },

            {
                text: "Complete & Payment",
                iconCss: "e-icons complete",
                id: "complete"
            },
            {
                text: "Payment",
                iconCss: "e-icons payment",
                id: "payment"
            },
            {
                text: "Print slip",
                iconCss: "e-icons print-slip",
                id: "print-slip"
            },
            {
                text: "Print bill",
                iconCss: "e-icons print",
                id: "print"
            },

            {
                text: "Edit payment",
                iconCss: "e-icons payment",
                id: "editPayment"
            },
            {
                text: "Customer confirm",
                iconCss: "e-icons confirm",
                id: "confirm"
            },
            {
                text: "Check-in",
                iconCss: "e-icons start",
                id: "start"
            },
            // {
            //     text: "Delete service",
            //     iconCss: "e-icons delete",
            //     id: "delete"
            // },
            {
                text: "Cancel booking",
                iconCss: "e-icons cancel",
                id: "cancel"
            },
            {
                text: "No show",
                iconCss: "e-icons noshow",
                id: "no-show"
            },
            {
                text: "Move booking to clipboard",
                iconCss: "e-icons clipboard",
                id: "clipboard"
            },
            {
                text: "Resend SMS",
                iconCss: "e-icons resend",
                id: "resend-sms"
            }
        ];
        this.selectedDate = dateSelected;
        // this.scheduleDataBound = _.debounce(this._scheduleDataBound, 2000);
        this.startHour = startHour;
        this.endHour = endHour;
        this.count = null;
        this.deleteRight = allRights.BOOKING_RIGHT__DELETE_BOOKING;
    }

    height = "calc(100vh - 135px)";

    checkDeleteRight() {
        return !this.deleteRight || rightUtils.checkRight(this.deleteRight);
    }

    componentWillReceiveProps(nextProps) {
        const {
            bookingSchedule,
            technicianSchedule,
            startHour,
            endHour,
            waitingList,
            dateSelected,
            flatAllData,
            reloadWaiting,
            readOnly,
            scrollToCurrentHour
        } = nextProps;
        this.scrollToCurrentHour = scrollToCurrentHour;
        if (dateSelected && moment(dateSelected).format("YYYY-MM-DD") !== moment(this.selectedDate).format("YYYY-MM-DD")) {
            this.selectedDate = dateSelected;
            this.scheduleObj.selectedDate = this.selectedDate;
            this.count = null;
            // this.scheduleObj.dataBound();
        }

        if (startHour !== this.startHour) {
            this.startHour = startHour;
        }
        if (endHour !== this.endHour) {
            this.endHour = endHour;
        }
        if (reloadWaiting) {
            this.waitingList = waitingList;
        }
        this.resourceData = technicianSchedule;
        this.scheduleObj.resources[0].dataSource = this.resourceData;
        this.dataSource = bookingSchedule;
        this.scheduleObj.eventSettings.dataSource = this.dataSource;
        this.scheduleDataBound();
    }

    componentWillUnmount() {
        this.scheduleObj && this.scheduleObj.destroy();
    }

    onDataBinding(args) {
        setTimeout(() => {
            if (_.size(this.resourceData) > 0) {
                const currentViewDates = this.scheduleObj.getCurrentViewDates();
                if (currentViewDates[0] && moment(currentViewDates[0]).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) {
                    this.scheduleObj.resetWorkHours();
                } else {
                    _.each(this.resourceData, (item, index) => {
                        if (_.size(item.workTimes) > 1) {
                            this.scheduleObj.resetWorkHours([currentViewDates], item.startHour, item.endHour, index);
                            _.each(item.workTimes, (time, idx) => {
                                delete time.id;
                                this.scheduleObj.setWorkHours([currentViewDates], time.startHour, time.endHour, index);
                            });
                        } else {
                            this.scheduleObj.setWorkHours([currentViewDates], item.startHour, item.endHour, index);
                        }
                    });
                }
            }
            if (this.scrollToCurrentHour) {
                this.onScroll();
            }
        });
    }

    async scheduleDataBound() {
        setTimeout(async () => {
            (await this.scheduleObj) && this.scheduleObj.dataBound();
        }, 1000);
    }

    round(date, duration, method) {
        return moment(Math[method](+date / +duration) * +duration);
    }

    openOverlay() {
        this.refOverlay && this.refOverlay.open(<span
            style={{color: "#006096", fontSize: 12}}>Please waiting...</span>);
    }

    closeOverlay() {
        this.refOverlay && this.refOverlay.close();
    }

    onCreated() {
        // this.onScroll();
    }

    onScroll() {
        const newDate = moment().subtract(60, "minutes");
        const roundedDate = this.round(newDate, moment.duration(-15, "minutes"), "ceil");
        this.scheduleObj && this.scheduleObj.scrollTo(roundedDate.format("HH:mm"));
    }

    onPopupOpen(args) {
        args.cancel = true;
    }

    isValidateTime(startDate, endDate, resIndex) {
        let resource = this.scheduleObj.getResourcesByIndex(resIndex);
        let startHour = parseInt(resource.resourceData.startHour.toString().slice(0, 2), 10);
        let endHour = parseInt(resource.resourceData.endHour.toString().slice(0, 2), 10);
        let flag = startHour <= startDate.getHours() && endHour >= endDate.getHours();
        return flag;
    }

    onContextMenuBeforeOpen(args) {
        if (this.props.readOnly) {
            args.cancel = true;
            return;
        }
        const {target} = args.event;
        this.scheduleObj.removeSelectedClass();
        let newEventElement = document.querySelector(".e-new-event");
        let selectedService = document.querySelectorAll(".e-appointment-border");
        if (newEventElement) {
            remove(newEventElement);
            removeClass([document.querySelector(".e-selected-cell")], "e-selected-cell");
        }
        _.each(selectedService, (x) => {
            x.classList.remove("e-appointment-border");
        });
        let targetElement = args.event.target;
        if (closest(targetElement, ".e-contextmenu")) {
            return;
        }
        this.menuObj.hideItems(
            ["add", "save", "cancel", "complete", "payment", "start", "update", "detail", "editPayment", "print", "clipboard", "no-show", "confirm", "resend-sms", "print-slip"],
            true
        );
        this.selectedTarget = closest(targetElement, ".e-appointment,.e-work-hours," + ".e-vertical-view .e-date-header-wrap .e-all-day-cells,.e-vertical-view .e-date-header-wrap .e-header-cells");
        if (isNullOrUndefined(this.selectedTarget)) {
            args.cancel = true;
            return;
        } else {
            const cellData = this.scheduleObj.getCellDetails(target);
            if (this.selectedTarget.classList.contains("e-appointment")) {
                this.selectedTarget.classList.add("e-appointment-border");
                this.menuObj.hideItems(["add"], true);
                this.eventObj = this.scheduleObj.getEventDetails(this.selectedTarget);
                const {waitingList, printBillSlip} = this.props;
                switch (this.eventObj.Status) {
                    case STATUS_WAITING:
                        this.menuObj.showItems(["start", "update", "cancel", "clipboard", "no-show", "resend-sms"], true);
                        if (!this.eventObj.Confirm) {
                            this.menuObj.showItems(["confirm"], true);
                        }
                        if (printBillSlip) {
                            this.menuObj.showItems(["print-slip"], true);
                        }
                        if (_.find(waitingList, (x) => x.Id === this.eventObj.BookingId)) {
                            this.menuObj.hideItems(["start"], true);
                        }
                        break;
                    case STATUS_PROCESSING:
                        this.menuObj.showItems(["complete", "update"], true);
                        if (printBillSlip) {
                            this.menuObj.showItems(["print-slip"], true);
                        }
                        break;
                    case STATUS_COMPLETE:
                        const rightAdmin = this.checkDeleteRight();
                        if (!this.eventObj.PaymentMethod) {
                            if (rightAdmin) {
                                this.menuObj.showItems(["update"], true);
                            }
                            this.menuObj.showItems(["detail", "payment"], true);
                        } else {
                            this.menuObj.showItems(["detail", "editPayment", "print"], true);
                        }
                        break;
                    default:
                    // code block
                }
                return;
            } else {
                this.selectedTarget.classList.add("e-selected-cell");
                if (cellData && moment(cellData.startTime).add(15, "minutes").format("YYYY-MM-DD HH:mm") < moment().format("YYYY-MM-DD HH:mm")) {
                    args.cancel = true;
                    return;
                } else {
                    if (this.props.multiSetting) {
                        this.menuObj.showItems(["add"], true);
                    } else {
                        const format = "YYYY-MM-DD HH:mm:ss";
                        let resourceDetails = this.scheduleObj.getResourcesByIndex(cellData.groupIndex);
                        const technician = resourceDetails.resourceData.id;
                        const findAbove = _.find(
                            this.dataSource,
                            (x) =>
                                x.TechnicianId === technician &&
                                moment(x.EndTime).format(format) > moment(cellData.startTime).format(format) &&
                                moment(x.EndTime).format(format) < moment(cellData.endTime).format(format)
                        );
                        const start = findAbove ? moment(findAbove.EndTime).format(format) : cellData.startTime;
                        const isSlot = this.scheduleObj.isSlotAvailable(start, cellData.endTime, cellData.groupIndex);
                        if (isSlot) {
                            this.menuObj.showItems(["add"], true);
                        } else {
                            args.cancel = true;
                            return;
                        }
                    }
                    // const isSlot = this.scheduleObj.isSlotAvailable(cellData.startTime, cellData.endTime, cellData.groupIndex);
                    // if (isSlot) {
                    //     this.menuObj.showItems(["add"], true);
                    // } else {
                    //     args.cancel = true;
                    //     return;
                    // }
                }
            }
        }
    }

    onMenuItemSelect(args) {
        let selectedMenuItem = args.item.id;
        let node;
        if (this.selectedTarget.classList.contains("e-appointment")) {
            node = this.scheduleObj.getEventDetails(this.selectedTarget);
        }
        switch (selectedMenuItem) {
            case "start":
            case "complete":
            case "cancel":
            case "no-show":
            case "confirm":
            case "resend-sms":
                if (node) {
                    if (selectedMenuItem === "start" && moment().format("HH:mm") < this.startHour) {
                        AppUtil.ToastWarning("This action cannot be completed at this time");
                        return;
                    }
                    this.showConfirm("CONFIRM", `Are you sure you want to ${selectedMenuItem} booking ${node.BookingCode}?`, this.doActionBooking.bind(this, selectedMenuItem, node));
                }
                break;
            case "add":
                let selectedCells = this.scheduleObj.getSelectedElements();
                let activeCellsData = this.scheduleObj.getCellDetails(selectedCells.length > 0 ? selectedCells : this.selectedTarget);
                const technician = this.resourceData[activeCellsData.groupIndex];
                let start = activeCellsData.startTime;
                if (!this.props.multiSetting) {
                    const format = "YYYY-MM-DD HH:mm:ss";
                    const findAbove = _.find(
                        this.dataSource,
                        (x) =>
                            x.TechnicianId === technician.id &&
                            moment(x.EndTime).format(format) > moment(activeCellsData.startTime).format(format) &&
                            moment(x.EndTime).format(format) < moment(activeCellsData.endTime).format(format)
                    );
                    if (findAbove) {
                        start = findAbove.EndTime;
                    }
                }
                this.props.onCreateBooking && this.props.onCreateBooking(technician.id, start);
                break;
            case "update":
                if (node) {
                    this.props.onUpdateBooking && this.props.onUpdateBooking(node.BookingId, node.BookingCode, node.Status);
                }
                break;
            case "detail":
                if (node) {
                    this.props.onUpdateBooking && this.props.onUpdateBooking(node.BookingId, node.BookingCode, node.Status, true);
                }
                break;
            case "payment":
            case "editPayment":
                if (node) {
                    this.props.onProceedPayment && this.props.onProceedPayment(node.BookingId);
                }
                break;
            case "print":
                if (node) {
                    this.printBill(node.BookingId);
                }
                break;
            case "delete":
                if (node) {
                    this.showConfirm("Delete", `Are you sure you want to delete this service?`, this.onDeleteNode.bind(this, node.Id, node.BookingItemId));
                }
                break;
            case "clipboard":
                if (node) {
                    this.showConfirm("CONFIRM", `Are you sure you want to move booking ${node.BookingCode} to clipboard?`, this.onMoveToClipBoard.bind(this, node.BookingId));
                }
                break;
            case "print-slip":
                if (node) {
                    this.printBillSlip(node && node.BookingId);
                }
                break;
            default:
            // code block
        }
    }

    async onMoveToClipBoard(bookingId) {
        this.openOverlay();
        const response = await AppUtil.postApi("/booking/move-clip-board", {id: bookingId});
        if (_.get(response, "data.success")) {
            this.props.onReloadData();
            // this.closeOverlay();
            AppUtil.ToastSaveSuccess("Move success");
        } else {
            this.closeOverlay();
            AppUtil.ToastApiError();
        }
    }

    async printBill(id) {
        const response = await AppUtil.getApi("/booking/get-bill/" + id);
        if (_.get(response, "data.success")) {
            const template = response.data.template;
            this.BillComponent && this.BillComponent.onChangBill(template);
        } else {
            AppUtil.ToastApiError();
        }
    }

    async printBillSlip(id) {
        const response = await AppUtil.getApi("/booking/get-bill-slip/" + id);
        if (_.get(response, "data.success")) {
            const template = response.data.template;
            this.BillComponent && this.BillComponent.onChangBill(template);
        } else {
            AppUtil.ToastApiError();
        }
    }

    sendEmailReview(id) {
        const params = {id: id};
        const response = AppUtil.getApi("/booking/send-mail-review", params);
    }

    showConfirm = async (titleConfirm, messageConfirm, callback) => {
        let confirm = await Alert.Swal_confirm(titleConfirm, messageConfirm, 3);
        if (_.get(confirm, "value") === true) {
            callback && callback();
        }
    };

    async doActionBooking(actionName, node) {
        const {multiSetting, flatAllData} = this.props;
        const items = _.filter(flatAllData, (x) => x.BookingId === node.BookingId);
        const bookingItems = _.map(items, (x) => {
            return {
                service_id: x.ServiceId ? x.ServiceId : null,
                technician: x.TechnicianId ? x.TechnicianId : null,
                startTime: x.StartTime ? x.StartTime : null,
                endTime: x.EndTime ? x.EndTime : null
            };
        });
        const submitValue = {isScheduleBooking: true, bookingItems: bookingItems};
        this.openOverlay();
        const url = `/booking/${actionName}?id=` + node.BookingId;
        const res = await AppUtil.postApi(url, submitValue);
        if (_.get(res, "data.success")) {
            this.closeOverlay();
            this.props.onReloadData();
            AppUtil.ToastSaveSuccess();
        } else if (res && res.data.success === false && res.data.message) {
            this.closeOverlay();
            if (res.data.message === "Please select full technician for services") {
                return AppUtil.ToastApiError("Please select full technician for services");
            }
            if (!multiSetting && actionName === "start" && _.get(res, "data.warning")) {
                const mess = res.data.message && res.data.message.split(".");
                const newMess = mess && mess[0] + ". Are you want to start at the time of booking in schedule?";
                const url = `/booking/${actionName}?id=` + node.BookingId;
                let confirmStart = await Alert.Swal_confirm("Notification", newMess, 3);
                if (_.get(confirmStart, "value") === true) {
                    const res = await AppUtil.postApi(url, {
                        isScheduleBooking: true,
                        bookingItems: bookingItems,
                        startByPass: true
                    });
                    if (_.get(res, "data.success")) {
                        this.closeOverlay();
                        this.props.onReloadData();
                        AppUtil.ToastSaveSuccess();
                    }
                }
                return;
            }
            if (!multiSetting && actionName !== "start") {
                const newMess = res.data.message.split(".");
                Alert.Swal_info("WARNING", newMess && newMess[0], 3);
                return;
            }
            if (multiSetting) {
                let confirm = await Alert.Swal_confirm("WARNING", res.data.message, 3);
                if (_.get(confirm, "value") === true) {
                    const dataPost = {};
                    dataPost.isScheduleBooking = true;
                    dataPost.unCheckWarning = true;
                    dataPost.id = node.BookingId;
                    dataPost.bookingItems = bookingItems;
                    const res2 = await AppUtil.postApi(`/booking/${actionName}`, dataPost);
                    if (_.get(res2, "data.success")) {
                        this.props.onReloadData();
                        AppUtil.ToastSaveSuccess();
                    } else {
                        AppUtil.ToastApiError(_.get(res2, "data.message"));
                    }
                }
                return;
            }
        } else {
            this.closeOverlay();
            return;
        }
        if (actionName === "complete") {
            this.sendEmailReview(node.BookingId);
            (await this.props.onProceedPayment) && this.props.onProceedPayment(node.BookingId);
        }
    }

    async onDeleteNode(nodeId, id) {
        this.openOverlay();
        const res = await AppUtil.deleteApi("/booking/delete-booking-item/" + id);
        if (_.get(res, "data.success")) {
            AppUtil.ToastDeleteSuccess();
            this.scheduleObj.deleteEvent(nodeId);
            this.dataSource = _.filter(this.dataSource, (x) => x.Id !== nodeId);
            this.closeOverlay();
        } else {
            AppUtil.ToastDeleteError();
            this.scheduleObj.refreshEvents();
            this.closeOverlay();
        }
    }

    onRenderCell(args) {
        if (args) {
            if (args.elementType === "dateHeader") {
                addClass([args.element], "display-none");
            }
            if (args.elementType === "emptyCells") {
                addClass([args.element], "content-header");
            }
            if (args.elementType === "workCells") {
                var _ = this;
                args.element.onmouseover = function () {
                    _.toggleMouseTooltip(args.date);
                };
                args.element.onclick = function () {
                    _.onClickMouse();
                };
            }
        }
    }

    toggleMouseTooltip = (date) => {
        this.MouseTooltipCpn && this.MouseTooltipCpn.toggleMouseTooltip(date);
    };
    onClickMouse = () => {
        this.MouseTooltipCpn && this.MouseTooltipCpn.onClickMouse();
    };

    onClickAdd() {
        this.scheduleObj.addEvent([]);
    }

    onClickSave() {
        this.scheduleObj.saveEvent({});
    }

    onClickDelete() {
        this.scheduleObj.deleteEvent(4);
    }

    onActionComplete(args) {
    }

    async onActionBegin(args) {
        if (args) {
            if (args.requestType === "dateNavigate") {
                if (args.event && (args.event.action === "rightArrow" || args.event.action === "leftArrow")) {
                    args.cancel = true;
                }
            }
            if (args.requestType === "eventChange" && this.dataPost && !_.isEmpty(this.dataPost)) {
                this.scrollToCurrentHour = false;
                const {dataPost, dataClone, update} = this.dataPost;
                const {multiSetting} = this.props;
                const groupIndex = _.findIndex(this.resourceData, (x) => x.id === dataPost.technician);
                const isSlot = this.scheduleObj.isSlotAvailable(dataClone.StartTime, dataClone.EndTime, groupIndex);
                if (!isSlot && !multiSetting) {
                    AppUtil.ToastWarning("Not allow multitasking!");
                    args.cancel = true;
                    this.scheduleObj.refreshEvents();
                    return;
                } else {
                    await this.updateBookingItem(dataPost, dataClone, update);
                }
            }
        }
    }

    onItemDragDB(args) {
        const {event} = args;
        if (event.name === "drag") {
            let dragElement = document.querySelectorAll(".e-appointment.e-schedule-event-clone.e-drag-clone");
            if (event.target.classList.contains("e-work-hours")) {
                if (dragElement[0].classList.contains("e-cannot-drop-custom-db")) {
                    dragElement[0].classList.remove("e-cannot-drop-custom-db");
                }
                if (this.props.multiSetting) {
                    document.body.style.cursor = "";
                    dragElement[0].classList.add("e-can-drop-custom-db");
                } else {
                }
            } else {
                if (dragElement[0].classList.contains("e-can-drop-custom-db")) {
                    dragElement[0].classList.remove("e-can-drop-custom-db");
                }
                dragElement[0].classList.add("e-cannot-drop-custom-db");
            }
        }
    }

    onItemDrag(event) {
        if (this.scheduleObj.isAdaptive) {
            let classElement = this.scheduleObj.element.querySelector(".e-device-hover");
            if (classElement) {
                classElement.classList.remove("e-device-hover");
            }
            if (event.target.classList.contains("e-work-cells")) {
                addClass([event.target], "e-device-hover");
            }
        }
        if (event.target.classList.contains("e-work-cells")) {
            let contentArea = document.querySelector(".e-content-wrap").getBoundingClientRect();
            let targetArea = event.target.getBoundingClientRect();
            let scroll = document.querySelector(".e-content-wrap").scrollLeft;
            let scrollableWidth = document.querySelector(".e-content-table").offsetWidth;
            let scrollRight = scroll + contentArea.width;
            if (contentArea.left >= targetArea.left - 100 && scroll > 0) {
                document.querySelector(".e-content-wrap").scrollLeft = scroll - 50;
            }
            if (contentArea.right <= targetArea.right + 100 && scrollRight < scrollableWidth) {
                document.querySelector(".e-content-wrap").scrollLeft = scroll + 50;
            }
        }

        if (event.name === "nodeDragging") {
            let dragElementIcon = document.querySelectorAll(".e-drag-item.treeview-external-drag .e-icons");
            if (event.target.classList.contains("e-work-hours")) {
                dragElementIcon[0].classList.add("e-can-drop-custom");
                if (document.body.style.cursor === "not-allowed") {
                    document.body.style.cursor = "";
                }
            } else {
                if (dragElementIcon[0].classList.contains("e-can-drop-custom")) {
                    dragElementIcon[0].classList.remove("e-can-drop-custom");
                }
                dragElementIcon[0].classList.add("e-can-not-drop-custom");
            }
        }
    }

    async onDragStart(args) {
        this.MouseTooltipCpn && this.MouseTooltipCpn.onChangeVisible(false);
        this.menuObj && this.menuObj.close();
        if (args) {
            const {data} = args;
            args.interval = 0.35;
            if (_.indexOf([STATUS_WAITING, STATUS_PROCESSING], data.Status) < 0 || data.Block) {
                args.cancel = true;
            } else {
                if (data.Type === "services") {
                    const currentViewDates = this.scheduleObj.getCurrentViewDates();
                    const clone = _.cloneDeep(this.resourceData);
                    _.each(clone, (x, idx) => {
                        if (_.indexOf(data.TechnicianSkill, x.id) < 0) {
                            this.scheduleObj.resetWorkHours([currentViewDates], "00:00", "24:00", idx);
                        }
                    });
                }
            }
        }
    }

    onTreeDragStart(event) {
        this.MouseTooltipCpn && this.MouseTooltipCpn.onChangeVisible(false);
        const {draggedNodeData} = event;
        const treeInstance = this.treeCpn.getTreeInstance();
        treeInstance.selectedNodes = [draggedNodeData.id];
        const nodes = this.treeCpn.getTreeData(draggedNodeData.id);
        const node = nodes[0];
        const clone = _.cloneDeep(this.resourceData);
        const currentViewDates = this.scheduleObj.getCurrentViewDates();
        if (node.Status === STATUS_NEW && node.BookingId) {
            event.cancel = true;
            this.scheduleObj.refreshEvents();
            return;
        }
        if (node.Type === "services") {
            event.cancel = true;
            return;
            // _.each(clone, (x, idx) => {
            //     if (_.indexOf(node.TechnicianSkill, x.id) < 0) {
            //         this.scheduleObj.resetWorkHours([currentViewDates], "00:00", "24:00", idx);
            //     }
            //     if (node.Sex) {
            //         if (node.Sex === "FEMALE" && x.sex !== "FEMALE") {
            //             this.scheduleObj.resetWorkHours([currentViewDates], "00:00", "24:00", idx);
            //         }
            //         if (node.Sex === "MALE" && x.sex !== "MALE") {
            //             this.scheduleObj.resetWorkHours([currentViewDates], "00:00", "24:00", idx);
            //         }
            //     }
            // });
        } else {
            const techNeeds = _.map(node.Children, (x) => x.TechnicianSkill);
            let intersection = techNeeds[0];
            _.each(techNeeds, (x, index) => {
                intersection = _.intersection(intersection, x);
            });
            _.each(clone, (x, idx) => {
                if (_.indexOf(_.uniq(intersection), x.id) < 0) {
                    this.scheduleObj.resetWorkHours([currentViewDates], "00:00", "24:00", idx);
                }
                if (node.Sex) {
                    if (node.Sex === "FEMALE" && x.sex !== "FEMALE") {
                        this.scheduleObj.resetWorkHours([currentViewDates], "00:00", "24:00", idx);
                    }
                    if (node.Sex === "MALE" && x.sex !== "MALE") {
                        this.scheduleObj.resetWorkHours([currentViewDates], "00:00", "24:00", idx);
                    }
                }
            });
        }
    }

    async onDragStop(args) {
        const {event, data} = args;
        const dataClone = _.cloneDeep(data);
        if (event.name === "dragStop") {
            if (event.target.classList.contains("e-work-hours")) {
                const dataEvent = this.scheduleObj.getEvents();
                const rawData = _.find(dataEvent, (x) => x.Id === dataClone.Id);
                let cellData = this.scheduleObj.getCellDetails(event.target);
                let resourceDetails = this.scheduleObj.getResourcesByIndex(cellData.groupIndex);
                const technician = resourceDetails.resourceData.id;
                if (moment(rawData.StartTime).format("YYYY-MM-DD HH:mm") !== moment(dataClone.StartTime).format("YYYY-MM-DD HH:mm") || rawData.TechnicianId !== technician) {
                    const startTime = moment(dataClone.StartTime).format("YYYY-MM-DD HH:mm:ss");
                    const endTime = moment(dataClone.StartTime).add(dataClone.ServiceTime, "minutes").format("YYYY-MM-DD HH:mm:ss");
                    const dataPost = {
                        bookingItemId: dataClone.BookingItemId,
                        startTime,
                        endTime,
                        technician
                    };
                    this.dataPost = {
                        dataPost,
                        dataClone,
                        update: true
                    };
                    // this.updateBookingItem(dataPost, dataClone, true);
                } else {
                    this.scrollToCurrentHour = false;
                    args.cancel = true;
                    this.scheduleObj.refreshEvents();
                }
            } else {
                this.scrollToCurrentHour = false;
                args.cancel = true;
                this.scheduleObj.refreshEvents();
            }
        }
    }

    async onTreeDragStop(event) {
        let treeElement = closest(event.target, ".e-treeview");
        let classElement = this.scheduleObj.element.querySelector(".e-device-hover");
        if (classElement) {
            classElement.classList.remove("e-device-hover");
        }
        if (["e-drop-in", "e-drop-out", "e-drop-next"].indexOf(event.dropIndicator) >= 0) {
            this.scrollToCurrentHour = false;
            event.cancel = true;
            this.scheduleObj.refreshEvents();
            return;
        }
        if (treeElement) {
            this.scrollToCurrentHour = false;
            event.cancel = true;
            this.scheduleObj.refreshEvents();
            return;
        } else {
            let scheduleElement = closest(event.target, ".e-content-wrap");
            if (scheduleElement) {
                if (event.target.classList.contains("e-work-hours")) {
                    const nodes = this.treeCpn.getTreeData(event.draggedNodeData.id);
                    const node = nodes[0];
                    let cellData = this.scheduleObj.getCellDetails(event.target);
                    let resourceDetails = this.scheduleObj.getResourcesByIndex(cellData.groupIndex);
                    const technician = resourceDetails.resourceData.id;
                    const startTime = moment(cellData.startTime).format("YYYY-MM-DD HH:mm:ss");
                    const endTime = moment(cellData.startTime).add(node.ServiceTime, "minutes").format("YYYY-MM-DD HH:mm:ss");
                    if (node.Type === "services") {
                        const dataPost = {
                            bookingItemId: node.BookingItemId,
                            startTime,
                            endTime,
                            technician
                        };
                        this.updateBookingItem(dataPost, node);
                    } else {
                        const {flatAllData} = this.props;
                        const format = "YYYY-MM-DD HH:mm:ss";
                        const findAbove = _.find(
                            this.dataSource,
                            (x) =>
                                x.TechnicianId === technician &&
                                moment(x.EndTime).format(format) > moment(cellData.startTime).format(format) &&
                                moment(x.EndTime).format(format) < moment(cellData.endTime).format(format)
                        );
                        const childsId = _.map(node.Children, (x) => x.Id);
                        const itemsAlreadyInSchedule = _.filter(flatAllData, (x) => x.BookingId && x.BookingId === node.Id && _.indexOf(childsId, x.Id) < 0);
                        const itemsParse = _.map(itemsAlreadyInSchedule, (x) => {
                            return {
                                service_id: x.ServiceId,
                                technician: x.TechnicianId,
                                startTime: x.StartTime,
                                endTime: x.EndTime
                            };
                        });
                        let rawStart = findAbove ? moment(findAbove.EndTime).format(format) : _.cloneDeep(startTime);
                        const dataPost = {
                            bookingId: node.Id,
                            startTime: moment(rawStart).seconds(0).format(format),
                            technician: technician
                        };
                        const itemDrops = [];
                        _.each(node.Children, (x, index) => {
                            const startTime = moment(rawStart, format).clone().format(format);
                            const endTime = moment(startTime, format).clone().add(x.ServiceTime, "minutes").format(format);
                            const obj = {
                                service_id: x.ServiceId,
                                technician: _.indexOf(x.TechnicianSkill, technician) > -1 ? technician : null,
                                startTime,
                                endTime
                            };
                            itemDrops.push(obj);
                            rawStart = obj.endTime;
                        });
                        const mergeItem = _.concat(itemsParse, itemDrops);
                        dataPost.bookingItems = mergeItem;

                        if (node.Status === STATUS_NEW) {
                            dataPost.status = STATUS_WAITING;
                        }
                        const lastTimeBooking = _.get(_.last(mergeItem), "endTime");
                        if (this.props.minuteOverlap > 0 && !this.props.multiSetting && lastTimeBooking) {
                            const findBelow = _.find(
                                this.dataSource,
                                (x) =>
                                    x.TechnicianId === technician &&
                                    moment(x.StartTime).format(format) < lastTimeBooking &&
                                    moment(lastTimeBooking, format).subtract(this.props.minuteOverlap, "minutes").format(format) <= moment(x.StartTime).format(format)
                            );
                            const findBelowError = _.find(
                                this.dataSource,
                                (x) =>
                                    x.TechnicianId === technician &&
                                    moment(x.StartTime).format(format) < lastTimeBooking &&
                                    moment(x.StartTime).format(format) > dataPost.startTime &&
                                    moment(lastTimeBooking, format).subtract(this.props.minuteOverlap, "minutes").format(format) > moment(x.StartTime).format(format)
                            );
                            if (findBelowError) {
                                AppUtil.ToastWarning(`Cannot do this action because this booking has overlapped more than ${this.props.minuteOverlap} minutes`);
                                this.scrollToCurrentHour = false;
                                this.scheduleObj.refreshEvents();
                                return;
                            }
                            if (findBelow) {
                                let confirm = await Alert.Swal_confirm("CONFIRM", "Booking is overlap! Are you sure you want to reduce time execute booking?", 3);
                                if (_.get(confirm, "value") === true) {
                                    const diff = moment(lastTimeBooking, format).diff(moment(findBelow.StartTime), "minutes");
                                    const minuteDiffEachItem = diff / _.size(dataPost.bookingItems);
                                    let newItems = _.map(dataPost.bookingItems, (x, index) => {
                                        return {
                                            ...x,
                                            startTime:
                                                index === 0
                                                    ? moment(x.startTime).seconds(0).format(format)
                                                    : moment(x.startTime, format).subtract(minuteDiffEachItem, "minutes").seconds(0).format(format),
                                            endTime: moment(x.endTime, format)
                                                .subtract(minuteDiffEachItem * (index + 1), "minutes")
                                                .seconds(0)
                                                .format(format)
                                        };
                                    });
                                    dataPost.bookingItems = newItems;
                                } else {
                                    this.scrollToCurrentHour = false;
                                    this.scheduleObj.refreshEvents();
                                    return;
                                }
                            }
                        }
                        this.updateBooking(dataPost);
                    }
                } else {
                    this.scrollToCurrentHour = false;
                    event.cancel = true;
                    this.scheduleObj.refreshEvents();
                    return;
                }
            } else {
                this.scrollToCurrentHour = false;
                event.cancel = true;
                this.scheduleObj.refreshEvents();
                return;
            }
        }
    }

    async updateBookingItem(dataPost, node, update = false) {
        this.openOverlay();
        const res = await AppUtil.postApi("/booking/update-booking-item", dataPost);
        this.dataPost = {};
        if (_.get(res, "data.success")) {
            const result = _.get(res, "data.result");
            AppUtil.ToastSaveSuccess();
            node.StartTime = result.startTime;
            node.EndTime = result.endTime;
            node.TechnicianId = _.get(result, "technician.id");
            if (!update) {
                this.scheduleObj.addEvent(node);
                this.dataSource.push(node);
                AppStore.dispatch(
                    loadTaskSchedule({
                        bookingSchedule: this.dataSource,
                        nodeUpdate: node,
                        reloadWaiting: true,
                        scrollToCurrentHour: false
                    })
                );
            } else {
                this.scheduleObj.saveEvent(node);
                const dataEvent = this.scheduleObj.getEvents();
                AppStore.dispatch(
                    loadTaskSchedule({
                        bookingSchedule: dataEvent,
                        nodeUpdate: node,
                        reloadWaiting: false,
                        scrollToCurrentHour: false
                    })
                );
            }
            this.closeOverlay();
        } else {
            AppUtil.ToastApiError();
            this.scheduleObj.refreshEvents();
            this.closeOverlay();
        }
    }

    async updateBooking(dataPost) {
        this.openOverlay();
        const res = await AppUtil.postApi("/booking/update-items", dataPost);
        if (_.get(res, "data.success")) {
            this.props.onReloadData();
            AppUtil.ToastSaveSuccess();
            // this.closeOverlay();
        } else if (res.data && res.data.success === false && res.data.message) {
            this.checkMessage(_.get(res, "data.message"), dataPost);
        } else {
            AppUtil.ToastApiError();
            this.scheduleObj.refreshEvents();
            this.closeOverlay();
        }
    }

    handleUpdateNode(booking, node) {
        const {technicianSkills} = this.props;
        const skill = _.find(technicianSkills, (x) => x.service_id === node.service_id);
        node.Id = `${booking.id}_${node.id}`;
        node.BookingItemId = node.id;
        node.ServiceId = node.service_id;
        node.ServiceName = node.name;
        node.BookingId = booking.id;
        node.TechnicianId = node.technician_id;
        node.StartTime = node.startTime;
        node.EndTime = node.endTime;
        node.Status = booking.status;
        node.Subject = `${booking.code} - ${node.name}`;
        node.Type = "services";
        node.TechnicianSkill = skill ? skill.technicians : [];
        node.ServiceTime = node.time;
        node.CustomerName = booking.customerName;
        node.Phone = booking.phone;
        return node;
    }

    async checkMessage(message, dataPost) {
        if (message) {
            let confirm = await Alert.Swal_confirm("WARNING", message, 3);
            if (_.get(confirm, "value") === true) {
                dataPost.unCheckWarning = true;
                dataPost.id = dataPost.bookingId;
                const res = await AppUtil.postApi(`/booking/update-items`, dataPost);
                this.closeOverlay();
                if (_.get(res, "data.success")) {
                    // const result = _.get(res, "data.entity");
                    // const { bookingItems } = result;
                    // const nodeUpdate = [];
                    // _.each(bookingItems, (node) => {
                    //     const newNode = this.handleUpdateNode(result, node);
                    //     this.scheduleObj.addEvent(newNode);
                    //     this.dataSource.push(newNode);
                    //     nodeUpdate.push(newNode);
                    // });
                    // AppStore.dispatch(
                    //     loadTaskSchedule({
                    //         bookingSchedule: this.dataSource,
                    //         nodeUpdate: nodeUpdate,
                    //         reloadWaiting: true
                    //     })
                    // );
                    this.props.onReloadData();
                    AppUtil.ToastSaveSuccess();
                }
            } else {
                this.closeOverlay();
            }
        }
    }

    onEventRendered(args) {
        if (args) {
            const {data} = args;
            let statusBackground = "";
            let colorBorder = "";
            if (data) {
                switch (data.Status) {
                    case STATUS_WAITING:
                        statusBackground = "#fbf6ca";
                        colorBorder = "#f5ac53";
                        break;
                    case STATUS_PROCESSING:
                        statusBackground = "#D1EEFF";
                        colorBorder = "#2c8eb8";
                        break;
                    case STATUS_COMPLETE:
                        statusBackground = "#f2ffe4";
                        colorBorder = "#73a839";
                        break;
                    case STATUS_CANCEL:
                    case STATUS_NOSHOW:
                        statusBackground = "#eee";
                        colorBorder = "#ccc";
                        break;
                    default:
                        args.cancel = true;
                }
            }
            const findSimilar = _.filter(this.dataSource, (x) => x.BookingId === data.BookingId);
            const sizeSimilar = _.size(findSimilar);
            args.element.style.backgroundColor = statusBackground;
            args.element.style.border = `1.5px solid ${colorBorder}`;
            if (data.Color && data.Color !== "#ffffff" && sizeSimilar > 1) {
                args.element.style.borderLeft = `2px solid ${data.Color}`;
            }
            args.element.style.color = `#000`;
            args.element.classList.add(data.Status);
        }
    }

    eventClick(args) {
        const {event} = args;
        const bookingItems = _.filter(this.dataSource, (x) => x.BookingId === event.BookingId);
        const arrIds = _.map(bookingItems, (x) => "Appointment_" + x.Id);
        let appointments = document.querySelectorAll(".e-appointment.e-lib");
        const itemsNode = _.filter(appointments, (x) => _.indexOf(arrIds, x.getAttribute("data-id")) >= 0);
        _.each(itemsNode, (node) => {
            node.classList.add("e-appointment-border");
        });
    }

    onCellClick(args) {
    }

    onCellDoubleClick(args) {
    }

    eventTemplate(props) {
        const {
            Subject,
            StartTime,
            EndTime,
            CustomerName,
            Phone,
            Status,
            PaymentMethod,
            Block,
            Color,
            TypeBooking,
            CustomerNotes,
            BookingCode,
            Description,
            Confirm,
            CustomerId
        } = props;
        const {waitingList} = this.props;
        let warning = <></>;
        if (_.find(waitingList, (x) => x.Id === props.BookingId)) {
            warning = <Icon type="exclamation-circle" style={{color: "red", fontSize: 13}}/>;
        }
        let block = <></>;
        if (Block) {
            block = <Icon type="lock" style={{color: "red", fontSize: 15}}/>;
        }
        let confirm = <></>;
        if (Confirm) {
            confirm = <Icon type="like" style={{color: "#00a98f", fontSize: 13}}/>;
        }
        const findSimilar = _.filter(this.dataSource, (x) => x.BookingId === props.BookingId);
        const index = _.findIndex(findSimilar, (x) => x.Id === props.Id);
        const sizeSimilar = _.size(findSimilar);
        return (
            <div className="template-wrap-lmt">
                {PaymentMethod && Status === STATUS_COMPLETE ? (
                    <>
                        <div className="e-subject subject">
                            {TypeBooking === "TYPE_BOOKING_ONLINE" &&
                                <span style={{color: "#f1632a", marginTop: -1}}>@</span>}
                            {Description &&
                                <Icon style={{color: "#2d72d9", fontSize: 12, lineHeight: "11px"}} type="info-circle"/>}
                            {Subject}
                        </div>
                        <div className="e-icon">
                            <Icon style={{color: "#73a839", fontSize: 13}} type="check-circle"/>
                            {sizeSimilar > 1 && (
                                <div className="link">
                                    <span className="number-link"
                                          style={{color: Color ? Color : "var(--main-color-red)"}}>
                                        {index + 1}/{sizeSimilar}
                                    </span>
                                    <Icon type="link" style={{color: Color ? Color : "red", fontSize: 13}}/>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="e-subject subject">
                            {TypeBooking === "TYPE_BOOKING_ONLINE" &&
                                <span style={{color: "#f1632a", marginTop: -1}}>@</span>}
                            {Description &&
                                <Icon style={{color: "#2d72d9", fontSize: 12, lineHeight: "11px"}} type="info-circle"/>}
                            {Subject}
                        </div>
                        <div className="e-icon">
                            {warning}
                            {block}
                            {confirm}
                            {sizeSimilar > 1 && (
                                <div className="link">
                                    <span className="number-link"
                                          style={{color: Color ? Color : "var(--main-color-red)"}}>
                                        {index + 1}/{sizeSimilar}
                                    </span>
                                    <Icon type="link" style={{color: Color ? Color : "red", fontSize: 13}}/>
                                </div>
                            )}
                        </div>
                    </>
                )}
                <div className="e-customer link-underline" onClick={(e) => this.openDetailCustomer(e, CustomerId)}>
                    {CustomerName}
                </div>
                <div className="e-customer e-phone">{Phone}</div>
                <div
                    className="e-time time">{moment(StartTime).format("hh:mm A") + " - " + moment(EndTime).format("hh:mm A")}</div>
            </div>
        );
    }

    openDetailCustomer(e, customerId) {
        e.preventDefault();
        this.Modal &&
        this.Modal.onOpen(
            <FormDetailCustomer
                customerId={customerId}
                phoneSetting={this.props.phoneSetting}
                onClose={(reload) => {
                    this.Modal.onClose(reload);
                    if (reload) {
                        this.props.onReloadData();
                    }
                }}
            />,
            <span style={{position: "relative", marginRight: 7, display: "flex", alignItems: "center"}}>
                    <Icon type="form" style={{fontSize: 16, display: "flex", color: "var(--main-color-blue-ocean)"}}/>
                    <span style={{fontSize: 15, display: "flex", marginLeft: 7}}>Detail Customer</span>
                </span>,
            "900px"
        );
    }

    renderOverlay() {
        return (
            <Overlay
                ref={(component) => (this.refOverlay = component)}
                style={{
                    borderRadius: 3,
                    zIndex: 99
                }}
            />
        );
    }

    treeTemplate(props) {
        return (
            <div id="waiting">
                <div id="waitdetails">
                    <div id="waitlist">{props.ServiceName ? props.ServiceName : props.Subject}</div>
                </div>
            </div>
        );
    }

    tooltipTemplate(props) {
        const {Subject, StartTime, EndTime, CustomerName, Phone, Status, CustomerNotes, Description, CreatedAt} = props;
        return (
            <div className="tooltip-wrap-custom">
                <div className="row-tt subject">{Subject}</div>
                <div className={`row-tt`}>
                    <span className="sub-title"> Status: </span>
                    <span className={`status-tooltip ${Status}`}>{Status}</span>
                </div>
                <div className="row-tt customer">
                    <span className="sub-title">Customer: </span>
                    <span>{CustomerName}</span>
                </div>
                <div className="row-tt phone">
                    <span className="sub-title">Phone: </span>
                    <span>{Phone}</span>
                </div>
                <div className="row-tt time">
                    <span className="sub-title">Time: </span>
                    <span> {moment(StartTime).format("hh:mm A") + " - " + moment(EndTime).format("hh:mm A")}</span>
                </div>
                <div className="row-tt time">
                    <span className="sub-title">Created at: </span>
                    <span> {moment(CreatedAt).format(AppUtil.GLOBAL_DATETIME_FORMAT)}</span>
                </div>
                {Description && (
                    <div className="row-tt time">
                        <span className="sub-title">Note: </span>
                        <span>{Description}</span>
                    </div>
                )}
                {!_.isEmpty(CustomerNotes) && (
                    <div className="row-tt time">
                        <span className="sub-title">Customer Note: </span>
                        <div>
                            {_.map(CustomerNotes, (x) => {
                                return <div>- {x.note}</div>;
                            })}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    resourceHeaderTemplate(props) {
        const {resourceData} = props;
        if (resourceData) {
            const serviceByTech = _.filter(this.dataSource, (x) => x.TechnicianId === resourceData.id);
            const bookingByTech = _.size(_.uniqBy(serviceByTech, "BookingId"));
            let classSex = "any-sex";
            let avatar = resourceData.image ?
                <Avatar style={{width: 28, height: 28, lineHeight: "28px", marginRight: 7, marginLeft: -10}}
                        src={resourceData.image}/> : null;
            if (resourceData.sex === "FEMALE") {
                classSex = "female-sex";
            } else if (resourceData.sex === "MALE") {
                classSex = "male-sex";
            }
            return (
                <div className={classSex}>
                    {avatar}
                    {resourceData.name}
                    {bookingByTech > 0 && <span className="tech-count-booking">{bookingByTech}</span>}
                </div>
            );
        }
        return <></>;
    }

    majorSlotTemplate(props) {
        const {date} = props;
        const {flatAllData} = this.props;
        const format = "HH:mm";
        const hourFormat = moment(date).format("hh:mm A");
        const endMajor = moment(date).add(14, "m").format(format);
        const startMajor = moment(date).format(format);
        const rs = [];
        if (!_.isEmpty(flatAllData)) {
            _.each(flatAllData, (item) => {
                if (item.Type !== "booking" && item.Status !== STATUS_NEW && item.StartTime && item.EndTime && item.TechnicianId) {
                    const startItem = moment(item.StartTime).format(format);
                    const endItem = moment(item.EndTime).format(format);
                    if ((startItem >= startMajor && startItem < endMajor) || (startMajor >= startItem && startMajor < endItem)) {
                        rs.push(item);
                    }
                }
            });
            const count = _.size(_.uniqBy(rs, "BookingId"));
            if (count !== this.count) {
                this.count = count;
                return (
                    <div className="custom-major-lmt">
                        <span className="hour-format">{hourFormat}</span>
                        <span className="count">{count}</span>
                    </div>
                );
            }
        }
        return (
            <div className="custom-major-lmt">
                <span className="hour-format">{hourFormat}</span>
                {moment(date).format("HH:mm") === this.startHour && <span className="count">{0}</span>}
            </div>
        );
    }

    renderModal() {
        return (
            <Modal
                draggable
                ref={(component) => {
                    this.Modal = component;
                }}
                onClose={this.onCloseDefault}
                maskClosable={false}
                className="popup-crud popup-container"
            />
        );
    }

    onCloseDefault = (reload) => {
    };

    renderMouseTooltip() {
        return <MouseTooltipCpn ref={(c) => (this.MouseTooltipCpn = c)}/>;
    }

    renderBill() {
        return <BillComponent ref={(c) => (this.BillComponent = c)}/>;
    }

    render() {
        const {multiSetting} = this.props;
        return (
            <div className={`main-dashboard  ${this.classMobile} `}>
                <div className="schedule-control-section">
                    <div className="col-lg-12 control-section">
                        <div className="control-wrapper drag-sample-wrapper">
                            <div className="treeview-container" style={{paddingRight: 10}}>
                                <TreeListWaiting
                                    ref={(tree) => (this.treeCpn = tree)}
                                    waitingList={this.waitingList}
                                    onTreeDragStop={this.onTreeDragStop.bind(this)}
                                    onTreeDragStart={this.onTreeDragStart.bind(this)}
                                    onItemDrag={this.onItemDrag.bind(this)}
                                    reloadWaiting={this.props.reloadWaiting}
                                    flatAllData={this.props.flatAllData}
                                    onUpdateBooking={this.props.onUpdateBooking}
                                    onUpdateBookingNew={this.props.onUpdateBookingNew}
                                    onReloadData={this.props.onReloadData}
                                    openOverlay={() => this.openOverlay()}
                                />
                            </div>

                            <div className={`schedule-container ${multiSetting ? "multiSetting" : ""}`}>
                                <ScheduleComponent
                                    ref={(schedule) => (this.scheduleObj = schedule)}
                                    allowResizing={false}
                                    allowMultiDrag={false}
                                    allowMultiCellSelection={false}
                                    showHeaderBar={false}
                                    showTimeIndicator={true}
                                    timeScale={{
                                        enable: true,
                                        interval: 15,
                                        slotCount: 1,
                                        majorSlotTemplate: this.majorSlotTemplate.bind(this)
                                    }}
                                    width="100%"
                                    height={this.height}
                                    selectedDate={this.selectedDate}
                                    startHour={this.startHour}
                                    endHour={this.props.extraEndHour ? this.props.extraEndHour : this.endHour}
                                    eventSettings={{
                                        dataSource: extend([], this.dataSource, null, true),
                                        enableTooltip: true,
                                        tooltipTemplate: this.tooltipTemplate.bind(this),
                                        enableMaxHeight: !multiSetting
                                    }}
                                    group={{
                                        resources: ["Technicians"],
                                        enableCompactView: false
                                    }}
                                    currentView="Day"
                                    showQuickInfo={false}
                                    created={this.onCreated.bind(this)}
                                    dataBound={this.onDataBinding.bind(this)}
                                    renderCell={this.onRenderCell.bind(this)}
                                    eventRendered={this.onEventRendered.bind(this)}
                                    popupOpen={this.onPopupOpen.bind(this)}
                                    actionComplete={this.onActionComplete.bind(this)}
                                    actionBegin={this.onActionBegin.bind(this)}
                                    drag={this.onItemDragDB.bind(this)}
                                    dragStart={this.onDragStart.bind(this)}
                                    dragStop={this.onDragStop.bind(this)}
                                    cellClick={this.onCellClick.bind(this)}
                                    // cellDoubleClick={this.onCellDoubleClick.bind(this)}
                                    allowDragAndDrop={true}
                                    readonly={this.props.readOnly}
                                    resourceHeaderTemplate={this.resourceHeaderTemplate.bind(this)}
                                    eventClick={this.eventClick.bind(this)}>
                                    <ResourcesDirective>
                                        <ResourceDirective
                                            name="Technicians"
                                            field="TechnicianId"
                                            title="Choose"
                                            allowMultiple={true}
                                            dataSource={this.resourceData}
                                            textField="name"
                                            idField="id"
                                            startHourField="startHour"
                                            endHourField="endHour"></ResourceDirective>
                                    </ResourcesDirective>
                                    <ViewsDirective>
                                        <ViewDirective option={"Day"} eventTemplate={this.eventTemplate.bind(this)}/>
                                    </ViewsDirective>
                                    <Inject services={[Day, Resize, DragAndDrop]}/>
                                </ScheduleComponent>
                            </div>
                        </div>
                        <ContextMenuComponent
                            cssClass="schedule-context-menu"
                            ref={(menu) => (this.menuObj = menu)}
                            target=".e-schedule"
                            items={this.menuItems}
                            beforeOpen={this.onContextMenuBeforeOpen.bind(this)}
                            select={this.onMenuItemSelect.bind(this)}
                        />
                    </div>
                </div>
                {this.renderOverlay()}
                {this.renderMouseTooltip()}
                {this.renderModal()}
                {this.renderBill()}
            </div>
        );
    }
}
