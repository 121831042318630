import { Alert } from "@idtek/component/lib";
import Icon from "@idtek/component/lib/icon/Icon";
import Textbox from "@idtek/component/lib/textbox/Textbox";
import Tooltip from "@idtek/component/lib/tooltip/Tooltip";
import { closest, isNullOrUndefined } from "@syncfusion/ej2-base";
import { ContextMenuComponent, SidebarComponent, TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import { Empty } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import AppUtil from "../../../utils/AppUtil";
import TreeUtil from "../../../utils/TreeUtil";
import { STATUS_NEW } from "../../dashboard/constant/BookingConstant";
import ButtonCollapse from "./ButtonCollapse";

class TreeListWaiting extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.enableDock = true;
        this.dockSize = "72px";
        this.width = "180px";
        this.target = ".main-dashboard";
        this.mediaQuery = "(min-width: 400px)";
        this.isOpen = true;
        this.menuItems = [
            {
                text: "Update booking",
                iconCss: "e-icons update",
                id: "update"
            },
            {
                text: "Confirm booking",
                iconCss: "e-icons confirm",
                id: "confirm"
            }
        ];
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.reloadWaiting) {
            if (this.textBox && this.textBox.getValue()) {
                this.textBox && this.textBox.setValue("");
            }
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProp, prevState) {
        const { waitingList } = this.props;
        if (!_.isEmpty(waitingList)) {
            let clone = _.cloneDeep(waitingList);
            // const sort = _.sortBy(clone, (x) => x.StartTime);
            const nearestNode = this.findNearestTime(clone);
            if (nearestNode) {
                let nodes = document.querySelectorAll(".e-list-item");
                const node = _.find(nodes, (x) => x.getAttribute("data-uid") === nearestNode.Id.toString());
                if (node) {
                    let classElement = document.querySelectorAll(".e-control.e-treeview");
                    if (classElement[0]) {
                        classElement[0].scrollTop = node.offsetTop;
                    }
                }
            }
        }
    }

    findNearestTime(times) {
        let currentTime = Date.now();
        let diff = times.map((time) => {
            return Math.abs(currentTime - Date.parse(time.StartTime));
        });

        let i = diff.indexOf(Math.min(...diff));

        return times[i];
    }

    treeTemplate(props) {
        const { Subject, StartTime, EndTime, CustomerName, Phone, Status, Type, ServiceCode, Sex } = props;
        let classSex = "";
        switch (Sex) {
            case "MALE":
                classSex = "node-male-sex";
                break;
            case "FEMALE":
                classSex = "node-female-sex";
                break;
            default:
            // code block
        }
        let classBookingOnline = "booking-waiting";
        if (Status === STATUS_NEW) {
            classBookingOnline = "booking-online";
        }
        if (props.Type !== "services") {
            return (
                <div id="waiting">
                    <Tooltip
                        title={
                            <div className="tooltip-wrap-custom tooltip-tree">
                                <div className="row-tt subject">{Type === "services" ? Subject : `Booking code: ${props.BookingCode} `}</div>
                                <div className="row-tt phone">
                                    <span className="sub-title">Status: </span>
                                    <span className={`status-tooltip ${Status}`}>{Status}</span>
                                </div>
                                <div className="row-tt customer">
                                    <span className="sub-title">Customer: </span>
                                    <span>{CustomerName}</span>
                                </div>
                                <div className="row-tt phone">
                                    <span className="sub-title">Phone: </span>
                                    <span>{Phone}</span>
                                </div>
                                <div className="row-tt time">
                                    <span className="sub-title">{EndTime ? "Time: " : "Start time: "}</span>
                                    {StartTime && EndTime && (
                                        <span style={{ paddingLeft: 10 }}>
                                            {moment(StartTime).format("hh:mm A") + " - " + moment(EndTime).format("hh:mm A") + " " + moment(EndTime).format(AppUtil.GLOBAL_DATE_FORMAT)}
                                        </span>
                                    )}
                                    {StartTime && !EndTime && <span> {moment(StartTime).format(AppUtil.GLOBAL_DATETIME_FORMAT)}</span>}
                                </div>

                                {Sex && (
                                    <div className="row-tt phone">
                                        <span className="sub-title">Priority: </span>
                                        <span>{Sex}</span>
                                    </div>
                                )}
                            </div>
                        }
                        placement={this.sidebarobj.isOpen ? "top" : "right"}>
                        <div className={`waitdetails ${classSex}`} id="waitdetails">
                            <div className={`show-time booking ${classBookingOnline}`}>
                                <div className="date">{moment(props.StartTime).format("MMM/DD")}</div>
                                <span className="time">{moment(props.StartTime).format("hh:mm A")}</span>
                            </div>
                            <div id="waitlist" className={`waitlist ${props.Type !== "services" ? "booking-wait-list" : ""}`}>
                                {props.Subject}
                            </div>
                        </div>
                    </Tooltip>
                </div>
            );
        }
        return (
            <div id="waiting">
                <div className={`waitdetails ${classSex}`} id="waitdetails">
                    <div className="show-time">
                        <span className="circle">
                            <div className="ant-timeline-item-head ant-timeline-item-head-blue custom-lmt-head"></div>
                        </span>
                    </div>
                    <div id="waitlist" className={`waitlist ${props.Type !== "services" ? "booking-wait-list" : ""}`}>
                        {props.ServiceName} - {props.ServiceTime} mins
                    </div>
                    {ServiceCode && (
                        <div id="waitlist" className="waitlist none" style={{ display: "none" }}>
                            {ServiceCode}
                        </div>
                    )}
                </div>
            </div>
        );
    }
    onTreeDragStop(args) {
        this.props.onTreeDragStop && this.props.onTreeDragStop(args);
    }
    onItemDrag(args) {
        this.props.onItemDrag && this.props.onItemDrag(args);
    }
    onTreeDragStart(args) {
        this.props.onTreeDragStart && this.props.onTreeDragStart(args);
    }

    getTreeInstance() {
        return this.treeObj;
    }

    onClickButton() {
        this.sidebarobj.toggle();
    }

    sidebarChange(args) {
        if (this.isOpen !== this.sidebarobj.isOpen) {
            // this.treeObj && this.treeObj.refresh();
            this.btnCollapse && this.btnCollapse.setOpen(this.sidebarobj.isOpen);
            // if (this.sidebarobj.isOpen) {
            //     this.treeObj.expandAll();
            // } else {
            //     this.treeObj.collapseAll();
            // }
            this.isOpen = this.sidebarobj.isOpen;
        }
    }

    getTreeData(id) {
        return this.treeObj && this.treeObj.getTreeData(id);
    }
    _onSearch = (value) => {
        const { waitingList } = this.props;
        const arrSearch = [];
        if (this.treeObj) {
            if (value) {
                const clone = _.cloneDeep(waitingList);
                _.each(clone, (root) => {
                    TreeUtil.forEachTreeFromNodeToLeaf(root, "Children", (node) => {
                        const bodau = AppUtil.boDauTiengViet(value);
                        if (
                            AppUtil.boDauTiengViet(_.get(node, "Subject")).indexOf(bodau) > -1 ||
                            AppUtil.boDauTiengViet(_.get(node, "Status")).indexOf(bodau) > -1 ||
                            AppUtil.boDauTiengViet(_.get(node, "StartTime")).indexOf(bodau) > -1 ||
                            AppUtil.boDauTiengViet(_.get(node, "EndTime")).indexOf(bodau) > -1
                        ) {
                            if (node.BookingId) {
                                const parent = _.find(clone, (x) => x.Id === node.BookingId);
                                arrSearch.push(parent);
                            }
                            arrSearch.push(node);
                        }
                    });
                });
                const newWaitingList = TreeUtil.buildTreeFromFlatData(arrSearch, "Id", "BookingId", "Children");
                this.treeObj.fields.dataSource = newWaitingList;
            } else {
                this.treeObj.fields.dataSource = waitingList;
            }
        }
    };

    onContextMenuBeforeOpen(args) {
        const { waitingList } = this.props;
        let targetElement = args.event.target;
        this.selectedTarget = closest(targetElement, ".booking-wait-list,.time,.booking");
        this.menuObj.hideItems(["confirm"], true);
        if (isNullOrUndefined(this.selectedTarget)) {
            args.cancel = true;
            return;
        }
        const targetNodeId = this.treeObj.selectedNodes[0];
        const find = _.find(waitingList, (x) => x.Id.toString() === targetNodeId);
        if (find && find.Status === STATUS_NEW) {
            this.menuObj.showItems(["confirm"], true);
        }
    }

    async onMenuItemSelect(args) {
        const { waitingList } = this.props;
        let targetNodeId = this.treeObj.selectedNodes[0];
        if (targetNodeId) {
            let selectedMenuItem = args.item.id;
            const find = _.find(waitingList, (x) => x.Id.toString() === targetNodeId);
            if (find) {
                if (selectedMenuItem === "update") {
                    if (find.Status !== STATUS_NEW) {
                        this.props.onUpdateBooking && this.props.onUpdateBooking(find.Id, find.BookingCode, find.Status, false, "clipboard");
                    } else {
                        this.props.onUpdateBookingNew && this.props.onUpdateBookingNew(find.Id, find.BookingCode, find.Status, false, "clipboard");
                    }
                }
                if (selectedMenuItem === "confirm") {
                    let confirm = await Alert.Swal_confirm("CONFIRM", "Are you sure you want to confirm this booking?", 3);
                    if (_.get(confirm, "value") === true) {
                        const url = "/booking/save?id=" + find.Id;
                        const data = { ...find };
                        const bookingItems = _.map(data.bookingItems, (item) => {
                            return {
                                service_id: item.service_id ? item.service_id : null,
                                technician: item.technician_id ? item.technician_id : null,
                                startTime: item.startTime ? item.startTime : null,
                                endTime: item.endTime ? item.endTime : null
                            };
                        });

                        data.bookingItems = bookingItems;
                        data.status = "Waiting";
                        const dataPost = {};
                        _.mapKeys(data, (value, key) => {
                            if (key && key.substring(0, 1) && key.substring(0, 1).toUpperCase() !== key.substring(0, 1)) {
                                dataPost[key] = value;
                            }
                        });
                        this.props.openOverlay && this.props.openOverlay();
                        const res = await AppUtil.postApi(url, dataPost);
                        if (_.get(res, "data.success")) {
                            this.props.onReloadData && this.props.onReloadData();
                        } else {
                            AppUtil.ToastApiError();
                        }
                    }
                }
            }
        }
    }

    nodeclicked(args) {
        const uid = args.node.getAttribute("data-uid");
        if (args.event.which === 3) {
            this.treeObj.selectedNodes = [uid];
        }
    }
    render() {
        const { waitingList } = this.props;
        return (
            <SidebarComponent
                id="sidebar-menu"
                ref={(Sidebar) => (this.sidebarobj = Sidebar)}
                enableDock={this.enableDock}
                mediaQuery={this.mediaQuery}
                dockSize={this.dockSize}
                width={this.width}
                target={this.target}
                closeOnDocumentClick={false}
                type={"Push"}
                zIndex={0}
                change={this.sidebarChange.bind(this)}
                enableGestures={false}>
                <div className="main-menu">
                    <div className="top-menu">
                        <ButtonCollapse ref={(c) => (this.btnCollapse = c)} onClick={this.onClickButton.bind(this)} />
                        <Textbox iconType="search" ref={(c) => (this.textBox = c)} isClearable={true} onChange={_.debounce(this._onSearch, 200)} placeholder="Search..." className="search-input " />
                    </div>
                    <div className="main-menu-header">
                        <div className="collapse-icon">
                            <Tooltip title="Expand all">
                                <div className="expand" onClick={() => this.treeObj && this.treeObj.expandAll()}>
                                    <Icon type="column-height" />
                                </div>
                            </Tooltip>
                            <Tooltip title="Collapse all">
                                <div className="collapse-all" onClick={() => this.treeObj && this.treeObj.collapseAll()}>
                                    <Icon type="vertical-align-middle" />
                                </div>
                            </Tooltip>
                        </div>
                        <span>Clipboard</span>
                    </div>
                    {_.isEmpty(waitingList) ? (
                        <div className="global-flex-justify-align-center empty-tree-waiting">
                            <Empty />
                        </div>
                    ) : (
                        <>
                            <TreeViewComponent
                                ref={(tree) => (this.treeObj = tree)}
                                cssClass="treeview-external-drag"
                                dragArea=".main-dashboard"
                                nodeTemplate={this.treeTemplate.bind(this)}
                                fields={{
                                    dataSource: waitingList,
                                    id: "Id",
                                    text: "Subject",
                                    child: "Children",
                                    expanded: "Expanded",
                                    parentID: "BookingId"
                                }}
                                height={"calc(100vh - 145px)"}
                                nodeDragStop={this.onTreeDragStop.bind(this)}
                                nodeDragStart={this.onTreeDragStart.bind(this)}
                                nodeDragging={this.onItemDrag.bind(this)}
                                allowDragAndDrop={true}
                                allowMultiSelection={false}
                                fullRowSelect={true}
                                nodeClicked={this.nodeclicked.bind(this)}
                            />
                            <ContextMenuComponent
                                cssClass="tree-context-menu"
                                ref={(menu) => (this.menuObj = menu)}
                                target=".e-treeview"
                                items={this.menuItems}
                                beforeOpen={this.onContextMenuBeforeOpen.bind(this)}
                                select={this.onMenuItemSelect.bind(this)}
                            />
                        </>
                    )}
                </div>
            </SidebarComponent>
        );
    }
}

export default TreeListWaiting;
