import { Icon } from "@idtek/component/lib";
import Combobox from "@idtek/component/lib/combobox/Combobox";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { updateBrandInAuthData } from "../../../application/actions/action";
import AppStore from "../../../store";
import AppUtil from "../../../utils/AppUtil";
import ReactDatePickerCustom from "../../booking/component/ReactDatePickerCustom";
import SelectCustomComponent from "../../dashboard/component/SelectCustomComponent";
import StatisticOfDay from "../../dashboard/component/StatisticOfDay";
import ThreeDotButton from "../../dashboard/component/ThreeDotButton";
import "../../dashboard/style/dashboard-v2.scss";
import "../../dashboard/style/dashboard.scss";

export default class FilterHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            branchId: _.get(props, "authData.user.branchId"),
            filterDate: moment().format("YYYY-MM-DD")
        };
        this.filterDate = moment().format("YYYY-MM-DD");
    }

    componentDidMount() {}

    getDataFilter() {
        return {
            filterDate: this.state.filterDate,
            branchId: this.state.branchId
        };
    }

    loadTotalNewBooking = async () => {
        const branch = this.state.branchId;
        const params = {
            branch: branch ? branch : 1
        };
        const res = await AppUtil.getApi("/booking/get-new", params);

        if (_.get(res, "data.success")) {
            const totalNewBooking = _.get(res, "data.totalCount");
            this.ThreeDotButton && this.ThreeDotButton.setCount(totalNewBooking);
        }
    };

    async loadData(date, scrollToCurrentHour = false) {
        const newDate = date ? date : this.filterDate ? this.filterDate : moment().format("YYYY-MM-DD");
        this.filterDate = newDate;
        this.setState({
            filterDate: newDate
        });
        this.props.onChangeDate && this.props.onChangeDate(newDate, scrollToCurrentHour);
    }

    onChangeDate(key) {
        let scrollToCurrentHour = false;
        let date = moment().format("YYYY-MM-DD");
        switch (key) {
            case "prev":
                date = moment(this.filterDate).add(-1, "days").format("YYYY-MM-DD");
                break;
            case "next":
                date = moment(this.filterDate).add(1, "days").format("YYYY-MM-DD");
                break;
            case "today":
                date = moment().format("YYYY-MM-DD");
                scrollToCurrentHour = true;
                break;
            default:
                date = moment().format("YYYY-MM-DD");
                break;
        }
        this.filterDate = date;
        this.loadData(date, scrollToCurrentHour);
    }

    onChangeFilterDate(date) {
        let scrollToCurrentHour = false;
        this.filterDate = moment(date).format("YYYY-MM-DD");
        if (this.filterDate === moment().format("YYYY-MM-DD")) {
            scrollToCurrentHour = true;
        }
        this.loadData(date, scrollToCurrentHour);
    }

    onShowDrawer = () => {
        this.props.onShowDrawer && this.props.onShowDrawer();
    };

    setScheduleBooking(bookings) {
        this.setState({
            scheduleBooking: bookings
        });
    }

    onSelectBranch(val) {
        AppStore.dispatch(updateBrandInAuthData(this.props.authData, val));
        this.setState(
            {
                branchId: val
            },
            () => {
                this.props.onChangeDate && this.props.onChangeDate(this.state.filterDate, false, val);
            }
        );
    }

    renderContent() {
        return (
            <div className="header-schedules overflow-visible filter-date" style={{ display: "flex" }}>
                <div className="filter-left">
                    <div style={{ flex: 1, width: 250 }}>
                        <SelectCustomComponent
                            onChange={(data) => {
                                this.props.onClickUpdateBooking(data);
                            }}
                            branch={this.props.authData.user.branchId}
                            date={this.state.filterDate}
                        />
                    </div>
                    <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                        <div style={{ padding: "0px 10px 0px 20px", fontSize: 14, color: "#272829" }}>Date</div>
                        <div className="today">
                            <button onClick={() => this.onChangeDate("today")} className="btn-date-filer today-date">
                                TODAY
                            </button>
                        </div>
                        <div className="date-cpn-filter">
                            <Icon type="left" className="prev" onClick={() => this.onChangeDate("prev")} />
                            <ReactDatePickerCustom ref={(ref) => (this.dateFilter = ref)} value={this.state.filterDate} onChange={this.onChangeFilterDate.bind(this)} dateFormat={"MMM/dd/yyyy"} />
                            <Icon type="right" className="next" onClick={() => this.onChangeDate("next")} />
                        </div>
                    </div>
                </div>

                <div
                    className="filter-right"
                    style={{
                        justifyContent: "flex-end",
                        flex: 1,
                        display: "flex"
                    }}>
                    <div
                        className="filter-branch"
                        style={{
                            // display: this.props.authData.user.branches.length < 2 ? 'none' : '',
                            width: 250
                        }}>
                        <Combobox
                            ref={(ref) => (this.comboboxBranch = ref)}
                            value={this.state.branchId}
                            placeholder="Select branch..."
                            isClearable={false}
                            options={this.props.authData.user.branches}
                            valueKey="id"
                            labelKey="name"
                            onChange={(val) => this.onSelectBranch(val)}
                        />
                    </div>

                    <StatisticOfDay bookings={this.state.scheduleBooking || []} date={this.filterDate} />
                    {/* <ThreeDotButton ref={(c) => (this.ThreeDotButton = c)} onClick={this.onShowDrawer} /> */}
                </div>
            </div>
        );
    }

    render() {
        return this.renderContent();
    }
}
