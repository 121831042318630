import Icon from "@idtek/component/lib/icon/Icon";
import Form from "@idtek/form/lib/IDForm";
import TextField from "@idtek/form/lib/text/TextField";
import TextAreaField from "@idtek/form/lib/textarea/TextAreaField";
import _ from "lodash";
import moment from "moment";
import React, {Component} from "react";
import AppUtil from "../../../utils/AppUtil";
import BillComponent from "../../dashboard_premium/component/BillComponent";
import {TYPE_RECEIVE_MONEY} from "../constant/BookingConstant";
import "../style/receive-money.scss";
import Row from "@idtek/component/lib/container/Row";
import Cell from "@idtek/component/lib/container/Cell";

export default class FormRecevieMoney extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.initialValues = {};
        this.formDefaults = {
            alignLabel: "left",
            widthLabel: "130px"
        };
        this.model = {
            fields: {
                note: {
                    label: "Note"
                },
                value: {
                    label: "Input Cash Amount",
                    required: true
                }
            }
        };
    }

    posType = TYPE_RECEIVE_MONEY;

    async onFinish(form, values, printBill = false) {
        form && form.mask();
        const receive = {
            booking: null,
            posType: this.posType,
            status: 1,
            value: _.get(values, "value") ? parseFloat(_.get(values, "value")) : 0,
            actionTime: moment(),
            note: _.get(values, "note"),
            branch: this.props.branch,
            type: "CASH"
        };
        const res = await AppUtil.postApi(`/pos-action/create`, receive);
        if (_.get(res, "data.success")) {
            form && form.unmask();
            AppUtil.ToastCreateSuccess("Success!");
            if (printBill) {
                const {id} = _.get(res, "data");
                const resBill = await AppUtil.getApi("/booking/get-pos-action/" + id);
                if (_.get(resBill, "data.success")) {
                    this.props.onClose && this.props.onClose();
                    this.BillComponent && this.BillComponent.onChangBill(_.get(resBill, "data.template"));
                }
            } else {
                this.props.onClose && this.props.onClose();
            }
        } else {
            form && form.unmask();
            AppUtil.ToastApiError();
        }
    }

    render() {
        const {model, initialValues, formDefaults} = this;
        const {user} = this.props;
        return (
            <div className="receive-money-form">
                <Form
                    ref={(component) => (this.form = component)}
                    initialValues={initialValues}
                    model={model}
                    buttons={[
                        {
                            text: "Print",
                            type: "submit",
                            className: "btn-success",
                            icon: <Icon type="printer" style={{fontSize: 16}}/>,
                            style: {width: "112px"},
                            onPress: (form, values) => this.onFinish(form, values, true)
                        },
                        {
                            text: "Finish",
                            type: "submit",
                            className: "btn-primary-lighter",
                            style: {width: "112px"},
                            icon: <Icon type="check-square" style={{fontSize: 16}}/>,
                            onPress: (form, values) => this.onFinish(form, values)
                        }
                    ]}
                    defaults={formDefaults}>
                    <div className="receive-money-content">
                        <Row>
                            <Cell style={{
                                fontStyle: "italic",
                                marginBottom: 5,
                                color: "#4B5050",
                                fontWeight: "bold",
                                textAlign: "right"
                            }}>
                                <span>User: </span>
                                <span style={{marginLeft: 5}}>{user}</span>
                            </Cell>
                        </Row>
                        <Row>
                            <Cell style={{
                                fontStyle: "italic",
                                marginBottom: 5,
                                color: "#4B5050",
                                fontWeight: "bold",
                                textAlign: "right"
                            }}>
                                <span>Time: </span>
                                <span style={{marginLeft: 5}}>{moment().format(AppUtil.GLOBAL_DATETIME_FORMAT)}</span>
                            </Cell>
                        </Row>
                        <Row>
                            <Cell>
                                <TextField name="value" type="money"/>
                            </Cell>
                        </Row>
                        <Row>
                            <Cell>
                                <TextAreaField name="note" minHeight={40}/>
                            </Cell>
                        </Row>

                    </div>
                </Form>
                <BillComponent ref={(c) => (this.BillComponent = c)}/>;
            </div>
        );
    }
}
