import React, { Component } from "react";
import { connect } from "react-redux";
import DashboardPremium from "./DashboardPremium";

class DashboardDesktop extends DashboardPremium {}
const mapStateToProps = (state) => {
    return { ...(state.root || {}) };
};

export default connect(mapStateToProps)(DashboardDesktop);
