import React, { Component } from 'react'
import { TYPE_TAKE_MONEY } from '../constant/BookingConstant';
import FormRecevieMoney from './FormRecevieMoney'

export default class FormTakeMoney extends FormRecevieMoney {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.posType = TYPE_TAKE_MONEY
        this.model = {
            fields: {
                note: {
                    label: 'Note',
                },
                value: {
                    label: 'Input Cash Amount',
                    required: true
                }
            }
        };
    }
}
