import Container from "@idtek/component/lib/container/Container";
import FullBrowser from "@idtek/component/lib/fullScreen/FullBrowser";
import Icon from "@idtek/component/lib/icon/Icon";
import BaseStore from "@idtek/grid/lib/stores/BaseStore";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import Button from "../../../common/component/Button";
import { SERVICE_TYPE_CONSTANT } from "../../service/constant/ServiceConstant";
import ServiceListView from "../../service/view/ServiceListView";

class TreeGridSelectService extends ServiceListView {
    constructor(props) {
        super(props);
        this.state = {};
        const { technicianId, technicianSkillStore } = props;
        let data = props.allServiceStore ? props.allServiceStore : [];
        if (technicianId) {
            let techSkill = [];
            _.each(technicianSkillStore, (x) => {
                if (_.indexOf(x.technicians, technicianId) >= 0) {
                    techSkill.push(x.service_id);
                }
            });
            techSkill = _.uniq(techSkill);
            data = _.map(props.allServiceStore, (group) => {
                _.map(group.children, (x) => {
                    if (_.indexOf(techSkill, x.service_id) >= 0) {
                        x.color = "#026eaa";
                    }
                    return {
                        ...x
                    };
                });
                return { ...group, children: _.sortBy(group.children, (x) => x.color) };
            });
        }
        this.store = new BaseStore({
            data: data
        });
        this.className = "tree-grid-select-service";
        this.columnDefs = [
            {
                headerName: "Name",
                field: "name",
                minWidth: 300
            },
            {
                headerName: "",
                field: "actionSelect",
                width: 70
            },
            {
                headerName: "Price (CAD)",
                field: "price",
                width: 110
            },
            {
                headerName: " Duration (minutes)",
                field: "time",
                width: 110
            }
        ];
        this.cellRenderer = {
            name: {
                renderer: (data, value) => {
                    if (data.type === SERVICE_TYPE_CONSTANT) {
                        return (
                            <div className="global-flex-justify-align-center" style={{ color: data.color }}>
                                {value + " (" + data.code + ")"}
                            </div>
                        );
                    }
                    return value;
                }
            },
            actionSelect: {
                renderer: (data, value, valueNode) => {
                    if (_.size(data.children) > 0 || data.leaf) {
                        return (
                            <div className="global-flex-justify-align-center">
                                <div className={"btn-export-report"} style={{ height: 25, width: 25 }}>
                                    <Button icon={"select"} tooltip={"Select"} fontSize={13} onClick={() => this.onSelect(data, valueNode)} />
                                </div>
                            </div>
                        );
                    }
                    return null;
                }
            }
        };
        this.heightGrid = "500px";
    }
    componentWillReceiveProps(nextProps) {
        this.myGrid && this.myGrid.store.setData(nextProps.allServiceStore);
    }

    onSelect = (dataRow, node) => {
        const { selectServiceHandlerByGrid } = this.props;
        if (_.get(dataRow, "leaf")) {
            selectServiceHandlerByGrid && selectServiceHandlerByGrid(dataRow.service_id);
        } else {
            selectServiceHandlerByGrid && selectServiceHandlerByGrid(dataRow.service_group_id, true);
        }
    };

    actionRows = () => {};

    renderActionBar() {
        const buttons = [
            {
                type: "fullScreen",
                onPress: () => this.FullBrowser.toogleFullScreen(),
                tooltip: "Full screen"
            }
        ];

        return buttons;
    }
    renderButton() {
        return (
            <div className="global-flex-justify-center" style={{ marginTop: 10 }}>
                <button className={"btn-form btn btn-danger"} onClick={() => this.props.onClose && this.props.onClose()}>
                    <Icon type="close-square" style={{ fontSize: 16 }} />
                    <span style={{ marginLeft: 5, marginRight: 5, marginBottom: 2, fontSize: 14 }}>Close</span>
                </button>
            </div>
        );
    }

    render() {
        return (
            <FullBrowser ref={(component) => (this.FullBrowser = component)}>
                <Container ref={(c) => (this.refContainer = c)}>
                    <div className={this.className}>
                        <div style={{ display: "flex", flexDirection: "column", flex: 1, height: "auto" }}>
                            <div style={{ flex: 1 }}>{this.renderTreeGrid()}</div>
                            {this.renderButton()}
                        </div>
                    </div>
                </Container>
            </FullBrowser>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        allServiceStore: state.root.storeList.allServiceStore,
        technicianSkillStore: state.root.storeList.technicianSkillStore
    };
};

export default connect(mapStateToProps)(TreeGridSelectService);
