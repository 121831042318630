import Alert from "@idtek/component/lib/alert/Alert";
import Container from "@idtek/component/lib/container/Container";
import FullBrowser from "@idtek/component/lib/fullScreen/FullBrowser";
import Icon from "@idtek/component/lib/icon/Icon";
import ComboField from "@idtek/form/lib/combox/ComboField";
import DisplayField from "@idtek/form/lib/display/DisplayField";
import IDField from "@idtek/form/lib/IDField";
import Form from "@idtek/form/lib/IDForm";
import RadioListField from "@idtek/form/lib/radiolist/RadioListField";
import SwitchField from "@idtek/form/lib/switch/SwitchField";
import TextField from "@idtek/form/lib/text/TextField";
import TextAreaField from "@idtek/form/lib/textarea/TextAreaField";
import _ from "lodash";
import moment from "moment";
import React from "react";
import {
    loadAllServiceStore,
    loadComboboxDataBookingForm,
    loadComboboxPriotityTechnician,
    resetServiceStore,
    selectMainTechnician,
    selectService,
    selectServiceGroup,
    updateCustomer
} from "../../../application/actions/action";
import {forceSelectMenu} from "../../../application/actions/menuAction";
import BaseListView from "../../../common/baseView/BaseListView";
import BasePermisstion from "../../../common/baseView/BasePermisstion";
import ContentView from "../../../common/component/ContentView";
import Loading from "../../../pages/Loading";
import AppStore from "../../../store";
import AppUtil from "../../../utils/AppUtil";
import {
    STATUS_COMPLETE,
    TYPE_LOYALTY_BOOKING_BASED,
    TYPE_LOYALTY_POINT_BASED
} from "../../dashboard/constant/BookingConstant";
import ColorRandomField from "../component/ColorRandomField";
import GridBookingService from "../component/GridBookingService";
import ReactDatePickerCustom from "../component/ReactDatePickerCustom";
import SearchPhoneComponent from "../component/SearchPhoneComponent";
import ApiConstants from "../constant/ApiConstants";
import BillComponent from "../../dashboard_premium/component/BillComponent";

import "../style/style-update-booking.scss";
import Row from "@idtek/component/lib/container/Row";
import Cell from "@idtek/component/lib/container/Cell";

class BaseBookingCreate extends BaseListView {
    constructor(props) {
        super(props);
        this.isMobile = props.isMobile || false;
        const branchIdInit = _.get(props.authData, "user.branchId");
        this.initialValues = {
            branch: branchIdInit,
            startTime: props.startTime ? props.startTime : this.round(moment().add(-1, "days"), moment.duration(15, "minutes"), "ceil"),
            sex: "ANY",
            technician: props.techSchedule
        };
        this.state = {
            initialValues: this.initialValues,
            loading: true
        };

        this.model = {
            fields: {
                phone: {
                    label: "Phone",
                    required: true
                },
                startTime: {
                    label: "Time",
                    required: true
                },
                customerName: {
                    label: "Customer Name",
                    required: true
                },
                email: {
                    label: "Email"
                },
                branch: {
                    label: "Branch",
                    required: true
                },
                status: {
                    label: "Status",
                    required: true
                },
                technician: {
                    label: "Technician"
                },
                description: {
                    label: "Note"
                },
                quantity: {
                    label: "Quantity",
                    required: true
                },
                block: {
                    label: "Block Technician"
                },
                sex: {
                    label: "Priority technician"
                },
                color: {
                    label: "Color"
                },
                createdAt: {
                    label: "Created at"
                }
            }
        };
        this.className = "style-update-booking";
        AppStore.dispatch(forceSelectMenu(["/booking/index"]));
        this.apiCreate = "/booking/save-history";
        this.isChangeData = false;
    }

    maxDate = null;
    minDate = null;
    type = _.get(this.props, "match.params.type");

    isChangeData = () => {
        return this.isChangeData;
    };

    renderButtons() {
        return [
            {
                text: "Save",
                type: "submit",
                className: "btn-success",
                icon: <Icon type="save" style={{fontSize: 16}}/>,
                onPress: (form, values) => this.onSave(form, values)
            },
            {
                text: "Back",
                className: "btn-danger",
                icon: <Icon type="rollback" style={{fontSize: 16}}/>,
                onPress: () => this.onBack()
            }
        ];
    }

    // componentWillUnmount() {
    //     AppStore.dispatch(resetServiceStore());
    // }

    componentDidMount() {
        this.loadFormData();
    }

    onCloseDefault = () => {
        AppStore.dispatch(resetServiceStore());
    };

    async loadFormDataUpdate() {
        // this.form && this.form.mask();
        // const { storeList, authData } = this.props;
        // const { id } = this.state;
        // const branchIdInit = _.get(authData, "user.branchId");
        // const dateInit = this.props.startTime ? moment(this.props.startTime).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
        // this.dateInit = dateInit;
        // let initialValues = {
        //     branch: branchIdInit,
        //     startTime: this.startTime
        // };
        // const fetchs = [await AppUtil.getApi(ApiConstants.API_LOAD_COMBOBOX_DATA_BOOKING, {}), AppUtil.getApi(`/booking/show/` + id, {})];
        // if (_.isEmpty(_.get(this.props.storeList, "priorityTechnicians"))) {
        //     fetchs.push(AppUtil.getApi(`/technician/combobox-priority`, { branch: initialValues.branch, date: dateInit }));
        // }
        // await AppUtil.Axios.all(fetchs)
        //     .then(async ([res, resDetail, resCbxPrio]) => {
        //         if (_.get(res, "data.success") && _.get(resDetail, "data.success")) {
        //             initialValues = _.get(resDetail, "data.results");
        //             const resCus = await AppUtil.getApi(`/customer/combobox`, {
        //                 start: 0,
        //                 limit: 100,
        //                 query: initialValues.phone
        //             });
        //             AppStore.dispatch(loadComboboxDataBookingForm(_.get(res, "data.results")));
        //             if (resCbxPrio) {
        //                 AppStore.dispatch(loadComboboxPriotityTechnician(resCbxPrio.data));
        //             }
        //             initialValues.createdAt = moment(initialValues.createdAt).format(AppUtil.GLOBAL_DATETIME_FORMAT);
        //             AppStore.dispatch(selectManyService(storeList, initialValues.bookingItems));
        //             AppStore.dispatch(updateExtraService(initialValues.extraServices));
        //             AppStore.dispatch(updateBooking(initialValues));
        //             AppStore.dispatch(updateCustomer(_.get(resCus, "data")));
        //         } else {
        //             this.props.onClose && this.props.onClose();
        //             AppUtil.ToastApiError();
        //         }
        //         // this.setState({
        //         //     initialValues
        //         // });
        //         this.form && this.form.setValues(initialValues);
        //         this.form && this.form.unmask();
        //     })
        //     .catch((error) => {
        //         AppUtil.ToastApiError();
        //     })
        //     .finally(() => {
        //         this.refContainer && this.refContainer.unmask();
        //     });
    }

    async loadFormData() {
        const {initialValues} = this;
        // this.form && this.form.mask();
        const dateInit = this.props.startTime ? moment(this.props.startTime).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
        this.dateInit = dateInit;
        const fetchs = [
            await AppUtil.getApi(ApiConstants.API_LOAD_COMBOBOX_DATA_BOOKING, {}),
            AppUtil.getApi(`/customer/combobox`, {
                start: 0,
                limit: 100,
                query: ""
            }),
            AppUtil.getApi(`/service/get-service-tree`, {}),
            AppUtil.getApi(`/technician/combobox-priority`, {
                branch: _.get(this.props.authData, "user.branchId"),
                date: dateInit
            })
        ];

        await AppUtil.Axios.all(fetchs)
            .then(([res, resCus, resSer, resCbxPrio]) => {
                if (_.get(res, "data.success") && resCus && resSer) {
                    AppStore.dispatch(loadAllServiceStore(resSer.data.children));
                    AppStore.dispatch(loadComboboxDataBookingForm(_.get(res, "data.results")));
                    if (resCbxPrio) {
                        AppStore.dispatch(loadComboboxPriotityTechnician(resCbxPrio.data));
                    }
                    AppStore.dispatch(updateCustomer(_.get(resCus, "data")));
                } else {
                    AppUtil.ToastApiError();
                }
                const startTime = _.get(res, "data.results.startTime");
                if (startTime > moment(initialValues.startTime).format("HH:mm")) {
                    initialValues.startTime = null;
                }
                this.setState({
                    initialValues,
                    loading: false
                });
                // this.form && this.form.setValues(initialValues);
                // this.form && this.form.unmask();
            })
            .catch((error) => {
                AppUtil.ToastApiError();
                this.form && this.form.unmask();
            });
    }

    onChangeDate = async (startTime) => {
        const {initialValues} = this;
        const {priorityTechnicians, selectedServiceStoreReordering} = this.props;
        const dataForm = this.form && this.form.getValues();
        let technicianId = dataForm["technician"];
        const newDate = moment(startTime).format("YYYY-MM-DD");
        if (this.dateInit && newDate !== this.dateInit) {
            const res = await AppUtil.getApi(`/technician/combobox-priority`, {
                branch: initialValues.branch,
                date: newDate
            });
            if (res && res.data) {
                AppStore.dispatch(loadComboboxPriotityTechnician(res.data));
                this.dateInit = newDate;
            } else {
                AppUtil.ToastApiError();
            }
            const find = _.find(priorityTechnicians, (x) => x.id === technicianId);
            if (!find) {
                this.form && this.form.setValueField("technician", null);
            }
            this.GridBooking && this.GridBooking.onChangeDate();
        }
    };

    onSelectTechnicianOnGrid(service_id, technicianId) {
        // const { selectedServiceStoreReordering, storeList } = this.props;
        // const technicianFind = storeList.priorityTechniciansDropdown.find((obj) => {
        //     return obj.id === technicianId;
        // });
        // selectedServiceStoreReordering.forEach((selectedService, index) => {
        //     if (selectedService.service_id === service_id) {
        //         selectedService.technician = technicianId;
        //     }
        //     if (technicianFind) {
        //         selectedService.technicianName = technicianFind.value;
        //     }
        // });
    }

    selectServiceHandler = (service_id, isServiceGroup) => {
        const {selectedServiceStoreReordering, storeList} = this.props;
        const dataForm = this.form && this.form.getValues();
        let technicianId = dataForm["technician"];
        if (selectedServiceStoreReordering.length > 0) {
            const lastService = selectedServiceStoreReordering[selectedServiceStoreReordering.length - 1];
            if (!technicianId) {
                if (lastService.technician) {
                    const findSkill = _.find(storeList.technicianSkillStore, (x) => x.service_id === service_id);
                    const techIds = findSkill ? findSkill.technicians : [];
                    if (_.indexOf(techIds, lastService.technician) >= 0) {
                        technicianId = lastService.technician;
                    }
                }
            }
        }
        if (!isServiceGroup) {
            AppStore.dispatch(selectService(service_id, storeList, selectedServiceStoreReordering, technicianId));
        } else {
            AppStore.dispatch(selectServiceGroup(service_id, storeList, selectedServiceStoreReordering, technicianId));
        }
    };

    beforeSave(form, values, actionName) {
        const dataPost = {};
        const {booking} = this.props;
        const {services, extra} = this.GridBooking && this.GridBooking.getData();
        const stringFormat = "YYYY-MM-DD HH:mm";
        const bookingItems = _.map(services, (item, index) => {
            return {
                service_id: item.service_id ? item.service_id : null,
                technician: item.technician ? item.technician : null,
                startTime: item.startTime && booking.startTime && moment(values.startTime).format(stringFormat) === moment(booking.startTime).format(stringFormat) ? item.startTime : null,
                endTime: item.endTime && booking.startTime && moment(values.startTime).format(stringFormat) === moment(booking.startTime).format(stringFormat) ? item.endTime : null
            };
        });
        let extraServices = _.map(extra, (item) => {
            return {
                ...item,
                startTime: values.startTime ? moment(values.startTime).format("YYYY-MM-DD") : null,
                price: item.price ? parseFloat(item.price) : 0
            };
        });
        dataPost.email = values.email;
        dataPost.startTime = moment(values.startTime);
        if (this.unCheckWarning) {
            dataPost.unCheckWarning = this.unCheckWarning;
        }
        dataPost.bookingItems = bookingItems ? bookingItems : [];
        dataPost.extraServices = extraServices ? extraServices : [];
        dataPost.phone = _.get(values.phone, "phone") ? _.get(values.phone, "phone") : values.phone;
        if (values.id) {
            dataPost.id = values.id;
        }
        dataPost.customerName = values.customerName;
        dataPost.technician = values.technician;
        dataPost.branch = values.branch;
        dataPost.paymentMethod = values.paymentMethod ? values.paymentMethod : null;
        dataPost.description = values.description;
        if (values.quantity) {
            dataPost.quantity = values.quantity;
        }

        dataPost.block = values.block ? values.block : 0;
        if (values.status && ["start", "complete", "cancel", "no-show"].indexOf(actionName) < 0 && !this.props.cloneView) {
            dataPost.status = values.status;
        }
        dataPost.sex = values.sex ? values.sex : "";
        dataPost.color = values.color ? values.color : "";
        dataPost.block = values.block ? 1 : 0;
        dataPost.typeBooking = values.typeBooking ? values.typeBooking : "TYPE_BOOKING_OFFLINE";
        return dataPost;
    }

    checkValidValues(values, continute, start, actionName) {
        if (actionName && actionName !== "cancel" && _.isEmpty(values.bookingItems)) {
            AppUtil.ToastError("Please select services");
            return false;
        }
        if (start) {
            const find = _.find(values.bookingItems, (x) => !x.technician);
            if (find) {
                AppUtil.ToastError("Please select full technician for services");
                return false;
            }
        }
        return true;
    }

    showConfirm = async (titleConfirm, messageConfirm, callback) => {
        let confirm = await Alert.Swal_confirm(titleConfirm, messageConfirm, 3);
        if (_.get(confirm, "value") === true) {
            callback && callback();
        }
    };

    onSave = async (form, values, continute = false, start = false, url = this.apiCreate, actionName = "save") => {
        const {id} = this.state;
        if (id) {
            url = `${url}?id=${id}`;
        }
        if (this.GridBooking && this.GridBooking.isValidBooking()) {
            let newValues = await this.beforeSave(form, values, actionName);
            if (this.checkValidValues(newValues, continute, start, actionName)) {
                let confirm = {
                    value: false
                };
                if (start) {
                    confirm = await Alert.Swal_confirm("Confirm", "Are you sure you want to start booking? ", 3);
                }
                if (!start || _.get(confirm, "value")) {
                    form && form.mask();
                    this.GridBooking && this.GridBooking.gridLoading();
                    if (start && confirm.value) {
                        url = "/booking/start";
                        if (id) {
                            url = `${url}?id=${id}`;
                        }
                    }
                    const res = await AppUtil.postApi(url, newValues);
                    this.afterSave(form, res, actionName);
                    form && form.unmask();
                    this.GridBooking && this.GridBooking.gridCloseLoading();
                    if (res) {
                        if (res.data && !res.data.success) {
                            if (_.get(res, "data.errors")) {
                                AppUtil.ToastApiError(_.get(res, "data.errors"));
                            }
                            this.checkMessage(_.get(res, "data.message"), newValues, actionName, continute);
                        } else {
                            values[this.keyID] ? AppUtil.ToastSaveSuccess() : AppUtil.ToastCreateSuccess();
                            if (this.inModal) {
                                if (continute) {
                                    this.continute();
                                }
                                if (!continute) {
                                    AppStore.dispatch(resetServiceStore());
                                    this.props.onClose && this.props.onClose();
                                    this.props.onReloadData && this.props.onReloadData();
                                }
                            } else {
                                AppStore.dispatch(resetServiceStore());
                                this.onBack();
                            }
                        }
                    } else {
                        AppUtil.ToastApiError();
                    }
                }
            }
        } else {
            //AppUtil.ToastError("Booking invalid!");
        }
        setTimeout(() => {
            form && form.unmask();
            this.GridBooking && this.GridBooking.gridCloseLoading();
        }, 200);
    };

    continute() {
        AppStore.dispatch(resetServiceStore());
        this.GridBooking && this.GridBooking.clearService();
        // this.loadComboboxFormData()
        this.loadFormData();
        this.props.onReloadData && this.props.onReloadData();
    }

    continuteAfterCheckMessage() {
        this.props.onClose && this.props.onClose();
        this.props.onReloadData && this.props.onReloadData();
    }

    async checkMessage(message, newValues, actionName, continute) {
        if (message && message === "Please select full technician for services") {
            AppUtil.ToastError(message);
        } else if (message) {
            let confirm = await Alert.Swal_confirm("WARNING", message, 3);
            if (_.get(confirm, "value") === true) {
                newValues.unCheckWarning = true;
                newValues.id = this.state.id;
                const res = await AppUtil.postApi(`/booking/${actionName}`, newValues);
                if (_.get(res, "data.success")) {
                    // AppUtil.ToastSaveSuccess();
                    if (continute) {
                        this.continuteAfterCheckMessage();
                    } else {
                        this.props.onClose && this.props.onClose();
                        this.props.onReloadData && this.props.onReloadData();
                        if (!this.inModal) {
                            this.onBack();
                        }
                    }
                }
            }
        }
    }

    onBack() {
        this.props.history.push("/booking/index");
    }

    onSelectMainTechnician(techId) {
        const {selectedServiceStoreReordering, storeList, extraServices, priorityTechnicians} = this.props;
        AppStore.dispatch(selectMainTechnician(techId, selectedServiceStoreReordering, storeList, extraServices));
    }

    onChangePhone = (phone) => {
        this.form && this.form.setValueField("customerName", _.get(phone, "name", ""), true);
        const arrEmail = _.get(phone, "email") ? phone.email.split(",") : [];
        this.form && this.form.setValueField("email", _.isEmpty(arrEmail) ? "" : _.last(arrEmail), true);
    };

    onChangeField(name, value) {
    }

    checkRenderLoyaltyInfo() {
        const {initialValues} = this.state;
        let loyaltyInfo = null;
        const {discountCustomer} = initialValues;
        if (["Cancel", "Completed"].indexOf(initialValues.status) < 0) {
            if (_.get(discountCustomer, "type") === TYPE_LOYALTY_BOOKING_BASED) {
                const times = _.get(discountCustomer, "value.content") ? parseInt(_.get(discountCustomer, "value.content")) + 1 : 0;
                let title = "";
                switch (times) {
                    case 1:
                        title = "1st";
                        break;
                    case 2:
                        title = "2nd";
                        break;
                    case 3:
                        title = "3rd";
                        break;
                    default:
                        title = times + "th";
                    // code block
                }
                loyaltyInfo = `*Payment to receive loyalty discount for the ${title} booking`;
            } else if (_.get(discountCustomer, "type") === TYPE_LOYALTY_POINT_BASED && discountCustomer.value > 0) {
                loyaltyInfo = `*Customer have $${discountCustomer.value} accumulated money`;
            }
        }

        if (loyaltyInfo && discountCustomer.value) {
            return <Row>
                <Cell className={"loyalty-info"}>
                    {loyaltyInfo}
                </Cell>
            </Row>
        }
        return <></>;
    }

    onSetErrorField = (field, message) => {
        this.form && this.form.setErrorField(field, message);
    };

    renderForm() {
        const {authData, storeList, optionsCustomer} = this.props;
        const {initialValues} = this.state;
        let flagChangeStatus = false;
        if (moment(initialValues.startTime).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) {
            flagChangeStatus = true;
        }
        let rightAdmin = this.checkDeleteRight();
        if (this.props.isDetail) {
            rightAdmin = false;
        }
        if (rightAdmin && initialValues.status === STATUS_COMPLETE) {
            flagChangeStatus = true;
        }
        if (["cloneHistory"].indexOf(this.type) >= 0) {
            flagChangeStatus = false;
        }
        if (initialValues.status === "NEW") {
            flagChangeStatus = false;
        }

        if (this.state.loading) return <Loading/>;
        return (
            <Form
                ref={(component) => (this.form = component)}
                initialValues={initialValues}
                model={this.model}
                className="base-form-modal create-booking"
                style={{padding: 15, display: "flex", flexDirection: "column"}}
                buttons={this.renderButtons()}
                onInputChange={(name, value) => this.onChangeField(name, value)}
                defaults={{
                    alignLabel: "left",
                    widthLabel: this.widthLabel ? this.widthLabel : 115,
                    isClearable: true,
                    inCondition: true,
                    labelKey: "name",
                    valueKey: "id",
                    readOnly: _.get(initialValues, "status") === "Completed" && !rightAdmin
                }}>
                <Row>
                    <Cell>
                        {this.checkRenderLoyaltyInfo()}
                    </Cell>
                </Row>
                <Row>
                    <Cell className={"phone-input"}>
                        <IDField name="phone" onSetErrorField={this.onSetErrorField} onChange={this.onChangePhone}
                                 options={optionsCustomer}>
                            <SearchPhoneComponent/>
                        </IDField>
                    </Cell>
                    <Cell>
                        <IDField
                            status={this.type !== "cloneHistory" ? _.get(initialValues, "status") : ""}
                            showTimeSelect={true}
                            name="startTime"
                            minDate={this.minDate}
                            maxDate={this.maxDate}
                            onChange={this.onChangeDate}
                            startTime={storeList.startTime}
                            rightAdmin={rightAdmin}
                            timeWork={storeList.timeWork}>
                            <ReactDatePickerCustom/>
                        </IDField>
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <TextField name="customerName" upperCase={true}/>
                    </Cell>
                    <Cell>
                        <TextField name="email" type="email"/>
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <ComboField name="branch" options={_.get(authData, "user.branches")}/>
                    </Cell>
                    <Cell>
                        {flagChangeStatus && (
                            <ComboField name="status" options={storeList.bookingStatus} isClearable={false}/>
                        )}
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <ComboField name="technician" options={this.props.priorityTechnicians}
                                    placeholder="Select technician..."
                                    onChange={(val) => this.onSelectMainTechnician(val)}/>
                    </Cell>
                    <Cell>
                        <RadioListField
                            name={"sex"}
                            viewMode={"horizontal"}
                            labelKey={"label"}
                            valueKey={"value"}
                            options={[
                                {
                                    label: "Any",
                                    value: "ANY"
                                },
                                {
                                    label: "Male",
                                    value: "MALE"
                                },
                                {
                                    label: "Female",
                                    value: "FEMALE"
                                }
                            ]}
                        />
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <SwitchField name={"block"}/>
                    </Cell>
                    <Cell>
                        <IDField name="color" onChange={this.onChangeColor}>
                            <ColorRandomField/>
                        </IDField>
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <IDField name="color" onChange={this.onChangeColor}>
                            <ColorRandomField/>
                        </IDField>
                    </Cell>
                    <Cell>
                        <DisplayField name="createdAt" readOnlY={true}/>
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <TextAreaField name="description"/>
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <GridBookingService
                            ref={(c) => (this.GridBooking = c)}
                            // onSelectTechnician={this.onSelectTechnicianOnGrid.bind(this)}
                            technicianId={initialValues.technician}
                            selectServiceHandler={this.selectServiceHandler}
                            isMobile={this.isMobile}
                            rightAdmin={rightAdmin}
                            {...this.props}
                        />
                    </Cell>
                </Row>

            </Form>
        );
    }

    renderTop() {
        return (
            <div className={"style-fixed-header render-top-label"}>
                <div className={"header-left"}>
                    <span>CREATE BOOKING </span>
                </div>
            </div>
        );
    }

    render() {
        const {contentHeight} = this;
        return (
            <BasePermisstion rightAccess={this.rightAccess}>
                <FullBrowser ref={(component) => (this.FullBrowser = component)}>
                    <Container ref={(c) => (this.refContainer = c)}>
                        <ContentView>
                            {" "}
                            <div className={this.className}>
                                <div style={{display: "flex", flexDirection: "column", flex: 1, height: contentHeight}}>
                                    {this.renderTop()}
                                    <div style={{
                                        flex: 1,
                                        overflowY: "auto",
                                        overflowX: "hidden"
                                    }}>{this.renderForm()}</div>
                                    {this.renderModal()}
                                    <BillComponent ref={(c) => (this.BillComponent = c)}/>
                                </div>
                            </div>
                        </ContentView>
                    </Container>
                </FullBrowser>
            </BasePermisstion>
        );
    }
}

export default BaseBookingCreate;
