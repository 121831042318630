import React, { Component } from 'react'

export default class IconThreeDot extends Component {
    render() {
        return (
            <svg width="6" height="14" viewBox="0 0 6 24" fill="#333" xmlns="http://www.w3.org/2000/svg">
                <circle cx="3" cy="3" r="3" />
                <circle cx="3" cy="12" r="3" />
                <circle cx="3" cy="21" r="3" />
            </svg>

        )
    }
}
