import { Icon } from "@idtek/component/lib";
import Container from "@idtek/component/lib/container/Container";
import Modal from "@idtek/component/lib/modal/Modal";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { loadComboboxPriotityTechnician, loadConfigDashboardV2, notResetStore } from "../../application/actions/action";
import MainDrawer from "../../application/components/MainDrawer";
import GlobalStore from "../../GlobalStore";
import Loading from "../../pages/Loading";
import AppStore from "../../store";
import AppUtil from "../../utils/AppUtil";
import TreeUtil from "../../utils/TreeUtil";
import FormBookingNew from "../booking/form/FormBookingNew";
import FormBookingCreateV2 from "../booking_premium/form/FormBookingCreateV2";
import FormBookingUpdateV2 from "../booking_premium/form/FormBookingUpdateV2";
import ComponentDrawerBooking from "../dashboard/component/ComponentDrawerBooking";
import FormPaymentInDashboard from "../dashboard/form/FormPaymentInDashboard";
import FilterHeader from "./component/FilterHeader";
import Scheduler from "./Scheduler";

class DashboardPremium extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.loadResource();
    }

    onReloadData = () => {
        this.loadResource(null, false);
    };

    async loadResource(date, scrollToCurrentHour = true) {
        this.Scheduler && this.Scheduler.openOverlay();
        const obj = this.FilterHeader.getDataFilter();
        const _date = date ? moment(date).format("YYYY-MM-DD") : moment(obj.filterDate).format("YYYY-MM-DD");
        const res = await AppUtil.getApi("/booking/schedule-booking", { branch: obj.branchId, date: _date });
        const resClipboard = await AppUtil.getApi("/booking/clipboard-booking", { branch: obj.branchId });
        if (_.get(res, "data.success") && _.get(resClipboard, "data.success")) {
            const configBooking = _.get(res, "data");
            const multi = parseInt(configBooking.multiSetting) === 1 ? true : false;
            const bookings = _.filter(_.get(configBooking, "results"), (x) => x.status !== "Cancel" && x.status !== "NoShow");
            const dataBooking = _.sortBy(bookings, (x) => x.startTime);
            this.FilterHeader && this.FilterHeader.setScheduleBooking(bookings);
            const skills = _.get(configBooking, "skills");
            const flatAllData = this.handleData(dataBooking, skills);
            const parseTimeWork = moment.utc(parseFloat(configBooking.timeWork) * 3600 * 1000).format("HH:mm");
            const totalMinutes = moment.duration(configBooking.startTime).asMinutes() + moment.duration(parseTimeWork).asMinutes();
            const objTime = this.timeConvert1(totalMinutes);
            const endHour = _.get(objTime, "time");
            let extra = endHour;
            if (_.get(objTime, "hour") < 23) {
                extra = moment(endHour, "HH:mm").add(60, "minutes").format("HH:mm");
            }
            const data = _.map(configBooking.technicianWorking, (item) => {
                const arr = [];
                let isFullService = true;
                _.each(skills, (sk) => {
                    if (_.indexOf(sk.technicians, item.id) < 0) {
                        isFullService = false;
                    }
                });
                _.each(item.workTimes, (x) => {
                    const obj = {
                        id: x.id,
                        name: isFullService ? item.name + " - FS" : item.name,
                        startHour: moment(x.workTimes[0], "HH:mm:ss").format("HH:mm"),
                        endHour: moment(x.workTimes[1], "HH:mm:ss").format("HH:mm")
                    };
                    arr.push(obj);
                });
                if (_.size(arr) === 0) {
                    return {
                        ...item,
                        name: isFullService ? item.name + " - FS" : item.name,
                        startHour: configBooking.startTime,
                        endHour: endHour
                    };
                } else {
                    return {
                        ...item,
                        name: isFullService ? item.name + " - FS" : item.name,
                        workTimes: arr,
                        startHour: arr[0].startHour,
                        endHour: arr[0].endHour
                    };
                }
            });
            const clipboardData = _.get(resClipboard, "data.results");
            const dataFlatWaiting = this.handleData(clipboardData, skills);
            const treeDataWaiting = TreeUtil.buildTreeFromFlatData(dataFlatWaiting, "Id", "BookingId", "Children");
            const config = {
                startHour: configBooking.startTime,
                endHour: endHour > "24:00" ? "24:00" : endHour,
                technicianSchedule: data,
                flatAllData,
                bookingSchedule: _.filter(flatAllData, (x) => x.Type === "services" && x.TechnicianId),
                waitingList: treeDataWaiting,
                technicianSkills: skills,
                readOnly: _date >= moment().format("YYYY-MM-DD") ? false : true,
                scrollToCurrentHour: scrollToCurrentHour,
                multiSetting: multi,
                extraEndHour: extra < "24:00" ? extra : null,
                minuteOverlap: parseInt(configBooking.multiSetting) === 1 ? 0 : parseInt(configBooking.minuteOverlap),
                printBillSlip: parseInt(configBooking.printBillSlip) === 1 ? true : false
            };

            if (_date) {
                config.dateSelected = moment(_date, "YYYY-MM-DD").toDate();
            }
            AppStore.dispatch(loadComboboxPriotityTechnician(configBooking.technicianWorking, true));
            AppStore.dispatch(loadConfigDashboardV2(config));
            this.Scheduler && this.Scheduler.closeOverlay();
        } else {
            this.Scheduler && this.Scheduler.closeOverlay();
            AppUtil.ToastApiError();
        }
    }

    handleData(dataBooking, skills) {
        const flatAllData = [];
        _.each(dataBooking, (item) => {
            const { bookingItems } = item;
            const objBooking = { ...item };
            objBooking.Id = item.id;
            objBooking.BookingItemId = null;
            objBooking.ServiceId = null;
            objBooking.BookingId = null;
            objBooking.TechnicianId = null;
            objBooking.StartTime = item.startTime;
            objBooking.EndTime = item.endTime;
            objBooking.Status = item.status;
            objBooking.Subject = `${item.code} - ${item.customerName} ${item.phone ? "- " + item.phone : ""}`;
            objBooking.Type = "booking";
            objBooking.Expanded = true;
            objBooking.CustomerName = item.customerName;
            objBooking.CustomerId = item.customer;
            objBooking.Phone = item.phone;
            objBooking.BookingCode = item.code;
            objBooking.PaymentMethod = item.paymentMethod;
            objBooking.Sex = item.sex;
            objBooking.Block = item.block;
            objBooking.Color = item.color;
            objBooking.TypeBooking = item.typeBooking;
            objBooking.CustomerNotes = item.customerNotes;
            objBooking.Description = item.description;
            objBooking.CreatedAt = item.createdAt;
            objBooking.Confirm = item.confirm;
            flatAllData.push(objBooking);
            _.each(bookingItems, (service) => {
                const obj = {};
                const skill = _.find(skills, (x) => x.service_id === service.service_id);
                obj.Id = `${item.id}_${service.id}`;
                obj.BookingItemId = service.id;
                obj.ServiceId = service.service_id;
                obj.ServiceName = service.name;
                obj.BookingId = item.id;
                obj.TechnicianId = service.technician_id;
                obj.StartTime = service.startTime;
                obj.EndTime = service.endTime;
                obj.Status = item.status;
                obj.Subject = `${item.code} - ${service.name}`;
                obj.Type = "services";
                obj.TechnicianSkill = skill ? skill.technicians : [];
                obj.ServiceTime = service.time;
                obj.CustomerName = item.customerName;
                obj.CustomerId = item.customer;
                obj.Phone = item.phone;
                obj.BookingCode = item.code;
                obj.ServiceCode = service.code;
                obj.PaymentMethod = item.paymentMethod;
                obj.Sex = item.sex;
                obj.Block = item.block;
                obj.Color = item.color;
                obj.TypeBooking = item.typeBooking;
                obj.CustomerNotes = item.customerNotes;
                obj.Description = item.description;
                obj.CreatedAt = item.createdAt;
                obj.Confirm = item.confirm;
                flatAllData.push(obj);
            });
        });
        return flatAllData;
    }
    timeConvert1(data) {
        var minutes = data % 60;
        var hours = (data - minutes) / 60;
        return {
            time: hours + ":" + ("0" + minutes).slice(-2),
            hour: hours
        };
    }
    renderModal() {
        return (
            <Modal
                draggable
                ref={(component) => {
                    this.Modal = component;
                }}
                onClose={this.onCloseDefault}
                maskClosable={false}
                className="popup-crud popup-container"
            />
        );
    }

    onShowDrawer = () => {
        GlobalStore.MainDrawer &&
            GlobalStore.MainDrawer.open(
                <React.Suspense fallback={<Loading />}>
                    <ComponentDrawerBooking ref={(c) => (this.drawer = c)} />
                </React.Suspense>,
                null,
                "33%"
            );
    };

    renderDrawer() {
        return (
            <MainDrawer
                ref={(component) => (GlobalStore.MainDrawer = component)}
                onClose={() => {
                    if (this.drawer.checkChangeData()) {
                        setTimeout(() => {
                            // this.loadTotalNewBooking();
                            this.onReloadData();
                        }, 300);
                    }
                }}
            />
        );
    }

    onCreateBooking = (technicianId, startTime) => {
        this.Modal.onOpen(
            <FormBookingCreateV2
                ref={(c) => (this.refCreateBooking = c)}
                onClose={() => {
                    this.Modal.onClose();
                }}
                onReloadData={this.onReloadData}
                startTime={moment(startTime)}
                techSchedule={technicianId}
                multiSetting={this.props.multiSetting}
            />,
            <span>
                <span style={{ position: "relative", marginRight: 7, display: "flex", alignItems: "center" }}>
                    <Icon type="form" style={{ fontSize: 16, display: "flex", color: "var(--main-color-blue-ocean)" }} />
                    <span style={{ fontSize: 15, display: "flex", marginLeft: 7 }}>Create booking</span>
                </span>
            </span>,
            "1000px"
        );
    };

    onUpdateBooking = (bookingId, code, status, isDetail = false, type) => {
        let title = "Update booking";
        if (isDetail) {
            title = "Detail booking";
        }
        let unsetMinDate = false;
        if (type === "clipboard") {
            unsetMinDate = true;
        }
        this.Modal.onOpen(
            <FormBookingUpdateV2
                ref={(c) => (this.refUpdateBooking = c)}
                onClose={() => {
                    this.Modal.onClose();
                }}
                onReloadData={this.onReloadData}
                onClone={(booking) => this.onClone(booking)}
                onProceedPayment={this.onProceedPayment}
                id={bookingId}
                isDetail={isDetail}
                unsetMinDate={unsetMinDate}
                buttons={[
                    {
                        text: "Close",
                        className: "btn-danger",
                        icon: <Icon type="close-square" style={{ fontSize: 16 }} />,
                        onPress: this.onClose
                    }
                ]}
                multiSetting={this.props.multiSetting}
            />,
            <span>
                <span style={{ position: "relative", marginRight: 7, display: "flex", alignItems: "center" }}>
                    <Icon type="form" style={{ fontSize: 16, display: "flex", color: "var(--main-color-blue-ocean)" }} />
                    {code && status ? (
                        <span style={{ fontSize: 15, display: "flex", marginLeft: 7 }}>{title + " " + code + " - Status: " + status}</span>
                    ) : (
                        <span style={{ fontSize: 15, display: "flex", marginLeft: 7 }}>{title}</span>
                    )}
                </span>
            </span>,
            "1000px"
        );
    };

    onUpdateBookingNew = (bookingId, code, status) => {
        let title = "Update booking";
        if (code) {
            title = `Update booking ${code} - Status: NEW`;
        }
        this.Modal.onOpen(
            <FormBookingNew
                ref={(c) => (this.refNewBooking = c)}
                onClose={() => {
                    this.Modal.onClose();
                }}
                onReloadData={this.onReloadData}
                id={bookingId}
                multiSetting={this.props.multiSetting}
            />,
            <span>
                <span style={{ position: "relative", marginRight: 7, display: "flex", alignItems: "center" }}>
                    <Icon type="form" style={{ fontSize: 16, display: "flex", color: "var(--main-color-blue-ocean)" }} />
                    <span style={{ fontSize: 15, display: "flex", marginLeft: 7 }}>{title}</span>
                </span>
            </span>,
            "1000px"
        );
    };

    onClone(bookingData) {
        bookingData.status = "Waiting";
        AppStore.dispatch(notResetStore(true));
        this.Modal && this.Modal.onClose();
        this.ModalClone.onOpen(
            <FormBookingCreateV2
                initData={bookingData}
                cloneView={true}
                ref={(c) => (this.refCloneBooking = c)}
                onClose={() => {
                    this.ModalClone.onClose();
                    AppStore.dispatch(notResetStore(false));
                }}
                onReloadData={this.onReloadData}
                startTime={_.get(bookingData, "startTime")}
                multiSetting={this.props.multiSetting}
            />,
            <span>
                <span style={{ position: "relative", marginRight: 7, display: "flex", alignItems: "center" }}>
                    <Icon type="form" style={{ fontSize: 16, display: "flex", color: "var(--main-color-blue-ocean)" }} />
                    <span style={{ fontSize: 15, display: "flex", marginLeft: 7 }}>{`Clone booking from ${bookingData.code}`}</span>
                </span>
            </span>,
            "1000px"
        );
    }

    onProceedPayment = (bookingId) => {
        const { authData } = this.props;
        const obj = this.FilterHeader.getDataFilter();
        const { paymentMethod } = this.props.authData;
        const branch = obj.branchId;
        this.ModalMethod.onOpen(
            <FormPaymentInDashboard
                id={bookingId}
                onClose={() => {
                    this.ModalMethod.onClose();
                }}
                onReloadData={this.onReloadData}
                thueVAT={_.get(authData, "gst")}
                // booking={booking}
                user={_.get(authData, "user.fullName")}
                paymentMethod={paymentMethod}
                branch={branch}
            />,
            <span>Take Payment</span>,
            800
        );
    };

    renderModalClone() {
        return (
            <Modal
                draggable
                ref={(component) => {
                    this.ModalClone = component;
                }}
                onClose={this.onCloseDefault}
                maskClosable={false}
                className="popup-crud popup-container popup-in-dashboard"
            />
        );
    }

    renderModalMethod() {
        return (
            <Modal
                draggable
                ref={(component) => {
                    this.ModalMethod = component;
                }}
                onClose={this.onCloseDefault}
                maskClosable={false}
                className="popup-crud popup-container popup-method"
            />
        );
    }

    render() {
        const {
            bookingSchedule,
            technicianSchedule,
            startHour,
            endHour,
            waitingList,
            dateSelected,
            flatAllData,
            reloadWaiting,
            technicianSkills,
            readOnly,
            scrollToCurrentHour,
            multiSetting,
            extraEndHour,
            minuteOverlap,
            printBillSlip,
            phoneSetting
        } = this.props;
        return (
            <Container ref={(c) => (this.container = c)} style={{ height: "100%", width: "100%", position: "absolute", top: 56, background: "#EAEFF3" }}>
                <div className={`dashboard-premium dashboard-v2`} style={{ width: "100%", padding: "16px 21px 16px 21px" }}>
                    <FilterHeader
                        onReloadData={this.onReloadData}
                        ref={(c) => (this.FilterHeader = c)}
                        onShowDrawer={this.onShowDrawer}
                        onClickUpdateBooking={this.onUpdateBooking}
                        onChangeDate={(date, scrollToCurrentHour) => this.loadResource(date, scrollToCurrentHour)}
                        {...this.props}
                    />
                    <Scheduler
                        ref={(c) => (this.Scheduler = c)}
                        technicianSchedule={technicianSchedule}
                        bookingSchedule={bookingSchedule}
                        startHour={startHour}
                        endHour={endHour}
                        waitingList={waitingList}
                        dateSelected={dateSelected}
                        flatAllData={flatAllData}
                        reloadWaiting={reloadWaiting}
                        technicianSkills={technicianSkills}
                        readOnly={readOnly}
                        scrollToCurrentHour={scrollToCurrentHour}
                        multiSetting={multiSetting}
                        extraEndHour={extraEndHour}
                        minuteOverlap={minuteOverlap}
                        printBillSlip={printBillSlip}
                        phoneSetting={phoneSetting}
                        onProceedPayment={this.onProceedPayment}
                        onCreateBooking={this.onCreateBooking}
                        onUpdateBooking={this.onUpdateBooking}
                        onUpdateBookingNew={this.onUpdateBookingNew}
                        onReloadData={this.onReloadData}
                    />
                    {/* {this.renderDrawer()} */}
                </div>
                {this.renderModal()}
                {this.renderModalClone()}
                {this.renderModalMethod()}
            </Container>
        );
    }
}
export default DashboardPremium;
// const mapStateToProps = (state) => {
//     return { ...(state.root || {}) };
// };

// export default connect(mapStateToProps)(DashboardPremium);
