import moment from 'moment'
import React, { Component } from 'react'
import AppUtil from '../../../utils/AppUtil'
import { RECEIVE_MONEY, TAKE_MONEY } from '../constant/BookingConstant'
import ICON_RETURN_MONEY from '../../../application/images/return_money.svg'
import ICON_RECEIVE_MONEY from '../../../application/images/rece_money.svg'
import ICON_WITHDRAW from '../../../application/images/withdraw-money.svg'
import ICON_TOPPED from '../../../application/images/insert_money.png'
import ICON_GIFT from '../../../application/images/gift-card.svg'

import {
    CheckCircleFilled,
    ExclamationCircleFilled,
} from '@ant-design/icons';
import Tooltip from '@idtek/component/lib/tooltip/Tooltip'
import _ from 'lodash'

export default class ItemPosAction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            item: props.item,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ item: nextProps.item })
    }

    handleContent() {
        const { item } = this.state
        const { posType, giftCard, createdBy, actionTime, note, status, value } = item
        let row1 = ""
        const row2 = ` ${moment(actionTime).format(AppUtil.GLOBAL_DATETIME_FORMAT)} - By: ${createdBy.fullName}`
        const statusContent = status ? "Successfull" : "Failed"
        const iconCheck = status ?
            <CheckCircleFilled style={{ color: "var(--main-color-green)" }} /> :
            <ExclamationCircleFilled style={{ color: "var(--main-color-red)" }} />
        let valueText = ""
        let icon = ""
        let classNameIcon = ""
        if (posType) {
            switch (posType.name) {
                case RECEIVE_MONEY:
                    valueText = `+ $${value}`
                    row1 = `Topped-up money`
                    icon = ICON_TOPPED
                    classNameIcon = "icon-topped"
                    break;
                case TAKE_MONEY:
                    valueText = `- $${value}`
                    row1 = `Withdrew money`
                    classNameIcon = "icon-withdraw"
                    icon = ICON_WITHDRAW
                    break;
                default:
                    row1 = ""
            }

        }


        return { row1, row2, row3: note, status: statusContent, value: valueText, icon, classNameIcon, iconCheck }
    }

    renderItem() {
        const { row1, row2, row3, status, value, icon, classNameIcon, iconCheck } = this.handleContent()
        return <div className="item-action">
            <div className="icon">
                <img className={`img-pos ${classNameIcon}`} src={icon} alt="" />
                <div className="pos-status-icon">
                    {iconCheck}
                </div>
            </div>
            <div className="content">
                <div className="row-pos content-tranfer">{row1}</div>
                <div className="row-pos action-time"> {row2}</div>
                <div className="row-pos bottom">
                    {
                        row3 && row3.length >= 30 ? <Tooltip title={row3}>
                            <div className={`info-item`}>
                                Note: {row3}
                            </div>
                        </Tooltip> :
                            <div className={`info-item`}>
                                Note: {row3}
                            </div>
                    }
                    <div className="value-pos">
                        {value}
                    </div>
                </div>
            </div>


        </div>
    }
    render() {
        return (
            <>
                {this.renderItem()}
            </>
        )
    }
}
