import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Menu from "@idtek/component/lib/menu/Menu";
import { Link } from "react-router-dom";
import AppStore from "../../store";
import { onChangeOpenKeys } from "../actions/menuAction";
import AppUtil from "../../utils/AppUtil";
import LogUtil from "../../utils/LogUtil";
import SideMenu from "./SideMenu";
import { changeSideMenu, changeBreadcrumb } from "../actions/action";
import _ from "lodash";

class AppMenu extends PureComponent {
    componentWillReceiveProps(nextProps) {
        if (nextProps.selectKeys[0] !== this.props.selectKeys[0]) {
            const nextRoute = SideMenu.findRoute(nextProps.selectKeys[0], nextProps.allMenuItems) || {};
            const breadcrumbs = [];
            this.pushBreadcrumb(nextRoute, breadcrumbs);
            AppStore.dispatch(changeBreadcrumb(breadcrumbs));
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectKeys[0] !== this.props.selectKeys[0] || prevProps.allMenuItems !== this.props.allMenuItems) {
            const { allMenuItems } = this.props;
            const nextRoute = SideMenu.findRoute(this.props.selectKeys[0], allMenuItems) || {};
            const prevRoute = SideMenu.findRoute(prevProps.selectKeys[0], allMenuItems) || {};
            // computed openKeys
            // computed sideMenus

            if (prevRoute.route !== nextRoute.route) {
                const action = LogUtil.createActionLog("", "Truy cập", "", nextRoute.name);
                LogUtil.writeActionLog(action);
                const inDomainItems = SideMenu.getSideMenus(this.props.selectKeys[0], allMenuItems);
                const parentRoute = AppUtil.DataUtil.find(allMenuItems, (x) => x.id === nextRoute.parentId);
                if (parentRoute) {
                    AppStore.dispatch(onChangeOpenKeys([parentRoute.route]));
                }
                if (inDomainItems) {
                    // computed domain
                    let tree = SideMenu.buildMenuTree(inDomainItems);
                    tree = SideMenu.customSort(tree);
                    AppStore.dispatch(changeSideMenu(tree));
                }
            }
        }
    }

    pushBreadcrumb = (nextRoute, breadcrumbs) => {
        if (_.get(nextRoute, "parent") && _.get(nextRoute, "parent.level") > 1) {
            this.pushBreadcrumb(nextRoute.parent, breadcrumbs);
        }
        breadcrumbs.push(nextRoute);
    };

    isCrossDomain(route) {
        return route && route.indexOf("http") !== -1;
    }

    generateRoute(route) {
        if (this.isCrossDomain(route)) return AppUtil.GLOBAL_API_PATH + "/redirect-loginv2?redirectUrl=" + route;
        return route;
    }

    renderMenu(menus, start) {
        return _.map(menus, (menu, index) => {
            if (menu.active) {
                if (!menu.children) {
                    const { icon, name, route, background } = menu;
                    return (
                        route && (
                            <Menu.Item key={route}>
                                {route.startsWith("http") ? (
                                    <a
                                        href={this.generateRoute(route)}
                                        target="_bank"
                                        className="route-side-menu"
                                        onClick={() => {
                                            AppStore.history.replace({ state: true });
                                        }}
                                    >
                                        {icon && (
                                            <div className="side-menu-icon-wrap">
                                                <i
                                                    className={`${icon} ${background ? "side-menu-icon" : ""}`}
                                                    style={{
                                                        background,
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <span className="side-menu-text">{`${menu.lvl > 1 ? (start > 0 ? start + "." + (index + 1) : index + 1) + " " : ""}${name}`}</span>
                                        {menu.bagdeNumber > 0 &&
                                            (menu.bagdeNumber > 100 ? <span className="side-menu-bagde">{"99+"}</span> : <span className="side-menu-bagde">{menu.bagdeNumber}</span>)}
                                    </a>
                                ) : (
                                    <Link
                                        to={route}
                                        className="route-side-menu"
                                        onClick={() => {
                                            AppStore.history.replace({ state: true });
                                        }}
                                    >
                                        <div className="side-menu-icon-wrap">
                                            <i
                                                className={`${icon} ${background ? "side-menu-icon" : ""}`}
                                                style={{
                                                    background,
                                                }}
                                            />
                                        </div>
                                        <span className="side-menu-text">{`${menu.lvl > 1 ? (start > 0 ? start + "." + (index + 1) : index + 1) + " " : ""}${name}`}</span>
                                        {menu.bagdeNumber > 0 &&
                                            (menu.bagdeNumber > 100 ? <span className="side-menu-bagde">{"99+"}</span> : <span className="side-menu-bagde">{menu.bagdeNumber}</span>)}
                                    </Link>
                                )}
                            </Menu.Item>
                        )
                    );
                } else {
                    const { children, icon, name, background, route } = menu;
                    let badgeNumber = 0;
                    _.forEach(children, (x) => (badgeNumber = badgeNumber + (x.bagdeNumber > 0 ? x.bagdeNumber : 0)));
                    return (
                        <Menu.SubMenu
                            key={route}
                            forceSubMenuRender
                            title={
                                <div className="route-side-menu ">
                                    {icon && (
                                        <div className="side-menu-icon-wrap">
                                            <i className={`${icon} ${background ? "side-menu-icon" : ""}`} style={{ background }} />
                                        </div>
                                    )}
                                    {`${menu.lvl > 1 ? index + 1 + ". " : ""}${name}`}
                                    {badgeNumber > 0 && (badgeNumber > 100 ? <span className="badge-total">{"99+"}</span> : <span className="badge-total">{badgeNumber}</span>)}
                                </div>
                            }
                        >
                            {this.renderMenu(children, index + 1)}
                        </Menu.SubMenu>
                    );
                }
            }
        });
    }

    onOpenChange = (openKeys) => {
        AppStore.dispatch(onChangeOpenKeys(openKeys));
    };

    render() {
        return (
            <Menu
                mode="inline"
                theme="light"
                style={{
                    flex: 1,
                    marginTop: 15,
                }}
                className="side-menu"
                inlineIndent={18}
                // inlineCollapsed={this.props.collapsed}
                onOpenChange={this.onOpenChange}
                openKeys={this.props.openKeys}
                selectedKeys={this.props.selectKeys}
            >
                {this.renderMenu(this.props.sideMenu, 0)}
            </Menu>
        );
    }
}

function mapStateToProps(state) {
    const rootState = state.root;
    return {
        selectKeys: rootState.selectKeys,
        openKeys: rootState.openKeys,
        sideMenu: rootState.sideMenu,
        allMenuItems: rootState.allMenuItems,
    };
}

export default connect(mapStateToProps)(AppMenu);
