import React, { Component } from "react";
import { ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import "../style/color-picker.scss";
import randomColor from "randomcolor";
import _ from "lodash";
import { createElement } from "@syncfusion/ej2-base";
export default class ColorRandomField extends Component {
    constructor() {
        super(...arguments);
        this.state = {};
    }

    onCreatedColor() {
        if (this.cp && !this.props.value && this.props.isCreate) {
            let color = randomColor({
                luminosity: "dark",
            });
            this.cp.value = color;
            this.props.onChange && this.props.onChange(color);
        } else {
            this.cp.value = this.props.value ? this.props.value : "#ffffff";
        }
    }

    beforeCircleTileRender(args) {
        args.element.classList.add("e-circle-palette");
        args.element.appendChild(createElement("span", { className: "e-circle-selection" }));
    }
    onChange(args) {
        this.props.onChange && this.props.onChange(args.value);
    }

    render() {
        return (
            <div className="control-pane color-picker-custom">
                <div className="control-section">
                    <div id="default-control" className="wrap">
                        <ColorPickerComponent
                            ref={(c) => (this.cp = c)}
                            created={this.onCreatedColor.bind(this)}
                            change={this.onChange.bind(this)}
                            beforeTileRender={this.beforeCircleTileRender.bind(this)}
                            id="color-picker"
                            value={this.props.value ? this.props.value : ""}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
