import _ from "lodash";
import AppUtil from "../../../utils/AppUtil";
import { TYPE_RECEIVE_MONEY, TYPE_TAKE_MONEY, TYPE_LOYALTY_BOOKING_BASED, TYPE_LOYALTY_POINT_BASED } from "../constant/BookingConstant";
import "../style/proceed-payment.scss";
import FormProceedPayment from "./FormProceedPayment";
import { TYPE_VOUCHER } from "../constant/BookingConstant";

export default class FormPaymentInDashboard extends FormProceedPayment {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            optionsCheckout: []
        };
    }

    componentDidMount() {
        this.loadData();
        this.loadOptions();
    }

    async loadData() {
        const res = await AppUtil.getApi(`/booking/show/` + this.props.id, {});
        if (_.get(res, "data.success")) {
            const booking = _.get(res, "data.results");
            let subTotal = 0;
            if (booking) {
                const { posActions } = booking;
                _.each(booking.bookingItems, (x) => {
                    subTotal += x.price;
                });
                _.each(booking.extraServices, (x) => {
                    if (x.type !== "tip") {
                        subTotal += x.price;
                    }
                });
                this.actionRecieved = _.find(posActions, (x) => x.posTypeId === TYPE_RECEIVE_MONEY);
                this.actionTake = _.find(posActions, (x) => x.posTypeId === TYPE_TAKE_MONEY);
                this.isCreate = _.size(posActions) > 0 ? false : true;
            }
            const firstMethod = _.head(this.props.paymentMethod);
            this.initialValues = {
                received: this.actionRecieved ? this.actionRecieved.value : "",
                return: this.actionTake ? this.actionTake.value : "",
                typeCheckout: this.actionRecieved ? this.actionRecieved.type : "CASH"
            };
            const { bookingDiscounts, giftCardActions } = booking;
            const voucher = _.find(bookingDiscounts, (x) => x.type === TYPE_VOUCHER);
            const giftInUse = giftCardActions ? giftCardActions[0] : {};
            const loyalty = _.find(bookingDiscounts, (x) => x.type === TYPE_LOYALTY_BOOKING_BASED);
            const loyaltyPointDiscount = _.find(bookingDiscounts, (x) => x.type === TYPE_LOYALTY_POINT_BASED);
            let loyaltyPointBaseInfo = null;
            if (_.get(booking, "discountCustomer.type") === TYPE_LOYALTY_POINT_BASED) {
                loyaltyPointBaseInfo = _.get(booking, "discountCustomer");
            }
            if (loyaltyPointDiscount) {
                this.initialValues.moneyPoint = Math.abs(loyaltyPointDiscount.value);
            }

            const selected = _.get(booking, "paymentMethod") ? _.get(booking, "paymentMethod") : firstMethod ? firstMethod.id : "";
            this.onSelectMethod(selected);
            this.setState({
                booking,
                totalAmount: subTotal,
                voucher,
                giftInUse,
                gift: giftInUse,
                loyaltyPointBaseInfo,
                loyalty: loyalty ? loyalty : loyaltyPointDiscount ? loyaltyPointDiscount : loyaltyPointBaseInfo,
                loading: false,
                moneyPoint: 0,
                flag: booking.paymentMethod ? true : false
                // selected
            });
        } else {
            AppUtil.ToastApiError();
            this.props.onClose && this.props.onClose();
        }
    }
}
