import _ from "lodash";

const rightUtils = {
    rights: {},
    defaultRouteManager: null,
    checkRight: function (right) {
        if (right) {
            let rs = [];
            if (right instanceof Array) {
                rs = right;
            } else {
                rs = right.split(",");
            }
            const rights = rightUtils.rights;
            let r = false;
            for (let i = 0; i < rs.length; i++) {
                r |= !!rights[rs[i]];
            }
            return r;
        }
        return true;
    },
    applyRightToNode(nodes) {
        const newNodes = _.map(nodes, (node) => {
            if (_.get(node, "right")) {
                node.visible = rightUtils.checkRight(node.right);
                if (node.visible && _.get(node, "right2")) {
                    node.visible = rightUtils.checkRight(node.right2);
                }
            } else {
                node.visible = true;
            }
            return {
                ...node
            };
        });
        return newNodes;
    }
};

export const allRights = {
    USER_RIGHT__ACCESS: "USER_RIGHT__ACCESS",
    USER_RIGHT__UPDATE: "USER_RIGHT__UPDATE",

    USER_RIGHT__ACCESS_USER_PERMISSIONS: "USER_RIGHT__ACCESS_USER_PERMISSIONS",
    USER_RIGHT__UPDATE_USER_PERMISSIONS: "USER_RIGHT__UPDATE_USER_PERMISSIONS",
    ROLE_RIGHT__ACCESS: "ROLE_RIGHT__ACCESS",
    ROLE_RIGHT__UPDATE: "ROLE_RIGHT__UPDATE",

    SERVICE_RIGHT__ACCESS: "SERVICE_RIGHT__ACCESS",
    SERVICE_RIGHT__UPDATE: "SERVICE_RIGHT__UPDATE",
    CUSTOMER_RIGHT__ACCESS: "CUSTOMER_RIGHT__ACCESS",
    CUSTOMER_RIGHT__UPDATE: "CUSTOMER_RIGHT__UPDATE",
    BRANCH_RIGHT__ACCESS: "BRANCH_RIGHT__ACCESS",
    BRANCH_RIGHT__UPDATE: "BRANCH_RIGHT__UPDATE",
    BOOKING_RIGHT__ACCESS_HISTORY: "BOOKING_RIGHT__ACCESS_HISTORY",
    BOOKING_REVIEW_RIGHT__ACCESS: "BOOKING_REVIEW_RIGHT__ACCESS",
    BOOKING_RIGHT__UPDATE_HISTORY_BOOKING: "BOOKING_RIGHT__UPDATE_HISTORY_BOOKING",
    BOOKING_RIGHT__DELETE_BOOKING: "BOOKING_RIGHT__DELETE_BOOKING",
    TECHNICIAN_RIGHT__ACCESS: "TECHNICIAN_RIGHT__ACCESS",
    TECHNICIAN_RIGHT__UPDATE: "TECHNICIAN_RIGHT__UPDATE",
    TECHNICIAN_TIMESHEET_RIGHT__ACCESS: "TECHNICIAN_TIMESHEET_RIGHT__ACCESS",
    TECHNICIAN_TIMESHEET_RIGHT__UPDATE: "TECHNICIAN_TIMESHEET_RIGHT__UPDATE",
    REPORT_RIGHT__EXPORT: "REPORT_RIGHT__EXPORT",
    REPORT_RIGHT__ACCESS: "REPORT_RIGHT__ACCESS",
    MAP_USER_BRANCH_RIGHT__ACCESS: "MAP_USER_BRANCH_RIGHT__ACCESS",
    MAP_USER_BRANCH_RIGHT___UPDATE: "MAP_USER_BRANCH_RIGHT___UPDATE",
    SMS_MARKETING_ACCESS: "SMS_MARKETING_ACCESS"
};

export default rightUtils;
