import Alert from "@idtek/component/lib/alert/Alert";
import Container from "@idtek/component/lib/container/Container";
import FullBrowser from "@idtek/component/lib/fullScreen/FullBrowser";
import Icon from "@idtek/component/lib/icon/Icon";
import Modal from "@idtek/component/lib/modal/Modal";
import Grid from "@idtek/grid/lib/grid/Grid";
import TreeGrid from "@idtek/grid/lib/treegrid/TreeGrid";
import _ from "lodash";
import React, { Component } from "react";
import AppUtil from "../../utils/AppUtil";
import cipherUtils from "../../utils/cipherUtils";
import BaseForm from "../baseForm/BaseForm";
import ContentView from "../component/ContentView";
import Overlay from "../component/Overlay";
import moment from "moment";
import rightUtils from "../../utils/rightUtils";
import BasePermisstion from "./BasePermisstion";
class BaseListView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.isComponent = props.isComponent ? props.isComponent : false;
    }
    apiDelete = "";
    apiUpdate = "";
    apiCreate = "";
    apiDetail = "";
    apiExport = "";
    keyID = "id";
    // default right action
    module = "";
    ///right
    createRight = undefined;
    updateRight = undefined;
    deleteRight = undefined;
    rightExport = "";

    //action
    hasUpdate = true;
    hasDelete = true;
    hasDetail = true;
    hasCreate = true;

    rightAccess = "";
    columnDefs = [];
    pageSize = 15;
    functionName = AppUtil.getMenuName() ? AppUtil.getMenuName() : "";
    contentHeight = window.innerHeight - 95;
    // default form
    moduleName = "";
    titleCreate = "CREATE ";
    titleUpdate = "UPDATE ";
    titleDetail = "DETAIL ";
    model = { fields: {} };
    buttons = [
        {
            text: "Save",
            className: "btn-success",
            type: "submit",
            icon: <Icon type="save" style={{ fontSize: 16 }} />,
            onPress: (form, values) => this.onSave(form, values)
        },
        {
            text: "Close",
            className: "btn-danger",
            icon: <Icon type="close-square" style={{ fontSize: 16 }} />,
            onPress: () => this.Modal.onClose()
        }
    ];
    // default Grid
    levelTree = {
        levelMax: 2,
        levelFormat: ["I", "1"]
    };
    suppressColumnVirtualisation = false;
    groupDefaultExpanded = false;
    groupUseEntireRow = false;
    autoHeightRow = false;
    cellRenderer = {};
    defaultRenderGrid = true;
    defaultNumberRows = {
        headerName: "No"
    };
    defaultColDef = {};
    popUpWidth = "850px";
    headerHeight = 32;
    textCenterRow = "";
    rowSelection = "multiple";
    floatingFilter = true;
    globalFilter = true;
    gridProps = {};
    renderTitle = "";
    className = null;
    isRowDoubleClick = false;
    formDefaults = {
        alignLabel: "left",
        widthLabel: "100px"
    };
    titleActionRow = " ";
    BaseForm = BaseForm;
    heightGrid = window.innerHeight - 100;
    minHeightGrid = "";
    rowHeight = "28px";
    widthActionRow = 150;
    overrideTopLeftGrid = false;
    checkboxSelection = false;
    headerCheckboxSelection = false;
    suppressRowClickSelection = false;
    animateRows = false;
    rowDragManaged = false;
    ///check right
    componentWillMount() {
        const isMobile = this.checkIsMobile();
        if (isMobile) {
            this.popUpWidth = "100%";
        }
    }
    checkIsMobile() {
        return (
            navigator.userAgent.match(/Android/i) !== null ||
            navigator.userAgent.match(/webOS/i) !== null ||
            navigator.userAgent.match(/iPhone/i) !== null ||
            navigator.userAgent.match(/iPad/i) !== null ||
            navigator.userAgent.match(/iPod/i) !== null ||
            navigator.userAgent.match(/BlackBerry/i) !== null ||
            navigator.userAgent.match(/Windows Phone/i) !== null
        );
    }
    checkCreateRight() {
        return !this.createRight || rightUtils.checkRight(this.createRight);
    }

    checkUpdateRight() {
        return !this.updateRight || rightUtils.checkRight(this.updateRight);
    }

    checkDeleteRight() {
        return !this.deleteRight || rightUtils.checkRight(this.deleteRight);
    }
    // event api

    reLoad = () => {
        this.myGrid && this.store.reload();
    };

    round(date, duration, method) {
        return moment(Math[method](+date / +duration) * +duration);
    }

    encodeParameters(params) {
        return params ? cipherUtils.encodeUrl(JSON.stringify(params)) : cipherUtils.encodeUrl(JSON.stringify({}));
    }

    decodeParameters(cipherText) {
        return cipherText ? JSON.parse(cipherUtils.decodeUrl(cipherText)) : {};
    }

    onShow = async (id) => {
        this.refBasseForm && this.refBasseForm.onMask();
        const res = await AppUtil.getApi(`${this.apiDetail}/${id}`, {});
        if (_.get(res, "data.success")) {
            const values = await this.beforeSetValues(_.get(res.data, "results"));
            this.refBasseForm && this.refBasseForm.pageSetValueForm(values);
            this.afterSetValues(values);
        }
        this.refBasseForm && this.refBasseForm.onUnMask();
    };

    onLoadInitForm = async () => { };

    onSave = async (form, values) => {
        form && form.mask();
        this.beforeSave(form, values);
        //Format Ngày
        // await CommonUtil.formatDateTime(values, this.refBasseForm.templateForm);
        //Lưu file
        //await CommonUtil.getSingleFile(values, this.refBasseForm.attachField);
        const res = await AppUtil.postApi(values[this.keyID] ? this.apiUpdate + "/" + values[this.keyID] : this.apiCreate, values);
        this.afterSave(form, res);
        form && form.unmask();
        if (res) {
            if ((res.data && res.data.status === false) || !_.get(res.data, "success")) {
                AppUtil.ToastApiError();
            } else {
                AppUtil.ToastSuccess();
                this.Modal.onClose();
                this.reLoad();
            }
        } else {
            AppUtil.ToastApiError();
        }
    };

    beforeSave(form, values) {
        return values;
    }

    afterSave(form, res) {
        const data = form && form.getValues();
        if (_.get(data, "id")) {
            // const log = LogUtil.createActionLog(this.entityType, "Cập nhật", _.get(res, "results.data[this.keyID]"), this.functionName || this.titleUpdate);
            // LogUtil.writeActionLog(log);
        } else {
            // const log = LogUtil.createActionLog(this.entityType, "Tạo mới", _.get(res, "results.data[this.keyID]"), this.functionName || this.titleCreate);
            // LogUtil.writeActionLog(log);
        }
    }

    beforeSetValues = async (values) => {
        return values;
    };

    afterSetValues = (values) => { };

    // event click
    onCreate = () => {
        this.formDefaults["readOnly"] = false;
        const BaseForm = this.BaseForm;
        this.Modal.onOpen(
            <BaseForm
                ref={(refBasseForm) => (this.refBasseForm = refBasseForm)}
                initialValues={this.initialValues ? this.initialValues : {}}
                model={this.model}
                onDidMount={this.onLoadInitForm.bind(this)}
                formDefaults={this.formDefaults}
                buttons={this.buttons}
                renderBodyForm={this.renderBodyForm()}
            />,
            <span>{this.titleCreate + this.moduleName}</span>,
            this.popUpWidth
        );
        this.refContainer && this.refContainer.unmask();
    };

    onEditRow = async (row) => {
        this.formDefaults["readOnly"] = false;
        const BaseForm = this.BaseForm;
        this.Modal.onOpen(
            <BaseForm
                ref={(refBasseForm) => (this.refBasseForm = refBasseForm)}
                initialValues={this.initialValues ? this.initialValues : {}}
                model={this.model}
                onDidMount={this.onShow.bind(this, row[this.keyID])}
                formDefaults={this.formDefaults}
                buttons={this.buttons}
                renderBodyForm={this.renderBodyForm(row, false)}
            />,
            <span>{this.titleUpdate + this.moduleName}</span>,
            this.popUpWidth
        );
        this.refContainer && this.refContainer.unmask();
    };
    onExport = () => { };
    onDetailRow = async (row) => {
        this.formDefaults["readOnly"] = true;
        let newModel = _.cloneDeep(this.model);
        _.mapKeys(newModel.fields, (value, key) => {
            if (key) {
                value.visibleIcon = false;
                value.placeholder = " ";
            }
        });

        const BaseForm = this.BaseForm;
        this.Modal.onOpen(
            <BaseForm
                ref={(refBasseForm) => (this.refBasseForm = refBasseForm)}
                initialValues={this.initialValues ? this.initialValues : !this.apiDetail ? row : {}}
                onDidMount={this.onShow.bind(this, row[this.keyID])}
                formDefaults={this.formDefaults}
                model={newModel}
                buttons={[
                    {
                        text: "Close",
                        className: "btn-danger",
                        icon: <Icon type="close-square" style={{ fontSize: 16 }} />,
                        onPress: this.onClose
                    }
                ]}
                renderBodyForm={this.renderBodyForm(row, true)}
            />,
            <span>{this.titleDetail + this.moduleName}</span>,
            this.popUpWidth
        );
        this.refContainer && this.refContainer.unmask();
    };

    onDeleteRow = async (row) => {
        let confirm = await Alert.Swal_confirm("Confirm", "Are you sure you want to delete this record?", 3);
        if (_.get(confirm, "value") === true) {
            const res = await AppUtil.deleteApi(`${this.apiDelete}/${row[this.keyID]}`);
            if (res && _.get(res.data, "success")) {
                // const log = LogUtil.createActionLog(this.entityType, "Xóa dữ liệu", _.get(res, "results.data[this.keyID]"), this.functionName || "Xóa dữ liệu");
                // LogUtil.writeActionLog(log);
                AppUtil.ToastDeleteSuccess();
                this.reLoad();
            } else {
                AppUtil.ToastDeleteError(_.get(res.data, "messages") || _.get(res.data, "message") || "Data error, please try again!");
            }
        }
    };

    onClose = () => {
        this.Modal.onClose();
    };
    onCloseDefault = () => { };

    onRowDoubleClicked = (value) => {
        this.onDetailRow(value);
    };

    onGlobalFilter = () => { };

    //render

    renderBodyForm(rowData, isDetail) { }

    renderLeftActionBar = () => {
        return <div className={"title-grid"}>{_.toUpper(this.renderTitle)}</div>;
    };

    renderActionBar() {
        const buttons = [];
        if (this.checkUpdateRight() && this.hasCreate) {
            buttons.push({
                className: "create-btn",
                tooltip: "Create " + this.moduleName,
                icon: <Icon type="plus" />,
                onPress: this.onCreate
            });
        }
        if (this.apiExport && this.rightExport) {
            buttons.push({
                className: "export-btn",
                tooltip: "Export " + this.moduleName,
                icon: <Icon type="file-excel" />,
                onPress: this.onExport
            });
        }
        buttons.push(
            {
                type: "refresh",
                tooltip: "Refresh",
                onPress: this.reLoad
            },
            {
                type: "fullScreen",
                tooltip: "Full screen",
                onPress: () => this.FullBrowser.toogleFullScreen()
            }
        );
        return buttons;
    }

    actionRows = () => {
        return {
            pinned: "right",
            width: this.widthActionRow,
            title: this.titleActionRow,
            items: this.renderActions
        };
    };

    renderActions = () => {
        // button disable them className: "button-action-row-disabled"
        const buttons = [];
        buttons.push({
            type: "detail",
            onPress: this.onDetailRow,
            icon: {
                type: "info-circle",
                style: { background: "#5e72e4" },
                color: "#ffffff"
            },
            tooltip: "Detail"
        });
        if (this.checkUpdateRight() && this.hasUpdate) {
            buttons.push({
                type: "edit",
                onPress: this.onEditRow,
                icon: {
                    type: "edit",
                    style: { background: "#2dce89" },
                    color: "#ffffff"
                },
                tooltip: "Update"
            });
        }
        if (this.checkUpdateRight() && this.hasDelete) {
            buttons.push({
                type: "delete",
                onPress: this.onDeleteRow,
                icon: {
                    type: "delete",
                    style: { background: "var(--main-color-red)" },
                    color: "#ffffff"
                },
                tooltip: "Delete"
            });
        }
        return buttons;
    };

    renderTop() { }

    renderCustom = () => { };
    onGridReady() { }

    onSelectionChanged = () => { };

    onRowDataChanged = (val) => { };

    onRowDragEnd = (val) => { };

    renderGrid() {
        return (
            <Grid
                height={this.heightGrid}
                minHeight={this.minHeightGrid ? this.minHeightGrid : ""}
                numberRows={this.defaultNumberRows}
                ref={(myGrid) => (this.myGrid = myGrid)}
                rowSelection={this.rowSelection}
                checkboxSelection={this.checkboxSelection}
                headerCheckboxSelection={this.headerCheckboxSelection}
                pagination={this.pagination}
                rowHeight={this.rowHeight}
                className={`base-grid ${this.textCenterRow} `}
                clsToolbar="base-grid-toolbar"
                store={this.store}
                globalFilter={this.globalFilter}
                floatingFilter={this.floatingFilter}
                columnDefs={this.columnDefs}
                onSelected={this.onSelectedRow}
                groupDefaultExpanded={this.groupDefaultExpanded}
                groupUseEntireRow={this.groupUseEntireRow}
                renderLeftActionBar={this.overrideTopLeftGrid ? this.renderLeftActionBar : false}
                actionToolbar={this.renderActionBar()}
                onRowDoubleClicked={this.isRowDoubleClick ? this.onRowDoubleClicked : false}
                autoHeightRow={this.autoHeightRow}
                headerVerticalLines={true}
                headerHorizotalLines={true}
                suppressColumnVirtualisation={this.suppressColumnVirtualisation}
                headerHeight={this.headerHeight}
                defaultColDef= {Object.assign(this.defaultColDef, {resizable:true})}
                onRowDataChanged={this.onRowDataChanged}
                animateRows={this.animateRows}
                rowDragManaged={this.rowDragManaged}
                gridOptions={{
                    rowClassRules: this.rowClassRules,
                    onRowDragEnd: this.onRowDragEnd,
                    excelStyles: AppUtil.excelStylesv2()
                }}
                actionRows={this.actionRows()}
                cellRenderer={this.cellRenderer}
                onGridReady={() => {
                    this.onGridReady();
                }}
                onSelectionChanged={this.onSelectionChanged}
                {...this.gridProps}
            />
        );
    }

    renderTreeGrid() {
        return (
            <TreeGrid
                height={this.heightGrid}
                numberRows={this.defaultNumberRows}
                ref={(myGrid) => (this.myGrid = myGrid)}
                levelTree={this.levelTree}
                rowSelection={this.rowSelection}
                checkboxSelection={this.checkboxSelection}
                headerCheckboxSelection={this.headerCheckboxSelection}
                className={`base-tree-grid ${this.textCenterRow} base-data-grid`}
                clsToolbar="base-grid-toolbar"
                store={this.store}
                globalFilter={this.globalFilter}
                onGlobalFilter={_.debounce(this.onGlobalFilter, 300)}
                floatingFilter={this.floatingFilter}
                columnDefs={this.columnDefs}
                onSelected={this.onSelectedRow}
                groupDefaultExpanded={this.groupDefaultExpanded}
                groupUseEntireRow={this.groupUseEntireRow}
                onRowDoubleClicked={this.isRowDoubleClick ? this.onRowDoubleClicked : false}
                renderLeftActionBar={this.overrideTopLeftGrid ? this.renderLeftActionBar : false}
                actionToolbar={this.renderActionBar()}
                autoHeightRow={this.autoHeightRow}
                headerVerticalLines={false}
                headerHorizotalLines={false}
                headerHeight={this.headerHeight}
                defaultColDef= {Object.assign(this.defaultColDef, {resizable:true})}
                rowClassRules={this.rowClassRules}
                actionRows={this.actionRows()}
                cellRenderer={this.cellRenderer}
                onSelectionChanged={this.onSelectionChanged}
                suppressRowClickSelection={this.suppressRowClickSelection}
                lineType={"dashed"}
                treeLine={true}
                {...this.gridProps}
            />
        );
    }

    renderBottom() { }

    renderModal() {
        return (
            <Modal
                draggable
                ref={(component) => {
                    this.Modal = component;
                }}
                onClose={this.onCloseDefault}
                maskClosable={false}
                className="popup-crud popup-container"
            />
        );
    }

    renderOverLay() {
        return (
            <Overlay
                ref={(component) => (this.refOverlay = component)}
                style={{
                    padding: 15,
                    borderRadius: 3,
                    zIndex: 9
                }}
            />
        );
    }

    render() {
        const contentHeight = this.contentHeight;
        return (
            <BasePermisstion rightAccess={this.rightAccess}>
                <FullBrowser ref={(component) => (this.FullBrowser = component)}>
                    <Container ref={(c) => (this.refContainer = c)}>
                        {this.isComponent ? (
                            <div className={this.className}>
                                <div style={{ display: "flex", flexDirection: "column", flex: 1, height: contentHeight }}>
                                    {this.renderTop()}
                                    <div style={{ flex: 1, display: "flex" }}>{this.defaultRenderGrid ? this.renderGrid() : this.renderTreeGrid()}</div>
                                    {this.renderBottom()}
                                </div>
                                {this.renderModal()}
                                {this.renderOverLay()}
                            </div>
                        ) : (
                                <ContentView>
                                    <div className={this.className}>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1, height: contentHeight }}>
                                            {this.renderTop()}
                                            <div style={{ flex: 1 }}>
                                                {this.defaultRenderGrid ? this.renderGrid() : this.renderTreeGrid()}
                                                {this.renderCustom()}
                                            </div>
                                            {this.renderBottom()}
                                        </div>
                                        {this.renderModal()}
                                        {this.renderOverLay()}
                                    </div>
                                </ContentView>
                            )}
                    </Container>
                </FullBrowser>
            </BasePermisstion>
        );
    }
}

export default BaseListView;
