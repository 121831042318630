import DateRange from "@idtek/component/lib/datetime/DateRange";
import { Empty } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import AppUtil from "../../../utils/AppUtil";
import "../style/pos-actions-detail.scss";
import ItemPosAction from "./ItemPosAction";
import IDDatePicker from "@idtek/component/lib/datetime/IDDatePicker";
import { Tag } from "antd";
import ItemPosBookingAction from "./ItemPosBookingAction";
import Overlay from "../../../common/component/Overlay";
import { RECEIVE_MONEY, TAKE_MONEY } from "../constant/BookingConstant";
import CountUp from "react-countup";
import ItemPosGiftAction from "./ItemPosGiftAction";

const { CheckableTag } = Tag;
export default class PosActionsDetail extends Component {
    constructor(props) {
        super(props);
        this.fromDate = moment().format('YYYY-MM-DD');
        // this.toDate = moment();
        this.state = {
            data: [],
            optionFilter: [{ id: "all", name: "ALL", code: "all" }],
            selectedTags: ["all"],
            groupBooking: []
        };
        this.selectedTags = ["all"];
    }

    componentDidMount() {
        this.loadCombox();
        this.loadData();
    }

    componentWillMount() {
        this.rawBookingCode = null;
    }
    async loadData() {
        this.refOverlay && this.refOverlay.open(<span>Please waiting...</span>);
        const { branchId } = this.props;
        const { selectedTags } = this;
        const { optionFilter } = this.state;
        const params = {
            branchId
        };
        if (this.fromDate) {
            params.fromDate = this.fromDate;
        }
        if (_.size(selectedTags) > 0 && _.indexOf(selectedTags, "all") < 0) {
            const typeArr = _.filter(optionFilter, (opt) => _.indexOf(selectedTags, opt.id) >= 0);
            const typeCodes = _.map(typeArr, (x) => x.code);
            params.type = typeCodes;
        }
        const res = await AppUtil.postApi("/pos-action/detail-by-date", params);
        if (_.get(res, "data.success")) {
            const data = _.get(res, "data.results");
            const dataBooking = _.filter(data, (x) => x.booking);
            const dataGiftCard = _.filter(data, (x) => x.giftCard);
            const groupBooking = _.groupBy(dataBooking, (x) => x.booking.code);
            const groupGift = _.groupBy(dataGiftCard, (x) => x.giftCard.code);
            this.setState({ data, groupBooking, groupGift });
            this.refOverlay && this.refOverlay.close();
        } else {
            AppUtil.ToastApiError();
            this.refOverlay && this.refOverlay.close();
        }
    }

    async loadCombox() {
        const { optionFilter } = this.state;
        const res = await AppUtil.getApi("/global-setting/checkout-payment-method");
        if (_.get(res, "data.success")) {
            const data = _.get(res, "data.results.methods");
            let options = optionFilter;
            if (data) {
                options = _.concat(optionFilter, data);
            }
            this.setState({
                optionFilter: options
            });
        }
    }

    async onChangeDate(date) {
        if (date === "today") {
            this.fromDate = moment().format("YYYY-MM-DD");
        } else {
            this.fromDate = date.format("YYYY-MM-DD");
        }
        this.loadData();
    }

    renderListActions() {
        const { data, groupBooking, groupGift } = this.state;
        let rawBookingCode = null;
        let rawGiftCode = null;
        if (_.size(data) > 0) {
            return _.map(data, (item, index) => {
                const { booking, giftCard } = item;
                const ref = `pos_${item.id}`;
                if (booking) {
                    if (rawBookingCode !== booking.code) {
                        rawBookingCode = booking.code;
                        const arrData = _.find(groupBooking, (item, key) => key === booking.code);
                        return <ItemPosBookingAction ref={(c) => (this[ref] = c)} data={arrData} />;
                    }
                } else if (giftCard) {
                    if (rawGiftCode !== giftCard.code) {
                        rawGiftCode = giftCard.code;
                        const arrData = _.find(groupGift, (item, key) => key === giftCard.code);
                        return <ItemPosGiftAction ref={(c) => (this[ref] = c)} data={arrData} />;
                    }
                } else {
                    return <ItemPosAction ref={(c) => (this[ref] = c)} item={item} />;
                }
            });
        } else {
            return (
                <div className="global-flex-justify-align-center">
                    <Empty />
                </div>
            );
        }
    }

    handleChange(tag, checked) {
        const { selectedTags } = this.state;
        const selectedTagsNotAll = _.filter(selectedTags, (x) => x !== "all");
        let nextSelectedTags = [];
        if (tag !== "all") {
            nextSelectedTags = checked ? [...selectedTagsNotAll, tag] : selectedTagsNotAll.filter((t) => t !== tag);
        } else {
            nextSelectedTags = checked ? [tag] : selectedTags.filter((t) => t !== tag);
        }
        if (_.isEmpty(nextSelectedTags)) {
            nextSelectedTags = ["all"];
        }
        this.setState({ selectedTags: nextSelectedTags });
        this.selectedTags = nextSelectedTags;
        this.loadData();
    }

    renderTag() {
        const { optionFilter, selectedTags } = this.state;
        return _.map(optionFilter, (tag) => {
            return (
                <CheckableTag key={tag.id} checked={selectedTags.indexOf(tag.id) > -1} onChange={(checked) => this.handleChange(tag.id, checked)} color={"#026eaa"}>
                    {tag.code}
                </CheckableTag>
            );
        });
    }
    renderInfoReport() {
        const { data } = this.state;
        const actionRecieved = _.filter(data, (x) => x.posType?.name === RECEIVE_MONEY && x.status === 1);
        const actionTake = _.filter(data, (x) => x.posType?.name === TAKE_MONEY && x.status === 1);
        const sumRecieved = _.sumBy(actionRecieved, (x) => x.value);
        const sumTake = _.sumBy(actionTake, (x) => x.value);
        const total = sumRecieved - sumTake;
        return (
            <div className="report">
                <div className="title">Overview</div>
                <div className="report-money">
                    <div className="report-recieved">
                        <div className="value">
                            <span className="dollar">$</span>
                            <span className="icon">+</span>
                            <CountUp separator="," preserveValue={true} duration={0.5} decimals={2} end={sumRecieved} />
                        </div>
                        <div className="description">Amount received</div>
                    </div>
                    <div className="report-take">
                        <div className="value">
                            <span className="dollar">$</span>
                            <span className="icon">-</span>
                            <CountUp separator="," preserveValue={true} duration={0.5} decimals={2} end={sumTake} />
                        </div>
                        <div className="description">Amount back</div>
                    </div>
                </div>
                <div className="total-rest">
                    <div className="value">
                        <span className="dollar">$</span>
                        <CountUp separator="," preserveValue={true} duration={0.5} decimals={2} end={total} />
                    </div>
                    <div className="des-total">Remain balance</div>
                </div>
            </div>
        );
    }
    renderOverlay() {
        return (
            <Overlay
                ref={(component) => (this.refOverlay = component)}
                style={{
                    padding: 15,
                    borderRadius: 3,
                    zIndex: 9
                }}
            />
        );
    }
    render() {
        return (
            <div className="pos-actions-detail">
                <div className="filter">
                    <div className="filter-date">
                        <span className="date">Date</span>
                        <div className="today">
                            <button onClick={() => this.onChangeDate("today")} className="btn-date-filer today-date">
                                TODAY
                            </button>
                        </div>
                        <div className="date-range">
                            <IDDatePicker ref={(c) => (this.Date = c)} isClearable={false} format={AppUtil.GLOBAL_DATE_FORMAT} onChange={this.onChangeDate.bind(this)} value={this.fromDate} />
                        </div>
                    </div>
                    <div className="filter-action">
                        <span className="date action-label">Type</span>
                        <div className="tab-filter">{this.renderTag()}</div>
                    </div>
                </div>
                {this.renderInfoReport()}
                <div className="list-actions">
                    {/* <div className="header"></div> */}
                    <div className="actions">{this.renderListActions()}</div>
                </div>
                {this.renderOverlay()}
            </div>
        );
    }
}
