import Icon from "@idtek/component/lib/icon/Icon";
import ComboField from "@idtek/form/lib/combox/ComboField";
import ConditionField from "@idtek/form/lib/condition/ConditionField";
import IDField from "@idtek/form/lib/IDField";
import Form from "@idtek/form/lib/IDForm";
import RadioListField from "@idtek/form/lib/radiolist/RadioListField";
import TextField from "@idtek/form/lib/text/TextField";
import TextAreaField from "@idtek/form/lib/textarea/TextAreaField";
import _ from "lodash";
import moment from "moment";
import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {resetServiceStore} from "../../../application/actions/action";
import Loading from "../../../pages/Loading";
import AppStore from "../../../store";
import AppUtil from "../../../utils/AppUtil";
import GridBookingService from "../component/GridBookingService";
import ReactDatePickerCustom from "../component/ReactDatePickerCustom";
import SearchPhoneComponent from "../component/SearchPhoneComponent";
import "../style/style-update-booking.scss";
import BaseBookingCreate from "../view/BaseBookingCreate";
import Row from "@idtek/component/lib/container/Row";
import Cell from "@idtek/component/lib/container/Cell";

// có sử dụng
class FormBookingNew extends BaseBookingCreate {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            initialValues: {},
            loading: true
        };
        this.apiCreate = "/booking/save";
        this.inModal = true;
        this.type = props.type ? props.type : "update";
        this.maxDate = null;
        this.minDate = null;
    }

    componentDidMount() {
        this.loadFormDataUpdate();
    }

    componentWillUnmount() {
        if (!this.props.notResetStoreServices) {
            AppStore.dispatch(resetServiceStore());
        }
    }

    renderButtons() {
        const buttons = [];
        buttons.push(
            {
                text: "Save",
                type: "submit",
                className: "btn-success",
                icon: <Icon type="save" style={{fontSize: 16}}/>,
                onPress: (form, values) => this.onSave(form, values)
            },
            {
                text: "Confirm",
                type: "submit",
                className: "btn-transfer",
                icon: <Icon type="check-square" style={{fontSize: 16}}/>,
                onPress: (form, values) => this.onSave(form, values, true)
            },
            {
                text: "CANCEL",
                type: "submit",
                className: "btn-danger",
                icon: <Icon type="close-circle" style={{fontSize: 15}}/>,
                onPress: (form, values) => {
                    this.showConfirm("CANCEL", "Are you sure you want to cancel?", this.onSave.bind(this, form, values, false, true));
                }
            },
            {
                text: "Close",
                className: "btn-danger",
                icon: <Icon type="close-square" style={{fontSize: 15}}/>,
                onPress: () => {
                    this.props.onClose && this.props.onClose();
                }
            }
        );
        return buttons;
    }

    beforeSave(form, values, confirm, cancel) {
        const data = {};
        const {booking} = this.props;
        const {services, extra} = this.GridBooking && this.GridBooking.getData();
        const stringFormat = "YYYY-MM-DD HH:mm";
        const bookingItems = _.map(services, (item, index) => {
            return {
                service_id: item.service_id ? item.service_id : null,
                technician: item.technician ? item.technician : null,
                startTime: item.startTime && booking.startTime && moment(values.startTime).format(stringFormat) === moment(booking.startTime).format(stringFormat) ? item.startTime : null,
                endTime: item.endTime && booking.startTime && moment(values.startTime).format(stringFormat) === moment(booking.startTime).format(stringFormat) ? item.endTime : null
            };
        });
        const extraServices = _.map(extra, (item) => {
            return {
                ...item,
                startTime: values.startTime ? moment(values.startTime).format("YYYY-MM-DD") : null,
                price: item.price ? parseFloat(item.price) : 0
            };
        });
        data.email = values.email;
        data.bookingItems = bookingItems;
        data.branch = values.branch;
        data.customerName = values.customerName;
        data.description = values.description;
        data.extraServices = extraServices;
        data.paymentMethod = values.paymentMethod ? values.paymentMethod : null;
        if (confirm) {
            data.status = "Waiting";
        } else if (cancel) {
        } else {
            data.status = values.status;
        }
        if (typeof values.phone === "string" || values.phone instanceof String) {
            data.phone = values.phone;
        } else {
            data.phone = _.get(values.phone, "phone");
        }
        data.startTime = moment(values.startTime);
        data.sex = values.sex ? values.sex : "";
        data.typeBooking = values.typeBooking ? values.typeBooking : "TYPE_BOOKING_OFFLINE";
        return data;
    }

    onSave = async (form, values, confirm = false, cancel = false) => {
        let url = "/booking/save?id=" + values.id;
        if (cancel) {
            url = "/booking/cancel?id=" + values.id;
        }
        if (this.GridBooking && this.GridBooking.isValidBooking()) {
            let newValues = await this.beforeSave(form, values, confirm, cancel);
            if (this.checkValidValues(newValues)) {
                form && form.mask();
                this.GridBooking && this.GridBooking.gridLoading();
                const res = await AppUtil.postApi(url, newValues);
                this.afterSave(form, res);
                form && form.unmask();
                this.GridBooking && this.GridBooking.gridCloseLoading();
                if (res) {
                    if (res.data && res.data.success === false) {
                        this.checkMessage(_.get(res, "data.message"), newValues, "save");
                    } else {
                        values[this.keyID] ? AppUtil.ToastSaveSuccess() : AppUtil.ToastCreateSuccess();
                        this.props.onClose && this.props.onClose();
                        this.props.onReloadData && this.props.onReloadData(_.get(res, "data.entity"), confirm, cancel);
                    }
                } else {
                    AppUtil.ToastApiError();
                }
            }
        } else {
            //AppUtil.ToastError("Booking invalid!");
        }
        setTimeout(() => {
            form && form.unmask();
            this.GridBooking && this.GridBooking.gridCloseLoading();
        }, 200);
    };

    renderForm() {
        const {authData, storeList, optionsCustomer} = this.props;
        const {initialValues} = this.state;
        if (this.state.loading) return <Loading/>;
        return (
            <Form
                ref={(component) => (this.form = component)}
                initialValues={initialValues}
                model={this.model}
                className="base-form-modal create-booking"
                style={{padding: 15, display: "flex", flexDirection: "column"}}
                buttons={this.renderButtons()}
                onInputChange={(name, value) => this.onChangeField(name, value)}
                defaults={{
                    alignLabel: "left",
                    widthLabel: this.widthLabel ? this.widthLabel : 115,
                    isClearable: true,
                    inCondition: true,
                    labelKey: "name",
                    valueKey: "id"
                }}>
                <Row>
                    <Cell>
                        {this.checkRenderLoyaltyInfo()}
                    </Cell>
                </Row>
                <Row>
                    <Cell className={"phone-input"}>
                        <IDField name="phone" onChange={this.onChangePhone} options={optionsCustomer}>
                            <SearchPhoneComponent/>
                        </IDField>
                    </Cell>
                    <Cell>
                        <IDField
                            status={this.type !== "cloneHistory" ? _.get(initialValues, "status") : ""}
                            showTimeSelect={true}
                            name="startTime"
                            minDate={this.minDate}
                            maxDate={this.maxDate}
                            onChange={this.onChangeDate}>
                            <ReactDatePickerCustom/>
                        </IDField>
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <TextField name="customerName" upperCase={true}/>
                    </Cell>
                    <Cell>
                        <TextField name="email" type="email"/>
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <ComboField name="branch" options={_.get(authData, "user.branches")}/>
                    </Cell>
                    <Cell>
                        <ComboField name="technician" options={this.props.priorityTechnicians}
                                    onChange={(val) => this.onSelectMainTechnician(val)}/>
                    </Cell>
                </Row>
                <ConditionField
                    renderCondition={(val) => {
                        if (val) {
                            return false;
                        }
                        return true;
                    }}
                    when="technician">
                    <Row>
                        <Cell>
                            <RadioListField
                                name={"sex"}
                                viewMode={"horizontal"}
                                labelKey={"label"}
                                valueKey={"value"}
                                options={[
                                    {
                                        label: "Any",
                                        value: "ANY"
                                    },
                                    {
                                        label: "Male",
                                        value: "MALE"
                                    },
                                    {
                                        label: "Female",
                                        value: "FEMALE"
                                    }
                                ]}
                            />
                        </Cell>
                    </Row>
                </ConditionField>
                <Row>
                    <Cell>
                        <TextAreaField name="description"/>
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <GridBookingService
                            ref={(c) => (this.GridBooking = c)}
                            onSelectTechnician={this.onSelectTechnicianOnGrid.bind(this)}
                            selectServiceHandler={this.selectServiceHandler}
                            isMobile={this.isMobile}
                            {...this.props}
                        />
                    </Cell>
                </Row>
            </Form>
        );
    }

    render() {
        return (
            <div className={this.className}>
                <div style={{display: "flex", flexDirection: "column", flex: 1}}>
                    <div style={{
                        flex: 1,
                        minHeight: 400,
                        display: "flex",
                        alignItems: "center"
                    }}>{this.renderForm()}</div>
                    {this.renderModal()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.root.authData,
        storeList: state.root.storeList,
        selectedServiceStoreReordering: state.root.selectedServiceStoreReordering,
        extraServices: state.root.extraServices,
        servicesStore: state.root.storeList.servicesStore,
        priorityTechnicians: state.root.storeList.priorityTechnicians,
        subTotal: state.root.subTotal,
        totalTime: state.root.totalTime,
        totalAmount: state.root.totalAmount,
        booking: state.root.booking,
        notResetStoreServices: state.root.notResetStoreServices,
        optionsCustomer: state.root.optionsCustomer,
        totalGST: state.root.totalGST,
        technician: state.root.technician,
        ...state.root
    };
};
export default withRouter(connect(mapStateToProps, {}, null, {forwardRef: true})(FormBookingNew));
