import Alert from '@idtek/component/lib/alert/Alert';
import Button from "@idtek/component/lib/button/IDButton";
import CheckboxField from "@idtek/form/lib/checkbox/CheckboxField";
import Form from "@idtek/form/lib/IDForm";
import PasswordField from "@idtek/form/lib/password/PasswordField";
import TextField from "@idtek/form/lib/text/TextField";
import _ from "lodash";
import React, { Component } from 'react';
import { loginSuccessFull, setGst, setHeaderMenu } from './application/actions/action';
import { loadMenu, onChangeSelects } from './application/actions/menuAction';
import HeaderMenuConfig from './application/components/HeaderMenuConfig.json';
import { MenuConfig } from './application/components/MenuConfig';
import './login-page.scss';
import Loading from "./pages/Loading";
import AppStore from './store';
import AppUtil from './utils/AppUtil';

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: {
                username: '',
                password: '',
                remember: true
            },
            viewPassword: false,
            loading: true
        };
        this.model = {
            fields: {
                username: {
                    label: "Username",
                },
                password: {
                    label: "Password",
                },
                remember: {},
            }
        };
        this.onKeyPress = this.onKeyPressFunc.bind(this);
        document.addEventListener("keypress", this.onKeyPress);

    }

    componentDidMount() {
        this.checkLogin();
    }

    checkLogin = async () => {
        await AppUtil.Axios.all([
            AppUtil.getApi('/check-login'),
        ])
            .then(([resAuthData]) => {
                if (_.get(resAuthData, "data.success")) {
                    const { data } = _.get(resAuthData, "data");
                    AppStore.dispatch(loginSuccessFull(data, MenuConfig));
                    AppStore.dispatch(setHeaderMenu(HeaderMenuConfig));
                    AppStore.dispatch(setGst(_.get(data, 'gst')))
                    AppStore.dispatch(onChangeSelects([window.location.pathname]));
                }
            })
            .catch(err => {
                console.log(err);
            });
        this.setState({
            loading: false
        });
    };

    checkIsMobile() {
        return navigator.userAgent.match(/Android/i) !== null
            || navigator.userAgent.match(/webOS/i) !== null
            || navigator.userAgent.match(/iPhone/i) !== null
            || navigator.userAgent.match(/iPad/i) !== null
            || navigator.userAgent.match(/iPod/i) !== null
            || navigator.userAgent.match(/BlackBerry/i) !== null
            || navigator.userAgent.match(/Windows Phone/i) !== null;

    }

    onKeyPressFunc = (e) => {
        const me = this;
        if (e.key === "Enter") {
            // me.onLogin();
        }
    };

    onLogin = (event) => {
        const dataForm = this.form && this.form.getValues();
        if (dataForm.username && dataForm.password) {
            this.setState({
                loading: true
            });
            AppUtil.postApi("/login", dataForm)
                .then((response) => {
                    const { data } = response;
                    if (!data.success) {
                        Alert.Toast_info("Notification", "Login fail!", 0, {
                            position: 'topRight'
                        });
                    }
                })
                .catch(() => {
                    Alert.Toast_info("Notification", "Login fail!", 0, {
                        position: 'topRight'
                    });
                })
                .finally(() => {
                    setTimeout(() => {
                        this.checkLogin();
                    }, 500);
                });
        } else {
            this.form && this.form.setErrors();
            AppUtil.ToastError("Wrong username or password, please try again!")
        }
    };

    render() {
        const paddingFormLogin = window.innerHeight > 670;
        if (this.state.loading) return <div style={{ flex: 1, height: "100%" }}><Loading /></div>;
        return (
            <div
                className={this.checkIsMobile() ? "login-page-custom login-page-mobile" : "login-page-custom login-page-desktop"}>
                <div style={{ height: 80 }} className="header-login-page">
                    <div style={{ flex: 1 }}>
                        <div className="login-logo" />
                    </div>
                    <div style={{ width: 200 }}>
                        <div className="phone-number">
                            <i className="fa fa-phone" />
                            <a className="number" href="tel:+842862885088">+1-647-948-8678</a>
                        </div>
                    </div>
                </div>
                <div style={{ flex: 1 }} className="div-login-page">
                    <div style={{ flex: 1 }} className="login-background">
                        <h3 className="project-name">
                            MANAGE YOUR SALON EASIER
                        </h3>
                        <p className="project-description">
                            Manage your <span style={{ color: 'rgb(255, 194, 80)' }}>schedule</span> • <span
                                style={{ color: 'rgb(255, 194, 80)' }}>staff</span> • <span
                                    style={{ color: 'rgb(255, 194, 80)' }}>customer</span> from a single interface with our salon
                            reservation system. <a className="view-more"
                                href="http://idtek.com.vn/en/product/product/nail-crm"
                                target="_blank">View more</a>
                        </p>
                        <div style={{ flex: 1 }} className="login-background-image">
                        </div>
                    </div>
                    <div
                        style={{
                            width: "35%",
                            minWidth: '300px',
                            paddingBottom: paddingFormLogin ? '15%' : '0px',
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <div
                            style={{ padding: "10 0 25px 0" }}
                            className="box-login"
                        >
                            <div className={"pack-start"}>
                                <div className="login-title">LOGIN</div>
                            </div>
                            <Form
                                ref={form => this.form = form}
                                className="login-form"
                                model={this.model}
                                style={{ padding: "0 30px" }}
                                initialValues={{
                                    remember: true
                                }}
                            >
                                <TextField
                                    placeholder={"   "}
                                    required
                                    requiredMessage="Please enter Username"
                                    name="username"
                                    className="input-mobile"
                                    errorTarget="under"
                                />
                                <PasswordField
                                    placeholder={"  "}
                                    className="input-mobile"
                                    required
                                    requiredMessage="Please enter password!"
                                    name="password"
                                    revealable
                                    errorTarget="under"
                                />
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <CheckboxField name="remember" widthLabel={0} />
                                    <div style={{ marginLeft: 10 }}>Remember me</div>
                                    <Button style={{
                                        marginLeft: 30,
                                        backgroundColor: "#025b80",
                                        borderColor: "#025b80",
                                        color: "#ffffff"
                                    }}
                                        onClick={this.onLogin}
                                    >
                                        <i className="fa fa-sign-in" aria-hidden="true" />
                                        <span style={{ marginLeft: 5, fontSize: 14 }}>LOGIN</span>
                                    </Button>
                                </div>
                            </Form>
                            <div className="footer">Copyright © 2021 - <a
                                href="http://idtek.com.vn/en/?utm_source=IDBeauty&amp;utm_medium=Footer"
                                target="_blank">IDTEK JSC</a></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginPage;