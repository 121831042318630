import BaseStore from "@idtek/grid/lib/stores/BaseStore";
import _ from "lodash";
import React from "react";
import BaseListView from "../../../common/baseView/BaseListView";
import "../style/grid-detail-booking.scss";
export default class GridDetailBooking extends BaseListView {
    constructor(props) {
        super(props);
        this.state = {};
        this.store = new BaseStore({
            data: props.data ? props.data : [],
            pageSize: this.pageSize
        });
        this.defaultColDef = { sortable: false, filter: false };
        this.pagination = false;
        this.globalFilter = false;
        this.floatingFilter = false;
        if (props.data) {
            this.columnDefs = [
                {
                    headerName: "Booking code",
                    field: "booking_code",
                    width: 120
                },
                {
                    headerName: "Services",
                    field: "serviceName",
                    minWidth: 300,
                    colSpan: function (params) {
                        if (_.get(params, "data.field") === "total") {
                            return 2;
                        }
                        return 1;
                    }
                },
                {
                    headerName: "Price",
                    field: "price",
                    width: 120
                },
                {
                    headerName: "Time",
                    field: "time",
                    width: 120
                }
            ];
        } else {
            this.columnDefs = [
                {
                    headerName: "Name",
                    field: "name",
                    minWidth: 300,
                    colSpan: function (params) {
                        if (_.get(params, "data.field") === "total") {
                            return 2;
                        }
                        return 1;
                    }
                },
                {
                    headerName: "Technician",
                    field: "technician_name",
                    width: 120
                },
                {
                    headerName: "Price",
                    field: "price",
                    width: 120
                },
                {
                    headerName: "Time",
                    field: "time",
                    width: 120
                }
            ];
        }
        this.cellRenderer = {
            name: {
                renderer: (data, value) => {
                    const classText = data.field === "total" ? "global-flex-align-center-justify-end" : "global-flex-align-center";
                    return <div className={`name-field ${classText}`}>{value ? value : _.get(data, "service.name")}</div>;
                }
            },
            serviceName: {
                renderer: (data, value) => {
                    return <div className="global-flex-align-center name-field">{value ? value : _.get(data, "service.name")}</div>;
                }
            }
        };
        this.minHeightGrid = 200;
        this.heightGrid = "200px";
    }

    renderActionBar() {}

    actionRows = () => {
        return {};
    };

    onGridReady() {
        if (this.props.data) {
            const { data } = this.props;
            let totalPrice = 0;
            let totalTime = 0;
            _.each(data, (item) => {
                totalPrice += item.price;
                totalTime += item.time;
            });
            const totalData = [
                {
                    serviceName: "Total",
                    technician_name: "",
                    price: "$ " + totalPrice,
                    time: totalTime + " mins",
                    stt: " "
                }
            ];
            this.myGrid && this.myGrid.gridApi.setPinnedBottomRowData(totalData);
        }
    }

    setDataGird(data) {
        this.myGrid && this.myGrid.store.setData(data.bookingItems);
        let totalGiftCard =0;
        const totalData = [
            {
                name: "Subtotal",
                technicianName: "",
                price: "$ " + _.sumBy(data.bookingItems,'price'),
                time: "",
                stt: " ",
                field: "total"
            },
            {
                name: "Discount",
                technicianName: "",
                price: "$ " + _.get(data, "paymentFee"),
                time: "",
                stt: " ",
                field: "total"
            },
            {
                name: `HST ${_.get(data, "vat", 0)}%`,
                technicianName: "",
                price: "$ " + data.totalFee,
                time: "",
                stt: " ",
                field: "total"
            },

        ];

        totalData.push(
            {
                name: "Total",
                technician_name: "",
                price: "$ " +data.totalAmount ,
                time: data.totalTime + " mins",
                stt: " ",
                field: "total"
            }
        )
        _.get(data,"giftCardActions",[]).map(giftCard =>{
            totalGiftCard = totalGiftCard + _.get(giftCard, "value", 0);
            totalData.push(
                {
                    name:`Pay gift card (${_.get(giftCard, "code", "")})`,
                    technician_name: "",
                    price: "$ -" + _.get(giftCard, "value", 0),
                    time: "",
                    stt: " ",
                    field: "total"
                }
            )
        })
        this.myGrid && this.myGrid.gridApi.setPinnedBottomRowData(totalData);
    }

    render() {
        return <div className="grid-detail-booking">{this.renderGrid()}</div>;
    }
}
