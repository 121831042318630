import Alert from "@idtek/component/lib/alert/Alert";
import Combobox from "@idtek/component/lib/combobox/Combobox";
import Icon from "@idtek/component/lib/icon/Icon";
import Textbox from "@idtek/component/lib/textbox/Textbox";
import DropdownList from "@idtek/grid/lib/common/components/dropdown/DropdownList";
import BaseStore from "@idtek/grid/lib/stores/BaseStore";
import _ from "lodash";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { deselectService, updateExtraService, updateSelectedServices } from "../../../application/actions/action";
import BaseListView from "../../../common/baseView/BaseListView";
import AppStore from "../../../store";
import AppUtil from "../../../utils/AppUtil";
import TreeGridSelectService from "./TreeGridSelectService";

class GridBookingService extends BaseListView {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            technicianId: props.technicianId
        };
        this.store = new BaseStore({
            data: props.selectedServiceStoreReordering ? props.selectedServiceStoreReordering : [],
            pageSize: this.pageSize
        });
        this.pagination = false;
        this.globalFilter = false;
        this.floatingFilter = false;
        this.heightGrid = "auto";
        this.overrideTopLeftGrid = true;
        this.minHeightGrid = "250px";
        this.defaultNumberRows = {
            headerName: "No",
            width: 60,
            textAlign: "center"
        };
        this.readOnly = (_.get(props, "booking.status") === "Completed" || props.readOnly) && !props.rightAdmin ? true : false;
        if (this.readOnly) {
            this.defaultColDef = {
                sortable: false,
                filter: false,
                headerAlign: "left",
                textAlign: "left"
            };
        }
        this.animateRows = !this.readOnly;
        this.rowDragManaged = !this.readOnly;
        this.checkboxSelection = !this.readOnly;
        this.headerCheckboxSelection = !this.readOnly;
        this.widthActionRow = this.readOnly ? 0 : 80;
        this.columnDefs = [
            {
                headerName: "Name",
                field: "name",
                minWidth: props.isMobile ? 150 : 250,
                textAlign: "left",
                rowDrag: true,
                colSpan: function (params) {
                    if (_.get(params, "data.field") === "total") {
                        return 2;
                    }
                    return 1;
                }
            },
            {
                headerName: "Technician",
                field: "technician",
                width: props.isMobile ? 150 : 180
            },
            {
                headerName: "Price (CAD)",
                field: "price",
                width: 100
            },
            {
                headerName: " Duration (minutes)",
                field: "time",
                width: props.isMobile ? 90 : 100
            }
        ];
        this.cellRenderer = this.handleCellRenderer(props);
    }

    handleCellRenderer(props) {
        const cellRenderer = {
            name: {
                renderer: (data, value) => {
                    if (_.get(data, "field") === "extra") {
                        const ref = `ExtraName_${data.index}`;
                        return (
                            <Textbox
                                ref={(c) => (this[ref] = c)}
                                className={`cpn-add-extra ${ref}`}
                                value={value}
                                placeholder={"Input name of extra service"}
                                onBlur={(val) => this.onBlurInput(data, val, "name", value)}
                                inputStyle={{ textAlign: "left" }}
                                autoFocus={true}
                                readOnly={this.readOnly}
                                isClearable={!this.readOnly}
                                visibleIcon={!this.readOnly}
                            />
                        );
                    } else if (_.get(data, "field") === "total") {
                        return <div className="global-flex-align-center-justify-end label-bottom">{value}</div>;
                    }
                    return <div className="global-flex-align-center">{value}</div>;
                }
            },
            price: {
                renderer: (data, value) => {
                    const classText = data.field === "total" ? "label-bottom" : "";
                    if (_.get(data, "field") === "extra") {
                        const ref = `ExtraPrice_${data.index}`;
                        return (
                            <Textbox
                                ref={(c) => (this[ref] = c)}
                                className={`cpn-add-extra ${ref} price`}
                                value={value}
                                placeholder={<span>Input...</span>}
                                onBlur={(val) => this.onBlurInput(data, val, "price", value)}
                                inputStyle={{ textAlign: "left" }}
                                type={"number"}
                                autoFocus={true}
                                readOnly={this.readOnly}
                                isClearable={!this.readOnly}
                                visibleIcon={!this.readOnly}
                                iconLine={false}
                                icon={<span style={{ fontSize: 12, fontWeight: 600 }}>$</span>}
                                iconAlign="before"
                            />
                        );
                    }
                    return (
                        <div className="global-flex-align-center">
                            <span className="label-bottom">$</span>
                            <div className={`global-flex-align-center-justify-end global-flex-1 ${classText}`}>{value}</div>
                        </div>
                    );
                }
            },
            time: {
                renderer: (data, value) => {
                    const classText = data.field === "total" ? "label-bottom" : "";
                    if (!value && _.get(data, "field") === "extra") {
                        return null;
                    }
                    return <div className={`global-flex-align-center-justify-end ${classText}`}>{value}</div>;
                }
            },
            technician: {
                renderer: (data, value, rowNode) => {
                    if (_.get(data, "field") !== "total") {
                        let optionsDrop = [];
                        const technicianSkillStore = _.get(props, "storeList.technicianSkillStore");
                        const priorityTechnicians = _.get(props, "priorityTechnicians");
                        const findSkill = _.find(technicianSkillStore, (x) => x.service_id === data.service_id);
                        const techIds = findSkill ? findSkill.technicians : [];
                        const options = _.filter(priorityTechnicians, (x) => _.indexOf(techIds, x.id) >= 0);
                        const ref = `ExtraTech_${data.index ? data.index : data.id}`;
                        if (_.get(data, "field") === "extra") {
                            optionsDrop = priorityTechnicians;
                        } else {
                            optionsDrop = options;
                        }
                        const findVal = _.find(optionsDrop, (x) => x.id === value);
                        data.technician = findVal ? value : null;
                        data.technicianName = findVal ? findVal.name : null;
                        return (
                            <DropdownList
                                value={findVal ? value : null}
                                className={`drop-down-cpn ${ref}`}
                                options={optionsDrop}
                                labelKey={"name"}
                                valueKey={"id"}
                                placeholder="Select ..."
                                getPopupContainer={(trigger) => trigger.parentNode.closest(".popup-container") || document.body}
                                onChange={(val) => {
                                    data.technician = val;
                                }}
                                readOnly={this.readOnly}
                                allowClear={!this.readOnly}
                                visibleIcon={!this.readOnly}
                            />
                        );
                    }
                }
            }
        };
        return cellRenderer;
    }

    componentWillReceiveProps(nextProps) {
        const { extraServices, booking, technicianId, startTime } = nextProps;
        let newPinned = _.cloneDeep(extraServices);
        if (technicianId !== this.props.technicianId) {
            const options = this.handleOptions(technicianId);
            this.cbx && this.cbx.setData(options, null);
        }
        if (_.get(booking, "id")) {
            newPinned.push(
                {
                    name: "Subtotal",
                    technician: "",
                    price: nextProps.subTotal,
                    time: "",
                    stt: " ",
                    field: "total"
                },
                {
                    name: "Discount",
                    technician: "",
                    price: _.get(booking, "paymentFee"),
                    time: "",
                    stt: " ",
                    field: "total"
                },
                {
                    name: "HST",
                    technician: "",
                    price: nextProps.totalGST,
                    time: "",
                    stt: " ",
                    field: "total"
                },
                {
                    name: "Total",
                    technician: "",
                    price: nextProps.totalAmount,
                    time: nextProps.totalTime + " mins",
                    stt: " ",
                    field: "total"
                }
            );
        } else {
            const totalObj = {
                name: "Total",
                technician: "",
                price: nextProps.subTotal,
                time: nextProps.totalTime + " mins",
                stt: " ",
                field: "total"
            };
            newPinned.push(totalObj);
        }
        if (_.size(newPinned) > 0) {
            newPinned = _.sortBy(newPinned, (x) => x.type === "tip");
            this.myGrid && this.myGrid.gridApi && this.myGrid.gridApi.setPinnedBottomRowData(newPinned);
        }
        this.myGrid && this.myGrid.store.setData(nextProps.selectedServiceStoreReordering);
    }

    onChangeDate() {
        this.cellRenderer = this.handleCellRenderer(this.props);
        this.myGrid && this.myGrid.setColumnDefs(this.columnDefs, { cellRenderer: this.cellRenderer }, false);
    }

    clearService() {
        AppStore.dispatch(updateSelectedServices([]));
        AppStore.dispatch(
            updateExtraService([
                //     {
                //     name: 'TIP',
                //     technician: "",
                //     price: 0,
                //     time: "",
                //     stt: "TIP",
                //     field: "extra",
                //     type: "tip",
                //     index: uuidv4()
                // }
            ])
        );
    }

    gridLoading() {
        this.myGrid && this.myGrid.loading();
    }

    gridCloseLoading() {
        this.myGrid && this.myGrid.closeLoading();
    }

    setError() {}

    isValidBooking() {
        const sizePinned = this.myGrid.gridApi.getPinnedBottomRowCount();
        const dataGridBooking = this.myGrid && this.myGrid.store.getData();
        if (_.size(dataGridBooking) === 0) {
            AppUtil.ToastError("Please select service!");
            return;
        }
        const filterNullTech = _.filter(dataGridBooking, (x) => !x.technician);
        let rawError = document.querySelectorAll(".error-technican");
        _.each(rawError, (x) => {
            x.classList.remove("error-technican");
        });
        if (!_.isEmpty(filterNullTech)) {
            _.each(filterNullTech, (x) => {
                const classParent = `ExtraTech_${x.id}`;
                const input = document.querySelectorAll(`.${classParent} .rc-select-selection-search-input`);
                input[0].classList.add("error-technican");
                // if (input) {
                //     input[0].style.borderBottom = "1px solid red";
                //     input[0].style.opacity = 1;
                //     input[0].style.marginBottom = "1px";
                //     input[0].focus();
                // }
            });
            AppUtil.ToastError("Booking invalid!");
            return false;
        }

        for (let i = 0; i < sizePinned; i++) {
            const rowPin = this.myGrid.gridApi.getPinnedBottomRow(i);
            const { data } = rowPin;
            if (data.field === "extra") {
                if (!data.name) {
                    const classParent = `ExtraName_${data.index}`;
                    const input = document.querySelectorAll(`.${classParent} > .text-input`);
                    if (input) {
                        input[0].focus();
                        input[0].style.borderBottom = "1px solid red";
                    }
                    AppUtil.ToastError("Booking invalid!");
                    return false;
                }
                if (!data.technician) {
                    const classParent = `ExtraTech_${data.index}`;
                    const input = document.querySelectorAll(`.${classParent} .rc-select-selection-search-input`);
                    if (input) {
                        input[0].style.borderBottom = "1px solid red";
                        input[0].style.opacity = 1;
                        input[0].style.marginBottom = "1px";
                        input[0].focus();
                    }
                    AppUtil.ToastError("Booking invalid!");
                    return false;
                }
                if (!data.price) {
                    if (data.price === 0) {
                        return true;
                    }
                    const classParent = `ExtraPrice_${data.index}`;
                    const input = document.querySelectorAll(`.${classParent} > .text-input`);
                    if (input) {
                        input[0].focus();
                        input[0].style.borderBottom = "1px solid red";
                    }
                    AppUtil.ToastError("Booking invalid!");
                    return false;
                }
            }
        }
        return true;
    }

    onSelectTechnicianOnGrid(service_id, technicianId) {
        const { selectedServiceStoreReordering, storeList, priorityTechnicians } = this.props;
        const technicianFind = priorityTechnicians.find((obj) => {
            return obj.id === technicianId;
        });
        selectedServiceStoreReordering.forEach((selectedService, index) => {
            if (technicianFind && selectedService.service_id === service_id) {
                selectedService.technician = technicianId;
                selectedService.technicianName = technicianFind.name;
            }
        });
        // AppStore.dispatch(updateSelectedServices(selectedServiceStoreReordering));
    }

    onRowDragEnd = (val) => {
        if (val) {
            const dataGridBooking = this.myGrid && this.myGrid.store.getData();
            const { overIndex } = val;
            const rowData = this.getAllRows();
            const nodeDrag = _.get(val, "node.data");
            const nodeOver = dataGridBooking[overIndex];
            if (nodeDrag.id !== nodeOver.id) {
                AppStore.dispatch(updateSelectedServices(rowData));
            }
        }
    };

    getAllRows() {
        let rowData = [];
        this.myGrid.gridApi.forEachNode((node) => rowData.push(node.data));
        return rowData;
    }

    getData() {
        const dataGridBooking = this.myGrid && this.myGrid.store.getData();
        const objPin = this.getDataPinned();
        const obj = {
            services: dataGridBooking,
            extra: objPin.dataExtra
        };
        return obj;
    }

    getDataPinned() {
        const dataExtra = [];
        let toTal = {};
        const sizePinned = this.myGrid.gridApi.getPinnedBottomRowCount();
        for (let i = 0; i < sizePinned; i++) {
            const rowPin = this.myGrid.gridApi.getPinnedBottomRow(i);
            if (_.get(rowPin, "data.field") !== "total") {
                dataExtra.push(rowPin.data);
            } else {
                toTal = rowPin.data;
            }
        }
        return {
            dataExtra,
            totalRow: toTal
        };
    }

    onChangeTechnician(data, value, options) {
        // const { extraServices } = this.props
        // if (_.get(data, 'field') !== "extra") {
        //     this.onSelectTechnicianOnGrid(data.service_id, value)
        // } else {
        //     const find = _.find(options, x => x.id === value)
        //     let newExtra = _.map(extraServices, item => {
        //         if (item.index === data.index) {
        //             item.technician = find ? find.value : null
        //             item.technicianName = find ? find.name : null
        //         }
        //         return { ...item }
        //     })
        //     newExtra = _.sortBy(newExtra, x => x.type)
        //     // AppStore.dispatch(updateExtraService(newExtra))
        // }
    }

    onBlurInput = async (data, value, fieldKey, rawValue) => {
        if (rawValue != value) {
            data[fieldKey] = value;
            this.myGrid && this.myGrid.gridApi.updateRowData({ update: this.myGrid.store.getData() });
            const dataPinned = this.getDataPinned();
            const { dataExtra } = dataPinned;
            AppStore.dispatch(updateExtraService(dataExtra));
        }
    };

    selectServiceHandler(val) {
        this.props.selectServiceHandler && this.props.selectServiceHandler(val);
    }

    deselectServiceHandler(services) {
        const { selectedServiceStoreReordering, storeList } = this.props;
        AppStore.dispatch(deselectService(services, storeList, selectedServiceStoreReordering));
    }

    selectServiceHandlerByGrid = (val, isServiceGroup = false) => {
        this.props.selectServiceHandler && this.props.selectServiceHandler(val, isServiceGroup);
    };

    onSelect(technicianId) {
        const widthModal = this.props.isMobile ? "100%" : "800px";
        this.Modal.onOpen(
            <TreeGridSelectService ref={(c) => (this.GridSelect = c)} technicianId={technicianId} selectServiceHandlerByGrid={this.selectServiceHandlerByGrid} onClose={this.onClose} />,
            <span>All services</span>,
            widthModal
        );
        this.refContainer && this.refContainer.unmask();
    }

    handleAddPinnedRows(newExtra) {
        const dataPinned = this.getDataPinned();
        let { dataExtra, totalRow } = dataPinned;
        if (newExtra) {
            dataExtra.push(newExtra);
        }
        dataExtra = _.sortBy(dataExtra, (x) => x.type);
        if (_.get(this.props.booking, "id")) {
            dataExtra.push(
                {
                    name: "Subtotal",
                    technician: "",
                    price: this.props.subTotal,
                    time: "",
                    stt: " ",
                    field: "total"
                },
                {
                    name: "Discount",
                    technician: "",
                    price: _.get(this.props.booking, "paymentFee"),
                    time: "",
                    stt: " ",
                    field: "total"
                },
                {
                    name: "HST",
                    technician: "",
                    price: _.get(this.props.booking, "totalFee"),
                    time: "",
                    stt: " ",
                    field: "total"
                },
                {
                    name: "Total",
                    technician: "",
                    price: this.props.totalAmount,
                    time: this.props.totalTime + " mins",
                    stt: " ",
                    field: "total"
                }
            );
        } else {
            dataExtra.push(totalRow);
        }
        if (_.size(dataExtra) > 0) {
            dataExtra = _.sortBy(dataExtra, (x) => x.type === "tip");
            this.myGrid && this.myGrid.gridApi.setPinnedBottomRowData(dataExtra);
        }
    }

    handleDeselectPinnedRows(dataExtra, totalRow) {
        dataExtra = _.sortBy(dataExtra, (x) => x.type);
        if (_.get(this.props.booking, "id")) {
            dataExtra.push(
                {
                    name: "Subtotal",
                    technician: "",
                    price: this.props.subTotal,
                    time: "",
                    stt: " ",
                    field: "total"
                },
                {
                    name: "Discount",
                    technician: "",
                    price: _.get(this.props.booking, "paymentFee"),
                    time: "",
                    stt: " ",
                    field: "total"
                },
                {
                    name: "HST",
                    technician: "",
                    price: _.get(this.props.booking, "totalFee"),
                    time: "",
                    stt: " ",
                    field: "total"
                },
                {
                    name: "Total",
                    technician: "",
                    price: this.props.totalAmount,
                    time: this.props.totalTime + " mins",
                    stt: " ",
                    field: "total"
                }
            );
        } else {
            dataExtra.push(totalRow);
        }
        if (_.size(dataExtra) > 0) {
            dataExtra = _.sortBy(dataExtra, (x) => x.type === "tip");
            this.myGrid && this.myGrid.gridApi.setPinnedBottomRowData(dataExtra);
        }
    }

    onAddTIP = () => {
        if (this.myGrid) {
            const newExtra = {
                name: "TIP",
                technician: this.props.technician ? _.get(this.props.technician, "id") : null,
                price: 0,
                time: null,
                stt: `TIP`,
                field: "extra",
                index: uuidv4(),
                type: "tip"
            };
            this.handleAddPinnedRows(newExtra);
        }
    };

    onAddExtra = () => {
        if (this.myGrid) {
            const newExtra = {
                name: null,
                technician: this.props.technician ? _.get(this.props.technician, "id") : null,
                price: null,
                time: null,
                stt: `Extra`,
                field: "extra",
                index: uuidv4(),
                type: "extra"
            };
            this.handleAddPinnedRows(newExtra);
        }
    };

    renderActionBar() {
        const buttons = [];
        if (!this.readOnly) {
            buttons.push(
                {
                    className: "create-btn text-button",
                    text: "Select ",
                    tooltip: "Select service",
                    style: {
                        width: 107
                    },
                    icon: <Icon type="plus-square" />,
                    onPress: () => this.onSelect(this.props.technicianId)
                },
                {
                    className: "import-btn text-button",
                    tooltip: "Add extra service",
                    text: "Extra",
                    style: {
                        width: 107
                    },
                    icon: <Icon type="file-add" />,
                    onPress: this.onAddExtra
                },
                // {
                //     className: "cer-btn text-button",
                //     tooltip: "Add tip",
                //     text: "Tip",
                //     style: {
                //         width: 107
                //     },
                //     icon: <Icon type="dollar" />,
                //     onPress: this.onAddTIP
                // },
                {
                    className: "delete-btn text-button",
                    text: "Deselect",
                    tooltip: "Deselect service",
                    icon: <Icon type="minus-square" />,
                    onPress: this.onDeselect
                }
            );
        }

        return buttons;
    }

    async onDeselectExtra(row) {
        let dataPinned = this.getDataPinned();
        let { dataExtra, totalRow } = dataPinned;
        if (!row.name && !row.price && !row.technician) {
            const newExtra = _.filter(dataExtra, (item) => item.index !== row.index);
            AppStore.dispatch(updateExtraService(newExtra));
            this.handleDeselectPinnedRows(newExtra, totalRow);
        } else {
            let title = "Are you sure you want to deselect selected extra service?";
            if (_.get(row, "type") === "tip") {
                title = "Are you sure you want to deselect TIP?";
            }
            let confirm = await Alert.Swal_confirm("Confirm", title, 3);
            if (_.get(confirm, "value") === true) {
                const newExtra = _.filter(dataExtra, (item) => item.index !== row.index);
                AppStore.dispatch(updateExtraService(newExtra));
                this.handleDeselectPinnedRows(newExtra, totalRow);
            }
        }
    }

    renderActions = (row) => {
        const buttons = [];
        if (_.get(row, "field") !== "total" && !this.readOnly) {
            buttons.push({
                type: "delete",
                onPress: async (row) => {
                    if (row.field !== "extra") {
                        let confirm = await Alert.Swal_confirm("Confirm", "Are you sure you want to deselect selected service?", 3);
                        if (_.get(confirm, "value") === true) {
                            this.deselectServiceHandler([row]);
                        }
                    } else {
                        this.onDeselectExtra(row);
                    }
                },
                icon: {
                    type: "minus-square",
                    style: { background: "var(--main-color-red)" },
                    color: "#ffffff"
                },
                tooltip: "Deselect"
            });
        }
        return buttons;
    };

    onDeselect = async () => {
        const selectedRows = this.myGrid.gridApi.getSelectedRows();
        if (_.size(selectedRows) > 0) {
            let confirm = await Alert.Swal_confirm("Confirm", "Are you sure you want to deselect selected services?", 3);
            if (_.get(confirm, "value") === true) {
                this.deselectServiceHandler(selectedRows);
            }
        } else {
            AppUtil.ToastError("Select at least service to deselect");
        }
    };

    onGridReady() {
        const { extraServices, booking, discountLoyalty, discountVoucher } = this.props;
        let newPinned = _.cloneDeep(extraServices);
        if (_.get(booking, "id")) {
            newPinned.push({
                name: "Subtotal",
                technician: "",
                price: this.props.subTotal,
                time: "",
                stt: " ",
                field: "total"
            });
            if (discountVoucher !== 0) {
                newPinned.push({
                    name: "Voucher",
                    technician: "",
                    price: -discountVoucher,
                    time: "",
                    stt: " ",
                    field: "total"
                });
            }
            newPinned.push(
                {
                    name: "Discount",
                    technician: "",
                    price: _.get(booking, "paymentFee"),
                    time: "",
                    stt: " ",
                    field: "total"
                },
                {
                    name: "HST",
                    technician: "",
                    price: _.get(booking, "totalFee"),
                    time: "",
                    stt: " ",
                    field: "total"
                }
            );
            // if (discountLoyalty !== 0) {
            //     newPinned.push({
            //         name: 'Loyalty discount',
            //         technician: '',
            //         price: -discountLoyalty,
            //         time: "",
            //         stt: " ",
            //         field: "total"
            //     })
            // }
            //
            newPinned.push({
                name: "Total amount",
                technician: "",
                price: this.props.totalAmount,
                time: this.props.totalTime + " mins",
                stt: " ",
                field: "total"
            });
            if(booking.status == "Completed"){
                _.get(booking,"giftCardActions",[]).map(giftCard =>{
                    newPinned.push(
                        {
                            name:`Pay gift card (${_.get(giftCard, "code", "")})`,
                            technician: "",
                            price: "$ -" + _.get(giftCard, "value", 0),
                            time: "",
                            stt: " ",
                            field: "total"
                        }
                    )
                })
            }
        } else {
            newPinned.push({
                name: "Total",
                technician: "",
                price: this.props.subTotal,
                time: this.props.totalTime + " mins",
                stt: " ",
                field: "total"
            });
        }
        if (_.size(newPinned) > 0) {
            newPinned = _.sortBy(newPinned, (x) => x.type === "tip");
            this.myGrid && this.myGrid.gridApi.setPinnedBottomRowData(newPinned);
        }
    }

    handleOptions(technicianId) {
        const { servicesStore, storeList } = this.props;
        const { technicianSkillStore } = storeList;
        //mergeServiceAndSkill
        const mergeOption = _.map(servicesStore, (service) => {
            const skill = _.find(technicianSkillStore, (x) => x.service_id === service.service_id);
            return {
                ...service,
                skills: skill ? skill.technicians : []
            };
        });
        let newOption = _.map(mergeOption, (x) => {
            if (_.indexOf(x.skills, technicianId) >= 0) {
                x.color = "#026eaa";
            }
            x.name = x.name + " (" + x.code + ")";
            return {
                ...x
            };
        });
        newOption = _.sortBy(mergeOption, (x) => x.color);
        return newOption;
    }

    renderLeftActionBar = () => {
        const { servicesStore, technicianId } = this.props;
        let options = _.filter(servicesStore, (x) => !x.hidden);
        if (technicianId) {
            options = this.handleOptions(technicianId);
        }
        return (
            <div style={{ display: "flex", flex: 1 }}>
                <div className="label-field label-field-left" style={{ width: 110, marginRight: 10, paddingTop: 5 }}>
                    Choose service
                </div>
                <Combobox
                    className="choose-service"
                    style={{ flex: 1, marginRight: 15 }}
                    ref={(c) => (this.cbx = c)}
                    options={options}
                    labelKey="name"
                    valueKey="service_id"
                    onChange={(val) => this.selectServiceHandler(val)}
                />
            </div>
        );
    };

    render() {
        return (
            <div className="grid-booking-service">
                {this.renderGrid()}
                {this.renderModal()}
            </div>
        );
    }
}
export default GridBookingService;
