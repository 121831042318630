import React, { Component } from "react";
import Form from "@idtek/form/lib/IDForm";

class BaseForm extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        if (this.form) {
            (await this.props.onDidMount) && this.props.onDidMount();
        }
    }
    checkIsValid() {
        return this.form && this.form.isValid();
    }

    pushField(key, obj) {
        this.form && this.form.push(key, obj);
    }
    removeField(key, id) {
        this.form && this.form.remove(key, id);
    }
    getValues() {
        return this.form && this.form.getValues();
    }

    pageSetValueForm = (values) => {
        this.form && this.form.setValues(values);
    };

    onMask() {
        this.form && this.form.mask();
    }
    onUnMask() {
        this.form && this.form.unmask();
    }
    setErrorField(field, message) {
        this.form && this.form.setErrorField(field, message);
    }
    render() {
        const { model, renderBodyForm, buttons, initialValues, formDefaults } = this.props;
        return (
            <Form
                ref={(component) => (this.form = component)}
                initialValues={initialValues}
                model={model}
                className="base-form-modal"
                style={{ padding: 15 }}
                buttons={buttons}
                defaults={formDefaults}>
                {renderBodyForm}
            </Form>
        );
    }
}

export default BaseForm;
