import Popover from "@idtek/component/lib/popover/Popover";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import AppUtil from "../../../utils/AppUtil";
import "../style/search-phone.scss";
import InputSearch from "./InputSearch";
import OptionRowPhone from "./OptionRowPhone";
class SearchPhoneComponent extends Component {
    constructor(props) {
        super(props);
        const find = _.find(props.options, (x) => x.phone === props.value);
        this.state = {
            value: find,
            options: this.getOptions(props.value, props.options),
            rawOptions: props.options,
            widthPopover: 300,
            visible: false
        };
        this.onSearch = _.debounce(this._onSearch, 200);
        this.lengthPhone = _.get(this.props.phoneSetting, "length") || 10;
    }

    componentWillReceiveProps(nextProps) {
        if ((!nextProps.value && !this.props.value) || nextProps.value !== this.props.value) {
            const find = _.find(nextProps.options, (x) => x.phone === nextProps.value);
            if (find) {
                this.InputSearch && this.InputSearch.setValue(find.phone);
            }
            this.setState({
                value: find,
                options: this.getOptions(nextProps.value, nextProps.options),
                rawOptions: nextProps.options
            });
        }
    }

    getOptions(value, options) {
        const result = _.filter(options, (option) => {
            return value !== option.phone;
        });

        return [...result];
    }

    formatPhone(str) {
        return str.replace(/\s+/g, "");
    }

    async _onSearch(phoneSearch) {
        if (phoneSearch !== _.get(this.state.value, "phone")) {
            const res = await AppUtil.getApi(`/customer/combobox`, {
                start: 0,
                limit: 100,
                query: phoneSearch
            });
            const options = _.get(res, "data");
            if (!phoneSearch) {
                this.props.onChange && this.props.onChange("");
            }
            if (_.isEmpty(options) && phoneSearch.length >= this.lengthPhone) {
                this.props.onChange && this.props.onChange({ phone: phoneSearch });
            } else if (options[0] && phoneSearch.length >= this.lengthPhone) {
                this.props.onChange && this.props.onChange(options[0]);
            }
            if (!_.isEmpty(options) && phoneSearch) {
                this.setState({
                    options: this.getOptions(phoneSearch, options),
                    visible: true
                });
            } else {
                this.setState({
                    options: this.getOptions(phoneSearch, options)
                });
            }
        }
    }

    handleVisibleChange = (visible) => {
        this.setState({ visible });
    };

    onSelected(selected) {
        const { value, rawOptions } = this.state;
        this.setState({
            value: selected,
            visible: false,
            options: this.getOptions(value, rawOptions)
        });
        this.InputSearch && this.InputSearch.setValue(selected.phone);
        this.props.onChange && this.props.onChange(selected);
    }

    render() {
        const { value, options } = this.state;
        const { phoneSetting } = this.props;
        return (
            <div className="search-cpn-phone">
                <Popover
                    trigger="click"
                    placement="bottomLeft"
                    overlayClassName="search-contact-overlay"
                    getPopupContainer={(trigger) => trigger.parentNode.closest(".popup-container") || document.body}
                    content={
                        <div style={{ width: this.state.widthPopover || 300 }}>
                            {_.size(options) > 0 ? _.map(options, (option, index) => <OptionRowPhone key={`option-${index}`} data={option} onSelected={() => this.onSelected(option)} />) : <></>}
                        </div>
                    }
                    visible={this.state.visible}
                    onVisibleChange={this.handleVisibleChange}>
                    <InputSearch
                        ref={(c) => (this.InputSearch = c)}
                        value={_.get(value, "phone")}
                        onOpen={(widthPopover) => {
                            this.setState({ visible: true, widthPopover });
                        }}
                        readOnly={this.props.readOnly}
                        phoneSetting={phoneSetting}
                        onChange={(val) => {
                            if (val && val.length < this.lengthPhone) {
                                this.props.onSetErrorField && this.props.onSetErrorField("phone", `Please input ${this.lengthPhone} digits phone number`);
                            }
                            this.onSearch(val);
                        }}
                    />
                </Popover>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        phoneSetting: state.root.phoneSetting
    };
}
export default connect(mapStateToProps)(SearchPhoneComponent);
