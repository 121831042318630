import Icon from "@idtek/component/lib/icon/Icon";
import React, { Component } from "react";
import { Menu, Button } from "antd";

export default class ButtonCollapse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
        };
    }
    openClick() {
        this.props.onClick();
    }
    setOpen(isOpen) {
        this.setState({
            isOpen,
        });
    }
    render() {
        const { isOpen } = this.state;
        return (
            <button className="global-flex-align-center collapse-button" onClick={this.openClick.bind(this)}>
                {isOpen ? <Icon type="menu-fold" /> : <Icon type="menu-unfold" />}
            </button>
        );
    }
}
