import React, { Component } from 'react'
import _ from 'lodash'
export default class OptionRowPhone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            data: nextProps.data
        });
    }

    onSelected() {
        this.props.onSelected && this.props.onSelected();
    }


    render() {
        const { data } = this.state;
        return (
            <div
                className="phone-row"
                onClick={() => this.onSelected()}
            >
                {_.get(data, 'text')}
            </div>
        );
    }
}
