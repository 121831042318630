import { MaskedTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import * as React from "react";
import { SampleBase } from "../../../common/component/sample-base";
import _ from "lodash";
import Icon from "@idtek/component/lib/icon/Icon";
import { connect } from "react-redux";
export default class InputSearch extends SampleBase {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }
    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.value !== this.state.value) {
    //         this.setState({ value: nextProps.value });
    //     }
    // }

    setValue(value) {
        this.setState({
            value
        });
    }
    maskChange = (args) => {
        if (args) {
            this.props.onChange && this.props.onChange(args.value);
            this.setState({
                value: args.value
            });
            this.rawValue = args.value;
        }
    };
    render() {
        const { value } = this.state;
        const { readOnly, phoneSetting } = this.props;
        return (
            <div className="wrap-search-box" ref={(c) => (this.wrapSearchBox = c)}>
                <div
                    style={{ flex: 1 }}
                    onClick={() => {
                        const widthPopover = this.wrapSearchBox.clientWidth;
                        this.props.onOpen && this.props.onOpen(widthPopover);
                    }}>
                    <MaskedTextBoxComponent
                        value={value}
                        className="input-ej2"
                        style={{ width: "100%" }}
                        mask={_.get(phoneSetting, "regex") || "000 000 0000"}
                        change={this.maskChange}></MaskedTextBoxComponent>
                </div>
                {!readOnly && value && (
                    <span style={{ margin: "0px 10px" }}>
                        <Icon
                            type="close"
                            style={{ color: "red", fontSize: 12 }}
                            onClick={() => {
                                this.setState({ value: "" });
                                this.props.onChange("");
                            }}
                        />
                    </span>
                )}
                <span class="icon-field-line" style={{ width: "1px", height: "16px", borderLeft: " 1px solid rgb(204, 204, 204)", marginTop: 6 }}></span>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: 35 }}>
                    <Icon type="phone" style={{ fontSize: 16, color: "#0d81c0" }} />
                </div>
            </div>
        );
    }
}
