import { allRights } from "../../utils/rightUtils";

export const MenuConfig = [
    {
        icon: "fa fa-clock-o",
        route: "/booking/index",
        background: "#4285f4",
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Booking",
        level: 2,
        selectAsParent: true,
        name: "Booking",
        parentId: null,
        localIndex: 0,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "af3ba032-eece-4789-ba8b-08d898033aa7",
        domainEvents: null,
        right: [allRights.BOOKING_RIGHT__ACCESS_HISTORY]
    },
    {
        icon: "fa fa-comments",
        route: "/booking-review",
        background: "#ff727d",
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "BookingReview",
        level: 2,
        selectAsParent: true,
        name: "Customer Reviews",
        parentId: null,
        localIndex: 0,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "af3ba032-eece-4789-ba8b-08d898033ac7",
        domainEvents: null,
        right: [allRights.BOOKING_REVIEW_RIGHT__ACCESS],
        right2: [allRights.BOOKING_RIGHT__ACCESS_HISTORY]
    },
    {
        icon: "fa fa-gift",
        route: "/system/gift-card",
        background: "#40a9ff",
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Gift card",
        level: 2,
        selectAsParent: true,
        name: "Gift card",
        parentId: null,
        localIndex: 0,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "1832df72-241c-496a-a14a-092333f80479",
        domainEvents: null,
        right: [allRights.BRANCH_RIGHT__ACCESS, allRights.BRANCH_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-wrench",
        route: "/service",
        background: "#333",
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Service",
        level: 2,
        selectAsParent: true,
        name: "Service",
        parentId: null,
        localIndex: 0,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "F7E5A320-AF37-4388-B509-08D8C40E95D4",
        domainEvents: null,
        right: [allRights.SERVICE_RIGHT__ACCESS, allRights.SERVICE_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-user-circle-o",
        route: "/customer",
        background: "#e80015",
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Customer",
        level: 2,
        selectAsParent: true,
        name: "Customer",
        parentId: null,
        localIndex: 0,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "B9B68B01-B1CE-4A9A-A425-08D8378B5898",
        domainEvents: null,
        right: [allRights.CUSTOMER_RIGHT__ACCESS, allRights.CUSTOMER_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-building-o",
        route: "/branch",
        background: "#909",
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Branch",
        level: 2,
        selectAsParent: true,
        name: "Branch",
        parentId: null,
        localIndex: 0,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "9174FDCF-8E2E-46D8-BA8C-08D898033AA7",
        domainEvents: null,
        right: [allRights.BRANCH_RIGHT__ACCESS, allRights.BRANCH_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-briefcase",
        route: "/technician",
        background: "var(--main-color-green)",
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Technician",
        level: 2,
        selectAsParent: true,
        name: "Technician",
        parentId: null,
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "b50a8b17-13f8-469f-7f87-08d8820aeeea",
        domainEvents: null,
        right: [allRights.TECHNICIAN_RIGHT__ACCESS, allRights.TECHNICIAN_TIMESHEET_RIGHT__ACCESS, allRights.TECHNICIAN_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-user",
        route: "/technician/index",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Technician",
        level: 3,
        selectAsParent: false,
        name: "Technician",
        parentId: "b50a8b17-13f8-469f-7f87-08d8820aeeea",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "39def8b4-0b95-4f10-b50a-08d8c40e95d4",
        domainEvents: null,
        right: [allRights.TECHNICIAN_RIGHT__ACCESS]
    },
    {
        icon: "fa fa-users",
        route: "/technician/time-sheet",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Timesheet",
        level: 3,
        selectAsParent: false,
        name: "Timesheet",
        parentId: "b50a8b17-13f8-469f-7f87-08d8820aeeea",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "f0cae9ad-9eef-4333-df40-08d81fe71b78",
        domainEvents: null,
        right: [allRights.TECHNICIAN_TIMESHEET_RIGHT__ACCESS, allRights.TECHNICIAN_TIMESHEET_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-bar-chart",
        route: "/report",
        background: "#880e4f",
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Report",
        level: 2,
        selectAsParent: true,
        name: "Report",
        parentId: null,
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "66dc0751-fb97-4700-ac50-05028198c6f1",
        domainEvents: null,
        right: [allRights.REPORT_RIGHT__ACCESS]
    },
    {
        icon: "fa fa-user",
        route: "/report/sumary",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "ReportSumarty",
        level: 3,
        selectAsParent: false,
        name: "Revenue",
        parentId: "66dc0751-fb97-4700-ac50-05028198c6f1",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "b20df533-b3c3-4052-bcde-55d06907b241",
        domainEvents: null,
        right: [allRights.REPORT_RIGHT__ACCESS]
    },
    {
        icon: "fa fa-user",
        route: "/report/money",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "ReportMoney",
        level: 3,
        selectAsParent: false,
        name: "Daily transaction report",
        parentId: "66dc0751-fb97-4700-ac50-05028198c6f1",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "b20df533-b3c3-4052-bcde-55d06907b223",
        domainEvents: null,
        right: [allRights.REPORT_RIGHT__ACCESS]
    },
    {
        icon: "fa fa-user",
        route: "/report/money-flows",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "ReportMoneyFlows",
        level: 3,
        selectAsParent: false,
        name: "Money flows report",
        parentId: "66dc0751-fb97-4700-ac50-05028198c6f1",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "b20df533-b3c3-4052-bcde-55d069072123",
        domainEvents: null,
        right: [allRights.REPORT_RIGHT__ACCESS]
    },
    {
        icon: "fa fa-user",
        route: "/report/technicians",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "ReportTechnicians",
        level: 3,
        selectAsParent: false,
        name: "Technician",
        parentId: "66dc0751-fb97-4700-ac50-05028198c6f1",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "b20df533-b3c6-4052-bcde-55d06907b266",
        domainEvents: null,
        right: [allRights.REPORT_RIGHT__ACCESS]
    },
    {
        icon: "fa fa-user",
        route: "/report/daily-technician",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Daily technician report",
        level: 3,
        selectAsParent: false,
        name: "Daily technician report",
        parentId: "66dc0751-fb97-4700-ac50-05028198c6f1",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "b20df533-b3c6-4052-bcde-55d06907b123",
        domainEvents: null,
        right: [allRights.REPORT_RIGHT__ACCESS]
    },
    {
        icon: "fa fa-users",
        route: "/report/revenue",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Month",
        level: 3,
        selectAsParent: false,
        name: "Month",
        parentId: "66dc0751-fb97-4700-ac50-05028198c6f1",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "2025185f-47d2-4ec6-810b-a16613d75b13",
        domainEvents: null,
        right: [allRights.REPORT_RIGHT__ACCESS]
    },
    {
        icon: "fa fa-users",
        route: "/report/customer",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Customer last booking",
        level: 3,
        selectAsParent: false,
        name: "Customer last booking",
        parentId: "66dc0751-fb97-4700-ac50-05028198c6f1",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "445b0bd3-8871-49b4-bc27-4d29d68acc61",
        domainEvents: null,
        right: [allRights.REPORT_RIGHT__ACCESS]
    },
    {
        icon: "fa fa-wrench",
        route: "/system",
        background: "#ff7043",
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "System",
        level: 2,
        selectAsParent: true,
        name: "System",
        parentId: null,
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "6B8CFD95-A389-4EF4-991B-517A481BBB9C",
        domainEvents: null
    },
    {
        icon: "fa fa-wrench",
        route: "/system/payment-method",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Payment method",
        level: 2,
        selectAsParent: true,
        name: "Payment method",
        parentId: "6B8CFD95-A389-4EF4-991B-517A481BBB9C",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "FA471A43-0239-4008-BBBD-509E27CA8559",
        domainEvents: null,
        right: [allRights.BRANCH_RIGHT__ACCESS, allRights.BRANCH_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-wrench",
        route: "/system/checkout-payment",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Checkout payment",
        level: 2,
        selectAsParent: true,
        name: "Checkout payment",
        parentId: "6B8CFD95-A389-4EF4-991B-517A481BBB9C",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "C7EA7DF7-FBBA-4FFD-A78C-19DFCB812341",
        domainEvents: null,
        right: [allRights.BRANCH_RIGHT__ACCESS, allRights.BRANCH_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-wrench",
        route: "/system/loyalty-voucher",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Loyalty voucher",
        level: 2,
        selectAsParent: true,
        name: "Loyalty voucher",
        parentId: "6B8CFD95-A389-4EF4-991B-517A481BBB9C",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "37daf792-16e9-4839-ae73-d82edc883162",
        domainEvents: null,
        right: [allRights.BRANCH_RIGHT__ACCESS, allRights.BRANCH_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-wrench",
        route: "/system/voucher",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Voucher",
        level: 2,
        selectAsParent: true,
        name: "Voucher",
        parentId: "6B8CFD95-A389-4EF4-991B-517A481BBB9C",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "69815ead-3ad2-4523-b56c-edeb040ecfac",
        domainEvents: null,
        right: [allRights.BRANCH_RIGHT__ACCESS, allRights.BRANCH_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-wrench",
        route: "/system/email-template",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Email template",
        level: 2,
        selectAsParent: true,
        name: "Email template",
        parentId: "6B8CFD95-A389-4EF4-991B-517A481BBB9C",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "30FA0570-2D74-4D17-A14F-0D98E14EB123",
        domainEvents: null,
        right: [allRights.BRANCH_RIGHT__ACCESS, allRights.BRANCH_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-wrench",
        route: "/system/sms-message",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "SMS Message",
        level: 2,
        selectAsParent: true,
        name: "SMS Message",
        parentId: "6B8CFD95-A389-4EF4-991B-517A481BBB9C",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "30FA0570-2D74-4D17-A14F-0D98E14EBF67",
        domainEvents: null,
        right: [allRights.BRANCH_RIGHT__ACCESS, allRights.BRANCH_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-wrench",
        route: "/system/sms-campaign",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "SMS Marketing Campaign",
        level: 2,
        selectAsParent: true,
        name: "SMS Marketing Campaign",
        parentId: "6B8CFD95-A389-4EF4-991B-517A481BBB9C",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "30FA0570-2D74-4D17-A14F-0D98E14EBFS7",
        domainEvents: null,
        right: [allRights.SMS_MARKETING_ACCESS, allRights.SMS_MARKETING_ACCESS]
    },
    {
        icon: "fa fa-wrench",
        route: "/system/config-remind-time",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Remind hours",
        level: 2,
        selectAsParent: true,
        name: "Remind hours",
        parentId: "6B8CFD95-A389-4EF4-991B-517A481BBB9C",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "C7EA7DF7-FBBA-4FFD-A78C-19DFCB81FE28",
        domainEvents: null,
        right: [allRights.BRANCH_RIGHT__ACCESS, allRights.BRANCH_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-wrench",
        route: "/system/config-remind-before",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Limited Reminders",
        level: 2,
        selectAsParent: true,
        name: "Limited Reminders",
        parentId: "6B8CFD95-A389-4EF4-991B-517A481BBB9C",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "96C1A1FC-C0AE-47B4-9CA8-FFCB43795645",
        domainEvents: null,
        right: [allRights.BRANCH_RIGHT__ACCESS, allRights.BRANCH_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-wrench",
        route: "/system/config-vat",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "HST",
        level: 2,
        selectAsParent: true,
        name: "HST",
        parentId: "6B8CFD95-A389-4EF4-991B-517A481BBB9C",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "94E05CF7-6059-4650-85A0-7B465265A21D",
        domainEvents: null,
        right: [allRights.BRANCH_RIGHT__ACCESS, allRights.BRANCH_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-wrench",
        route: "/system/user",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "User",
        level: 2,
        selectAsParent: true,
        name: "User",
        parentId: "6B8CFD95-A389-4EF4-991B-517A481BBB9C",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "A12CB2D3-84E8-473B-B555-0A2477FBC3F7",
        domainEvents: null,
        right: [allRights.USER_RIGHT__ACCESS, allRights.USER_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-wrench",
        route: "/system/map-user-branch",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Map User-Branch",
        level: 2,
        selectAsParent: true,
        name: "Map User-Branch",
        parentId: "6B8CFD95-A389-4EF4-991B-517A481BBB9C",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "E52E48B7-3921-4ECC-9DB4-041FB0F15ACC",
        domainEvents: null,
        right: [allRights.MAP_USER_BRANCH_RIGHT__ACCESS, allRights.MAP_USER_BRANCH_RIGHT___UPDATE]
    },
    {
        icon: "fa fa-wrench",
        route: "/system/role",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Role",
        level: 2,
        selectAsParent: true,
        name: "Role",
        parentId: "6B8CFD95-A389-4EF4-991B-517A481BBB9C",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "17afd7f2-f652-4edd-b6fb-b383f858b9c3",
        domainEvents: null,
        right: [allRights.ROLE_RIGHT__ACCESS, allRights.ROLE_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-wrench",
        route: "/system/right-rule",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Permissions",
        level: 2,
        selectAsParent: true,
        name: "Permissions",
        parentId: "6B8CFD95-A389-4EF4-991B-517A481BBB9C",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "62ceb3e2-000d-4ca1-bc28-26d62b343247",
        domainEvents: null,
        right: [allRights.USER_RIGHT__ACCESS_USER_PERMISSIONS, allRights.USER_RIGHT__UPDATE_USER_PERMISSIONS]
    },
    {
        icon: "fa fa-cogs",
        route: "/config",
        background: "#78909c",
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Setting",
        level: 2,
        selectAsParent: true,
        name: "Setting",
        parentId: null,
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "4D21BDB5-09A1-4557-BBF5-9AD9E1A85D85",
        domainEvents: null,
        right: [allRights.BRANCH_RIGHT__ACCESS, allRights.BRANCH_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-cogs",
        route: "/config/config-start-time",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Start Time",
        level: 2,
        selectAsParent: true,
        name: "Start Time",
        parentId: "4D21BDB5-09A1-4557-BBF5-9AD9E1A85D85",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "BE69B6A0-5EA9-492F-941A-1FB8723769BE",
        domainEvents: null,
        right: [allRights.BRANCH_RIGHT__ACCESS, allRights.BRANCH_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-cogs",
        route: "/config/config-time-work",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Working Time",
        level: 2,
        selectAsParent: true,
        name: "Working Time",
        parentId: "4D21BDB5-09A1-4557-BBF5-9AD9E1A85D85",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "7E32383C-CCD4-4580-9BB1-74B8AF1A975B",
        domainEvents: null,
        right: [allRights.BRANCH_RIGHT__ACCESS, allRights.BRANCH_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-cogs",
        route: "/config/config-schedule",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Working Schedule",
        level: 2,
        selectAsParent: true,
        name: "Working Schedule",
        parentId: "4D21BDB5-09A1-4557-BBF5-9AD9E1A85D85",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "7E32383C-CCD4-4580-9BB1-74B8AF1A975X",
        domainEvents: null,
        right: [allRights.BRANCH_RIGHT__ACCESS, allRights.BRANCH_RIGHT__UPDATE]
    },
    {
        icon: "fa fa-cogs",
        route: "/config/config-holiday",
        background: null,
        domain: null,
        children: [],
        isShowHeader: null,
        pathName: "Day off",
        level: 2,
        selectAsParent: true,
        name: "Day off",
        parentId: "4D21BDB5-09A1-4557-BBF5-9AD9E1A85D85",
        localIndex: 1,
        active: true,
        root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
        badgeFunction: null,
        bagdeNumber: null,
        id: "7E32383C-CCD4-4580-9BB1-74B8AF1A975M",
        domainEvents: null,
        right: [allRights.BRANCH_RIGHT__ACCESS, allRights.BRANCH_RIGHT__UPDATE]
    },
    // {
    //     icon: "fa fa-cogs",
    //     route: "/config/config-technician-multitasking",
    //     background: null,
    //     domain: null,
    //     children: [],
    //     isShowHeader: null,
    //     pathName: "Working Time",
    //     level: 2,
    //     selectAsParent: true,
    //     name: "Technician Multitasking",
    //     parentId: "4D21BDB5-09A1-4557-BBF5-9AD9E1A85D85",
    //     localIndex: 1,
    //     active: true,
    //     root: "cca84021-f153-4a7c-90e6-2be7af9403b3",
    //     badgeFunction: null,
    //     bagdeNumber: null,
    //     id: "7E32383C-CCD4-4580-9BB1-74B8AF1A979a",
    //     domainEvents: null,
    //     right: [allRights.BRANCH_RIGHT__ACCESS, allRights.BRANCH_RIGHT__UPDATE]
    // }
];
