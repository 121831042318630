import Alert from "@idtek/component/lib/alert/Alert";
import Icon from "@idtek/component/lib/icon/Icon";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { resetServiceStore } from "../../../application/actions/action";
import AppStore from "../../../store";
import AppUtil from "../../../utils/AppUtil";
import FormProceedPayment from "../../dashboard/form/FormProceedPayment";
import "../style/style-update-booking.scss";
import BaseBookingCreate from "../view/BaseBookingCreate";

class UnUse_FormBookingUpdate extends BaseBookingCreate {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            initialValues: {},
            loading: true
        };
        this.apiCreate = "/booking/save";
        this.inModal = true;
        this.type = props.type ? props.type : "update";
        this.maxDate = null;
        this.minDate = null;
    }

    componentDidMount() {
        this.loadFormDataUpdate();
    }

    componentWillUnmount() {
        if (!this.props.notResetStoreServices) {
            AppStore.dispatch(resetServiceStore());
        }
    }

    onProceedPayment() {
        const { authData, booking } = this.props;
        const { paymentMethod } = this.props.authData;
        this.Modal.onOpen(
            <FormProceedPayment
                onClose={() => {
                    this.Modal.onClose();
                    this.loadFormDataUpdate();
                    this.props.onReloadData && this.props.onReloadData();
                }}
                thueVAT={_.get(authData, "gst")}
                booking={booking}
                user={_.get(authData, "user.fullName")}
                paymentMethod={paymentMethod}
                branch={_.get(authData, "user.branchId")}
            />,
            <span>Take Payment</span>,
            800
        );
    }

    renderButtons() {
        const { initialValues } = this.state;
        const buttons = [];
        if (moment(initialValues.startTime).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")) {
            if (initialValues.status !== "Completed") {
                buttons.push({
                    text: "Save",
                    type: "submit",
                    className: "btn-success",
                    icon: <Icon type="save" style={{ fontSize: 16 }} />,
                    onPress: (form, values) => this.onSave(form, values, true)
                });
            }
            if (["Waiting", "Checked in"].indexOf(initialValues.status) >= 0) {
                buttons.push({
                    text: "Start",
                    type: "submit",
                    className: "btn-primary",
                    icon: <Icon type="clock-circle" style={{ fontSize: 15 }} />,
                    onPress: (form, values) => {
                        this.onSave(form, values, false, true, "/booking/start", "start");
                    }
                });
            }
            if (["Completed"].indexOf(initialValues.status) >= 0) {
                buttons.push(
                    {
                        text: "Take Payment",
                        className: "btn-primary",
                        icon: <Icon type="dollar" style={{ fontSize: 18 }} />,
                        onPress: (form, values) => {
                            this.onProceedPayment();
                        }
                    },
                    {
                        text: "Bill",
                        className: "btn-primary",
                        icon: <Icon type="printer" style={{ fontSize: 18 }} />,
                        onPress: (form, values) => {
                            this.doActionBooking("bill");
                        }
                    }
                );
            }
            if (["Processing"].indexOf(initialValues.status) >= 0) {
                buttons.push({
                    text: "COMPLETE",
                    type: "submit",
                    className: "btn-success",
                    icon: <Icon type="check-square" style={{ fontSize: 16 }} />,
                    onPress: (form, values) => {
                        const url = `/booking/complete`;
                        this.showConfirm("COMPLETE", "Are you sure you want to complete?", this.onSave.bind(this, form, values, false, false, url, "complete"));
                    }
                });
            }
            if (["Cancel", "Completed"].indexOf(initialValues.status) < 0) {
                buttons.push({
                    text: "CANCEL",
                    type: "submit",
                    className: "btn-danger",
                    icon: <Icon type="close-circle" style={{ fontSize: 15 }} />,
                    onPress: (form, values) => {
                        const url = `/booking/cancel`;
                        this.showConfirm("CANCEL", "Are you sure you want to cancel?", this.onSave.bind(this, form, values, false, false, url, "cancel"));
                    }
                });
            }
        } else {
            if (initialValues.status === "Completed") {
                buttons.push({
                    text: "Bill",
                    className: "btn-primary",
                    icon: <Icon type="printer" style={{ fontSize: 16 }} />,
                    onPress: (form, values) => this.doActionBooking("bill")
                });
            } else {
                buttons.push({
                    text: "Save",
                    type: "submit",
                    className: "btn-success",
                    icon: <Icon type="save" style={{ fontSize: 16 }} />,
                    onPress: (form, values) => this.onSave(form, values, false, false, "/booking/save-history", "save-history")
                });
            }
        }
        buttons.push(
            {
                text: "Clone",
                className: "btn-clone",
                icon: <Icon type="copy" style={{ fontSize: 16 }} />,
                onPress: (form, values) => this.onClone(values)
            },
            {
                text: "Close",
                className: "btn-danger",
                icon: <Icon type="close-square" style={{ fontSize: 15 }} />,
                onPress: () => {
                    this.props.onClose && this.props.onClose();
                }
            }
        );
        return buttons;
    }

    onClone() {
        this.props.onClone && this.props.onClone();
    }

    async getTemplateBill(id) {
        const response = await AppUtil.getApi("/booking/get-bill/" + id);
        if (response.data.success) {
            return response.data.template;
        }
        return "Template not exist!";
    }

    async doActionBooking(actionName) {
        const me = this;
        const { id } = me.state;
        //Check action bill
        if (actionName === "bill") {
            AppUtil.loadAndPrintBooking(id);
        }
    }

    continute() {}

    continuteAfterCheckMessage() {
        this.props.onReloadData && this.props.onReloadData();
        AppUtil.ToastSaveSuccess();
    }

    beforeSave(form, values, actionName) {
        const data = {};
        const { services, extra } = this.GridBooking && this.GridBooking.getData();
        const bookingItems = _.map(services, (item) => {
            return {
                service_id: item.service_id ? item.service_id : null,
                technician: item.technician ? item.technician : null
            };
        });
        const extraServices = _.map(extra, (item) => {
            return {
                ...item,
                startTime: values.startTime ? moment(values.startTime).format("YYYY-MM-DD") : null,
                price: item.price ? parseFloat(item.price) : 0
            };
        });
        data.email = values.email ? values.email : null;
        data.bookingItems = bookingItems;
        data.branch = values.branch;
        data.customerName = values.customerName;
        data.description = values.description;
        data.extraServices = extraServices;
        data.paymentMethod = values.paymentMethod ? values.paymentMethod : null;
        if (values.status && ["start", "complete", "cancel"].indexOf(actionName) < 0 && !this.props.cloneView) {
            data.status = values.status;
        }

        if (typeof values.phone === "string" || values.phone instanceof String) {
            data.phone = values.phone;
        } else {
            data.phone = _.get(values.phone, "phone");
        }
        data.startTime = moment(values.startTime);
        data.typeBooking = values.typeBooking ? values.typeBooking : "TYPE_BOOKING_OFFLINE";
        return data;
    }

    async onCancel() {
        let confirm = await Alert.Swal_confirm("CANCEL", "Are you sure you want to cancel booking?", 3);
        if (_.get(confirm, "value") === true) {
        }
    }

    render() {
        return (
            <div className={this.className}>
                <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    <div style={{ flex: 1, minHeight: 400, display: "flex", alignItems: "center" }}>{this.renderForm()}</div>
                    {this.renderModal()}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        authData: state.root.authData,
        storeList: state.root.storeList,
        selectedServiceStoreReordering: state.root.selectedServiceStoreReordering,
        extraServices: state.root.extraServices,
        servicesStore: state.root.storeList.servicesStore,
        priorityTechnicians: state.root.storeList.priorityTechnicians,
        subTotal: state.root.subTotal,
        totalTime: state.root.totalTime,
        totalAmount: state.root.totalAmount,
        booking: state.root.booking,
        notResetStoreServices: state.root.notResetStoreServices,
        optionsCustomer: state.root.optionsCustomer,
        totalGST: state.root.totalGST,
        technician: state.root.technician,
        ...state.root
    };
};
export default withRouter(connect(mapStateToProps, {}, null, { forwardRef: true })(UnUse_FormBookingUpdate));
