import React, {Component} from "react";
import ReactToPrint from "react-to-print";


export default class BillComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            template: null
        };
    }

    onPrint() {
        this.ReactToPrint && this.ReactToPrint.handlePrint();
    }

    onChangBill(template) {
        this.setState({template}, () => {
            this.onPrint();
        });
    }

    render() {
        return (
            <div style={{display: "none"}}>
                <ReactToPrint ref={(c) => (this.ReactToPrint = c)} content={() => this.componentRef}/>
                <div ref={(el) => (this.componentRef = el)} dangerouslySetInnerHTML={{__html: this.state.template}}/>
            </div>
        );
    }
}
