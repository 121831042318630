import _ from "lodash";
import moment from "moment";
import React from "react";
import "../../dashboard/style/dashboard-v2.scss";
import "../../dashboard/style/dashboard.scss";
import FilterHeader from "./FilterHeader";

export default class FilterHeaderMobile extends FilterHeader {
    constructor(props) {
        super(props);
        this.state = {
            branchId: _.get(props, "authData.user.branchId"),
            filterDate: moment().format("YYYY-MM-DD"),
        };
        this.filterDate = moment().format("YYYY-MM-DD");
    }

    render() {
        return <div className="filter-header-mobile-v2">{this.renderContent()}</div>;
    }
}
