import Container from "@idtek/component/lib/container/Container";
import Icon from "@idtek/component/lib/icon/Icon";
import Popover from "@idtek/component/lib/popover/Popover";
import Textbox from "@idtek/component/lib/textbox/Textbox";
import { Empty } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import AppUtil from "../../../utils/AppUtil";
import IconCoupon from "../icon/IconCoupon";

export default class InputGiftComponent extends Component {
    constructor(props) {
        super(props);
        const init = props.gift;
        this.state = {
            visible: false,
            voucher: init,
            code: props.code,
            disabled: init ? true : false,
            options: []
        };
        this.initCode = props.code;
        this.apiCheckCode = "/booking/check-gift-code";
        this.voucherName = "Gift card";
        if (!this.state.disabled) {
            this.handleVisibleChange = _.debounce(this._handleVisibleChange, 100);
        } else {
            this.handleVisibleChange = this._handleVisibleChange;
        }
    }
    componentDidMount() {
        this.cpnInput && this.cpnInput.setValue(this.initCode);
        this.loadSuggestion();
    }

    async loadSuggestion() {
        this.refContainer && this.refContainer.mask();
        const { booking } = this.props;
        const res = await AppUtil.getApi("/booking/get-gift-code", {
            customer: booking.customer,
            branch: booking.branch
        });
        if (_.get(res, "data.success")) {
            this.options = _.uniqBy(_.get(res, "data.giftCard"), (x) => x.code);
            this.setState(
                {
                    options: this.options
                },
                () => {
                    this.refContainer && this.refContainer.unmask();
                }
            );
        } else {
            AppUtil.ToastApiError();
        }
    }
    onApply = async (e) => {
        const value = this.cpnInput && this.cpnInput.getValue();
        this.setState({ visible: true });
        this.onCheck(value);
    };

    onApplyItem = async (item) => {
        this.props.onChange && this.props.onChange(item);
        this.cpnInput && this.cpnInput.setValue(item.code);
        this.setState({
            voucher: item,
            code: item.code
        });
    };

    async onCheck() {
        const { valueText, options, disabled } = this.state;
        if (valueText && !disabled) {
            this.refContainer && this.refContainer.mask();
            const { booking } = this.props;
            const res = await AppUtil.getApi(this.apiCheckCode, {
                code: valueText,
                branch: booking.branch
            });
            if (_.get(res, "data.success")) {
                const giftCard = _.get(res, "data.giftCard");
                const _options = _.cloneDeep(options);
                const isValid = _.size(giftCard) > 0 ? true : false;
                if (isValid) {
                    _options.push(giftCard[0]);
                    this.props.onChange && this.props.onChange(giftCard[0]);
                } else {
                    this.cpnInput && this.cpnInput.setValue(null);
                    this.props.onChange && this.props.onChange(null, true);
                }
                const newOpt = _.uniqBy(_options, (x) => x.code);
                this.setState(
                    {
                        visible: isValid ? true : false,
                        options: newOpt,
                        voucher: isValid ? giftCard[0] : null,
                        code: valueText
                    },
                    () => {
                        this.refContainer && this.refContainer.unmask();
                    }
                );
            } else {
                AppUtil.ToastApiError();
            }
        }
    }

    onDeselect = async () => {
        this.props.onChange && this.props.onChange(null);
        this.cpnInput && this.cpnInput.setValue(null);
        this.setState({
            code: null,
            voucher: null,
            valueText: null
        });
    };

    renderContent() {
        const { options, voucher, disabled } = this.state;
        const optionsOther = _.differenceBy(options, this.options, "code");
        if (voucher && disabled) {
            let discount = "";
            if (voucher.total && voucher.total !== 0) {
                discount = voucher.total + "$";
            }
            return (
                <div className="disable-card">
                    <div className="voucher-card">
                        <div className="value">
                            {this.voucherName} {discount}
                        </div>
                        <div className="condition">{voucher.content}</div>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <div className="section-voucher-customer">
                    <span className="group-header">Gift of customer</span>
                    <div className="section-content">
                        {_.size(this.options) > 0 ? (
                            _.map(this.options, (item) => {
                                return this.renderItem(item);
                            })
                        ) : (
                            <div className="empty-data">
                                <Empty />
                            </div>
                        )}
                    </div>
                </div>
                {_.size(optionsOther) > 0 && (
                    <div className="section-other" style={{ marginTop: -5 }}>
                        <span className="group-header">Other</span>
                        <div className="section-content">
                            {_.map(optionsOther, (item) => {
                                return this.renderItem(item);
                            })}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    renderItem(item) {
        const { voucher, disabled } = this.state;
        let discount = "";
        if (item.total && item.total !== 0) {
            discount = item.total + "$";
        }
        let isSelected = _.get(voucher, "code") === item.code ? true : false;
        if (!disabled) {
            return (
                <div id={isSelected ? "selected-card" : ""} className={`voucher-card ${isSelected ? "selected-card" : ""}`}>
                    <div className="value">
                        {this.voucherName} {discount}
                    </div>
                    <div className="condition">{item.content}</div>
                    <div className="bottom" style={{ justifyContent: "flex-end" }}>
                        {isSelected ? (
                            <button onClick={this.onDeselect} className="deselect">
                                <Icon type="close-square" style={{ marginRight: 5 }} />
                                Detect
                            </button>
                        ) : (
                            <button className={`button-apply`} onClick={() => this.onApplyItem(item)}>
                                <Icon type="safety-certificate" style={{ marginRight: 5 }} />
                                Apply
                            </button>
                        )}
                    </div>
                </div>
            );
        }
        return (
            <div className="voucher-card">
                <div className="value">
                    {this.voucherName} {discount}
                </div>
                <div className="condition">{item.content}</div>
            </div>
        );
    }

    scrollToSelected() {
        const elmnt = document.getElementById("selected-card");
        if (elmnt && (!this.state.visible || !this.isFocus)) {
            elmnt.scrollIntoView();
        }
    }

    _handleVisibleChange = (visible, unCheck = false) => {
        const { voucher, disabled } = this.state;
        if (unCheck || disabled) {
            this.setState({ visible });
        } else {
            const activeElement = document.activeElement;
            const inputs = ["input", "button"];
            const listClass = activeElement.classList;
            if (
                !visible &&
                activeElement &&
                listClass &&
                listClass[1] &&
                inputs.indexOf(activeElement.tagName.toLowerCase()) >= 0 &&
                ["input-gift-cpn", "button-input-gift"].indexOf(listClass[1]) >= 0
            ) {
                if (listClass[1] === "input-gift-cpn") {
                    if (this.isFocus) {
                        this.setState({ visible: true });
                    } else {
                        this.setState({ visible: false });
                    }
                } else if (listClass[1] === "button-input-gift") {
                    this.setState({ visible: true });
                } else {
                    this.setState({ visible: false });
                }
            } else {
                if (this.code && voucher && this.code !== voucher.code) {
                    this.cpnInput && this.cpnInput.setValue(voucher.code);
                }
                this.setState({ visible });
            }
        }
        setTimeout(() => {
            this.scrollToSelected();
        }, 100);
    };

    render() {
        const { valueText, disabled } = this.state;
        return (
            <div className="voucher-code">
                <Popover
                    placement="bottomLeft"
                    trigger={["click"]}
                    overlayClassName="voucher-overlay gift-card-overlay"
                    getPopupContainer={(trigger) => trigger.parentNode.closest(".popup-container") || document.body}
                    visible={this.state.visible}
                    onVisibleChange={this.handleVisibleChange}
                    autoAdjustOverflow={false}
                    content={<Container ref={(c) => (this.refContainer = c)}>{this.renderContent()}</Container>}>
                    <div className="search-gift">
                        <Textbox
                            style={{ flex: 1 }}
                            ref={(c) => (this.cpnInput = c)}
                            placeholder={"Input code..."}
                            onChange={(val) => {
                                this.code = val;
                                this.setState({ valueText: val });
                            }}
                            inputStyle={{ textAlign: "left" }}
                            readOnly={disabled}
                            isClearable={false}
                            icon={<IconCoupon />}
                            iconAlign="before"
                            iconLine={false}
                            upperCase={true}
                            inputCls={"input-gift-cpn"}
                            onFocus={() => {
                                this.isFocus = true;
                                if (!this.state.visible && !disabled) {
                                    this.setState({ visible: true });
                                }
                            }}
                            onBlur={(val) => {
                                this.isFocus = false;
                                if (this.state.voucher && !val && !disabled) {
                                    this.cpnInput && this.cpnInput.setValue(this.state.voucher.code);
                                }
                            }}
                        />
                        <button ref={(c) => (this.button = c)} className={`button button-input-gift ${!valueText || disabled ? "readOnly" : ""}`} onClick={this.onApply}>
                            <Icon type="safety-certificate" style={{ marginRight: 5 }} />
                            Apply
                        </button>
                    </div>
                </Popover>
            </div>
        );
    }
}
