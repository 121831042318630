import { Alert } from "@idtek/component/lib";
import Icon from "@idtek/component/lib/icon/Icon";
import ComboField from "@idtek/form/lib/combox/ComboField";
import IDField from "@idtek/form/lib/IDField";
import Form from "@idtek/form/lib/IDForm";
import RadioListField from "@idtek/form/lib/radiolist/RadioListField";
import SwitchField from "@idtek/form/lib/switch/SwitchField";
import TextField from "@idtek/form/lib/text/TextField";
import TextAreaField from "@idtek/form/lib/textarea/TextAreaField";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import {
    notResetStore,
    resetServiceStore,
    updateBooking
} from "../../../application/actions/action";
import AppStore from "../../../store";
import AppUtil from "../../../utils/AppUtil";
import ColorRandomField from "../../booking/component/ColorRandomField";
import GridBookingService from "../../booking/component/GridBookingService";
import ReactDatePickerCustom from "../../booking/component/ReactDatePickerCustom";
import SearchPhoneComponent from "../../booking/component/SearchPhoneComponent";
import "../../booking/style/style-update-booking.scss";
import BaseBookingCreate from "../../booking/view/BaseBookingCreate";
import Loading from "../../../pages/Loading";
import Row from "@idtek/component/lib/container/Row";
import Cell from "@idtek/component/lib/container/Cell";
class FormBookingCreateV2 extends BaseBookingCreate {
    constructor(props) {
        super(props);
        this.contentHeight = "100%";
        this.inModal = true;
        this.apiCreate = "/booking/save";
        this.minDate = props.startTime;
        this.isCreate = true;
    }

    componentDidMount() {
        if (!this.props.notResetStoreServices) {
            this.loadFormData();
        } else {
            const { initData } = this.props;
            if (_.get(initData, "startTime")) {
                moment(initData.startTime);
                initData.quantity = 1;
                initData.id = null;
            }
            if (this.props.cloneView) {
                AppStore.dispatch(updateBooking(initData));
            }
            this.setState({
                loading: false,
                initialValues: this.props.initData
            });
        }
    }

    componentWillUnmount() {
        if (!this.props.notResetStoreServices) {
            AppStore.dispatch(resetServiceStore());
        }
    }

    afterSave() {
        AppStore.dispatch(notResetStore(false));
    }

    async checkMessage(message, newValues, actionName, continute) {
        const { multiSetting } = this.props;
        if (
            message &&
            message === "Please select full technician for services"
        ) {
            AppUtil.ToastError(message);
        } else if (message) {
            if (!multiSetting && actionName === "start") {
                const mess = message.split(".");
                const newMess =
                    mess &&
                    mess[0] +
                        ". Are you want to start at the time of booking in schedule?";
                const url = `/booking/${actionName}`;
                let confirmStart = await Alert.Swal_confirm(
                    "Notification",
                    newMess,
                    3
                );
                const newPost = newValues;
                newPost.startByPass = true;
                if (_.get(confirmStart, "value") === true) {
                    const res = await AppUtil.postApi(url, newPost);
                    if (_.get(res, "data.success")) {
                        this.props.onClose && this.props.onClose();
                        this.props.onReloadData && this.props.onReloadData();
                        return;
                    }
                }
                return;
            }
            if (!multiSetting && actionName !== "start") {
                const newMess = message.split(".");
                Alert.Swal_info("WARNING", newMess && newMess[0], 3);
                return;
            }
            let confirm = await Alert.Swal_confirm("WARNING", message, 3);
            if (_.get(confirm, "value") === true) {
                newValues.unCheckWarning = true;
                newValues.id = this.state.id;
                const res = await AppUtil.postApi(
                    `/booking/${actionName}`,
                    newValues
                );
                if (_.get(res, "data.success")) {
                    // AppUtil.ToastSaveSuccess();
                    if (continute) {
                        this.continuteAfterCheckMessage();
                    } else {
                        this.props.onClose && this.props.onClose();
                        this.props.onReloadData && this.props.onReloadData();
                        if (!this.inModal) {
                            this.onBack();
                        }
                    }
                }
            }
        }
    }

    renderForm() {
        const { authData, storeList, optionsCustomer } = this.props;
        const { initialValues } = this.state;
        if (this.state.loading) return <Loading />;
        return (
            <Form
                ref={(component) => (this.form = component)}
                initialValues={initialValues}
                model={this.model}
                className="base-form-modal create-booking"
                style={{
                    padding: 15,
                    display: "flex",
                    flexDirection: "column"
                }}
                buttons={[
                    {
                        text: "Save",
                        type: "submit",
                        className: "btn-success",
                        icon: <Icon type="save" style={{ fontSize: 16 }} />,
                        onPress: (form, values) => this.onSave(form, values)
                    },
                    {
                        text: "Continue",
                        type: "submit",
                        className: "btn-success",
                        icon: (
                            <Icon type="play-square" style={{ fontSize: 16 }} />
                        ),
                        onPress: (form, values) =>
                            this.onSave(form, values, true)
                    },
                    {
                        text: "Start",
                        type: "submit",
                        className: "btn-primary",
                        icon: (
                            <Icon
                                type="clock-circle"
                                style={{ fontSize: 16 }}
                            />
                        ),
                        onPress: (form, values) =>
                            this.onSave(form, values, false, true, "", "start")
                    },
                    {
                        text: "Close",
                        className: "btn-danger",
                        icon: (
                            <Icon
                                type="close-square"
                                style={{ fontSize: 16 }}
                            />
                        ),
                        onPress: () =>
                            this.props.onClose && this.props.onClose()
                    }
                ]}
                defaults={{
                    alignLabel: "left",
                    widthLabel: 115,
                    isClearable: true,
                    inCondition: true,
                    labelKey: "name",
                    valueKey: "id"
                }}>
                <>
                    <Row>
                        <Cell className="phone-input">
                            <IDField
                                name="phone"
                                onSetErrorField={this.onSetErrorField}
                                onChange={this.onChangePhone}
                                options={optionsCustomer}>
                                <SearchPhoneComponent />
                            </IDField>
                        </Cell>
                        <Cell>
                            <IDField
                                name="startTime"
                                status={
                                    !this.props.cloneView
                                        ? _.get(initialValues, "status")
                                        : ""
                                }
                                showTimeSelect={true}
                                minDate={this.minDate}
                                maxDate={this.maxDate}
                                onChange={this.onChangeDate}
                                startTime={storeList.startTime}
                                timeWork={storeList.timeWork}>
                                <ReactDatePickerCustom />
                            </IDField>
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <TextField name="customerName" upperCase={true} />
                        </Cell>
                        <Cell>
                            <TextField name="email" type="email" />
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <ComboField
                                name="branch"
                                options={_.get(authData, "user.branches")}
                            />
                        </Cell>
                        <Cell>
                            <ComboField
                                name="technician"
                                options={storeList.priorityTechnicians}
                                onChange={(val) =>
                                    this.onSelectMainTechnician(val)
                                }
                            />
                        </Cell>
                    </Row>
                    <Row>
                        <Cell style={{ height: 50 }}>
                            <RadioListField
                                name={"sex"}
                                viewMode={"horizontal"}
                                labelKey={"label"}
                                valueKey={"value"}
                                options={[
                                    {
                                        label: "Any",
                                        value: "ANY"
                                    },
                                    {
                                        label: "Male",
                                        value: "MALE"
                                    },
                                    {
                                        label: "Female",
                                        value: "FEMALE"
                                    }
                                ]}
                            />
                        </Cell>
                        <Cell style={{ height: 50 }}>
                            <SwitchField name={"block"} />
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <IDField name="color" onChange={this.onChangeColor}>
                                <ColorRandomField isCreate={this.isCreate} />
                            </IDField>
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <TextAreaField name="description" />
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <GridBookingService
                                ref={(c) => (this.GridBooking = c)}
                                onSelectTechnician={this.onSelectTechnicianOnGrid.bind(
                                    this
                                )}
                                selectServiceHandler={this.selectServiceHandler}
                                technicianId={initialValues.technician}
                                {...this.props}
                            />
                        </Cell>
                    </Row>
                </>
            </Form>
        );
    }

    render() {
        return (
            <div className={this.className}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1
                    }}>
                    <div
                        style={{
                            flex: 1,
                            minHeight: 400,
                            display: "flex",
                            alignItems: "center"
                        }}>
                        {this.renderForm()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        servicesStore: state.root.storeList.servicesStore,
        priorityTechnicians: state.root.storeList.priorityTechnicians,
        ...state.root
    };
};
export default connect(mapStateToProps)(FormBookingCreateV2);
