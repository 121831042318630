
import React, { lazy } from 'react';
const IconCash = lazy(() => import('./IconCash'));
const IconPercent = lazy(() => import('./IconPercent'));
const IconRef = lazy(() => import('./IconRef'));
const IconBirth = lazy(() => import('./IconBirth'));
const IconCoin = lazy(() => import('./IconCoin'));

export const OPTIONS_ICON = [
    { value: "CASH", label: 'Cash', component: IconCash },
    { value: "PERCENT", label: 'Percent', component: IconPercent },
    { value: "REFERRAL", label: 'Referral', component: IconRef },
    { value: "BIRTHDAY", label: 'Birthday', component: IconBirth },
    { value: "COIN", label: 'Coin', component: IconCoin },
]