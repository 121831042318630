import {Button, Fieldset} from "@idtek/component/lib";
import Container from "@idtek/component/lib/container/Container";
import FullBrowser from "@idtek/component/lib/fullScreen/FullBrowser";
import Icon from "@idtek/component/lib/icon/Icon";
import Modal from "@idtek/component/lib/modal/Modal";
import ComboField from "@idtek/form/lib/combox/ComboField";
import DisplayField from "@idtek/form/lib/display/DisplayField";
import FieldArray from "@idtek/form/lib/fieldarray/FieldArray";
import TextField from "@idtek/form/lib/text/TextField";
import Grid from "@idtek/grid/lib/grid/Grid";
import BaseStore from "@idtek/grid/lib/stores/BaseStore";
import _ from "lodash";
import moment from "moment";
import React, {Fragment} from "react";
import FormBooking from "../../common/baseForm/BaseForm";
import BaseListView from "../../common/baseView/BaseListView";
import BasePermisstion from "../../common/baseView/BasePermisstion";
import ContentView from "../../common/component/ContentView";
import AppUtil from "../../utils/AppUtil";
import {allRights} from "../../utils/rightUtils";
import GridDetailBooking from "../booking/component/GridDetailBooking";
import {CUSTOMER_LEVEL} from "./constant/CustomerConstant";
import IconFilter from "../../application/elements/IconFilter";
import IconFilterDown from "../../application/elements/IconFilterDown";
import "./style/customer-note.scss";
import {connect as reduxConnect} from "react-redux";
import Row from "@idtek/component/lib/container/Row";
import Cell from "@idtek/component/lib/container/Cell";

export class CustomerListView extends BaseListView {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        ///right
        this.updateRight = allRights.CUSTOMER_RIGHT__UPDATE;
        this.rightAccess = allRights.CUSTOMER_RIGHT__ACCESS;

        this.popUpWidth = "850px";
        this.apiIndex = "/customer/";
        this.apiDetail = "/customer/show";
        this.apiUpdate = "/customer/update";
        this.apiCreate = "/customer/create";
        this.apiDelete = "/customer/delete";
        this.store = new BaseStore({
            columns: this.columnDefs,
            url: AppUtil.GLOBAL_API_PATH + this.apiIndex,
            pageSize: this.pageSize
        });
        this.moduleName = "Customer";
        this.defaultTypeActionRow = false;
        this.floatingFilter = false;
        this.defaultColDef = {
            sortable: false,
            filter: false,
            headerAlign: "left",
            textAlign: "left"
        };
        this.module = "";
        this.columnDefs = [
            {
                headerName: "Name",
                field: "name",
                minWidth: 300
            },
            {
                headerName: "Phone",
                field: "phone",
                width: 120
            },
            {
                headerName: "Email",
                field: "email",
                width: 200
            },
            {
                headerName: "Level",
                field: "level",
                width: 80
            },
            {
                headerName: "Address",
                field: "address",
                minWidth: 200
            }
        ];
        this.columnDefsBooking = [
            {
                headerName: "Code",
                field: "code",
                width: 70
            },
            {
                headerName: "Customer",
                field: "customerName",
                width: 120
            },
            {
                headerName: "Note",
                field: "description",
                width: 200
            },
            {
                headerName: "Start Time",
                field: "startTime",
                width: 150
            },
            {
                headerName: "Status",
                field: "status",
                width: 100
            },
            {
                headerName: "Total",
                field: "totalAmount",
                width: 80
            },
            {
                headerName: "Created By",
                field: "createdBy.fullName",
                width: 120
            },
            {
                headerName: "Created At",
                field: "createdAt",
                width: 150
            }
        ];

        this.cellRenderer = {
            phone: {
                renderer: (data, value) => {
                    if (value) {
                        return <div className="global-flex-align-center">{AppUtil.formatPhone(value)}</div>;
                    } else return null;
                }
            },
            level: {
                renderer: (data, value) => {
                    if (value) {
                        const level = _.find(CUSTOMER_LEVEL, (item) => item.value === value);
                        if (level) {
                            return <div className="global-flex-align-center">{level.label}</div>;
                        }
                    } else return null;
                }
            }
        };
        this.cellRendererBooking = {
            startTime: {
                renderer: (data, value) => {
                    if (value) {
                        return <div
                            className={"global-flex-align-center"}>{moment(value).format(AppUtil.GLOBAL_DATETIME_FORMAT)}</div>;
                    } else return null;
                }
            },
            createdAt: {
                renderer: (data, value) => {
                    if (value) {
                        return <div
                            className={"global-flex-align-center"}> {moment(value).format(AppUtil.GLOBAL_DATETIME_FORMAT)} </div>;
                    } else return null;
                }
            },
            totalAmount: {
                renderer: (data, value) => {
                    if (value) {
                        return <div className={"global-flex-align-center"}>{"$ " + value}</div>;
                    } else return null;
                }
            },
            status: {
                renderer: (data, value) => {
                    if (value) {
                        const sttClass = value.replace(/\s+/g, "");
                        return (
                            <div style={{
                                display: "flex",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <span className={"status-booking " + sttClass}>{value}</span>
                            </div>
                        );
                    }
                    return null;
                }
            }
        };

        this.model = {
            fields: {
                name: {
                    label: "Name",
                    required: true
                },
                phone: {
                    label: "Phone",
                    required: true
                },
                email: {
                    label: "Email"
                },
                level: {
                    label: "Level"
                },
                levelName: {
                    label: "Level"
                },
                address: {
                    label: "Address"
                },
                description: {
                    label: "Description"
                },
                spentThisMonth: {
                    label: "Spent this month"
                },
                spentThisYear: {
                    label: "Spent this year"
                },
                notes: {
                    label: "Notes"
                }
            }
        };
        this.modelBooking = {
            fields: {
                customerName: {
                    label: "Customer",
                    required: true
                },
                phone: {
                    label: "Phone",
                    required: true
                },
                startTime: {
                    label: "Start Time"
                },
                endTime: {
                    label: "End Time"
                },
                level: {
                    label: "Level"
                },
                technicianName: {
                    label: "Main Technician"
                },
                description: {
                    label: "Description"
                }
            }
        };
        this.buttons = [];
        this.lengthPhone = _.get(this.props.phoneSetting, "length") || 10;
    }

    formDefaults = {
        alignLabel: "left",
        widthLabel: "110px"
    };

    onDetailRowBooking = async (row) => {
        let newModel = _.cloneDeep(this.modelBooking);
        _.mapKeys(newModel.fields, (value, key) => {
            if (key) {
                value.visibleIcon = false;
                value.placeholder = " ";
            }
        });
        this.ModalBooking.onOpen(
            <FormBooking
                ref={(c) => (this.refFormBooking = c)}
                initialValues={this.initialValues ? this.initialValues : {}}
                onDidMount={this.onShowBooking.bind(this, row.id)}
                formDefaults={{
                    alignLabel: "left",
                    widthLabel: "90px",
                    readOnly: true
                }}
                model={newModel}
                buttons={[
                    {
                        text: "Close",
                        className: "btn-danger",
                        icon: <Icon type="close-square" style={{fontSize: 16}}/>,
                        onPress: () => this.ModalBooking && this.ModalBooking.onClose()
                    }
                ]}
                renderBodyForm={this.renderBodyFormBooking()}
            />,
            <span>Detail Booking</span>,
            "800px"
        );
        this.refContainer && this.refContainer.unmask();
    };

    beforeSetValues = async (values) => {
        if (values.email) {
            const arrEmail = values.email.split(",");
            const data = [];
            _.each(arrEmail, (x) => {
                data.push({
                    mail: x
                });
            });
            values.email = data;
        }
        values.notes = _.sortBy(values.notes, "id");
        values.levelName = values.level ? _.find(CUSTOMER_LEVEL, (x) => x.value === values.level).label : "";
        return values;
    };

    onShowBooking = async (id) => {
        this.refFormBooking && this.refFormBooking.onMask();
        const res = await AppUtil.getApi(`/booking/detail/${id}`, {});
        if (_.get(res, "data.success")) {
            const values = await this.beforeSetValues(_.get(res.data, "results"));
            values.startTime = moment(values.startTime).format(AppUtil.GLOBAL_DATETIME_FORMAT);
            values.endTime = moment(values.endTime).format(AppUtil.GLOBAL_DATETIME_FORMAT);
            this.refFormBooking && this.refFormBooking.pageSetValueForm(values);
            this.GridDetailBooking && this.GridDetailBooking.setDataGird(values);
            this.afterSetValues(values);
        }
        this.refFormBooking && this.refFormBooking.onUnMask();
    };

    renderGridBooking(id) {
        return (
            <Grid
                height={"100%"}
                numberRows={this.defaultNumberRows}
                ref={(myGrid) => (this.myGridBooking = myGrid)}
                rowSelection={this.rowSelection}
                pagination={true}
                className={`base-grid ${this.textCenterRow} `}
                clsToolbar="base-grid-toolbar"
                store={
                    new BaseStore({
                        columns: this.columnDefsBooking,
                        url: AppUtil.GLOBAL_API_PATH + `/customer/booking/${id}`,
                        pageSize: this.pageSize
                    })
                }
                globalFilter={false}
                floatingFilter={false}
                columnDefs={this.columnDefsBooking}
                actionToolbar={false}
                autoHeightRow={this.autoHeightRow}
                headerVerticalLines={true}
                headerHorizotalLines={true}
                suppressColumnVirtualisation={this.suppressColumnVirtualisation}
                headerHeight={this.headerHeight}
                defaultColDef={this.defaultColDef}
                gridOptions={{
                    rowClassRules: this.rowClassRules
                }}
                actionRows={{
                    pinned: "right",
                    width: 60,
                    title: "",
                    items: [
                        {
                            type: "detail",
                            onPress: this.onDetailRowBooking,
                            icon: {
                                type: "info-circle",
                                style: {background: "#5e72e4"},
                                color: "#ffffff"
                            }
                        }
                    ]
                }}
                cellRenderer={this.cellRendererBooking}
                {...this.gridProps}
            />
        );
    }

    renderValueMail(field, filedChild) {
        if (field.fields.value) {
            return field.fields.value.map((item, index) => {
                return (
                    <div className="row-content" key={index}>
                        <div className="input">
                            <div className="time-picker">
                                <TextField type="email" name={`${field.fields.name}[${index}].mail`} required={true}
                                           label={`Email #${index + 1}`}/>
                            </div>
                        </div>
                        <div className="action-btn action-btn-delete  custom-delete"
                             onClick={() => this.removeStep("email", field.fields, index)}>
                            <Icon type="minus-square" style={{background: "var(--main-color-red)"}} color="#ffffff"/>
                        </div>
                    </div>
                );
            });
        }
        return <></>;
    }

    renderValueNote(field, filedChild) {
        if (field.fields.value) {
            return field.fields.value.map((item, index) => {
                return (
                    <div className="row-content" key={index}>
                        <div className="input">
                            <div className="time-picker">
                                <TextField name={`${field.fields.name}[${index}].note`} required={true}
                                           label={`Note #${index + 1}`}/>
                            </div>
                        </div>
                        <div className="action-btn action-btn-delete  custom-delete"
                             onClick={() => this.removeStep("notes", field.fields, index)}>
                            <Icon type="minus-square" style={{background: "var(--main-color-red)"}} color="#ffffff"/>
                        </div>
                    </div>
                );
            });
        }
        return <></>;
    }

    renderValueDetail(field, filedChild, title) {
        if (field.fields.value) {
            return field.fields.value.map((item, index) => {
                return (
                    <Row>
                        <Cell>
                            <div key={index}>
                                <DisplayField name={`${field.fields.name}[${index}].${filedChild}`}
                                              label={`${title} #${index + 1}`}/>
                            </div>
                        </Cell>
                    </Row>

                );
            });
        }
        return <></>;
    }

    onAdd(key) {
        this.refBasseForm.pushField(key, {
            mail: "",
            autoFocus: true
        });
    }

    removeStep = (filedChild, fields, idx) => {
        this.refBasseForm.removeField(filedChild, idx);
    };

    renderBodyForm(rowData, isDetail) {
        let id = _.get(rowData, "id");
        const {phoneSetting} = this.props;
        if (isDetail) {
            return (
                <Fragment>
                    <Row>
                        <Cell>
                            <div>
                                <TextField name="name" upperCase={true}/>
                            </div>
                        </Cell>
                        <Cell>
                            <div>
                                <TextField
                                    name="phone"
                                    type="phone"
                                    options={{
                                        blocks: phoneSetting && phoneSetting.options ? JSON.parse(phoneSetting.options) : [3, 3, 4]
                                    }}
                                />
                            </div>
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <div>
                                <TextField name="address"/>
                            </div>
                        </Cell>
                        <Cell>
                            <div>
                                <TextField name="levelName"/>
                            </div>
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <div>
                                <TextField name="description"/>
                            </div>
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <div>
                                <TextField name="spentThisMonth"/>
                            </div>
                        </Cell>
                        <Cell>
                            <div>
                                <TextField name="spentThisYear"/>
                            </div>
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <FieldArray
                                name="email">{(field) => this.renderValueDetail(field, "mail", "Email")}</FieldArray>
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <FieldArray
                                name="notes">{(field) => this.renderValueDetail(field, "note", "Note")}</FieldArray>
                        </Cell>
                    </Row>
                    <div style={{marginTop: 10}}>
                        <Fieldset
                            title="List Booking"
                            collapsed={false}
                            icon={{
                                open: <IconFilterDown/>,
                                close: <IconFilter/>
                            }}>
                            <div className="grid-booking" style={{height: 300, marginBottom: 10, marginTop: 10}}>
                                {this.renderGridBooking(rowData.id)}
                            </div>
                        </Fieldset>
                    </div>
                </Fragment>
            );
        }
        return (
            <Fragment>
                <Row>
                    <Cell>
                        <div>
                            <TextField name="name" upperCase={true}/>
                        </div>
                    </Cell>
                    <Cell>
                        <div>
                            <TextField
                                name="phone"
                                type="phone"
                                options={{
                                    blocks: phoneSetting && phoneSetting.options ? JSON.parse(phoneSetting.options) : [3, 3, 4]
                                }}
                                placeholder={phoneSetting && phoneSetting.regex ? phoneSetting.regex.replaceAll("0", "_") : "___ ___ ____"}
                            />
                        </div>
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <div>
                            <TextField name="address"/>
                        </div>
                    </Cell>
                    <Cell>
                        <div>
                            <ComboField name="level" options={CUSTOMER_LEVEL}/>
                        </div>
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <div>
                            <TextField name="description"/>
                        </div>
                    </Cell>
                </Row>
                <Row className={"field-array-custom"}>
                    <Cell>
                        <div style={{display: 'flex'}}>
                            <div className="label">
                                <div className="label-field label-field-left"
                                     style={{marginRight: 10, paddingTop: 5, width: 110}}>
                                    <label className="label-field-text" style={{marginBottom: 5}}>
                                        Email
                                    </label>
                                </div>
                            </div>
                            <div className="content">
                                <div className="button-review">
                                    <Button onClick={() => this.onAdd("email")} className="global-flex-align-center"
                                            icon={<Icon type="plus-circle"/>}>
                                        Add email
                                    </Button>
                                </div>
                                <FieldArray name="email">{(field) => this.renderValueMail(field, "mail")}</FieldArray>
                            </div>
                        </div>
                    </Cell>
                </Row>
                <Row className={"field-array-custom"}>
                    <Cell>
                        <div style={{display: 'flex'}}>
                            <div className="label">
                                <div className="label-field label-field-left"
                                     style={{marginRight: 10, paddingTop: 5, width: 110}}>
                                    <label className="label-field-text" style={{marginBottom: 5}}>
                                        Notes
                                    </label>
                                </div>
                            </div>
                            <div className="content">
                                <div className="button-review">
                                    <Button onClick={() => this.onAdd("notes")} className="global-flex-align-center"
                                            icon={<Icon type="plus-circle"/>}>
                                        Add note
                                    </Button>
                                </div>
                                <FieldArray name="notes">{(field) => this.renderValueNote(field, "note")}</FieldArray>
                            </div>
                        </div>
                    </Cell>
                </Row>

                <div className="buttons button-center" style={{margin: "auto", display: "flex", alignItems: "center"}}>
                    <button className="btn-form btn btn-success" type="submit"
                            onClick={(form, values) => this.onSave(form, values)}>
                        <Icon type="save" style={{fontSize: 16}}/>
                        <span style={{marginLeft: 8, marginRight: 0}}>Save</span>
                    </button>
                    <button className="btn-form btn btn-danger" onClick={this.onClose}>
                        <Icon type="close-square" style={{fontSize: 16}}/>
                        <span style={{marginLeft: 8, marginRight: 0}}>Close</span>
                    </button>
                </div>
            </Fragment>
        );
    }

    onSave = async () => {
        if (this.refBasseForm.checkIsValid()) {
            this.refBasseForm && this.refBasseForm.onMask();
            const values = this.refBasseForm && this.refBasseForm.getValues();
            let emails = "";
            _.each(values.email, (x, index) => {
                if (index === 0) {
                    emails = emails + x.mail;
                } else {
                    emails = emails + "," + x.mail;
                }
            });
            values.email = emails;
            const dataPostNote = {
                notes: _.map(values.notes, (x) => x.note),
                id: values.id
            };
            if (values.phone.length < this.lengthPhone) {
                this.refBasseForm && this.refBasseForm.onUnMask();
                this.refBasseForm && this.refBasseForm.setErrorField("phone", `Please enter ${this.lengthPhone} digits number`);
                AppUtil.ToastError("Phone is invalid!");
                return;
            }

            const res = await AppUtil.postApi(values[this.keyID] ? this.apiUpdate + "/" + values[this.keyID] : this.apiCreate, values);
            const resNote = await AppUtil.postApi("/customer-note/save", dataPostNote);
            this.refBasseForm && this.refBasseForm.onUnMask();
            if (res) {
                if ((res.data && res.data.status === false) || !_.get(res.data, "success")) {
                    AppUtil.ToastApiError();
                } else {
                    AppUtil.ToastSuccess();
                    this.reLoad();
                    this.Modal.onClose();
                    if (values[this.keyID]) {
                    } else {
                        this.Modal.onClose();
                    }
                }
            } else {
                AppUtil.ToastApiError();
            }
        }
    };

    renderBodyFormBooking() {
        return (
            <Fragment>
                <Row>
                    <Cell>
                        <div>
                            <DisplayField name="customerName"/>
                        </div>
                    </Cell>
                    <Cell>
                        <div>
                            <DisplayField name="startTime"/>
                        </div>
                    </Cell>
                    <Cell>
                        <div>
                            <DisplayField name="technicianName"/>
                        </div>
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <div>
                            <DisplayField name="phone" type="phone"/>
                        </div>
                    </Cell>
                    <Cell>
                        <div>
                            <DisplayField name="endTime"/>
                        </div>
                    </Cell>
                    <Cell>
                        <div></div>
                    </Cell>
                </Row>
                <Row>
                    <div>
                        <DisplayField name="description"/>
                    </div>
                </Row>
                <div className="grid-booking" style={{marginBottom: 10}}>
                    <GridDetailBooking ref={(c) => (this.GridDetailBooking = c)}/>
                </div>
            </Fragment>
        );
    }

    renderModalDetailBooking() {
        return (
            <Modal
                draggable
                ref={(component) => {
                    this.ModalBooking = component;
                }}
                onClose={this.onCloseDefault}
                maskClosable={false}
                className="popup-crud popup-container"
            />
        );
    }

    render() {
        const contentHeight = this.contentHeight;
        return (
            <BasePermisstion rightAccess={this.rightAccess}>
                <FullBrowser ref={(component) => (this.FullBrowser = component)}>
                    <Container ref={(c) => (this.refContainer = c)}>
                        <ContentView>
                            <div className={this.className}>
                                <div style={{display: "flex", flexDirection: "column", flex: 1, height: contentHeight}}>
                                    <div style={{flex: 1}}>{this.renderGrid()}</div>
                                </div>
                                {this.renderModal()}
                                {this.renderModalDetailBooking()}
                            </div>
                        </ContentView>
                    </Container>
                </FullBrowser>
            </BasePermisstion>
        );
    }
}

export function connect(Component) {
    return reduxConnect((state, props) => ({
        phoneSetting: state.root.phoneSetting
    }))(Component);
}

export default connect(CustomerListView);
