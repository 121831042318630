import React, { Component, Fragment } from 'react';
import NotPermission from '../../pages/NotPermission';
import AppUtil from '../../utils/AppUtil';
import rightUtils from '../../utils/rightUtils';

class BasePermisstion extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.rightAccess = props.rightAccess ? props.rightAccess : "";
    }

    render() {
        const { children } = this.props;
        const { rights } = rightUtils
        const validRight = AppUtil.checkPermisstion(this.rightAccess, rights);
        return (
            <Fragment>
                {validRight ? children : <NotPermission />}
            </Fragment>
        );
    }
}

export default BasePermisstion;
