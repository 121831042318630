import _ from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import AppUtil from '../../../utils/AppUtil'

export default class ItemBooking extends Component {
    constructor(props) {
        super(props)
        this.state = {
            item: props.item,
            none: false
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ item: nextProps.item })
    }
    setData(item) {
        this.setState({ item: item })
    }
    setNone() {
        this.setState({ none: true })
    }
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    render() {
        const { item, none } = this.state
        const { className } = this.props
        return (
            <div style={{ display: none ? "none" : 'flex' }} className={`item-booking ${className}`} onClick={() => this.props.clickBooking && this.props.clickBooking(item)}>
                <div className="content-left">
                    <span className="title-value">INFORMATION -
                        <span className="highlight-blue" style={{ paddingLeft: 3 }}> {_.get(item, 'code')}</span>
                    </span>
                    <span className="title-value">
                        <span className="title-in-db">Name: </span>
                        <span className="highlight-blue">{_.get(item, 'customerName')}</span>
                    </span>
                    <span className="title-value">
                        <span className="title-in-db">Phone: </span>
                        <span>{AppUtil.formatPhone(_.get(item, 'phone'))}</span>
                    </span>
                    <span className="title-value">
                        <span className="title-in-db">Start time: </span>
                        <span className="highlight-value">{_.get(item, 'startTime') ? moment(_.get(item, 'startTime')).format(AppUtil.GLOBAL_DATETIME_FORMAT) : ""}</span>

                    </span>
                </div>
                <div className="content-right">
                    <span className="title-value">
                        <span className="title-in-db">Status: </span>
                        <span className={"schedule-status " + item.status}>{item.status ? this.capitalizeFirstLetter(item.status.toLowerCase()) : ""}</span>
                    </span>
                </div>
            </div>
        )
    }
}
