import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import ICON_RECEIVE_MONEY from "../../../application/images/rece_money.svg";
import AppUtil from "../../../utils/AppUtil";
import { RECEIVE_MONEY, TAKE_MONEY } from "../constant/BookingConstant";
import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
export default class ItemPosBookingAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ data: nextProps.data });
    }

    handleContent() {
        const { data } = this.state;
        const itemReceive = _.find(data, (x) => x.posType && x.posType.name === RECEIVE_MONEY);
        const itemTake = _.find(data, (x) => x.posType && x.posType.name === TAKE_MONEY);
        if (itemReceive && itemTake) {
            const { booking, createdBy, actionTime, value } = itemReceive;
            let row1 = `Booking ${booking.code} - ${booking.customerName}`;
            const row2 = `${moment(actionTime).format(AppUtil.GLOBAL_DATETIME_FORMAT)} - By: ${createdBy.fullName}`;
            const iconCheck = _.find(data, (x) => x.status) ? (
                <CheckCircleFilled style={{ color: "var(--main-color-green)" }} />
            ) : (
                <ExclamationCircleFilled style={{ color: "var(--main-color-red)" }} />
            );
            let valueRecieved = `+ $${value}`;
            let valueTake = `- $${itemTake.value}`;
            return { row1, row2, valueRecieved, valueTake, iconCheck, itemReceiveType: _.get(itemReceive, "type") };
        }
        return {};
    }
    renderItem() {
        const obj = this.handleContent();
        if (obj) {
            const { row1, row2, iconCheck, valueTake, valueRecieved, itemReceiveType } = obj;
            if (valueTake || valueRecieved) {
                const icon = ICON_RECEIVE_MONEY;
                const classNameIcon = "icon-receive";
                return (
                    <div className="item-action">
                        <div className="icon">
                            <img className={`img-pos ${classNameIcon}`} src={icon} alt="" />
                            <div className="pos-status-icon">{iconCheck}</div>
                        </div>
                        <div className="content">
                            <div className="row-pos content-tranfer">{row1}</div>
                            <div className="row-pos action-time"> {row2}</div>
                            {valueRecieved && (
                                <div className="row-pos bottom">
                                    <div className={`info-item`}>{`Amount received (${AppUtil.capitalizeFirstLetter(itemReceiveType && itemReceiveType.toLowerCase())})`}</div>
                                    <div className="value-pos">{valueRecieved}</div>
                                </div>
                            )}
                            {valueTake && (
                                <div className="row-pos bottom">
                                    <div className={`info-item`}>Amount back</div>
                                    <div className="value-pos">{valueTake}</div>
                                </div>
                            )}
                        </div>
                    </div>
                );
            }
        }
    }

    render() {
        return <>{this.renderItem()}</>;
    }
}
