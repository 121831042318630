import { Button, Fieldset, Icon } from "@idtek/component/lib";
import { DisplayField, FieldArray, Form, TextField } from "@idtek/form/lib";
import _ from "lodash";
import React from "react";
import IconFilter from "../../../application/elements/IconFilter";
import IconFilterDown from "../../../application/elements/IconFilterDown";
import AppUtil from "../../../utils/AppUtil";
import { CustomerListView, connect } from "../../customer/CustomerListView";
import Cell from "@idtek/component/lib/container/Cell";
import Row from "@idtek/component/lib/container/Row";
class FormDetailCustomer extends CustomerListView {
    constructor(props) {
        super(props);
        this.state = {};
        this.apiDetail = "/customer/show";
        this.initialValues = {};
        this.customerId = props.customerId;
        this.buttons = [
            {
                text: "Save",
                className: "btn-success",
                type: "submit",
                icon: <Icon type="save" style={{ fontSize: 16 }} />,
                onPress: (form, values) => this.onSave(form, values)
            },
            {
                text: "Close",
                className: "btn-danger",
                icon: <Icon type="close-square" style={{ fontSize: 16 }} />,
                onPress: () => this.props.onClose()
            }
        ];
        this.formDefaults = {
            alignLabel: "left",
            widthLabel: "110px"
        };
    }
    componentDidMount() {
        this.loadDetail();
    }
    async loadDetail() {
        this.onMask();
        const res = await AppUtil.getApi(`${this.apiDetail}/${this.customerId}`, {});
        if (_.get(res, "data.success")) {
            const values = await this.beforeSetValues(_.get(res.data, "results"));
            this.form && this.form.setValues(values);
            this.onUnMask();
        } else {
            AppUtil.ToastApiError();
            this.onUnMask();
        }
    }
    onMask() {
        this.form && this.form.mask();
    }
    onUnMask() {
        this.form && this.form.unmask();
    }
    beforeSetValues = async (values) => {
        const { phoneSetting } = this.props;
        if (values.email) {
            const arrEmail = values.email.split(",");
            const data = [];
            _.each(arrEmail, (x) => {
                data.push({
                    mail: x
                });
            });
            values.email = data;
        }
        values.notes = _.sortBy(values.notes, "id");
        values.phone = AppUtil.formatPhoneByBlock(values.phone, phoneSetting && phoneSetting.options ? JSON.parse(phoneSetting.options) : [3, 3, 4]);
        return values;
    };
    renderValueDetail(field, filedChild, title) {
        if (field && field.fields && field.fields.value) {
            return field.fields.value.map((item, index) => {
                return (
                    <Row>
                        <Cell key={index}>
                            <DisplayField name={`${field.fields.name}[${index}].${filedChild}`} label={`${title} #${index + 1}`} />
                        </Cell>
                    </Row>
                );
            });
        }
        return <></>;
    }
    renderValueNote(field, filedChild) {
        if (field && field.fields && field.fields.value) {
            return field.fields.value.map((item, index) => {
                return (
                    <div className="row-content" style={{ width: "100%" }} key={index}>
                        <div className="input">
                            <div className="time-picker">
                                <TextField style={{ width: "100%" }} name={`${field.fields.name}[${index}].note`} required={true} label={`Note #${index + 1}`} />
                            </div>
                        </div>
                        <div style={{ marginLeft: 10 }} className="action-btn action-btn-delete  custom-delete" onClick={() => this.removeStep("notes", field.fields, index)}>
                            <Icon type="minus-square" style={{ background: "var(--main-color-red)" }} color="#ffffff" />
                        </div>
                    </div>
                );
            });
        }
        return <></>;
    }
    onAdd(key) {
        this.form &&
            this.form.push(key, {
                mail: "",
                autoFocus: true
            });
    }
    removeStep = (filedChild, fields, idx) => {
        this.form && this.form.remove(filedChild, idx);
    };
    onSave = async () => {
        if (this.form.isValid()) {
            this.onMask();
            const values = this.form && this.form.getValues();
            const dataPostNote = {
                notes: _.map(values.notes, (x) => x.note),
                id: values.id
            };
            const resNote = await AppUtil.postApi("/customer-note/save", dataPostNote);
            this.onUnMask();
            if (_.get(resNote, "data.success")) {
                AppUtil.ToastSaveSuccess();
                this.props.onClose(true);
            } else {
                AppUtil.ToastApiError();
            }
        }
    };

    render() {
        const { model, buttons, initialValues, formDefaults } = this;
        return (
            <>
                <Form
                    ref={(component) => (this.form = component)}
                    initialValues={initialValues}
                    model={model}
                    className="base-form-modal"
                    style={{ padding: 15 }}
                    buttons={buttons}
                    defaults={formDefaults}>
                    <>
                        <Row>
                            <Cell style={{}}>
                                <DisplayField name="name" upperCase={true} required={false} />
                            </Cell>
                            <Cell style={{}}>
                                <DisplayField name="phone" type="phone" required={false} />
                            </Cell>
                            <Cell style={{}}>
                                <DisplayField name="address" />
                            </Cell>
                        </Row>
                        <Row>
                            <Cell style={{}}>
                                <DisplayField name="spentThisMonth" />
                            </Cell>
                            <Cell style={{}}>
                                <DisplayField name="spentThisYear" />
                            </Cell>
                            <Cell>
                                <div></div>
                            </Cell>
                        </Row>

                        <FieldArray name="email">{(field) => this.renderValueDetail(field, "mail", "Email")}</FieldArray>
                        <Row>
                            <Cell>
                                <DisplayField name="description" />
                            </Cell>
                        </Row>
                        <Row className={"field-array-custom"} style={{ paddingTop: 5 }}>
                            <Cell>
                                <div className="content">
                                    <div className="button-review">
                                        <Button onClick={() => this.onAdd("notes")} className="global-flex-align-center" icon={<Icon type="plus-circle" />}>
                                            Add note
                                        </Button>
                                    </div>
                                    <FieldArray name="notes">{(field) => this.renderValueNote(field, "note")}</FieldArray>
                                </div>
                            </Cell>
                        </Row>
                    </>

                    <div style={{ marginTop: 10 }}>
                        <Fieldset
                            title="List Booking"
                            collapsed={false}
                            icon={{
                                open: <IconFilterDown />,
                                close: <IconFilter />
                            }}>
                            <div className="grid-booking" style={{ height: 300, marginBottom: 10, marginTop: 10 }}>
                                {this.renderGridBooking(this.props.customerId)}
                            </div>
                        </Fieldset>
                    </div>
                </Form>
                {this.renderModalDetailBooking()}
            </>
        );
    }
}
export default connect(FormDetailCustomer);
