import React, { Component } from 'react'

export default class IconClock extends Component {
    render() {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0.5C9.98912 0.5 11.8968 1.29018 13.3033 2.6967C14.7098 4.10322 15.5 6.01088 15.5 8C15.5 9.98912 14.7098 11.8968 13.3033 13.3033C11.8968 14.7098 9.98912 15.5 8 15.5C6.01088 15.5 4.10322 14.7098 2.6967 13.3033C1.29018 11.8968 0.5 9.98912 0.5 8C0.5 6.01088 1.29018 4.10322 2.6967 2.6967C4.10322 1.29018 6.01088 0.5 8 0.5ZM8 2C6.4087 2 4.88258 2.63214 3.75736 3.75736C2.63214 4.88258 2 6.4087 2 8C2 9.5913 2.63214 11.1174 3.75736 12.2426C4.88258 13.3679 6.4087 14 8 14C9.5913 14 11.1174 13.3679 12.2426 12.2426C13.3679 11.1174 14 9.5913 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2ZM7.25 4.25C7.42554 4.24994 7.59554 4.31146 7.73039 4.42383C7.86524 4.53621 7.9564 4.69233 7.988 4.865L8 5V8H10.25C10.4374 7.99965 10.6182 8.06949 10.7567 8.19576C10.8952 8.32203 10.9814 8.49558 10.9983 8.68223C11.0152 8.86889 10.9617 9.05511 10.8482 9.20425C10.7347 9.35338 10.5694 9.45461 10.385 9.488L10.25 9.5H7.25C7.07446 9.50006 6.90446 9.43854 6.76961 9.32617C6.63476 9.21379 6.5436 9.05767 6.512 8.885L6.5 8.75V5C6.5 4.80109 6.57902 4.61032 6.71967 4.46967C6.86032 4.32902 7.05109 4.25 7.25 4.25Z" />
            </svg>

        )
    }
}
