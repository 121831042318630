import React, { Component, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Routes from './routes';
import Loading from './pages/Loading';
import _ from 'lodash';

class AppRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullscreen: false
        }
    }

    getRoute = () => {
        const { menuId } = this.props;
        return Routes.map((route, index) => {
            const { id, component: BaseComponent, exact } = route;
            return (
                <Route
                    key={id}
                    exact={exact}
                    path={id}
                    render={(props) => {
                        return (
                            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
                                className="body-full-screen">
                                <BaseComponent {...props} menuId={menuId} />
                            </div>
                        )
                    }}
                    breadcrumb=''
                />
            );
        });
    }

    render() {
        const { headerMenu } = this.props;
        return (
            <Suspense fallback={<Loading />}>
                <Switch>
                    <Redirect from="/" to={_.get(headerMenu, '0.route', "/")} exact />
                    {this.getRoute()}
                </Switch>
            </Suspense>
        );
    }
}

export default AppRoute;