import React, { Component } from 'react'

export default class IconInfo extends Component {
    render() {
        return (
            <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.04167 4.83333C8.04167 5.05435 7.95387 5.26631 7.79759 5.42259C7.64131 5.57887 7.42935 5.66667 7.20833 5.66667C6.98732 5.66667 6.77536 5.57887 6.61908 5.42259C6.4628 5.26631 6.375 5.05435 6.375 4.83333C6.375 4.61232 6.4628 4.40036 6.61908 4.24408C6.77536 4.0878 6.98732 4 7.20833 4C7.42935 4 7.64131 4.0878 7.79759 4.24408C7.95387 4.40036 8.04167 4.61232 8.04167 4.83333Z" fill="#026EAA" />
                <path d="M6.58325 7.125V11.2917C6.58325 11.4574 6.6491 11.6164 6.76631 11.7336C6.88352 11.8508 7.04249 11.9167 7.20825 11.9167C7.37401 11.9167 7.53298 11.8508 7.65019 11.7336C7.7674 11.6164 7.83325 11.4574 7.83325 11.2917V7.125C7.83325 6.95924 7.7674 6.80027 7.65019 6.68306C7.53298 6.56585 7.37401 6.5 7.20825 6.5C7.04249 6.5 6.88352 6.56585 6.76631 6.68306C6.6491 6.80027 6.58325 6.95924 6.58325 7.125Z" fill="#026EAA" />
                <path d="M0.333252 2.75002C0.333252 2.19749 0.552745 1.66758 0.943446 1.27688C1.33415 0.88618 1.86405 0.666687 2.41659 0.666687H11.9999C12.2735 0.666687 12.5444 0.720574 12.7972 0.825271C13.0499 0.929969 13.2796 1.08343 13.4731 1.27688C13.6665 1.47034 13.82 1.7 13.9247 1.95276C14.0294 2.20552 14.0833 2.47643 14.0833 2.75002V14.625C14.0833 14.7908 14.0174 14.9498 13.9002 15.067C13.783 15.1842 13.624 15.25 13.4583 15.25H1.58325C1.58325 15.471 1.67105 15.683 1.82733 15.8393C1.98361 15.9956 2.19557 16.0834 2.41659 16.0834H13.4583C13.624 16.0834 13.783 16.1492 13.9002 16.2664C14.0174 16.3836 14.0833 16.5426 14.0833 16.7084C14.0833 16.8741 14.0174 17.0331 13.9002 17.1503C13.783 17.2675 13.624 17.3334 13.4583 17.3334H2.41659C1.86405 17.3334 1.33415 17.1139 0.943446 16.7232C0.552745 16.3325 0.333252 15.8026 0.333252 15.25V2.75002ZM12.8333 14V2.75002C12.8333 2.52901 12.7455 2.31705 12.5892 2.16076C12.4329 2.00448 12.2209 1.91669 11.9999 1.91669H2.41659C2.19557 1.91669 1.98361 2.00448 1.82733 2.16076C1.67105 2.31705 1.58325 2.52901 1.58325 2.75002V14H12.8333Z" fill="#026EAA" />
            </svg>

        )
    }
}
