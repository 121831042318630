import React from 'react';
import Result from '@idtek/component/lib/result/Result';

const NotPermission = (props) => {
    const { text, route,
        status, subTitle, title,
        hideBack } = props;
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            flexDirection: 'column',
            zIndex: 2,
            backgroundColor: '#fff'
        }}>
            <Result
                status={status || "403"}
                title={title || "Access Denied"}
                subTitle={subTitle || "Sorry, but you don't have permission to access this page."}
                extra={hideBack ? null : <div style={{
                    display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <a
                        className="ant-btn ant-btn-primary"
                        href={`/dashboard`}
                        style={{
                            fontSize: 16, display: 'flex', alignItems: 'center',
                        }}>
                        {text || "Go to homepage"}
                    </a>
                </div>}
            />
        </div>
    );
};

export default NotPermission;