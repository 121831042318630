import Checkbox from '@idtek/component/lib/checkbox/Checkbox'
import Dropdown from '@idtek/component/lib/dropdown/Dropdown'
import Icon from '@idtek/component/lib/icon/Icon'
import Menu from '@idtek/component/lib/menu/Menu'
import Modal from '@idtek/component/lib/modal/Modal'
import Textbox from '@idtek/component/lib/textbox/Textbox'
import _ from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import Overlay from '../../../common/component/Overlay'
import AppUtil from '../../../utils/AppUtil'
import ReactDatePickerCustom from '../../booking/component/ReactDatePickerCustom'
import FormBookingNew from '../../booking/form/FormBookingNew'
import '../style/new-booking-drawer-cpn.scss'
import ItemBooking from './ItemBooking'
import { Empty } from 'antd';
export default class ComponentDrawerBooking extends Component {
    constructor(props) {
        super(props)
        this.state = {
            listBooking: [],
            visible: false,
            cbxName: {
                enable: false,
                typeSort: ''
            },
            cbxDate: {
                enable: false,
                typeSort: ''
            },
        }
        this.filterDate = moment().format('YYYY-MM-DD');
        this.api = "/booking/"
        this.offset = 0
        this.limit = 15
        this.isChangeData = false;
    }

    componentDidMount() {
        this.loadData()
    }

    async loadData(key, sort) {
        this.refOverlay && this.refOverlay.open(<span>Please waiting...</span>);
        let url = "/booking/"
        const params = {
            filter: JSON.stringify([{ "property": "status", "value": "new", "operator": "contains" }]),
        }
        url += "?filter=" + encodeURIComponent(params.filter)
        if (this.filterDate) {
            url += "&date=" + encodeURIComponent(this.filterDate)
        }
        if (key) {
            // params.key = key
            url += "&key=" + key
        }
        if (sort) {
            const parseString = JSON.stringify(sort)
            const searchParams = encodeURIComponent(parseString);
            url += `&sort=${searchParams}`
        }
        const res = await AppUtil.getApi(url, {})
        if (_.get(res, "data.success")) {
            this.setState({
                listBooking: _.get(res, "data.results"),
            })
            this.refOverlay && this.refOverlay.close();
        } else {
            AppUtil.ToastApiError()
            this.refOverlay && this.refOverlay.close();
        }
    }
    onChangeDate(key) {
        let date = moment().format('YYYY-MM-DD')
        switch (key) {
            case 'prev':
                date = moment(this.filterDate).add(-1, 'days').format('YYYY-MM-DD');
                break;
            case 'next':
                date = moment(this.filterDate).add(1, 'days').format('YYYY-MM-DD');
                break;
            case 'today':
                date = moment().format('YYYY-MM-DD');
                break;
            default:
                date = moment().format('YYYY-MM-DD');
                break;
        }
        this.filterDate = date;
        this.loadData();
    }

    onSearch = (val) => {
        this.loadData(val)
    }

    renderOverlay() {
        return <Overlay ref={component => this.refOverlay = component}
            style={{
                padding: 15,
                borderRadius: 3,
                zIndex: 9,
            }} />;
    }
    onChangeFilterDate(date) {
        if (moment(date).isValid()) {
            this.filterDate = moment(date).format('YYYY-MM-DD');
        } else {
            this.filterDate = null
        }
        this.loadData();

    }


    checkChangeData = () => {
        return this.isChangeData;
    };

    clickBooking = (bookingData) => {
        const booking = _.cloneDeep(bookingData)
        let title = "Update booking"
        if (booking) {
            title = `Update booking ${booking.code} - Status: ${booking.status}`
        }
        this.setState({
            active: `Booking_${booking.code}`
        })
        this.Modal.onOpen(<FormBookingNew
            ref={c => this.refNewBooking = c}
            onClose={() => {
                this.Modal.onClose();
            }}
            onReloadData={(booking, confirm, cancel) => {
                this.isChangeData = true
                if (confirm || cancel) {
                    this[`Booking_${booking.code}`] && this[`Booking_${booking.code}`].setNone()
                } else {
                    this[`Booking_${booking.code}`] && this[`Booking_${booking.code}`].setData(booking)
                }
            }}
            id={booking ? booking.id : null}
            record={booking}
        />, <span>
            <span style={{ position: 'relative', marginRight: 7, display: 'flex', alignItems: 'center' }}>
                <Icon type="form" style={{ fontSize: 16, display: 'flex', color: 'var(--main-color-blue-ocean)' }} />
                <span style={{ fontSize: 15, display: 'flex', marginLeft: 7 }}>
                    {title}
                </span>
            </span>
        </span>, '1000px');
    }

    renderListBooking() {
        const { listBooking } = this.state;
        if (_.size(listBooking) > 0) {
            return _.map(listBooking, (item, index) => {
                const ref = `Booking_${item.code}`
                let className = ""
                if (this.state.active === `Booking_${item.code}`) {
                    className = "active"
                }
                return <ItemBooking ref={c => this[ref] = c} item={item} className={className} clickBooking={this.clickBooking} />
            })
        } else {
            return <div className="global-flex-justify-align-center"><Empty /></div>
        }

    }

    renderModal() {
        return <Modal
            ref={component => {
                this.Modal = component
            }}
            onClose={this.onCloseDefault}
            maskClosable={false}
            className='popup-crud popup-container popup-method'
        />
    }

    onClickSort(key, notKey, e) {
        e.stopPropagation();
        if (this.state[key].enable) {
            this.setState({
                [key]: {
                    enable: false,
                    typeSort: this.state[key].typeSort + "asc"
                },

            })
        }
        else {
            this.setState({
                [key]: {
                    enable: true,
                    typeSort: "asc"
                },
                [notKey]: {
                    enable: false,
                    typeSort: ""
                },
            })
        }
    }

    onChangeSort(val, key, notKey, e) {
        if (val) {
            this.setState({
                [key]: {
                    enable: val,
                    typeSort: "asc"
                },
                [notKey]: {
                    enable: !val,
                    typeSort: ""
                },
            })
        }
        else {
            this.setState({
                [key]: {
                    enable: val,
                    typeSort: ""
                }
            })
        }
    }

    onChangeTypeSort(key, type) {
        this.setState({
            [key]: {
                enable: this.state[key].enable,
                typeSort: type
            }
        })
    }

    onResetSort() {
        this.setState({
            cbxName: {
                enable: false,
                typeSort: ''
            },
            cbxDate: {
                enable: false,
                typeSort: ''
            },
        })
        this.handleVisibleChange(false)
        this.loadData()
    }

    onOk() {
        const { cbxName, cbxDate } = this.state
        const sort = []
        if (cbxName.enable || cbxDate.enable) {
            if (cbxName.enable) {
                sort.push({ property: "customerName", direction: cbxName.typeSort.toUpperCase() })
            } else if (cbxDate.enable) {
                sort.push({ property: "startTime", direction: cbxDate.typeSort.toUpperCase() })
            }
            this.handleVisibleChange(false)
            this.loadData(null, sort)
        } else {
            this.handleVisibleChange(false)
            this.loadData()
        }

    }

    getItem = () => {
        const { cbxName, cbxDate } = this.state
        let classReset = "disabled"
        let classCustomer = ""
        let classDate = ""

        if (cbxName.enable || cbxDate.enable) {
            classReset = "enable"
        }
        if (cbxName.enable) {
            classCustomer = "selected"
        }
        if (cbxDate.enable) {
            classDate = "selected"
        }
        return <Menu>
            <Menu.Item className={classCustomer}>
                <div className="global-flex-align-center menu-item">
                    <div style={{ flex: 1, display: 'flex', alignItems: 'center' }} >
                        <Checkbox value={cbxName.enable}
                            onChange={(value, e) => this.onChangeSort(value, 'cbxName', 'cbxDate', e)}
                            className="custom-cbx"
                            ref={component => this.checkBoxName = component} />
                        <div onClick={(e) => this.onClickSort('cbxName', 'cbxDate', e)} className="label">Customer name</div>
                    </div>
                    <div className={`sort-arrow default ${cbxName.typeSort}`} >
                        <Icon type="caret-up" onClick={() => {
                            if (cbxName.enable) {
                                this.onChangeTypeSort('cbxName', 'asc')
                            }
                        }} />
                        <Icon type="caret-down" onClick={() => {
                            if (cbxName.enable) {
                                this.onChangeTypeSort('cbxName', 'desc')
                            }
                        }} />
                    </div>
                </div>
            </Menu.Item>
            <Menu.Item className={classDate}>
                <div className="global-flex-align-center menu-item" >
                    <div style={{ flex: 1, display: 'flex', alignItems: 'center' }} >
                        <Checkbox value={cbxDate.enable}
                            onChange={(value) => this.onChangeSort(value, 'cbxDate', 'cbxName')}
                            className="custom-cbx"
                            ref={component => this.checkBoxDate = component} />
                        <div onClick={(e) => this.onClickSort('cbxDate', 'cbxName', e)} className="label">Start time</div>
                    </div>

                    <div className={`sort-arrow default ${cbxDate.typeSort}`} >
                        <Icon type="caret-up" onClick={() => {
                            if (cbxDate.enable) {
                                this.onChangeTypeSort('cbxDate', 'asc')
                            }
                        }} />
                        <Icon type="caret-down" onClick={() => {
                            if (cbxDate.enable) {
                                this.onChangeTypeSort('cbxDate', 'desc')
                            }
                        }} />
                    </div>
                </div>
            </Menu.Item>
            <Menu.Item className="item-bottom">
                <div className="action-sort">
                    <div style={{ flex: 1 }}>
                        <div className={`button-rs ${classReset}`} onClick={() => {
                            if (classReset === "enable") {
                                this.onResetSort()
                            }
                        }}>
                            Reset
                        </div>
                    </div>
                    <div className="button-ok" onClick={() => this.onOk()}>
                        OK
                    </div>
                </div>
            </Menu.Item>
        </Menu>;
    };

    handleVisibleChange = (visible) => {
        this.setState({ visible });
    }

    render() {
        return (
            <div className="new-booking-drawer-cpn">
                <div className="search">
                    <Textbox ref={c => this.search = c}
                        inputStyle={{ textAlign: 'left' }}
                        readOnly={this.readOnly}
                        isClearable={true}
                        visibleIcon={true}
                        iconLine={false}
                        placeholder="Search..."
                        className="search-box"
                        inputCls="input-search-box"
                        icon={<Icon type="search" />}
                        onChange={_.debounce(this.onSearch, 300)} />
                </div>
                <div className="filter">
                    <span className="date">
                        Date
                    </span>
                    <div className="today" >
                        <button onClick={() => this.onChangeDate('today')}
                            className="btn-date-filer today-date">TODAY
                        </button>
                    </div>
                    <div className="date-cpn-filter">
                        <Icon type="left" className="prev" onClick={() => this.onChangeDate('prev')} />
                        <ReactDatePickerCustom
                            ref={ref => this.dateFilter = ref}
                            value={this.filterDate}
                            onChange={this.onChangeFilterDate.bind(this)}
                            dateFormat={"MMM/dd/yyyy"}
                            isClearable={true}
                        />
                        <Icon type="right" className="next" onClick={() => this.onChangeDate('next')} />
                    </div>
                </div>
                <div className="sort">
                    <Dropdown
                        visible={this.state.visible}
                        onVisibleChange={this.handleVisibleChange}
                        overlay={this.getItem()}
                        trigger={['click']} placement="bottomRight">
                        <div className="sort-icon">
                            <i class="fa fa-sort-amount-asc" aria-hidden="true"></i>
                        </div>
                    </Dropdown>
                </div>
                <div className="list-bookings-new">
                    {
                        this.renderListBooking()

                    }
                </div>
                {this.renderOverlay()}
                {this.renderModal()}
            </div>
        )
    }
}
