export const CUSTOMER_LEVEL = [
    {
        value: 1,
        label: 'Bronze'
    }, {
        value: 2,
        label: 'Silver'
    }, {
        value: 3,
        label: 'Gold'
    }, {
        value: 4,
        label: 'Diamond'
    }
]