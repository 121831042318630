import Container from "@idtek/component/lib/container/Container";
import Layout from "@idtek/component/lib/layout/Layout";
import BaseStore from "@idtek/grid/lib/stores/BaseStore";
import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AppStore from "../../store";
import AppUtil from "../../utils/AppUtil";
import TreeUtil from "../../utils/TreeUtil";
import { changeIsPermisstion } from "../actions/action";
import { onChangeSelects } from "../actions/menuAction";
import AppMenu from "./AppMenu";

const { Sider } = Layout;

class SideMenu extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: localStorage.getItem("collapsedSideMenu") === "true",
        };
        this.store = new BaseStore({ data: [] });
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        const prevLocation = prevProps.location.pathname;
        const nextLocation = this.props.location.pathname;
        if (nextLocation !== prevLocation) {
            return this.onRouteChanged(nextLocation, prevLocation);
        }
        if (nextLocation === "/trang-chu") {
            localStorage.setItem("collapsedSideMenu", "true");
            this.setState({
                collapsed: true,
                isHomePage: true,
            });
        } else {
            this.setState({
                isHomePage: false,
            });
        }
    }

    //Add sort
    static buildMenuTree = (items) => {
        let dataMenu = TreeUtil.buildTreeFromFlatData(items, "id", "parentId", "children");
        TreeUtil.forEachTreeFromLeafToNode({ children: dataMenu }, "children", (node) => {
            if (_.isEmpty(node.children)) {
                node.leaf = true;
                delete node.children;
            } else {
                node.leaf = false;
                node.expanded = true;
            }
        });
        return dataMenu;
    };

    static getSideMenus = (selectKey, menu) => {
        const routeModel = SideMenu.findRoute(selectKey, menu);
        if (routeModel) {
            const inDomainItems = AppUtil.DataUtil.filter(menu, (item) => {
                return item.root === routeModel.root;
            });
            return inDomainItems;
        }
        return null;
    };

    static findRoute = (route, list) => {
        return AppUtil.DataUtil.find(list, (item) => {
            return item.route === route;
        });
    };

    onRouteChanged(nextLocation, prevLocation, allItems = []) {
        if (allItems.length === 0) {
            allItems = this.props.allMenuItems;
        }
        const a = _.concat(allItems, this.props.headerMenu);
        const routeModel = AppUtil.DataUtil.find(a, (item) => {
            return item.route === nextLocation;
        });
        // if not find model then do nothing
        if (routeModel || nextLocation === "/trang-chu") {
            AppStore.dispatch(onChangeSelects([nextLocation]));
            AppStore.dispatch(changeIsPermisstion(true));
            //
        } else {
            AppStore.dispatch(changeIsPermisstion(false));
        }
        //
    }

    static customSort(tree) {
        if (tree.children) {
            tree.children = _.sortBy(tree.children, (data) => {
                return data.localIndex;
            });
            tree.children = _.forEach(tree.children, (data) => {
                return this.customSort(data);
            });
            return tree.children;
        }
        if (_.isArray(tree)) {
            tree = _.sortBy(tree, (data) => {
                return data.localIndex;
            });
            tree = _.forEach(tree, (data) => {
                return this.customSort(data);
            });
            return tree;
        }
        return tree;
    }

    render() {
        const { collapsed } = this.state;
        const { isMobile, showSideMenuDesktop } = this.props;
        return (
            <Sider
                trigger={null}
                collapsed={!showSideMenuDesktop}
                width={isMobile ? 150 : 260}
                collapsedWidth={0}
                className="side-menu-page custom-menu-page"
                style={{
                    position: "relative",
                }}
            >
                <div
                    style={{
                        overflow: "auto",
                        height: "calc(100vh - 72px)",
                    }}
                >
                    <Container ref={(component) => (this.Container = component)} style={{ height: "100%" }}>
                        <AppMenu collapsed={collapsed} />
                    </Container>
                </div>
            </Sider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...(state.root || {}),
    };
};

export default withRouter(connect(mapStateToProps)(SideMenu));
