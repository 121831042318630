import { Icon } from "@idtek/component/lib";
import Dropdown from "@idtek/component/lib/dropdown/Dropdown";
import Menu from "@idtek/component/lib/menu/Menu";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { STATUS_COMPLETE } from "../dashboard/constant/BookingConstant";
import Scheduler from "./Scheduler";

export default class SchedulerMobile extends Scheduler {
    constructor(props) {
        super(props);
        this.state = {};
        this.height = "calc(100vh - 170px)";
        this.classMobile = "main-schedule-mobile";
    }
}
