export const ROUTE_CHANGED = "ROOT::ROUTE_CHANGED";
export const TOGGLE_MENU_DESKTOP = "ROOT::TOGGLE_MENU_DESKTOP";
export const LOG_OUT = "ROOT::LOG_OUT";
export const LOG_OUT_SUCCESSFUL = "ROOT::LOG_OUT_SUCCESSFUL";
export const LOG_IN = "ROOT::LOG_IN";
export const LOG_IN_SUCCESSFUL = "ROOT::LOG_INT_SUCCESSFUL";
export const CHANGE_DIALOG = "ROOT::CHANGE_DIALOG";
export const CHANGE_BREADCRUMB = "ROOT::CHANGE_BREADCRUMB";
export const CHANGE_SIDEMENU = "ROOT::CHANGE_SIDEMENU";
export const CHANGE_SIDEMENU_SUCCESS = "ROOT::CHANGE_SIDEMENU_SUCCESS";
export const SET_HEADER_MENU = "ROOT::SET_HEADER_MENU";
export const SHOW_SIDE_BAR_MENU = "ROOT::SHOW_SIDE_BAR_MENU";
export const SET_HEADER_FILTER = "ROOT::SET_HEADER_FILTER";

export const CHANGE_PTW_RIGHT = "ROOT::CHANGE_PTW_RIGHTS";
export const CHANGE_USER_CREATED_BY = "ROOT::CHANGE_USER_CREATED_BY";
export const CHANGE_GST = "ROOT::CHANGE_GST";

export const CHANGE_HEADER_PICKERS = "ROOT::CHANGE_HEADER_PICKERS";

export const CHANGE_IS_PERMISSTION = "ROOT::CHANGE_IS_PERMISSTION";

export const LOAD_TECHNICIAN_WORKING_SCHEDULE = "ROOT::LOAD_TECHNICIAN_WORKING_SCHEDULE";
export const LOAD_TASK_SCHEDULE = "ROOT::LOAD_TASK_SCHEDULE";
export const LOAD_CONFIG_DASHBOARD_V2 = "ROOT::LOAD_CONFIG_DASHBOARD_V2";

export const HEADER_HEIGHT = 56;
export const FOOTER_HEIGHT = 32;
export const MENU_DESKTOP_WIDTH = 280;
export const MENU_MOBILE_WIDTH = 280;
export const MENU_MICRO_WIDTH = 56;
export const CONTAINER_PADDING = 10;

export const LIMIT = 10000;
export const LOAD_COMBOBOX_DATA_BOOKING_FORM = "SELECT_SEVICES::LOAD_COMBOBOX_DATA_BOOKING_FORM";
export const LOAD_COMBOBOX_PRIORITY_TECHNICIAN = "SELECT_SEVICES::LOAD_COMBOBOX_PRIORITY_TECHNICIAN";
export const LOAD_TECHNICIAN_SKILL_STORE = "SELECT_SEVICES::LOAD_TECHNICIAN_SKILL_STORE";
export const LOAD_BOOKING_STATUS = "SELECT_SEVICES::LOAD_BOOKING_STATUS";
export const LOAD_CUSTOMER_STORE = "SELECT_SEVICES::LOAD_CUSTOMER_STORE";
export const LOAD_SERVICE_STORE = "SELECT_SEVICES::LOAD_SERVICE_STORE";
export const LOAD_ALL_SERVICE_STORE = "SELECT_SEVICES::LOAD_ALL_SERVICE_STORE";

export const SELECT_SERVICE = "SELECT_SEVICES::SELECT_SERVICE";
export const SELECT_MANY_SERVICE = "SELECT_SEVICES::SELECT_MANY_SERVICE";
export const SELECT_SERVICE_GROUP = "SELECT_SEVICES::SELECT_SERVICE_GROUP";

export const DESELECT_SERVICE = "SELECT_SEVICES::DESELECT_SERVICE";
export const DESELECT_MULTIPLE_SERVICES = "SELECT_SEVICES::DESELECT_MULTIPLE_SERVICES";
export const SELECT_MAIN_TECHNICIAN = "SELECT_SEVICES::SELECT_MAIN_TECHNICIAN";
export const UPDATE_SELECTED_SERVICES = "SELECT_SEVICES::UPDATE_SELECTED_SERVICES";
export const SET_SELECTED_SERVICESBY_BOOKING_ITEMS = "SELECT_SEVICES::SET_SELECTED_SERVICESBY_BOOKING_ITEMS";
export const UPDATE_EXTRA_SERVICES = "SELECT_SEVICES::UPDATE_EXTRA_SERVICES";
export const RESET_SERVICES = "SELECT_SEVICES::RESET_SERVICES";
export const UPDATE_BOOKING = "SELECT_SEVICES::UPDATE_BOOKING";
export const UPDATE_CUSTOMER = "SELECT_SEVICES::UPDATE_CUSTOMER";
export const UPDATE_BRAND_IN_AUTH_DATA = "SELECT_SEVICES::UPDATE_BRAND_IN_AUTH_DATA";

export const ADD_NULL_EXTRA_SERVICES = "SELECT_SEVICES::ADD_NULL_EXTRA_SERVICES";
export const NOT_RESET_STORE = "SELECT_SEVICES::NOT_RESET_STORE";

export const SELECT_DATA_BOOKING = "SELECT_BOOKING::SELECT_DATA_BOOKING";
