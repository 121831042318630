export const TYPE_RECEIVE_MONEY = 2;
export const TYPE_TAKE_MONEY = 3;
export const TYPE_VOUCHER = "TYPE_VOUCHER_CODE";
export const TYPE_LOYALTY_BOOKING_BASED = "BOOKING_BASED";
export const TYPE_LOYALTY_POINT_BASED = "POINT_BASED";
///posType
export const RECEIVE_MONEY = "RECEIVE MONEY";
export const TAKE_MONEY = "TAKE MONEY";

//status
export const STATUS_NEW = "NEW";
export const STATUS_WAITING = "Waiting";
export const STATUS_PROCESSING = "Processing";
export const STATUS_COMPLETE = "Completed";
export const STATUS_CANCEL = "Cancel";

export const STATUS_NOSHOW = "NoShow";
