import AppUtil from "./AppUtil";
import _ from "lodash";

export default class LogUtil {
    static createActionLog(domain, action, objectId, functionName) {
        return {
            domain: domain,
            action: action,
            objectId: objectId,
            functionName: functionName
        }
    }

    static writeActionLog = async (arrayLog) => {
        // if (!_.isArray(arrayLog)) {
        //     arrayLog = [arrayLog];
        // }
        // const url = AppUtil.GLOBAL_API_PATH + "/action-log/create-range";
        // let response;
        // try {
        //     response = await AppUtil.Axios.post(url, arrayLog);
        // } catch {
        // }
        // return response
    }
}