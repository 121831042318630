import Collapse from "@idtek/component/lib/collapse/Collapse";
import Confirm from "@idtek/component/lib/confirm/Confirm";
import Icon from "@idtek/component/lib/icon/Icon";
import Tooltip from "@idtek/component/lib/tooltip/Tooltip";
import IDField from "@idtek/form/lib/IDField";
import Form from "@idtek/form/lib/IDForm";
import RadioListField from "@idtek/form/lib/radiolist/RadioListField";
import TextField from "@idtek/form/lib/text/TextField";
import _ from "lodash";
import moment from "moment";
import React, {Component, Suspense} from "react";
import Loading from "../../../pages/Loading";
import AppUtil from "../../../utils/AppUtil";
import {OPTIONS_ICON} from "../../system/constant/IconPaymentMethodConstant";
import InputGiftComponent from "../component/InputGiftComponent";
import InputVoucherComponent from "../component/InputVoucherComponent";
import {
    TYPE_LOYALTY_BOOKING_BASED,
    TYPE_LOYALTY_POINT_BASED,
    TYPE_RECEIVE_MONEY,
    TYPE_TAKE_MONEY,
    TYPE_VOUCHER
} from "../constant/BookingConstant";
import IconInfo from "../icon/IconInfo";

import IconMoney from "../icon/IconMoney";
import BillComponent from "../../dashboard_premium/component/BillComponent";
import "../style/proceed-payment.scss";

export default class FormProceedPayment extends Component {
    constructor(props) {
        super(props);
        const firstMethod = _.head(props.paymentMethod);
        let subTotal = 0;
        const {booking} = props;
        if (booking) {
            const {posActions} = booking;
            _.each(booking.bookingItems, (x) => {
                subTotal += x.price;
            });
            _.each(booking.extraServices, (x) => {
                if (x.type !== "tip") {
                    subTotal += x.price;
                }
            });
            this.actionRecieved = _.find(posActions, (x) => x.posTypeId === TYPE_RECEIVE_MONEY);
            this.actionTake = _.find(posActions, (x) => x.posTypeId === TYPE_TAKE_MONEY);
            this.isCreate = _.size(posActions) > 0 ? false : true;
        }
        this.initialValues = {
            received: this.actionRecieved ? this.actionRecieved.value : "",
            return: this.actionTake ? this.actionTake.value : "",
            typeCheckout: this.actionRecieved ? this.actionRecieved.type : "CASH"
        };
        const bookingDiscounts = _.get(booking, "bookingDiscounts");
        const giftCardActions = _.get(booking, "giftCardActions");
        const giftInUse = giftCardActions ? giftCardActions[0] : {};
        const voucher = _.find(bookingDiscounts, (x) => x.type === TYPE_VOUCHER);
        const loyalty = _.find(bookingDiscounts, (x) => x.type === TYPE_LOYALTY_BOOKING_BASED);
        const loyaltyPointDiscount = _.find(bookingDiscounts, (x) => x.type === TYPE_LOYALTY_POINT_BASED);
        let loyaltyPointBaseInfo = null;
        if (_.get(booking, "discountCustomer.type") === TYPE_LOYALTY_POINT_BASED) {
            loyaltyPointBaseInfo = _.get(booking, "discountCustomer");
        }
        if (loyaltyPointDiscount) {
            this.initialValues.moneyPoint = Math.abs(loyaltyPointDiscount.value);
        }
        this.state = {
            booking: booking,
            selected: _.get(props, "booking.paymentMethod") ? _.get(props, "booking.paymentMethod") : firstMethod ? firstMethod.id : "",
            totalAmount: subTotal,
            flag: true,
            voucher,
            giftInUse,
            gift: giftInUse,
            loyaltyPointBaseInfo,
            loyalty: loyalty ? loyalty : loyaltyPointDiscount ? loyaltyPointDiscount : loyaltyPointBaseInfo,
            moneyPoint: 0,
            optionsCheckout: []
        };

        this.formDefaults = {
            alignLabel: "left",
            widthLabel: "130px",
            textAlign: "left"
        };
        this.model = {
            fields: {
                received: {
                    label: "Amount received",
                    required: true
                },
                return: {
                    label: "Amount back",
                    required: true
                },
                voucherCode: {
                    label: "Voucher code"
                },
                giftCode: {
                    label: "Gift code"
                },
                moneyPoint: {
                    label: "Money point"
                },
                typeCheckout: {
                    label: "Checkout payment"
                }
            }
        };
        this.onChangeReceived = _.debounce(this._onChangeReceived, 10);
    }

    componentDidMount() {
        this.loadOptions();
    }

    loadOptions = async () => {
        const res = await AppUtil.getApi("/global-setting/checkout-payment-method");
        if (_.get(res, "data.success")) {
            const data = _.get(res, "data.results.methods");
            this.setState({optionsCheckout: data});
        } else {
            AppUtil.ToastApiError();
        }
    };

    async selectPaymentMethod(bookingId, paymentMethodId) {
        const {voucher, gift, loyalty, moneyPoint} = this.state;
        const data = {bookingId: bookingId, paymentMethodId: paymentMethodId};
        if (voucher) {
            data.voucherCode = voucher.code;
        }
        if (gift) {
            data.giftCode = gift.code;
        }
        if (loyalty && loyalty.type === TYPE_LOYALTY_POINT_BASED) {
            data.moneyPoint = parseFloat(moneyPoint);
        }
        const response = await AppUtil.postApi("/booking/select-payment-method", data);
        return response;
    }

    async getTemplateBill(id) {
        const response = await AppUtil.getApi("/booking/get-bill/" + id);
        return response;
    }

    async onFinish(form, values, printBill = false) {
        form && form.mask();
        const {booking} = this.state;
        const receive = {
            booking: booking.id,
            posType: TYPE_RECEIVE_MONEY,
            status: 1,
            value: _.get(values, "received") ? parseFloat(_.get(values, "received")) : 0,
            actionTime: moment(),
            note: "",
            branch: this.props.branch,
            type: values.typeCheckout
        };
        const take = {
            booking: booking.id,
            posType: TYPE_TAKE_MONEY,
            status: 1,
            value: _.get(values, "return") ? parseFloat(_.get(values, "return")) : 0,
            actionTime: moment(),
            note: "",
            branch: this.props.branch,
            type: values.typeCheckout
        };

        let urlRecieve = `/pos-action/create`;
        let urlTake = `/pos-action/create`;
        if (!this.isCreate) {
            urlRecieve = `/pos-action/update/${this.actionRecieved.id}`;
            urlTake = `/pos-action/update/${this.actionTake.id}`;
        }

        const fetchs = [AppUtil.postApi(urlRecieve, receive), AppUtil.postApi(urlTake, take)];
        await AppUtil.Axios.all(fetchs)
            .then(async ([resRecive, resTake]) => {
                if (_.get(resRecive, "data.success") && _.get(resTake, "data.success")) {
                    const choosePayment = await this.selectPaymentMethod(booking.id, this.state.selected);
                    if (_.get(choosePayment, "data.success")) {
                        AppUtil.ToastCreateSuccess("Success!");
                        if (printBill) {
                            this.BillComponent && this.BillComponent.onChangBill(_.get(choosePayment, "data.template"));
                        }
                    } else {
                        AppUtil.ToastApiError();
                    }
                }
                this.props.onClose && this.props.onClose();
                this.props.onReloadData && this.props.onReloadData();
            })
            .catch((error) => {
                AppUtil.ToastApiError();
            })
            .finally(() => {
                form && form.unmask();
            });
    }

    onConfirm = (id) => {
        this.onSelectMethod(id);
        this.setState({
            flag: false
        });
    };

    onSelectMethod(id, icon) {
        const {selected} = this.state;
        const dataForm = this.form && this.form.getValues();
        if (!selected || selected !== id) {
            this.setState({
                selected: id
            });
        }
        this.onChangeReceived(_.get(dataForm, "received"));
    }

    _onChangeReceived(val) {
        const obj = this.calculateMoney();
        const dataForm = this.form && this.form.getValues();
        let maxReturn = this.state.maxReturn;
        if (val) {
            const finallyValue = obj.valueFinally > 0 ? obj.valueFinally : 0;
            let returnValue = parseFloat(val) - finallyValue;
            if (returnValue >= 0) {
                this.form && this.form.setValueField("return", returnValue.toFixed(2));
                maxReturn = returnValue;
            } else {
                this.form && this.form.setValueField("return", "");
                this.form && this.form.setErrorField("received", "Not enough money");
                maxReturn = null;
            }
        } else {
            if (!_.get(this.initialValues, "return") && !_.get(this.initialValues, "received")) {
                if (obj.valueFinally <= 0) {
                    this.form && this.form.setValueField("received", 0);
                    this.form && this.form.setValueField("return", 0);
                    maxReturn = 0;
                } else {
                    if (dataForm.received || dataForm.received === 0) {
                        if (dataForm.received < obj.valueFinally) {
                            this.form && this.form.setErrorField("received", "Not enough money");
                            this.form && this.form.setValueField("return", "");
                            maxReturn = null;
                        } else {
                            const returnVl = dataForm.received - obj.valueFinally;
                            this.form && this.form.setValueField("return", returnVl.toFixed(2));
                            maxReturn = returnVl;
                        }
                    }
                }
            } else {
                const finallyValue = obj.valueFinally;
                const dataForm = this.form && this.form.getValues();
                let returnValue = parseFloat(dataForm.received) - finallyValue;
                if (returnValue >= 0) {
                    this.form && this.form.setValueField("return", returnValue.toFixed(2));
                    maxReturn = returnValue;
                } else {
                    this.form && this.form.setValueField("return", "");
                    this.form && this.form.setErrorField("received", "Not enough money");
                    maxReturn = null;
                }
            }
        }
        if (dataForm && dataForm.typeCheckout !== "CASH") {
            this.form && this.form.setValueField("received", obj.valueRowTotalBill);
            this.form && this.form.setValueField("return", 0);
            maxReturn = 0;
        }

        this.setState({maxReturn});
    }

    calculateMoneyWithVoucher(restValueAfterApplyHST) {
        const {voucher} = this.state;
        let valueRowVoucher = 0;
        let textRowVoucher = "";
        let restValueAfterApplyVoucher = restValueAfterApplyHST;
        if (voucher) {
            if (voucher.value && voucher.value !== 0) {
                valueRowVoucher = Math.abs(voucher.value);
                restValueAfterApplyVoucher = parseFloat(restValueAfterApplyHST) - parseFloat(valueRowVoucher);
                textRowVoucher = voucher.value + "$";
            } else if (voucher.percent && voucher.percent !== 0) {
                valueRowVoucher = (parseFloat(restValueAfterApplyHST) * Math.abs(voucher.percent)) / 100;
                textRowVoucher = voucher.percent + "%";
                restValueAfterApplyVoucher = parseFloat(restValueAfterApplyHST) - valueRowVoucher;
            }
        }

        return {
            valueRowVoucher: parseFloat(valueRowVoucher),
            textRowVoucher,
            restValueAfterApplyVoucher
        };
    }

    calculateMoneyLoyalty(valueRowTotalAmount) {
        const {loyalty, moneyPoint} = this.state;
        let textRowLoyalty = "";
        let valueRowLoyalty = 0;
        if (loyalty) {
            if (loyalty.type === TYPE_LOYALTY_BOOKING_BASED) {
                const times = _.get(loyalty, "content") ? parseInt(loyalty.content) + 1 : 0;
                let title = "";
                switch (times) {
                    case 1:
                        title = "1st";
                        break;
                    case 2:
                        title = "2nd";
                        break;
                    case 3:
                        title = "3rd";
                        break;
                    default:
                        title = times + "th";
                }
                if (loyalty.value !== 0) {
                    valueRowLoyalty = Math.abs(loyalty.value);
                    textRowLoyalty = `${title} booking -${Math.abs(loyalty.value)}$`;
                } else if (loyalty.percent !== 0) {
                    valueRowLoyalty = (parseFloat(valueRowTotalAmount) * Math.abs(loyalty.percent)) / 100;
                    textRowLoyalty = `${title} booking -${Math.abs(loyalty.percent)}%`;
                }
            } else if (loyalty.type === TYPE_LOYALTY_POINT_BASED && Math.abs(loyalty.value) > 0) {
                valueRowLoyalty = loyalty.id ? Math.abs(loyalty.value) : moneyPoint;
            }
        }
        return {textRowLoyalty, valueRowLoyalty: parseFloat(valueRowLoyalty)};
    }

    calculateMoneyWithGift(valueRowTotalAmount) {
        const {gift, booking, giftInUse} = this.state;
        const textRowGift = "Pay by gift card";
        let valueRowGift = 0;
        const valueGift = giftInUse ? giftInUse.value : gift ? gift.total : 0;
        const giftCardTotal = _.get(booking, "giftCardTotal") ? booking.giftCardTotal : 0;
        if (gift) {
            if (valueRowTotalAmount <= valueGift + giftCardTotal) {
                valueRowGift = valueRowTotalAmount;
            } else {
                valueRowGift = valueGift + giftCardTotal;
            }
        }
        return {
            textRowGift,
            valueRowGift: parseFloat(valueRowGift)
        };
    }

    onChangeMoneyPoint = (val) => {
        this.setState({
            moneyPoint: val ? val : 0
        });
        this.onChangeReceived();
    };

    calculateMoney() {
        const {selected, totalAmount} = this.state;
        const {thueVAT} = this.props;
        //row payment
        let selectObj = _.find(this.props.paymentMethod, (x) => x.id === selected);
        let titleRowPM = "";
        let valueRowPM = 0;
        if (selectObj) {
            if (selectObj.fee !== 0) {
                titleRowPM = selectObj.name + " " + selectObj.fee + "$";
                valueRowPM = parseFloat(selectObj.fee);
            } else if (selectObj.percentFee !== 0) {
                titleRowPM = selectObj.name + " " + selectObj.percentFee + "%";
                valueRowPM = (parseFloat(totalAmount) * parseFloat(selectObj.percentFee)) / 100;
            } else if (selectObj.fee === 0 && selectObj.percentFee === 0) {
                titleRowPM = selectObj.name;
                valueRowPM = 0;
            }
        }
        const valueAfterApplyPM = parseFloat(valueRowPM) + totalAmount;
        //row thue
        let valueRowHST = _.get(selectObj, "hst") ? (parseFloat(valueAfterApplyPM) * parseFloat(thueVAT)) / 100 : 0;
        if (valueRowHST < 0) {
            valueRowHST = 0;
        }
        // row total amount
        let valueRowTotalAmount = parseFloat(valueRowHST) + parseFloat(valueAfterApplyPM);
        if (valueRowTotalAmount < 0) {
            valueRowTotalAmount = 0;
        }
        /// calculate voucher
        const {
            restValueAfterApplyVoucher,
            valueRowVoucher,
            textRowVoucher
        } = this.calculateMoneyWithVoucher(valueRowTotalAmount);

        /// loyalty
        const {valueRowLoyalty, textRowLoyalty} = this.calculateMoneyLoyalty(restValueAfterApplyVoucher);
        ///get  gift value
        const {
            valueRowGift,
            textRowGift
        } = this.calculateMoneyWithGift(restValueAfterApplyVoucher - Math.abs(valueRowLoyalty));
        /// total bill sau khi tru loyalty
        const valueRowTotalBill = parseFloat(restValueAfterApplyVoucher - valueRowLoyalty);
        /// so tien thực nhận
        let valueFinally = parseFloat(restValueAfterApplyVoucher - (valueRowGift + valueRowLoyalty));

        this.valueLimitMoneyPoint = parseFloat(restValueAfterApplyVoucher - valueRowGift);

        return {
            titleRowPM: titleRowPM,
            valueRowPM: valueRowPM,
            valueRowHST: valueRowHST,
            valueRowTotalAmount: valueRowTotalAmount,
            //voucher
            valueRowVoucher,
            textRowVoucher,
            //gift
            valueRowGift,
            textRowGift,
            //loyalty
            valueRowLoyalty,
            textRowLoyalty,
            //total billT
            valueRowTotalBill,
            // thực nhận
            valueFinally: valueFinally.toFixed(2)
        };
    }

    onChangeCode = (voucher) => {
        this.setState({
            voucher
        });
        this.onChangeReceived();
    };

    onChangeGift = (gift, setError) => {
        if (setError) {
            this.form && this.form.setErrorField("giftCode", "This code is invalid! Try again!");
        }
        this.setState({
            gift
        });
        this.onChangeReceived();
    };

    onCollapseChange = (collapse) => {
        const visible = !_.isEmpty(collapse) ? true : false;
        if (_.isEmpty(this.state.gift) && visible) {
            this.inputGift && this.inputGift.handleVisibleChange(true, true);
        } else {
            this.inputGift && this.inputGift.handleVisibleChange(false, true);
        }
    };

    onChangeCheckout = (code) => {
        const obj = this.calculateMoney();
        let maxReturn = this.state.maxReturn;
        if (code !== "CASH") {
            this.form && this.form.setValueField("received", obj.valueFinally);
            this.form && this.form.setValueField("return", 0);
            maxReturn = 0;
        } else {
            this.form && this.form.setValueField("received", "");
            this.form && this.form.setValueField("return", "");
            maxReturn = null;
        }
        this.setState({maxReturn});
    };

    renderRowVoucher() {
        const {voucher} = this.state;
        if (voucher) {
            const {valueRowVoucher, textRowVoucher} = this.calculateMoney();
            return (
                <div className="final-caculate row-voucher">
                    <div className="sub-total">
                        <div className="title-final">{`Discount ${textRowVoucher}`}</div>
                        <div className="value-final">$ -{valueRowVoucher.toFixed(2)}</div>
                    </div>
                </div>
            );
        }
    }

    renderRowGift() {
        const {gift} = this.state;
        if (gift) {
            const {valueRowGift, textRowGift} = this.calculateMoney();
            // theRest = parseFloat(gift.value) - parseFloat(valueRowGift)
            return (
                <div className="final-caculate row-gift">
                    <div className="sub-total">
                        <div className="title-final" style={{display: "flex", alignItems: "center"}}>
                            {`${textRowGift}`}
                            {/* {
                            theRest > 0 && <Tooltip title={`The rest of the gift card: $ ${theRest.toFixed(2)}`}>
                                <Icon className="icon-info" type="info-circle" />
                            </Tooltip>
                        } */}
                        </div>
                        <div className="value-final">$ -{valueRowGift.toFixed(2)}</div>
                    </div>
                </div>
            );
        }
    }

    capitalizeFirstLetter(string) {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
    }

    renderCalculate() {
        const obj = this.calculateMoney();
        const {titleRowPM, valueRowPM, valueRowHST, valueRowTotalAmount} = obj;
        const {thueVAT} = this.props;
        const title = this.capitalizeFirstLetter(titleRowPM && titleRowPM.toLowerCase());
        return (
            <div className="final-caculate">
                {/* <div className="sub-total">
                    <div className="title-final">HST {thueVAT}%</div>
                    <div className="value-final">$ {valueRowHST !== 0 || valueRowHST ? valueRowHST.toFixed(2) : 0}</div>
                </div> */}
                <div className="sub-total" style={{marginTop: 0}}>
                    <div className="title-final">{title}</div>
                    <div className="value-final">$ {valueRowPM !== 0 || valueRowPM ? valueRowPM.toFixed(2) : 0}</div>
                </div>
                <div className="sub-total">
                    <div className="title-final">HST {thueVAT}%</div>
                    <div className="value-final">$ {valueRowHST !== 0 || valueRowHST ? valueRowHST.toFixed(2) : 0}</div>
                </div>
                <div className="sub-total">
                    <div className="title-final">Total amount</div>
                    <div className="value-final" style={{color: "var(--main-color-red)"}}>
                        $ {valueRowTotalAmount !== 0 ? valueRowTotalAmount.toFixed(2) : 0}
                    </div>
                </div>
            </div>
        );
    }

    renderDiscountByLoyalty() {
        const {loyalty, loyaltyPointBaseInfo} = this.state;
        const {initialValues} = this;
        if (loyalty) {
            const {valueRowLoyalty, textRowLoyalty, valueFinally} = this.calculateMoney();
            const max = _.min([loyalty.value, this.valueLimitMoneyPoint > 0 ? this.valueLimitMoneyPoint.toFixed(2) : 0]);
            if (loyalty.type === TYPE_LOYALTY_BOOKING_BASED) {
                return (
                    <div className="final-caculate discount-loyalty">
                        <div className="sub-total" style={{paddingLeft: 10}}>
                            <div className="title-final">{textRowLoyalty}</div>
                            <div className="value-final">$ -{valueRowLoyalty.toFixed(2)}</div>
                        </div>
                    </div>
                );
            } else if (loyalty.type === TYPE_LOYALTY_POINT_BASED) {
                return (
                    <div className="money-point">
                        {loyaltyPointBaseInfo && <div
                            className="point-info">{`*Customer have $${_.get(loyaltyPointBaseInfo, "value")} accumulated money`}</div>}
                        <div className="text-field">
                            <TextField
                                ref={(c) => (this.moneyPoint = c)}
                                readOnly={initialValues.moneyPoint && initialValues.moneyPoint > 0 ? true : false}
                                onChange={_.debounce(this.onChangeMoneyPoint, 100)}
                                max={parseFloat(max)}
                                className="back-money"
                                name="moneyPoint"
                                type="money"
                                options={{
                                    numeralPositiveOnly: true
                                }}
                            />
                        </div>
                    </div>
                );
            }
        }
        return <></>;
    }

    // renderRowTotalBill() {
    //     const { valueRowTotalBill } = this.calculateMoney()
    //     return <div className="final-caculate" >
    //         <div className="sub-total last-sub-total">
    //             <div className="title-final">Total amount</div>
    //             <div className="value-final" style={{ color: 'var(--main-color-red)' }}>$ {valueRowTotalBill !== 0 ? valueRowTotalBill.toFixed(2) : 0}</div>
    //         </div>
    //     </div>
    // }

    renderRowTotalFinally() {
        const {loyalty, voucher, gift} = this.state;
        const {valueFinally} = this.calculateMoney();
        return (
            <div className="final-caculate discount-loyalty">
                <div className="sub-total total-in-cash">
                    <div className="title-final" style={{width: 130}}>
                        Receivable cash
                    </div>
                    <div className="value-final" style={{color: "var(--main-color-red)"}}>
                        $ {valueFinally}
                    </div>
                </div>
            </div>
        );
    }

    renderRowHST() {
        // const { thueVAT } = this.props;
        // const { valueRowHST, valueRowTotalAmount } = this.calculateMoney();
        // return (
        //     <>
        //         <span className="title-value">
        //             <span className="title-in-db">HST {thueVAT}%: </span>
        //             <span className="highlight-value">${valueRowHST !== 0 || valueRowHST ? valueRowHST.toFixed(2) : 0}</span>
        //         </span>
        //         <span className="title-value" style={{ borderTop: "1px dashed #bbb", paddingTop: 5, marginTop: 5 }}>
        //             <span className="title-in-db">Amount: </span>
        //             <span className="highlight-value">${valueRowTotalAmount ? valueRowTotalAmount.toFixed(2) : 0}</span>
        //         </span>
        //     </>
        // );
    }

    render() {
        const {model, formDefaults, initialValues} = this;
        const {booking, loading, gift, selected, totalAmount, voucher, loyalty} = this.state;
        const {user, paymentMethod} = this.props;
        let technicians = "";
        if (booking) {
            booking.bookingItems.forEach((item, indext) => {
                if (technicians.indexOf(item.technician_name) === -1) {
                    technicians = technicians + item.technician_name + (indext !== booking.bookingItems.length - 1 ? ", " : "");
                }
            });
        }

        if (loading) {
            return <Loading loadingHeight={400}/>;
        }
        const objCalculate = this.calculateMoney();
        const zeroValue = objCalculate && objCalculate.valueFinally <= 0 ? true : false;
        const {discountCustomer} = booking;
        return (
            <div className="proceed-payment-wrap">
                <Form
                    ref={(component) => (this.form = component)}
                    initialValues={initialValues}
                    model={model}
                    buttons={[
                        {
                            text: "Print bill",
                            type: "submit",
                            className: "btn-success",
                            icon: <Icon type="printer" style={{fontSize: 16}}/>,
                            style: {width: "150px"},
                            onPress: (form, values) => this.onFinish(form, values, true)
                        },
                        {
                            text: "Finish payment",
                            type: "submit",
                            className: "btn-primary-lighter",
                            style: {width: "150px"},
                            icon: <Icon type="check-square" style={{fontSize: 16}}/>,
                            onPress: (form, values) => this.onFinish(form, values)
                        }
                    ]}
                    defaults={formDefaults}>
                    <div className="proceed-payment">
                        <div className="container-left">
                            <div className="info-top">
                                <div>
                                    <span>Staff: </span>
                                    <span style={{marginLeft: 5}}>{user}</span>
                                </div>
                                <div>
                                    <span>Date: </span>
                                    <span
                                        style={{marginLeft: 5}}>{moment().format(AppUtil.GLOBAL_DATETIME_FORMAT)}</span>
                                </div>
                            </div>
                            <div className="info-bottom">
                                <div className="title">
                                    <IconInfo/> Information booking:
                                </div>
                                <span className="title-value">
                                    INFORMATION -
                                    <span className="highlight-value" style={{paddingLeft: 3}}>
                                        {" "}
                                        {booking.code}
                                    </span>
                                </span>
                                <span className="title-value">
                                    <span className="title-in-db">Status: </span>
                                    <span className={"schedule-status " + booking.status}>{booking.status}</span>
                                </span>
                                <span className="title-value">
                                    <span className="title-in-db">Name: </span>
                                    <span className="highlight-value">{booking.customerName}</span>
                                </span>
                                <span className="title-value">
                                    <span className="title-in-db">Phone: </span>
                                    <span>{AppUtil.formatPhone(booking.phone)}</span>
                                </span>
                                <Tooltip
                                    title={booking.bookingItems.map((item, indexx) => {
                                        return item.code + ", ";
                                    })}>
                                    <span className="title-value-services title-value">
                                        <span className="title-in-db">Services: </span>
                                        <span style={{flex: 1, overflow: "hidden", textOverflow: "ellipsis"}}>
                                            {booking.bookingItems.map((item, indext) => {
                                                return item.code + (indext !== booking.bookingItems.length - 1 ? ", " : "");
                                            })}
                                        </span>
                                    </span>
                                </Tooltip>
                                <Tooltip title={technicians}>
                                    <span className="title-value">
                                        <span className="title-in-db">Techn: </span>
                                        <span className="highlight-technician">{technicians}</span>
                                    </span>
                                </Tooltip>

                                {booking.description !== null ? (
                                    <Tooltip title={booking.description}>
                                        <span className="title-value">
                                            <span className="title-in-db">Note: </span>
                                            <span className="highlight-description">{booking.description}</span>
                                        </span>
                                    </Tooltip>
                                ) : null}
                                <span className="title-value">
                                    <span className="title-in-db">Subtotal: </span>
                                    <span className="highlight-value">${totalAmount}</span>
                                </span>
                                {/* {this.renderRowHST()} */}
                            </div>
                        </div>
                        <div className="container-right">
                            <div className="total">
                                <IconMoney/>
                                <span className="title">Proceed Payment </span>
                            </div>
                            <div className="children">
                                <div className="payment-method">
                                    <div className="title">Payment method:</div>
                                    <div className="methods">
                                        {_.map(paymentMethod, (item, index) => {
                                            const findIcon = _.find(OPTIONS_ICON, (x) => x.value === item.icon);
                                            const ComponentIcon = _.get(findIcon, "component");
                                            let classActice = "";
                                            let styles = {};
                                            if (item.id === selected) {
                                                if (findIcon.value === "COIN") {
                                                    styles = {
                                                        stroke: "#fff"
                                                    };
                                                } else {
                                                    styles = {fill: "#fff"};
                                                }
                                                classActice = "actived";
                                            }
                                            if (!this.isCreate && this.state.flag) {
                                                return (
                                                    <Confirm
                                                        masked={true}
                                                        placement="topLeft"
                                                        title={"Confirm"}
                                                        confirmText={"Are you sure you want to change payment method?! "}
                                                        onOk={() => this.onConfirm(item.id)}
                                                        okText="Yes"
                                                        cancelText="No"
                                                        className="confirm-component">
                                                        <div className={`item ${classActice}`}
                                                             ref={(c) => (this[`method_${item.id}`] = c)}>
                                                            <Suspense fallback={<></>}>
                                                                <ComponentIcon style={styles}
                                                                               ref={(c) => (this[`icon_${item.id}`] = c)}/>
                                                            </Suspense>
                                                            <div className="item-title">{_.get(item, "name")}</div>
                                                            <div
                                                                className="item-title">{_.get(item, "fee") ? `${item.fee}$` : _.get(item, "percentFee") ? `${item.percentFee}%` : ""}</div>
                                                        </div>
                                                    </Confirm>
                                                );
                                            }
                                            return (
                                                <div className={`item ${classActice}`}
                                                     onClick={() => this.onSelectMethod(item.id, findIcon)}
                                                     ref={(c) => (this[`method_${item.id}`] = c)}>
                                                    <Suspense fallback={<></>}>
                                                        <ComponentIcon style={styles}
                                                                       ref={(c) => (this[`icon_${item.id}`] = c)}/>
                                                    </Suspense>
                                                    <div className="item-title">{_.get(item, "name")}</div>
                                                    <div
                                                        className="item-title">{_.get(item, "fee") ? `${item.fee}$` : _.get(item, "percentFee") ? `${item.percentFee}%` : "0$"}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                {this.renderCalculate()}
                            </div>
                            <div className="voucher-field">
                                <Collapse
                                    ghost
                                    className="collapse-cpn"
                                    expandIconPosition="right"
                                    defaultActiveKey={_.get(booking, "codeVoucher") ? ["1"] : null}
                                    expandIcon={({isActive}) => <Icon type="caret-right"
                                                                      style={{fontSize: 16, color: "#026eaa"}}
                                                                      rotate={isActive ? 90 : 0}/>}>
                                    <Collapse.Panel
                                        header={
                                            <div className="total" style={{marginBottom: 0}}>
                                                <div className="icon-gift">
                                                    <i class="fa fa-credit-card" aria-hidden="true"></i>
                                                </div>
                                                <span className="title">Voucher </span>
                                            </div>
                                        }
                                        key="1">
                                        <div className="gift-input" style={{paddingBottom: 0}}>
                                            <IDField widthLabel={120} readOnly={zeroValue && !voucher}
                                                     disabled={zeroValue && !voucher} name="voucherCode"
                                                     onChange={this.onChangeCode}>
                                                <InputVoucherComponent customer={_.get(booking, "customer")}
                                                                       code={_.get(booking, "codeVoucher")}
                                                                       voucher={voucher}/>
                                            </IDField>
                                            {this.renderRowVoucher()}
                                        </div>
                                    </Collapse.Panel>
                                </Collapse>
                            </div>
                            {loyalty && (
                                <div className="total">
                                    <div className="icon-gift">
                                        <i class="fa fa-id-card" aria-hidden="true"></i>
                                    </div>
                                    <span className="title">Loyalty discount </span>
                                </div>
                            )}
                            {this.renderDiscountByLoyalty()}
                            {/* {this.renderRowTotalBill()} */}
                            <div className="gift-card-field" style={{marginTop: 5}}>
                                <Collapse
                                    ghost
                                    onChange={this.onCollapseChange}
                                    className="collapse-cpn"
                                    expandIconPosition="right"
                                    defaultActiveKey={_.get(gift, "code") ? ["1"] : null}
                                    expandIcon={({isActive}) => <Icon type="caret-right"
                                                                      style={{fontSize: 16, color: "#026eaa"}}
                                                                      rotate={isActive ? 90 : 0}/>}>
                                    <Collapse.Panel
                                        header={
                                            <div className="total" style={{marginBottom: 0}}>
                                                <div className="icon-gift">
                                                    <i class="fa fa-gift" aria-hidden="true"></i>
                                                </div>
                                                <span className="title">Gift card </span>
                                            </div>
                                        }
                                        key="1">
                                        <div className="gift-input">
                                            <IDField readOnly={zeroValue && !gift} disabled={zeroValue && !gift}
                                                     widthLabel={120} name="giftCode" onChange={this.onChangeGift}>
                                                <InputGiftComponent ref={(c) => (this.inputGift = c)} booking={booking}
                                                                    code={_.get(gift, "code")} gift={gift}/>
                                            </IDField>
                                            {this.renderRowGift()}
                                        </div>
                                    </Collapse.Panel>
                                </Collapse>
                            </div>
                            {this.renderRowTotalFinally()}
                            <div className="text-field">
                                <RadioListField
                                    labelKey="name"
                                    valueKey="code"
                                    viewMode={"horizontal"}
                                    options={this.state.optionsCheckout || []}
                                    className="receive-money-field"
                                    name="typeCheckout"
                                    onChange={this.onChangeCheckout}
                                />
                            </div>
                            <div className="text-field">
                                <TextField
                                    options={{
                                        numeralPositiveOnly: true
                                    }}
                                    className="back-money"
                                    name="received"
                                    type="money"
                                    onChange={(val) => this.onChangeReceived(val)}
                                />
                            </div>
                            <div className="text-field">
                                <TextField
                                    options={{
                                        numeralPositiveOnly: true
                                    }}
                                    className="back-money"
                                    name="return"
                                    type="money"
                                    readOnly={this.state.maxReturn === 0 || !this.state.maxReturn ? true : false}
                                    max={this.state.maxReturn}
                                />
                            </div>
                            {_.get(discountCustomer, "type") === "BOOKING_BASED" && _.get(discountCustomer, "count") > 0 && (
                                <div className="note">
                                    *Number of booking /
                                    Discount: {discountCustomer.count} / {discountCustomer.setting}{" "}
                                </div>
                            )}
                            {_.get(booking, "isDiscountNextTime") &&
                                <div className="note">*This customer will be discount for next order</div>}
                        </div>
                    </div>
                    <BillComponent ref={(c) => (this.BillComponent = c)}/>
                </Form>
            </div>
        );
    }
}
