import { LanguageDictionary, LanguageProvider } from "@idtek/component/lib/language";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import LayoutPage from "./application/components/Layout";
import "./ConfigGlobal";
import history from "./history";
import "./index.scss";
import AppStore from "./store";

class App extends React.Component {
    componentWillMount() {
        LanguageDictionary.setUserLanguage("en");
    }
    render() {
        return (
            <div className="App" style={{ height: "100%" }}>
                <LanguageProvider defaultLanguage={"en"}>
                    <Provider store={AppStore}>
                        <Router history={history}>
                            <Route path="/" component={LayoutPage} />
                        </Router>
                    </Provider>
                </LanguageProvider>
            </div>
        );
    }
}

export default App;
